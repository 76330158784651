import { Formik, Form, Field, FieldArray } from 'formik'
import Yup, { TextareaValidation } from '../../../validation'
import { ApiInputType, ApiGraphQLTypes, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { CityAutocomplete, citySelector } from '../../form-fields/CityAutocomplete'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from '../steps/position-information'
import { titleTextStyle } from './kind-of-people-information'
import { Box, Button, Theme, Divider, Typography, useMediaQuery } from '@mui/material'
import { Salary } from '../../form-fields/Salary'
import { useCreateOrUpdateSalary } from '../../../hooks/useCreateOrUpdateSalary'
import { authMutation } from '../../../services/api'
import Uploader, { assetSelector } from '../../Uploader/Uploader'
import { IMAGES } from '../../../config/uploaderTypes'
import { TextField } from 'formik-mui'
import theme from '../../../styles/theme'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

type CityFields = ApiInputType<ApiGraphQLTypes['City'], typeof citySelector>

export const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

export type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

const companySelector = ApiSelector('Company')({
  commissionAndIncentives: true,
  commissionAndIncentivesFiles: assetSelector,
})

type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>

interface Props extends Partial<SalaryFields> {
  isSaving: boolean
  hiringInCities?: CityFields[]
  companyId: string,
  commissionAndIncentives: CompanyFields['commissionAndIncentives'],
  commissionAndIncentivesFiles: CompanyFields['commissionAndIncentivesFiles'],
  currentStep?: number
  backStep: () => void
  onFileDeleted?: (id: string) => void
  onSave: (hiringInCities: CityFields[], 
      commissionAndIncentives: CompanyFields['commissionAndIncentives'],
      commissionAndIncentivesFiles: CompanyFields['commissionAndIncentivesFiles'], 
      data: SalaryFields) => void
}

export const HiringInLocations = ({ hiringInCities, companyId, currency, from, to, id, schedule, commissionAndIncentives, commissionAndIncentivesFiles, currentStep, backStep, isSaving, onSave, onFileDeleted }: Props) => {
  const { t } = useTranslation()

  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  // const initialValues : SetOptional<SetNullable<SalaryFields, 'currency'>, 'id' | 'currency'> = {
  const initialValues = {
    hiringInCities: hiringInCities || [],
    id,
    from: from || 0,
    to: to || 0,
    schedule: schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: currency || null,
    commissionAndIncentives: commissionAndIncentives || '',
    commissionAndIncentivesFiles: commissionAndIncentivesFiles || [],
  }

  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const data = await createOrUpdateSalaryMutation.mutateAsync(values as SalaryFields)

        await authMutation({
          updateCompany: [
            {
              id: companyId,
              input: {
                basicSalary: {
                  connect: {
                    id: data.id,
                  },
                },
              },
            },
            {
              id: true,
            },
          ],
        })

        await onSave(values.hiringInCities, values.commissionAndIncentives, values.commissionAndIncentivesFiles, data)
        setSubmitting(false)
      }}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <CityAutocomplete name='hiringInCities' label={t('formFields.locationPlaceholder')} multiple={true} />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Typography sx={titleTextStyle}>{t('formFields.companyBasicSalaryTitle')}</Typography>
              <Salary signUp/>
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Typography sx={[titleTextStyle, uploadTitle]}>{t('formFields.companyCommissionAndIncentives')}</Typography>

              <FieldArray
                name={'commissionAndIncentivesFiles'}
                render={(arrayHelpers) => (
                  <>
                    <Uploader
                      assets={values.commissionAndIncentivesFiles}
                      multiple
                      allowedFileTypes={IMAGES}
                      onFileUploaded={(asset) => arrayHelpers.push(asset)}
                      onFileDeleted={(asset) => {
                        const index = (values.commissionAndIncentivesFiles || []).findIndex(({ id }) => asset.id === id)
                        arrayHelpers.remove(index)
                      }}
                    />
                  </>
                )}
              />
              <br/>
              <Field
                component={TextField}
                name='commissionAndIncentives'
                helperText={errors.commissionAndIncentives}
                placeholder={t('formFields.companyCommissionAndIncentives')}
                fullWidth
                sx={{ height: 'auto' }}
                multiline
                rows={matchesSm ? 7 : 8}
              />
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={!values.hiringInCities.length || !values.commissionAndIncentives || Object.keys(errors).length > 0}
                >
                  {t('saveNext')}
                </LoadingButton>
              </Box>
            </Box>

          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  hiringInCities: Yup.array().min(1).required(),
  from: Yup.number().required(),
  to: Yup.number().required(),
  schedule: Yup.string().required(),
  currency: Yup.object().nullable().required(),
  commissionAndIncentives: TextareaValidation.required(),
})

const uploadTitle = (theme: Theme) => ({
  mb: 0,
})