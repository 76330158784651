export enum EJobsBoxTypes {
  LOCATION = 'LOCATION',
  SALARY = 'SALARY',
  VACANCY_CATEGORY = 'VACANCY_CATEGORY',
}

export interface IJobsBoxOption {
  label?: string
  type: EJobsBoxTypes
}

export interface IJobs {
  vacancyTitle?: string
  companyName?: string
  logo?: any
  createDate?: string
  description?: string
  options?: Array<IJobsBoxOption>
}
