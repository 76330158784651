import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import {
  Box,
  Card,
  Theme,
  Typography, useMediaQuery,
  Divider
} from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SectorsAndSpecializationsAutocomplete } from '../../form-fields/SectorsAndSpecializationsAutocomplete'
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

const sectoreSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})

const specializationeSelector = ApiSelector('Specialization')({
  id: true,
  name: true,
  sector: sectoreSelector,
})

type SectorFields = ApiInputType<ApiGraphQLTypes['Sector'], typeof sectoreSelector>
type SpecializationFields = ApiInputType<ApiGraphQLTypes['Specialization'], typeof specializationeSelector>

type SpecialiseInComponentFields = {
  specializations: SpecializationFields[]
}

interface Props extends SpecialiseInComponentFields {
  isSaves?: boolean
  onSave: (values: SpecialiseInComponentFields) => void
}

export const SpecialiseInComponent = ({ onSave, specializations, isSaves }: Props) => {
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const initialValues: { sectors: SectorFields[]; specializations: SpecializationFields[] } = {
    sectors: [],
    specializations: specializations || [],
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async ({ specializations }, { setSubmitting }) => {
        await onSave({ specializations })
        setSubmitting(false)
      }}
    >
      {({ errors }) => {
        return (
          <Form>
            <Box sx={wrapper}>
              <Box sx={headWrapper}>
                <Typography sx={(theme) => ({
                            marginBottom: theme.spacing(2.5)
                          })} variant={'h2'}>Specialises in</Typography>
                <Divider sx={(theme) => ({
                            marginBottom: theme.spacing(4)
                          })}/>
              </Box>

              <SectorsAndSpecializationsAutocomplete multiple allowAddNewSpecialization showTitles />

              <FooterButton disabled={Object.keys(errors).length !== 0} loading={isSaves} />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  sectors: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1),
  specializations: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  // gap: theme.spacing(2.5),
  height: 'auto',
})

const headWrapper = (theme: Theme) => ({
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'space-between',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
