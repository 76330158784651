import React from 'react'
import { SvgProps } from '../types/icon'

const Copy = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 16.668H4.16667V5.83464C4.16667 5.3763 3.79167 5.0013 3.33333 5.0013C2.875 5.0013 2.5 5.3763 2.5 5.83464V16.668C2.5 17.5846 3.25 18.3346 4.16667 18.3346H12.5C12.9583 18.3346 13.3333 17.9596 13.3333 17.5013C13.3333 17.043 12.9583 16.668 12.5 16.668ZM16.6667 13.3346V3.33464C16.6667 2.41797 15.9167 1.66797 15 1.66797H7.5C6.58333 1.66797 5.83333 2.41797 5.83333 3.33464V13.3346C5.83333 14.2513 6.58333 15.0013 7.5 15.0013H15C15.9167 15.0013 16.6667 14.2513 16.6667 13.3346ZM15 13.3346H7.5V3.33464H15V13.3346Z" fill="black" fillOpacity="0.54" />
      <path d="M15 13.3346H7.5V3.33464H15V13.3346Z" fill="black" fillOpacity="0.54" />
    </svg>

  )
}

export default Copy