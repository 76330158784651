import { Box, Card, Chip, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/dashboard-employee'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'

export const employeeCareerNarrativeSelector = ApiSelector('Employee')({
  jobTypes: {
    id: true,
    name: true,
  },
  jobCriterias: {
    id: true,
    name: true,
  },
  recruitRoles: true,
  reasonForLeavingRecentEmployer: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeCareerNarrativeSelector>

interface Props extends EmployeeFields {
  onClick?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditCareerNarrative = ({
  jobCriterias = [],
  jobTypes = [],
  reasonForLeavingRecentEmployer,
  recruitRoles,
  onClick,
  type,
}: Props) => {
  return (
    <Card sx={wrapper}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant={'h6'}>Career Narrative</Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onClick}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      <Box sx={contentWrapper}>
        <Typography variant={'body1'} sx={grayColorStyle}>
          Looking for:
        </Typography>
        <Box sx={chipStyles}>
          {jobTypes.map(({ id, name }) => (
            <Chip color={'primary'} label={name} key={id} />
          ))}
        </Box>
        <Divider />
      </Box>
      <Box sx={contentWrapper}>
        <Typography variant={'body1'} sx={grayColorStyle}>
          Most Important Things:
        </Typography>
        <Box sx={chipStyles}>
          {jobCriterias.map(({ id, name }) => (
            <Chip color={'primary'} label={name} key={id} />
          ))}
        </Box>
        <Divider />
      </Box>
      <Box sx={contentWrapper}>
        <Typography variant={'body1'} sx={grayColorStyle}>
          Typical Roles Recruits:
        </Typography>
        <Box>
          <Typography variant={'body1'}>{recruitRoles}</Typography>
        </Box>
        <Divider />
      </Box>
      <Box sx={contentWrapper}>
        <Typography variant={'body1'} sx={grayColorStyle}>
          Reason for leaving your most recent employer?
        </Typography>
        <Box>
          <Typography variant={'body1'}>{reasonForLeavingRecentEmployer}</Typography>
        </Box>
        <Divider />
      </Box>
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: 'auto',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    },
    '& p': {
      fontSize: '12px',
    }
  }
})

const grayColorStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  '& .MuiChip-root': {
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
})

const chipStyles = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
})

const iconStyle = (theme: Theme) => ({
  cursor: 'pointer',
  color: theme.palette.text.secondary,
})
