import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'

const options = [
  ApiTypes.CompanySize.LESS_10,
  ApiTypes.CompanySize.BETWEEN_11_20,
  ApiTypes.CompanySize.BETWEEN_21_50,
  ApiTypes.CompanySize.BETWEEN_51_100,
  ApiTypes.CompanySize.BETWEEN_101_1000,
  ApiTypes.CompanySize.MORE_1000,
];

export const CompanySizeSelect = () => {
  const { t } = useTranslation()

  return (
    <Field name={'size'} component={Select} label={t('companyForm.size')} fullWidth>
      {options.map((value) => (
        <MenuItem value={value} key={value}>
          {t(`enum.CompanySize.${value}`)}
        </MenuItem>
      ))}
    </Field>
  )
}
