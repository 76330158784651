import { PaginationProps, PaginationItem, Pagination as PaginationMui, useMediaQuery } from '@mui/material'
import { Button } from '../button/button'
import { ArrowForwardRounded } from '@mui/icons-material'
import theme from '../../../styles/theme'

export const Pagination = ({ shape = 'rounded', ...props }: PaginationProps) => {
  const matchesSmall = useMediaQuery(theme.breakpoints.down(489));
  if (!props.count || props.count < 2) {
    return null
  }

  return (
    <PaginationMui
      shape={shape}
      {...props}
      boundaryCount={(matchesSmall && props.count > 4) ? 0 : 1}
      renderItem={(item) =>
        item.type === 'next' ? (
          <Button
            onClick={item.onClick}
            disabled={item.disabled}
            size={'small'}
            endIcon={
              <ArrowForwardRounded
                sx={
                  item.disabled ? {
                    '& path': {
                      fill: 'rgba(0, 0, 0, 0.26)!important'
                    }
                  } : {}
                }
                fontSize={'small'}
              />
            }
            sx={{ margin: '0 3px', paddingX: '20px' }}
          >
            More
          </Button>
        ) : (
          <PaginationItem {...item} />
        )
      }
    />
  )
}
