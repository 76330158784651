import { Box, Card, Tab, Tabs, Theme, Typography } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import Yup, { TextareaValidation } from '../../validation'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { TextField } from 'formik-mui'
import { useUpdateApplicationStatus } from '../../hooks/useApplication'
import { ApiTypes } from 'recruticka-frontend-libs'

interface Values {
  tabValue: Explanation
  message: string
}

interface Props {
  onClose: () => void
  onSave: (id: string) => void
  id: string
}

enum Explanation {
  STANDARD = 'STANDARD',
  DETAIL = 'DETAIL',
}

export const JobRejectPopup = ({ onClose, id, onSave }: Props) => {
  const rejectMutation = useUpdateApplicationStatus({ id: true })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        tabValue: Explanation.STANDARD,
        message: standardTemplate,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await rejectMutation.mutateAsync({
          id,
          message: values.message,
          status: ApiTypes.ApplicationStatus.REJECTED
        })

        onSave(id)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form>
            <Card sx={wrapper}>
              <Box sx={headWrapper}>
                <Typography variant={'h2'}>Reject</Typography>
                <Box onClick={onClose}>
                  <CloseRoundedIcon sx={iconStyle} />
                </Box>
              </Box>
              <Tabs
                value={values.tabValue}
                onChange={(_, value: Explanation) => {
                  setFieldValue('tabValue', value)

                  if (value === Explanation.STANDARD) {
                    setFieldValue('message', standardTemplate)
                  } else {
                    setFieldValue('message', '')
                  }
                }}
              >
                <Tab value={Explanation.STANDARD} label='Use standard template for refusal' sx={tabStyle} />
                <Tab value={Explanation.DETAIL} label='Detail description for refusal' sx={tabStyle} />
              </Tabs>
              <Field
                component={TextField}
                name='message'
                placeholder={'Describe the reason for rejection'}
                fullWidth
                sx={{ height: 'auto' }}
                multiline
                rows={6}
              />
              <LoadingButton
                sx={{ mx: 'auto' }}
                type='submit'
                size={'medium'}
                variant='contained'
                disabled={!!Object.keys(errors).length}
              >
                Send
              </LoadingButton>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const standardTemplate = 'Hey! Thanks for applying for our position! Unfortunately, at this stage, we don\'t believe that you are a suitable candidate for our role and we have stronger candidates in the pipeline. Feel free to apply again in the future!'

const validationSchema = Yup.object().shape({
  tabValue: Yup.string().required(),
  message: TextareaValidation.required(),
})

const tabStyle = (theme: Theme) => ({
  maxWidth: '247px',
  width: '100%',
  color: theme.palette.text.secondary,
  mr: theme.spacing(2),
})

const headWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: 'auto',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
