import {
  Box,
  Divider,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { Button } from './ui-kits/button/button'
import { SaveIcon } from './icons/saveIcon'
import { useTranslation } from 'react-i18next'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import theme from '../styles/theme'


interface propsI  {
  srollToElement:  () => void
  isSave? : boolean
}

export default function FooterButtonClient({srollToElement, isSave = false } : propsI) {

  const matchXs = useMediaQuery(theme.breakpoints.down('xs'))

  const { t } = useTranslation()

  return (
      <Box sx={buttonBoxWrap}>
        <Divider />
        <Box sx={buttonBox}>
          <Button
              size='squareSmall'
              endIcon={<NavigateNextIcon fill='#fff' />}
              type={isSave ? 'submit' : 'button'}
              onClick={srollToElement}
              sx={matchXs? fullWidthButton : buttonStyle}
              >
                {isSave ? t('save') : t('next')}
          </Button>
        </Box>
      </Box>
  );
}

const buttonBoxWrap = (theme: Theme) => ({
  width: '100%',
  padding: theme.spacing(4 , 0 , 0),
  [theme.breakpoints.down('md')]: {
    position: 'unset',
    padding: '24px 0 0',
  },
})

const buttonBox = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'end',
  paddingTop: theme.spacing(4),
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  }
})


const fullWidthButton = (theme: Theme) => ({
  width: '100%',
})

const buttonStyle = (theme: Theme) => ({

})