import * as Yup from 'yup'
import { customLocale } from './schema-translation'
import { ApiTypes } from 'recruticka-frontend-libs'

Yup.setLocale(customLocale)

export default Yup


export const TextareaValidation = Yup.string()
  .transform((v: string | null) => {
    if (v) {
      v = v.replace(/(<([^>]+)>)/gi, '')
    }

    return v
  })
  .max(5000)

export const ShortTextareaValidation = TextareaValidation.max(1000)
export const TextValidation = Yup.string().max(190)

export const RatingValidation = Yup.array(
  Yup.object({
    type: Yup.string(),
    url: Yup.string().test({
      name: 'qwerty',
      test (value: any){
        const parent = this.parent as {
          type: string,
          url: string
        }
        if (value){
          if (parent?.type === ApiTypes.CompanyRatingType.GLASSDOOR) {
            if (!value?.includes('https://www.glassdoor.com/')) {
              return this.createError({
                message: 'Url must include https://www.glassdoor.com/',
              })
            }
          } else {
            if (!value?.includes('https://www.trustpilot.com/')){
              return this.createError({
                message: 'Url must include https://www.trustpilot.com/',
              })
            }
          }
        }
        return true
      }
    })
  })
)