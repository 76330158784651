import { SyntheticEvent, useMemo, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message'
import CancelIcon from '@mui/icons-material/Cancel'
import { Content, Main } from '../components/common-files/common-styles'
import { JobOverviewHeader } from '../components/not-cms/job-overview-header'
import {
  Box,
  Card,
  Chip,
  CircularProgress, ClickAwayListener,
  Divider,
  Link,
  Modal,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Theme,
  Typography, useMediaQuery
} from '@mui/material'
import MyJobTable, { HeadCell } from '../components/not-cms/my-job-table'
import { JobRejectPopup } from '../components/not-cms/job-reject-popup'
import TempWrapperNew from '../components/tempWrapperNew'
import { generatePath, Link as RouterLink, useParams, useSearchParams } from 'react-router-dom'
import { useAuthQueryRequest } from '../services/api'
import { ApiSelector, ApiTypes, ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { useList } from '../hooks/useList'
import { buildAssetUrl } from '../utilities/buildAssetUrl'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'
import { useChat } from '../hooks/useConversations'
import { chatEnabled } from '../config/features'
import { PAGES } from '../router/AppRoutes'
import { MoreVert } from '@mui/icons-material'
import theme from '../styles/theme'
import { useUpdateApplicationStatus } from '../hooks/useApplication'


const jobSelector = ApiSelector('Job')({
  id: true,
  title: true,
  description: true,
  experience: true,
  salaryType: true,
  createdAt: true,
  updatedAt: true,
  companyId: true,
  salary: {
    schedule: true,
    from: true,
    to: true,
    currency: {
      id: true,
      symbol: true,
    },
  },
  location: {
    type: true,
    wfhDays: true,
    offices: {
      city: citySelector,
    },
  },
  sectors: {
    name: true,
  },
})

const applicationSelector = ApiSelector('Application')({
  id: true,
  status: true,

  profile: {
    deletedAt: true,
    id: true,
    isHidden: true,
    displayName: true,
    employee: {
      id: true,
      profileId: true,
      firstname: true,
      lastname: true,
      cv: {
        url: true,
      },
      currentCity: citySelector,
    },
    conversations: [
      { profileId: '' },
      {
        id: true
      }
    ]
  },
})

type ApplicationFields = ApiInputType<ApiGraphQLTypes['Application'], typeof applicationSelector>

const JobOverview = () => {
  const { id: profileId } = useRecoilValue(profileState);
  const { t } = useTranslation()
  const { jobId } = useParams()
  const updateApplicationStatusMutation = useUpdateApplicationStatus({ id: true })
  const [isPageLoaded, setIsPageLooaded] = useState(false)
  const [rejectId, setRejectId] = useState<string | undefined>()
  const { page, perPage, updatePage, updatePerPage } = useList()
  const [searchParams, setSearchParams] = useSearchParams()
  const status: ApiTypes.ApplicationStatus | null = (searchParams.get('status') as ApiTypes.ApplicationStatus) || null

  const closeModal = () => setRejectId(undefined)

  const setStatus = (status: ApiTypes.ApplicationStatus) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      status,
    })
  }

  const onTabChange = (event: SyntheticEvent, newValue: ApiTypes.ApplicationStatus) => {
    setStatus(newValue ?? '')
  }

  const jobsParams = { id: jobId, status, perPage, page }
  const {
    data: jobData,
    isRefetching: jobDataRefetching,
    refetch,
  } = useAuthQueryRequest(
    ['job', jobsParams],
    {
      getJob: [
        { where: { id: jobId as string } },
        {
          ...jobSelector,
          application: [
            {
              take: perPage,
              skip: page * perPage,
              where: {
                ...(status
                  ? {
                    status: {
                      equals: status,
                    },
                  }
                  : {}),
              },
            },
            {
              ...applicationSelector,
              profile: {
                ...applicationSelector.profile,
                isHidden: [
                { profileId },
                 true
                ],
                conversations: [
                  { profileId },
                  {
                    id: true
                  }
                ]
              }
            },
          ],
          countApplication: [
            {
              where: {
                ...(status
                  ? {
                    status: {
                      equals: status,
                    },
                  }
                  : {}),
              },
            },
            true,
          ],
        },
      ],
    },
    {
      enabled: jobId !== undefined,
      onSuccess() {
        setIsPageLooaded(true)
      },
    },
  )

  const openRejectModal = async (id: string, status: ApiTypes.ApplicationStatus) => {
    if (status !== ApiTypes.ApplicationStatus.REJECTED){
     await updateApplicationStatusMutation.mutateAsync({
       id,
       status: (status as ApiTypes.ApplicationStatus)
     })
      refetch()
    } else {
      setRejectId(id)
    }
  }

  const onReject = (id: string) => {
    refetch()
    /* queryClient.setQueryData(['job', jobsParams], {
      getJob: {
        ...(jobData?.getJob ?? {}),
        application: (jobData?.getJob.application ?? []).filter(application => {
          if (!status) {
            return true;
          }

          return application.id !== id;
        }),
        countApplication: jobData?.getJob.countApplication ? jobData?.getJob.countApplication - 1 : 0,
      }
    }); */

    closeModal()
  }

  if (!isPageLoaded) {
    return <CircularProgress />
  }

  return (
    <TempWrapperNew>
      <Main sx={wrapper}>
        <Content sx={contentWrapper}>
          <JobOverviewHeader
            job={{
              createdAt: jobData?.getJob.createdAt,
              updatedAt: jobData?.getJob.updatedAt,
              id: jobData?.getJob.id || '',
              description: jobData?.getJob.description,
              location: jobData?.getJob.location,
              salary: jobData?.getJob.salary,
              salaryType: jobData?.getJob.salaryType,
              title: jobData?.getJob.title,
              sectors: jobData?.getJob.sectors,
            }}
          />
          <Card sx={tableWrapper}>
            <Typography variant={'h2'}>Candidates</Typography>
            <Divider sx={dividerStyle} />
            <Tabs
              value={status}
              onChange={onTabChange}
              sx={tabsWrapper}
            >
              <Tab value={null} label={t('all')} sx={tabStyle} />
              <Tab value={ApiTypes.ApplicationStatus.ACTIVE} label={t('enum.ApplicationStatus.ACTIVE')} sx={tabStyle} />
              <Tab
                value={ApiTypes.ApplicationStatus.INVITED}
                label={t('enum.ApplicationStatus.INVITED')}
                sx={tabStyle}
              />
              <Tab value={ApiTypes.ApplicationStatus.HIRED} label={t('enum.ApplicationStatus.HIRED')} sx={tabStyle} />
              <Tab
                value={ApiTypes.ApplicationStatus.REJECTED}
                label={t('enum.ApplicationStatus.REJECTED')}
                sx={tabStyle}
              />
              <Tab
                value={ApiTypes.ApplicationStatus.FOUND_ANOTHER_JOB}
                label={t('enum.ApplicationStatus.FOUND_ANOTHER_JOB')}
                sx={tabStyle}
              />
            </Tabs>

            {jobDataRefetching && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}

            {!jobDataRefetching && (
              <MyJobTable
                headCells={headCells}
                pagination={{
                  rowsPerPage: perPage,
                  page,
                  onPageChange: updatePage,
                  onRowsPerPageChange: updatePerPage,
                  count: jobData?.getJob?.countApplication || 0,
                }}
              >
                {(jobData?.getJob?.application || []).map((application) => {
                  return (
                    <JobApplicationsRow key={application.id} companyId={jobData?.getJob?.companyId} application={application} onReject={openRejectModal} />
                  )
                })}
              </MyJobTable>
            )}
          </Card>
          <Modal open={rejectId !== undefined} onClose={closeModal}>
            <Box sx={modalWrapper}>
              <JobRejectPopup onClose={closeModal} id={rejectId as string} onSave={onReject} />
            </Box>
          </Modal>
        </Content>
      </Main>
    </TempWrapperNew>
  )
}

interface JobApplicationsRowProps {
  application: ApplicationFields
  onReject: (id: string, status: ApiTypes.ApplicationStatus) => void
  companyId?: string
}

const JobApplicationsRow = ({ application, onReject, companyId }: JobApplicationsRowProps) => {
  const { id: profileId } = useRecoilValue(profileState)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const { open } = useChat()

  const statusColor = useMemo(() => {
    switch (application.status) {
      case ApiTypes.ApplicationStatus.ACTIVE:
        return '#9C27B0'
      case ApiTypes.ApplicationStatus.REJECTED:
        return '#D32F2F'
      case ApiTypes.ApplicationStatus.INVITED:
        return '#2E7D32'
      case ApiTypes.ApplicationStatus.HIRED:
        return '#0F64E2'
      case ApiTypes.ApplicationStatus.FOUND_ANOTHER_JOB:
        return 'rgba(20, 29, 36, 0.4)'
      default:
        return 'success'
    }
  }, [application.status])

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={application.id}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        })}
      >
        <TableCell component='th' scope='row' sx={tableCellWrapper}>
          {!application.profile.isHidden && !application.profile.deletedAt ?
            <Link
              sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
              component={RouterLink}
              to={generatePath(PAGES.EMPLOYEE, {
                profileId,
                employeeId: application.profile.employee?.id
              })}
            >
              {`${application.profile.employee?.firstname}, ${application.profile.employee?.lastname}`}
            </Link>
            :
            !application.profile.deletedAt ?
              application.profile.displayName
              :
              'deleted account'
          }
        </TableCell>
        {/* <TableCell sx={tableCellWrapper}>
        <Typography variant={"body2"}>
          {row.experience}
        </Typography>
      </TableCell> */}
        <TableCell sx={tableCellWrapper}>
          <Typography variant={'body2'}>
            {application.profile.employee?.cv?.url && !application.profile.deletedAt ? (
              <Link href={buildAssetUrl(application.profile.employee.cv.url)}>CV</Link>
            ) : (
              '-'
            )}
          </Typography>
        </TableCell>
        <TableCell sx={tableCellWrapper}>
          <Typography variant={'body2'}>
            {!application.profile.deletedAt ?
              `${application.profile.employee?.currentCity?.country.name} ${application.profile.employee?.currentCity?.name}`
              :
              '-'
            }
          </Typography>
        </TableCell>
        <TableCell sx={tableCellWrapper}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!application.profile.deletedAt ?
              <Chip
                label={t(`enum.ApplicationStatus.${application.status}`)}
                // color={statusColor}
                sx={[{
                  height: '24px ',
                  borderRadius: '16px',
                  color: '#fff'
                }, {backgroundColor: statusColor}]}
              />
              : '-'
            }
            <Box sx={actionWrapper}>
              {
                chatEnabled &&
                !application.profile.deletedAt &&
                application.status !== ApiTypes.ApplicationStatus.REJECTED && (
                  <Box>
                    <MessageIcon sx={iconStyle} onClick={() => {
                      if (application.profile.employee?.id) {
                        open({
                          companyId: companyId,
                          withProfileId: application.profile.id,
                          conversationId: application.profile.conversations?.[0]?.id
                        })
                      }
                    }} />
                  </Box>
                )
              }
              { !matchSm &&
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                  <Box sx={{position: 'relative'}}>
                    <Box
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                      sx={
                        application.status !== ApiTypes.ApplicationStatus.REJECTED
                          ? { visibility: 'visible' }
                          : { visibility: 'hidden' }
                      }
                    >
                      <MoreVert cursor={'pointer'} color={'disabled'}/>
                    </Box>
                    { isOpen &&
                      <Box sx={statusModalSx}>
                        {Object.values(ApiTypes.ApplicationStatus).filter(status => application.status !== status)
                          .map(status =>
                            <Box
                              key={status}
                              onClick={() => onReject( application.id, (status as ApiTypes.ApplicationStatus))}
                            >
                              {t(`applicationStatusActions.${status}`)}
                            </Box>
                          )
                        }
                      </Box>
                    }
                  </Box>
                </ClickAwayListener>
              }
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <Box
        sx={(theme) => ({
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          },
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: '5px' }}>
          <Typography
            variant={'h5'}
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
          >
            {!application.profile.isHidden && !application.profile.deletedAt ?
              <Link
                sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                component={RouterLink}
                to={generatePath(PAGES.EMPLOYEE, {
                  profileId,
                  employeeId: application.profile.employee?.id
                })}
              >
                {`${application.profile.employee?.firstname}, ${application.profile.employee?.lastname}`}
              </Link>
              :
              !application.profile.deletedAt ?
                application.profile.displayName
                :
                'deleted account'
            }
          </Typography>
          { !application.profile.deletedAt &&
            application.status !== ApiTypes.ApplicationStatus.REJECTED && (
              <Box onClick={() => {
                if (application.profile.employee?.id) {
                  open({
                    companyId: companyId,
                    withProfileId: application.profile.id,
                    conversationId: application.profile.conversations?.[0]?.id
                  })
                }
              }}
              >
                <MessageIcon sx={iconStyle} />
              </Box>
            )}
        </Box>
        <Chip
          label={t(`enum.ApplicationStatus.${application.status}`)}
          // color={statusColor}
          sx={[{
            height: '24px ',
            borderRadius: '16px',
            width: 'fit-content',
            color: '#fff'
          }, {backgroundColor: statusColor}]}
        />
        {/* <Typography variant={'body2'}>*/}
        {/*  <Box>Experience :</Box>{' '}*/}
        {/* </Typography>*/}
        <Typography variant={'body2'} sx={{ display: 'flex' }}>
          <Box sx={(theme) => ({ color: theme.palette.text.secondary })}>Location :</Box>{' '}
          {!application.profile.deletedAt ?
            `${application.profile.employee?.currentCity?.country.name} ${application.profile.employee?.currentCity?.name}`
            :
            '-'
          }
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: '5px' }}>
          <Typography variant={'body2'}>
            {application.profile.employee?.cv?.url && !application.profile.deletedAt ? (
              <Link href={buildAssetUrl(application.profile.employee.cv.url)}>CV</Link>
            ) : (
              '-'
            )}
          </Typography>
          { matchSm &&
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
              <Box sx={{position: 'relative'}}>
                <Box
                  onClick={() => setIsOpen(!isOpen)}
                  sx={
                    application.status !== ApiTypes.ApplicationStatus.REJECTED
                      ? { visibility: 'visible' }
                      : { visibility: 'hidden' }
                  }
                >
                  <MoreVert color={'disabled'} cursor={'pointer'} />
                </Box>
                { isOpen &&
                  <Box sx={statusModalSx}>
                    {Object.values(ApiTypes.ApplicationStatus).filter(status => application.status !== status)
                      .map(status =>
                        <Box
                          onClick={() => onReject( application.id, (status as ApiTypes.ApplicationStatus))}
                          key={status}
                        >
                          {t(`applicationStatusActions.${status}`)}
                        </Box>
                      )
                    }
                  </Box>
                }
              </Box>
            </ClickAwayListener>
          }
        </Box>
        <Divider sx={(theme) => ({ width: '100%', color: theme.palette.text.secondary })} />
      </Box>
    </>
  )
}

const headCells: HeadCell[] = [
  { id: 'full name', label: 'Full name' },
  // { id: "calories", label: "Experience " },
  { id: 'link to CV', label: 'Link to CV' },
  { id: 'location', label: 'Location' },
  { id: 'status ', label: 'Status' },
]

const wrapper = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: '66px',
  paddingBottom: '128px',
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  gap: theme.spacing(4),
  boxSizing: 'border-box'
})

const tableWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
})

const statusModalSx = (theme: Theme) => ({
  position: 'absolute',
  backgroundColor: '#fff',
  top: '-70px',
  left: '-160px',
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2),' +
    ' 0px 1px 1px rgba(0, 0, 0, 0.14), ' +
    '0px 1px 3px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  minWidth: '163px',
  '& div': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '2px 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  [theme.breakpoints.down('sm')]: {
    top: '-80px'
  }
})

const tabStyle = (theme: Theme) => ({
  minWidth: '100px',
  color: theme.palette.text.secondary,
  mr: theme.spacing(2),
  textTransform: 'none',
  [theme.breakpoints.down('lg')]: {
    mr: theme.spacing(1),
  },
})

const tableCellWrapper = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  textAlign: 'left',
})

const dividerStyle = (theme: Theme) => ({
  mx: `-${theme.spacing(4)}`,
  mt: `-${theme.spacing(1)}`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const actionWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  ml: 'auto',
})

const tabsWrapper = () => ({
  '& > div > div': {
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
    width: '80vw',
    overflowX: 'auto',
  },
})

const modalWrapper = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  maxWidth: '864px',
  maxHeight: '90vh',
  overflowY: 'auto',
  width: '100%',
  outline: 'none',
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
  },
})

export default JobOverview
