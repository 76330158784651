import { Box, Card, Modal, Theme, Typography } from '@mui/material'
import { Button } from '../ui-kits/button/button'
import AddIcon from '@mui/icons-material/Add'
import { useState } from 'react'
import { DragDrop } from './ui-kits/drag-drop/drag-drop'
import { EDragDrops } from './types/company-profile-setup-types'
import { radiusOfComponents } from '../../styles/theme'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { SetOptional } from 'type-fest'
import { Field, useField, FieldArray, FastField } from 'formik'
import { TextField } from 'formik-mui'
import Uploader from '../Uploader/Uploader'
import { TextBlock as TextBlockOrg } from '../../hooks/useTextBlock'
import { TextBlockAsset } from '../../hooks/useTextBlockAsset'
import { TextEditorField } from '../form-fields/TextEditorField'
import { useTranslation } from 'react-i18next'
import { IMAGES, VIDEO } from '../../config/uploaderTypes'

interface Props {
  index: number
}

type TextBlock = SetOptional<TextBlockOrg, 'id'>

export const ProfilePerksSetup = ({ index }: Props) => {
  const [descriptionField] = useField<string>(`perks.${index}.description`)
  const [perkTextBlocksField] = useField<TextBlock[]>(`perks.${index}.textBlocks`)

  return (
    <>
      <FastField component={TextField} name={descriptionField.name} placeholder='Main Text' fullWidth />

      <Box sx={contentWrapper}>
        <FieldArray
          name={perkTextBlocksField.name}
          render={(arrayHelper) => (
            <>
              {perkTextBlocksField.value.map((_, textIndex) => (
                <Content key={textIndex} perkIndex={index} index={textIndex} />
              ))}

              <Box>
                <Button
                  variant='contained'
                  size={'squareSmall'}
                  onClick={() => {
                    arrayHelper.push({
                      description: '',
                      title: '',
                      textBlockAsset: [],
                    } as TextBlock)
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Items
                </Button>
              </Box>
            </>
          )}
        />
      </Box>
    </>
  )
}

interface ContentProps {
  index: number
  perkIndex: number
}

const Content = ({ index, perkIndex }: ContentProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false)
  const handleClose = () => setOpen(false)
  const [textBlockAssetsField] = useField<TextBlockAsset[]>(`perks.${perkIndex}.textBlocks.${index}.textBlockAsset`)

  return (
    <>
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <FastField
            component={TextField}
            name={`perks.${perkIndex}.textBlocks.${index}.title`}
            placeholder='Headline'
            fullWidth
            inputProps={{ maxLength: 190 }}
          />
        </Box>

        <TextEditorField
          name={`perks.${perkIndex}.textBlocks.${index}.description`}
          placeholder={t('description')}
          areaSx={{
            height: '70px'
          }}
        />
        <FieldArray
          name={textBlockAssetsField.name}
          render={(arrayHelpers) => (
            <>
              <Uploader
                label={'Images and Video'}
                assets={textBlockAssetsField.value.map(({ asset }) => asset)}
                multiple
                onFileUploaded={(asset) => {
                  arrayHelpers.push({ asset })
                }}
                onFileDeleted={({ id }) => {
                  const index = textBlockAssetsField.value.findIndex(({ asset }) => asset.id === id)
                  arrayHelpers.remove(index)
                }}
                allowedFileTypes={[...IMAGES, ...VIDEO]}
              />
            </>
          )}
        />
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute' as const,
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            top: '50%',
            left: '50%',
            '&::-webkit-scrollbar': {
              width: '0px',
              height: '0px',
            },
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            overflowY: 'auto',
            backgroundColor: 'white',
            borderRadius: radiusOfComponents,
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '32px',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h2'}>Select Assets</Typography>
            <Button size={'circleMedium'} startIcon={<SaveRoundedIcon />}>
              Save and Close
            </Button>
          </Box>
          <DragDrop type={EDragDrops.IMAGES_VIDEO} />
        </Box>
      </Modal>
    </>
  )
}

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  // padding: '24px',
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
})
