import { Box, Slider, SliderProps, Theme, Typography } from '@mui/material'

export interface SliderFieldProps extends SliderProps {
  helperText?: string | string[]
}

export const SliderField = ({ helperText, ...props }: SliderFieldProps) => {
  return (
    <Box sx={wrapper} className={'slider'}>
      <Slider {...props} />
      {helperText && (
        <Typography variant='body2' sx={errorText}>
          {helperText}
        </Typography>
      )}
    </Box>
  )
}
const wrapper = (theme: Theme) => ({
  width: '100%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '83%',
  },
})
const errorText = (theme: Theme) => ({
  color: theme.palette.error.main,
})
