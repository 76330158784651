import { Box, Card, Chip, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/dashboard-employee'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'

export const languageSelector = ApiSelector('Language')({
  id: true,
  name: true,
})

type LanguageFields = ApiInputType<ApiGraphQLTypes['Language'], typeof languageSelector>

interface Props {
  employeeName?: string
  languages?: LanguageFields[]
  onChange?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditLanguage = ({ employeeName, languages, onChange, type }: Props) => {
  return (
    <Box sx={wrapper}>
      <Box sx={headStyle}>
        <Typography variant={'subtitle2'} sx={{ mb: 1.5,}} >
          {employeeName && `${employeeName}’s `}
          Languages
        </Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onChange}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      {languages && (
        <Box sx={[infoStyle, { display: 'flex', flexWrap: 'wrap', gap: '10px' }]}>
          {languages.map(({ id, name }) => (
            <Chip key={id} label={name} variant="outlined" color={'primary'} />
          ))}
        </Box>
      )}
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    }
  }
})

const headStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const infoStyle = (theme: Theme) => ({
  '& .MuiChip-root': {
    // color: theme.palette.text.primary,
    // backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
})