import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional } from 'type-fest'
import { authMutation } from '../services/api'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>
type CreateOrUpdateSalaryFields = SetOptional<SalaryFields, 'id'>

export const useCreateOrUpdateSalary = (selector: PartialDeep<typeof salarySelector>) => {
  return useMutation(async (values: CreateOrUpdateSalaryFields): Promise<SalaryFields> => {
    const { id, ...otherData } = values

    const input = {
      from: otherData.from,
      to: otherData.to,
      schedule: otherData.schedule,
      currency: {
        connect: {
          id: otherData.currency.id,
        },
      },
    }

    if (id) {
      const data = await authMutation({
        updateSalary: [
          {
            id,
            input,
          },
          salarySelector,
        ],
      })

      return data.updateSalary
    }

    const data = await authMutation({
      createSalary: [{ input }, salarySelector],
    })

    return data.createSalary
  })
}
