import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { authMutation } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'

export const officeAssetSelector = ApiSelector('OfficeAsset')({
  id: true,
  isMain: true,
  asset: assetSelector,
})

export type OfficeAsset = ApiInputType<ApiGraphQLTypes['OfficeAsset'], typeof officeAssetSelector>
type CreateOrUpdateOfficeAsset = SetOptional<OfficeAsset, 'id'>

type MutationProps = {
  officeId: string
  values: CreateOrUpdateOfficeAsset
}

export const useCreateOrUpdateOfficeAsset = (selector: Partial<typeof officeAssetSelector>) => {
  return useMutation(async ({ officeId, values }: MutationProps) => {
    const { id, asset, ...input } = values

    if (id) {
      const { updateOfficeAsset } = await authMutation({
        updateOfficeAsset: [
          {
            id,
            input: input,
          },
          selector,
        ],
      })

      return updateOfficeAsset
    }

    const { createOfficeAsset } = await authMutation({
      createOfficeAsset: [
        {
          input: {
            ...input,
            asset: {
              connect: {
                id: asset.id,
              },
            },
            office: {
              connect: {
                id: officeId,
              },
            },
          },
        },
        selector,
      ],
    })

    return createOfficeAsset
  })
}

export const useDeleteOfficeAsset = () => {
  return useMutation(async (id: OfficeAsset['id']): Promise<boolean> => {
    await authMutation({
      deleteOfficeAsset: [{ id }, true],
    })

    return true
  })
}
