import { useEffect, useState } from 'react'
import {
  createTheme,
  InputAdornment,
  OutlinedInput,
  Theme,
  ThemeProvider,
  Grid,
  Box,
  Typography, ClickAwayListener
} from '@mui/material'
import { Button } from '../ui-kits/button/button'
import { FilterIcon } from '../icons/filter-icon'
import { SearchIcon } from '../icons/search-icon'
import { CreditCard } from '../icons/credit-card'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme, { radiusOfComponents } from '../../styles/theme'
import { Form, Formik, useField } from 'formik'
import { Cancel } from '@mui/icons-material'
import { SpecializationAutocomplete, SpecializationFields } from './SpecializationsAutocomplete'
import { ExperienceAutocomplete } from './ExperienceAutocomplete'
import { CurrencyFields } from '../form-fields/Salary'
import { CityAutocomplete, CityFields } from './CityAutocomplete'
import { ReadinessForWorkAutocomplete } from './ReadinessForWorkAutocomplete'
import { SectorAutocomplete, SectorFields } from './SectorAutocomplete'
import { ApiTypes } from 'recruticka-frontend-libs'
import { SalaryCard } from './SalaryCard'

export interface FilterFields {
  search: string,
  sectors: SectorFields[],
  specializations: SpecializationFields[],
  experience: ApiTypes.Experience | null,
  cities: CityFields[],
  jobLocationType: ApiTypes.JobLocationType | null,
  salaryPeriod: ApiTypes.SalaryPeriod | null,
  currency: CurrencyFields | null,
  from: number | string,
  to: number | string,
}
interface Props {
  onSave: (filters: FilterFields) => void,
  initialValues: FilterFields,
  onClearFilter: () => void
}

export const Filter = ({ onSave, initialValues, onClearFilter }: Props) => {
  const [isAdvanced, setIsAdvanced] = useState(false)
  const [isSalaryRange, setIsSalaryRange] = useState(false)
  const [currencyState, setCurrencyState] = useState<CurrencyFields | undefined>()

  return (
    <ThemeProvider theme={isAdvanced ? theme : withoutBorders}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values)
          setSubmitting(false)
        }}
      >
        {({ setFieldValue, values, resetForm }) => {

          values.currency && setCurrencyState(values.currency)
          return (
            <Form>
              <Box sx={isAdvanced ? advancedWrapper : wrapper}>
                <Grid container={isAdvanced} display={'flex'} width={'100%'} spacing={isAdvanced ? 1.5 : 0}>
                  <Grid item sx={!isAdvanced ? inputStyles : {}} lg={4.5} md={8} xs={12}>
                    <OutlinedInput
                      name="search"
                      placeholder="Search"
                      fullWidth
                      value={values.search}
                      sx={isAdvanced ? { height: '53px' } : {}}
                      onChange={(e) => setFieldValue('search', e.target.value)}
                      startAdornment={
                        <InputAdornment
                          position={'start'}
                          sx={!isAdvanced ? searchIconWrapper : {}}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    sx={
                      !isAdvanced ?
                        [selectStyles, values.sectors.length ? selectStyleOnSector : {} ]
                    : {}
                    }
                    lg={2.5} md={4} sm={6} xs={12}>
                    <SectorAutocomplete />
                  </Grid>
                  <Grid
                    item
                    sx={
                      !isAdvanced ?
                        [selectStyles, values.specializations.length ? selectStyleOnSpecialization : {} ]
                        : {}
                    }  lg={2.5} md={4} sm={6} xs={12}>
                    <SpecializationAutocomplete />
                  </Grid>
                  <Grid item sx={!isAdvanced ? selectStyles : {}} lg={2.5} md={4} sm={6} xs={12}>
                    <ExperienceAutocomplete />
                  </Grid>
                  {isAdvanced && (
                    <>
                      <Grid item lg={2.5} md={4} sm={6} xs={12}>
                        <ClickAwayListener onClickAway={() => setIsSalaryRange(false)}>
                          <Box sx={{ position: 'relative' }}>
                            <Button
                              endIcon={<KeyboardArrowDownIcon />}
                              sx={salaryRangeWrapper}
                              variant={'outlined'}
                              onClick={() => setIsSalaryRange(!isSalaryRange)}
                              fullWidth
                            >
                              <Box
                                sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}
                              >
                                <CreditCard />
                                {
                                  !values.to && !values.from && !values?.currency?.symbol ?
                                    <Typography sx={buttonTextStyle}>
                                      Salary Range
                                    </Typography>
                                    :
                                    <Typography sx={{ color: '#141D24', fontSize: '14px' }}>
                                      {
                                        `${values?.currency?.symbol ? values?.currency?.symbol : currencyState?.symbol} 
                                    ${values.from}-${values.to}`
                                      }
                                    </Typography>
                                }
                              </Box>
                            </Button>
                            {isSalaryRange && <Formik
                              initialValues={{
                                salaryPeriod: values.salaryPeriod || '',
                                currency: values.currency || '',
                                from: values.from || '',
                                to: values.to || ''
                              }}
                              onSubmit={(values, { setSubmitting }) => {
                                setFieldValue('salaryPeriod', values.salaryPeriod)
                                setFieldValue('currency', values.currency)
                                setFieldValue('from', values.from)
                                setFieldValue('to', values.to)
                                setSubmitting(false)
                                setIsSalaryRange(false)
                              }}
                              onReset={(values, formikHelpers) => {
                                setFieldValue('salaryPeriod', '')
                                setFieldValue('currency', '')
                                setFieldValue('from', '')
                                setFieldValue('to', '')
                                setIsSalaryRange(false)
                              }}
                            >
                              <SalaryCard />
                            </Formik>}
                          </Box>
                        </ClickAwayListener>
                      </Grid>
                      <Grid item sx={!isAdvanced ? selectStyles : {}} lg={2.5} md={4} sm={6} xs={12}>
                        <CityAutocomplete />
                      </Grid>
                      <Grid item sx={!isAdvanced ? selectStyles : {}} lg={2.5} md={4} sm={6} xs={12}>
                        <ReadinessForWorkAutocomplete />
                      </Grid>
                      <Grid item sx={!isAdvanced ? selectStyles : {}} alignSelf={'center'} lg={2.5} md={4} sm={6} xs={12}>
                        <Box
                          sx={actionWrapper}
                          onClick={() => {
                            onClearFilter()
                            resetForm()
                          }}>
                          <Cancel />
                          <Typography>Clear all</Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid item sx={isAdvanced ? [buttonsWrapper, advancedButtonsWrapper] : buttonsWrapper}>
                    <Button
                      variant={isAdvanced ? 'hovered' : 'text'}
                      size={isAdvanced ? 'squareMedium' : 'medium'}
                      sx={isAdvanced ? [parameterStyle, advancedParameterStyle] : parameterStyle}
                      onClick={() => setIsAdvanced(!isAdvanced)}
                    >
                      <FilterIcon />
                    </Button>
                    <Button
                      size={'medium'}
                      type={'submit'}
                      startIcon={
                        !isAdvanced && (
                          <Box sx={hideIcon}>
                            <SearchIcon />
                          </Box>
                        )
                      }
                      sx={isAdvanced ? [buttonStyle, advancedButtonStyle] : buttonStyle}
                    >
                      <Box sx={!isAdvanced ? hideText : {}}>Find</Box>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </ThemeProvider>
  )
}

const selectStyleOnSector = (theme: Theme) => ({
  '& .Mui-focused .MuiInputBase-root .MuiInputBase-input': {
  paddingLeft: '35px!important'
  },
  '& .Mui-focused .MuiInputAdornment-root svg': {
    marginBottom: '-35px'
  },
})

const selectStyleOnSpecialization = (theme: Theme) => ({
  '& .Mui-focused .MuiInputBase-root .MuiInputBase-input': {
    paddingLeft: '35px!important'
  },
  '& .Mui-focused .MuiInputAdornment-root svg': {
    marginBottom: '-35px'
  },
})

const salaryRangeWrapper = (theme: Theme) => ({
  justifyContent: 'space-between',
  px: '17px',
  color: theme.palette.text.secondary,
  border: '1px solid rgb(20 29 36 / 29%)',
  height: '53px'
});

const buttonTextStyle = (theme: Theme) => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.text.secondary,
});

const hideIcon = (theme: Theme) => ({
  display: 'flex',
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
});
const searchIconWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    '& > svg': { display: 'none' },
  },
});
const hideText = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

const parameterStyle = (theme: Theme) => ({
  height: '100%',
  width: '80px',
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    width: '68px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '54px',
  },
});

const advancedParameterStyle = (theme: Theme) => ({
  maxWidth: '53px',
  width: '100%',
  minWidth: 'auto',
  marginRight: theme.spacing(1.5),
});

const buttonsWrapper = {
  display: 'flex',
  marginLeft: 'auto',
};

const advancedButtonsWrapper = (theme: Theme) => ({
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

const buttonStyle = (theme: Theme) => ({
  height: '100%',
  maxWidth: '135px',
  whiteSpace: 'nowrap',
  '& > span': {
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    width: '56px',
  },
});

const advancedButtonStyle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    width: '100%',
  },
});

const inputStyles = {
  borderRight: '1px solid #EDF2F7',
  width: '100%',
};

const selectStyles = (theme: Theme) => ({
  maxWidth: '246px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRight: '1px solid #EDF2F7',
  '& .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .selected-chips': {
    maxWidth: '113px'
  },
  '& .Mui-focused .selected-chips': {
    textAlign: 'start'
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

const wrapper = (theme: Theme) => ({
  minHeight: '80px',
  display: 'flex',
  textAlign: 'end',
  width: '100%',
  borderRadius: radiusOfComponents,
  backgroundColor: theme.palette.primary.contrastText,
  [theme.breakpoints.down('md')]: {
    height: '68px',
    minHeight: 'unset'
  },
  [theme.breakpoints.down('sm')]: {
    height: '56px',
  },
});

const advancedWrapper = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: radiusOfComponents,
  padding: '32px 20px 32px 32px',
  boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
});

const actionWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  cursor: 'pointer',
  marginLeft: '17px',
  gap: theme.spacing(1),
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  color: theme.palette.text.secondary,
});

const withoutBorders = createTheme(theme, {
  ...theme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          borderRadius: radiusOfComponents,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
        containedSizeMedium: {
          minWidth: 'auto',
          borderRadius: radiusOfComponents,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '100%',
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
  },
});
