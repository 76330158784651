import { Box, Card, Theme, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { Button } from './ui-kits/button/button'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { usePreviewJob } from '../hooks/useJob'
import { LoadingButton } from '@mui/lab'
import theme from '../styles/theme'

interface Props {
  onPublish: () => void
  jobId: string
  isJobActive: boolean
  touched: boolean
}

export const JobCardHeaderScrollable = ({ onPublish, jobId, isJobActive, touched }: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { isSubmitting, submitForm, isValid } = useFormikContext<boolean>()
  const { t } = useTranslation()
  const { openPreview } = usePreviewJob()
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))

  const saveAndPreview = async () => {
    if (isValid) {
      await submitForm()
      openPreview(jobId)
    }
  }

  const saveAndPublish = async () => {
    if (isValid) {
      await submitForm()
      onPublish()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    const heightToShowFrom = 140
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToShowFrom) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  return (
    <Box sx={isVisible ? mainWrapper : { display: 'none' }}>
      <Card sx={wrapper}>
        <Box sx={buttonsWrapper}>
            <LoadingButton
              fullWidth={matchesSm}
              variant="contained"
              size={'large'}
              loading={isSubmitting}
              type="submit"
              disabled={!touched}
            >
              {t('save')}
            </LoadingButton>
          {!isJobActive && <>
            <Button
              size={'large'}
              variant={'outlined'}
              endIcon={!matchesSm && <RemoveRedEyeIcon />}
              onClick={saveAndPreview}
            >
              Preview
            </Button>
            <Button
              size={'large'}
              onClick={saveAndPublish}
            >
              Set Live
            </Button>
          </>}

        </Box>
      </Card>
    </Box>
  )
}

const mainWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.primary.contrastText,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2222,
  padding: '10px 24px'
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: '#fff',
    borderRadius: 'unset',
    boxShadow: 'unset',
    padding: 0,
    flexDirection: 'column-reverse',
    width: '100%',
    '& h2': {
      paddingBottom: '12px',
      width: '100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    }
  }
})

const buttonsWrapper = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('md')]:{
    fontSize: '16px',
    '& button':{
      padding: '16px 36px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& button': {
      fontSize: '14px',
      width: '100%',
      paddingY: '10px',
      paddingX: 'unset',
      borderRadius: '4px',
      height: '38px',
      marginBottom: '12px',
      '& span': {
        marginLeft: '8px!important'
      }
    }
  }
})
