import dayjs, { Dayjs } from 'dayjs'

export const dateTime = (date?: Date | Dayjs | string) => {
  return date ? dayjs(date).format('DD MMM YY, HH:mm') : date
}

export const date = (date?: Date | Dayjs | string) => {
  return date ? dayjs(date).format('DD MMM YY') : date
}

export const fromNow = (date?: Date | Dayjs | string) => {
  return date ? dayjs(date).fromNow() : date
}
