import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { Button } from '../../ui-kits/button/button'
import { Box, Card, Theme, Typography, useMediaQuery } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { LanguageAutocomplete } from '../../form-fields/LanguageAutocomplete'
import theme from '../../../styles/theme'

const languageSelector = ApiSelector('Language')({
  id: true,
  name: true,
})

type LanguageFields = ApiInputType<ApiGraphQLTypes['Language'], typeof languageSelector>
type LanguageComponentFields = {
  languages?: LanguageFields[]
}

interface Props extends LanguageComponentFields {
  onSave: (values: LanguageComponentFields) => void
  onClose: () => void
}

export const LanguageComponent = ({ onClose, onSave, languages = [] }: Props) => {
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Formik validationSchema={validationSchema} initialValues={{ languages }} onSubmit={onSave}>
      {({ values }) => {
        return (
          <Form>
            <Card sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'h2'}>Languages</Typography>
                <Box onClick={onClose}>
                  <CloseRoundedIcon sx={iconStyle} />
                </Box>
              </Box>
              <LanguageAutocomplete multiple={true} name='languages' />
              <Button
                sx={smWidth? { width: '100%' } : { mx: 'auto' }}
                type='submit'
                size={'medium'}
                disabled={values.languages?.length === 0}>
                Save
              </Button>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  languages: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .min(1),
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
