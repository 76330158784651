import { atom, selector } from 'recoil'
import { ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { companySelector, profileSelector } from '../../hooks/useProfile'
import { Asset, assetSelector } from '../../components/Uploader/Uploader'
import { CityFields, citySelector } from '../../components/form-fields/CityAutocomplete'

export type ProfileStateFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof profileSelector>
export type MainCompany = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>


export const profileState = atom<ProfileStateFields>({
  key: 'profileState',
  default: {} as ProfileStateFields,
})

export const profileCompanyId = atom({
  key: 'profileCompanyId',
  default: ''
})

export const mainCompanyState = atom<MainCompany>({
  key: 'mainCompanyState',
  default: {} as MainCompany,
})

export const profileEmployeeId = selector({
  key: 'profileEmployeeId',
  get: ({ get }) => {
    return get(profileState)?.employee?.id as string
  },
})

export const currentUserProfileId = selector({
  key: 'currentUserProfileId',
  get: ({ get }) => {
    return get(profileState)?.userProfile?.[0].id as string
  },
})
