import React from 'react'
import { ApiTypes } from 'recruticka-frontend-libs'
import { Box, FormControl, InputLabel, MenuItem, Select, Theme } from '@mui/material'
import { HeadCell } from './my-job-table'
import TableSortLabel from '@mui/material/TableSortLabel'

interface EnhancedTableProps {
  headCellsDropDown?: HeadCell[]
  onSortChange?: (cell: string, direction: ApiTypes.SortOrder) => void
}

export const TableSortDropDown = (props: EnhancedTableProps) => {

  return (
    <Box sx={sortWrapper} className={'sort-by-drop-down'}>
      <FormControl fullWidth>
        <InputLabel id="sort-by">Sort by</InputLabel>
        <Select
          labelId="sort-by"
          id="sort-by"
          label="Sort by"
        >
          {props?.headCellsDropDown?.map((headCell) => (
            <MenuItem
              value={headCell.directionId}
              sx={{
                borderBottom: '1px solid',
                borderColor: '#dfe0df'
              }}
              key={headCell.directionId}
            >
              <TableSortLabel
                active
                direction={headCell.direction}
                sx={sortArrowStyle}
                onClick={() => {
                  props.onSortChange && props.onSortChange(
                    headCell.id,
                    headCell.direction ? headCell.direction : ApiTypes.SortOrder.desc
                  )
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const sortWrapper = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]:{
    display: 'block',
    marginY: '15px',
  }
})

const sortArrowStyle = (theme: Theme) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'start',
  '& > *': {
    color: `${theme.palette.action.active} !important`,
  }
})

