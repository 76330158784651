import { Box, Card, Chip, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/dashboard-employee'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { useMemo } from 'react'

const sectorSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})

export const specializationSelector = ApiSelector('Specialization')({
  id: true,
  name: true,
  sector: sectorSelector,
})

type SpecializationFields = ApiInputType<ApiGraphQLTypes['Specialization'], typeof specializationSelector>
type SectorFields = ApiInputType<ApiGraphQLTypes['Sector'], typeof sectorSelector>

interface Props {
  employeeName?: string
  specializations?: SpecializationFields[]
  onChange?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditSpecialises = ({ employeeName, specializations = [], onChange, type }: Props) => {
  const sectors = useMemo(() => {
    const sectors: SectorFields[] = []

    specializations.map(({ sector }) => {
      if (!sectors.find(({ id }) => id === sector.id)) {
        sectors.push(sector)
      }
    })

    return sectors
  }, [specializations])

  return (
    <Card sx={wrapper}>
      <Box sx={headStyle}>
        <Typography variant={'h2'}>
          {employeeName && `${employeeName} `}
          Specialises in
        </Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onChange}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      {specializations.length > 0 && (
        <>
          <Box sx={infoStyle}>
            <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
              Recruitment Sector:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {sectors.map(({ id, name }) => (
                <Chip key={id} label={name} color={'primary'} />
              ))}
            </Box>
            <Divider />
          </Box>

          <Box sx={infoStyle}>
            <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
              Specialization:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {specializations.map(({ id, name }) => (
                <Chip key={id} label={name} color={'primary'} />
              ))}
            </Box>
            <Divider />
          </Box>
        </>
      )}
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    }
  }
})

const headStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const infoStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})
