import { Card, Box, Typography, Chip, Theme, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApiSelector, ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { assetSelector } from '../Uploader/Uploader'
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter'
import { Avatar } from '../Avatar'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { PAGES } from '../../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../state/atoms/profileState'
import { citySelector } from '../form-fields/CityAutocomplete'
import { HideButtonAndAlert } from '../HideButtonAndAlert'
import { Button } from '../ui-kits/button/button'
import { chatEnabled } from '../../config/features'


import { BillingIcon } from '../icons/billingIcon'
import { LocationIcon } from '../icons/location'

export const employeeListItemSelector = ApiSelector('Employee')({
  id: true,
  profile: {
    displayName: true,
    isHidden: true,
    id: true,
    conversations: [
      { profileId: '' },
      {
        id: true
      }
    ]
  },
  profileId: true,
  experience: true,
  firstname: true,
  lastname: true,
  avatar: assetSelector,
  jobTypes: {
    id: true,
    name: true
  },
  preferSalary: {
    from: true,
    to: true,
    schedule: true,
    currency: {
      id: true
    }
  },
  locationType: true,
  currentCity: citySelector,
  specializations: {
    id: true,
    name: true
  }
})

export type EmployeeListItem = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeListItemSelector>

interface Props {
  employee: EmployeeListItem,
  confirm: (employeeId: string) => void
  onClick: (employee: EmployeeListItem) => void
}

export const CandidateListCard = ({ employee, confirm, onClick }: Props) => {
  const { id: profileId } = useRecoilValue(profileState)
  const { t } = useTranslation()
  const from = useCurrencyFormatter((employee.preferSalary?.from as number) || 0, employee.preferSalary?.currency.id)
  const to = useCurrencyFormatter((employee.preferSalary?.to as number) || 0, employee.preferSalary?.currency.id)
  const handleSendMessage = () => {
    onClick(employee)
  }

  return (
    <Card sx={[wrapper, {position: 'relative'}]}>
      <Box sx={[contentWrapper, {pb: '68px', }]}>
          <Box sx={headerContent}>
            {!employee.profile.isHidden ?
              <Avatar src={employee.avatar?.url} alt={'avatar'} sx={avatarWrapper} />
              :
              <Avatar alt={'avatar'} sx={avatarWrapper} />
            }
        
            {
              !employee.profile.isHidden ?
                <Box sx={cardInfoWrapper}>
                  <Box sx={[contentWrapper, { gap: '2px' }]}>
                    <Typography variant={'subtitle1'} sx={candidateTitle}>
                      <Link
                        sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                        component={RouterLink}
                        to={generatePath(PAGES.EMPLOYEE, {
                          profileId,
                          employeeId: employee.id
                        })}
                      >
                        {employee.firstname} {employee.lastname}
                      </Link>
                    </Typography>
                    <Typography variant={'body1'}
                      sx={subTitle}>{employee.specializations?.map(({ name }) => name).join(' * ')}</Typography>
                    {!!employee.experience && (
                      <Typography variant={'body1'} sx={subTitle} color={(theme) => theme.palette.grey[800]}>
                        {t(`enum.Experience.${employee.experience}`)}
                      </Typography>
                    )}
                  </Box>
                  {!employee.profile.isHidden &&
                    <Avatar src={employee.avatar?.url} alt={'avatar'} sx={mobileAvatarWrapper} />
                  }
                </Box>
                :
                <HideButtonAndAlert confirm={() => confirm(employee.profile.id)} displayName={employee.profile.displayName} />
            }
          </Box>
          
          <Box sx={profileContentBox}>
            {(employee.jobTypes?.length || []) > 0 && (
              <Box sx={chipsWrapper}>
                {employee.jobTypes?.map((jobType) => (
                  <Typography key={jobType.id} noWrap={true} sx={chipsTitle}>
                    <Chip label={jobType.name} key={jobType.id} />
                  </Typography>
                ))}
              </Box>
            )}
            <Box sx={[contentWrapper , { gap: '10px' }]}>
              {!!(from && to) && employee.preferSalary?.schedule !== undefined && (
                <Box sx={candidateInfo}>
                  <Box sx={[listBox]}>
                    <BillingIcon fill='#FFBE40' />
                    <Typography variant={'body2'} color={(theme) => theme.palette.text.primary}>
                      Salary
                    </Typography>
                  </Box>
                  <Typography variant={'overline'} sx={subTitle}>
                      {from} - {to} * {t(`enum.SalaryPeriod.${employee.preferSalary.schedule}`)}
                    </Typography>
                </Box>
              )}
              {/*  <Box sx={[contentWrapper, { gap: "6px" }]}>
                <Typography
                  variant={"body2"}
                  color={(theme) => theme.palette.text.secondary}
                >
                  Experience
                </Typography>
                <Typography variant={"body1"}>{experienceAmount}</Typography>
              </Box> */}
              <Box sx={candidateInfo}>
                  <Box sx={[listBox]}>
                    <LocationIcon fill='#FFBE40' />
                    <Typography variant={'body2'} color={(theme) => theme.palette.text.primary}>
                      Location
                    </Typography>
                  </Box>

                  {!!employee.currentCity && employee.locationType && (
                    <Typography variant={'overline'} sx={subTitle}>
                      {employee.currentCity?.name}, {employee.currentCity?.country.name}
                      {' * '}
                      {t(`enum.JobLocationType.${employee.locationType}`)}
                    </Typography>
                  )}
              </Box>
            </Box>
          </Box>
        {chatEnabled &&
        <Box sx={buttonBox}> 
          <Button
            size={'small'}
            onClick={handleSendMessage}
            sx={sendButton}
            variant='outlined'
          >
            Send Message
          </Button>
        </Box>}
      </Box>
    </Card>
  )
}

const sendButton = (theme: Theme) => ({
  mr: 'auto',
  // mt: theme.spacing(1),
  textAlign: 'center',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

const cardInfoWrapper = (theme: Theme) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: '8px',
  }
})

const mobileAvatarWrapper = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '50px',
    height: '50px',
    margin: '0 !important',
  }
})

const subTitle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
  }
})

const candidateTitle = (theme: Theme) => ({
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
  }
})

const avatarWrapper = (theme: Theme) => ({
  width: '48px',
  height: '48px',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
})

const chipsTitle = (theme: Theme) => ({
  '& .MuiChip-root': {
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  [theme.breakpoints.between(370, 420)]: {
    maxWidth: '190px'
  }
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  width: '100%',
  boxShadow: 'none',
  padding: '0 !important',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row-reverse'
  }
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  // gap: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})


const headerContent = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'start',
  gap: '16px',
  padding: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
})
const chipsWrapper = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  mb: theme.spacing(1.5),
})

const profileContentBox = (theme: Theme) => ({
  padding: '24px',
})

const visibleButton = (theme: Theme) => ({
  width: '168px',
  padding: '14px 17px',
  justifyContent: 'space-between',
  height: '38px'
})

const candidateInfo = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#FFF4E5',
  padding: '10px',
  borderRadius: '4px',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
    alignItems: 'start',
    gap: '10px',
    flexWrap: 'wrap',
    flexDirection: 'column',
  }
})

const listBox = (theme: Theme) => ({
  display: 'flex',
  gap: '4px',})



const buttonBox = (theme: Theme) => ({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: theme.spacing(2),
  textAlign: 'center',
  position: 'absolute',
  bottom: '0',
  width: '100%',
})