import { AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useDebounce } from 'use-debounce'
import { useQueryRequest } from '../../services/api'

export const citySelector = ApiSelector('City')({
  id: true,
  name: true,
  region: {
    name: true,
  },
  country: {
    name: true,
  },
})

export type CityFields = ApiInputType<ApiGraphQLTypes['City'], typeof citySelector>

interface Props {
  multiple?: boolean
  name: string
  label?: string
}

export const CityAutocomplete = ({ name, label, multiple }: Props) => {
  const [field, meta, helpers] = useField(name)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [searchCityName, setSearchCityName] = useState<string>('')
  const [citySearch] = useDebounce(searchCityName, 100)

  const { data, isLoading } = useQueryRequest(
    ['searchCity', citySearch],
    {
      getCities: [
        {
          take: 20,
          where: {
            name: {
              startsWith: citySearch,
              mode: ApiTypes.QueryMode.insensitive
            },
            position: {
              gte: citySearch ? 0 : 1
            }
          }
          },
        citySelector,
      ],
    },
    {
      // enabled: citySearch.length > 2,
      onSuccess: (response) => {
        let selectedCity = field.value

        if (!multiple && selectedCity) {
          selectedCity = [selectedCity]
        }

        if (Array.isArray(selectedCity)) {
          response.getCities.push(...selectedCity)
        }
      },
    },
  )

  const invalidateSearchCity = () => queryClient.invalidateQueries('searchCity')

  return (
    <Field
      multiple={multiple}
      loading={isLoading}
      name={name}
      component={Autocomplete}
      onInputChange={(event: any, newInputValue: string) => {
        setSearchCityName(newInputValue)
      }}
      noOptionsText={t('startTyping')}
      options={data?.getCities || []}
      getOptionDisabled={() => Array.isArray(field.value) && field?.value?.length > 4}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option: CityFields, value: CityFields) => {
        return option.id === value.id
      }}
      getOptionLabel={(option: CityFields) => {
        if (option.id) {
          if (option.region.name !== option.name) {
            return `${option.name}, ${option.region.name}, ${option.country.name}`
          } 
          
          return `${option.name}, ${option.country.name}`
        }

        return ''
      }}
      onChange={(e: any, v: any) => {
        helpers.setValue(v)
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          label={label}
          variant='outlined'
          onBlur={() => invalidateSearchCity()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
