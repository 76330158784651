import { useState, useRef } from 'react'
import { 
  Card, 
  Theme, 
  Box, 
  useMediaQuery,
  CircularProgress
} from '@mui/material'
import { Main, Content } from '../components/common-files/common-styles'
import TempWrapperNew from '../components/tempWrapperNew'
import { authMutation, useAuthQueryRequest } from '../services/api'
import { ApiSelector, ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { useQueryClient } from 'react-query'
import { SaveEmployeeValues, useUpdateEmployee } from '../hooks/useEmployee'
import { assetSelector, Asset } from '../components/Uploader/Uploader'
import { useProfile } from '../hooks/useProfile'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'


import { UploadCv } from '../components/not-cms/upload-cv'
import { CareerNarrative } from '../components/not-cms/employee-edit-modal/career-narrative'
import { BillingComponent } from '../components/not-cms/employee-edit-modal/billing-component'
// import { LanguageComponent } from '../components/not-cms/employee-edit-modal/language-component'
import { EmployeePreferences } from '../components/not-cms/employee-edit-modal/employee-preferences'
import { SpecialiseInComponent } from '../components/not-cms/employee-edit-modal/specialise-in-component'
import { ClientsComponent } from '../components/not-cms/employee-edit-modal/clients-component'
import { EmployeeManage } from '../components/not-cms/employee-edit-modal/employee-manage'
import { HideFromModal } from '../components/not-cms/employee-edit-modal/hide-from-modal'
import { ProfileStrength } from '../components/not-cms/profile-strength'
import theme from '../styles/theme'
import {PageTitle} from '../components/pageTitle'
import { employeeSelector } from './dashboard-employee'
import SidebarInternalCandidate from '../components/SidebarInternalCandidate'
import Alert from '@mui/material/Alert';

export enum TypeOfComponent {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}


const profileHideMeSelector = ApiSelector('Profile')({
  id: true,
  companies: {
    profileId: true,
    name: true,
    logo: {
      url: true,
    },
    isMain: true,
    sisterCompanies: {
      profileId: true,
      name: true,
      isMain: true,
      status: true,
    }
  }
})


export type ProfileHideMeFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof profileHideMeSelector>

const ProfileEmployee = () => {

  const profile = useRecoilValue(profileState)
  const updateEmployeeMutation = useUpdateEmployee(employeeSelector)  
  const queryClient = useQueryClient()
  const { refetch: refetchProfile } = useProfile()
  
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    isRefetching: isEmployeeDataRefetching
  } = useAuthQueryRequest(
    'employee',
    {
      getEmployee: [{ id: profile?.employee?.id as string }, employeeSelector]
    },
    {
      enabled: profile?.employee?.id !== undefined
    }
  )

  const saveEmployee = async (values: Partial<SaveEmployeeValues>) => {
    if (employeeData?.getEmployee) {
      const data = await updateEmployeeMutation.mutateAsync({
        values: {
          id: employeeData.getEmployee.id,
          ...values,
        },
        employeeData: employeeData.getEmployee
      })

      if (data) {
        queryClient.setQueryData('employee', {
          getEmployee: data,
        })
      }
    }

    await refetchProfile()

  }

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)


  const handleSideDropDown = (index: number) => {
    setCurrentMenuIndex(index);
    srollToElement(index);
  };

  function srollToElement(index : number) {
    const offset = -100; 
    const element = sectionRefs.current[index];
    if (element) {
      const offsetPosition = element.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  const updateEmployeeAfterHideMeSaved = (
    hiddenFromProfile: ProfileHideMeFields[],
    visibleForProfile: ProfileHideMeFields[],
    hideFromEveryone: boolean,
    displayName?: string | undefined
  ) => {

    if (employeeData?.getEmployee) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          ...{
            profile: {
              ...employeeData.getEmployee.profile,
              visibleForProfile,
              hiddenFromProfile,
              hideFromEveryone,
              displayName,
            },
          },
        },
      })
    }

  }

  if (isEmployeeDataLoading || isEmployeeDataRefetching || !employeeData?.getEmployee) {
    return <CircularProgress />
  }

  const onCvUploaded = async (file: Asset) => {
    if (!profile?.employee?.id) {
      return
    }

    const { updateEmployee } = await authMutation({
      updateEmployee: [
        {
          id: profile.employee.id,
          input: {
            cv: {
              connect: {
                id: file.id,
              },
            },
          },
        },
        {
          id: true,
          cv: assetSelector,
        },
      ],
    })

    if (employeeData?.getEmployee && updateEmployee?.cv) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          cv: updateEmployee.cv,
        },
      })
    }
  }

  const onCvDeleted = async () => {
    if (!profile?.employee?.id) {
      return
    }

    if (employeeData?.getEmployee) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          cv: null,
        },
      })
    }
  }

  return (
    <TempWrapperNew>
      <Main sx={wrapper}>
        <Content sx={contentWrapper}>
          <PageTitle title='Profile'></PageTitle>
          <Box sx={profileStrengthBox}>
            <ProfileStrength value={profile?.employee?.strength ? profile?.employee?.strength : 0} showBar={true} isClient={false} isProfileHidden={profile?.hideFromEveryone} />
          </Box>
            <Box sx={tabWrapper}>
                { !matchSm &&
                  <SidebarInternalCandidate handleSideDropDown={handleSideDropDown} currentMenuIndex={currentMenuIndex} />
                }

                <Box sx={tabContentBox}>

                  <Alert severity="info" sx={{ mb: 2, }}>Make your profile stand out by sharing detailed billing information from as many previous years as possible. This will increase your chances of catching the eye of hiring managers and getting more interviews.</Alert>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[0] = ref as HTMLDivElement)}>
                      <CareerNarrative
                        recruitRoles={employeeData.getEmployee.recruitRoles}
                        reasonForLeavingRecentEmployer={employeeData.getEmployee.reasonForLeavingRecentEmployer}
                        jobTypes={employeeData.getEmployee.jobTypes}
                        jobCriterias={employeeData.getEmployee.jobCriterias}
                        onSave={saveEmployee}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[1] = ref as HTMLDivElement)}>
                      <EmployeePreferences
                        preferSalary={employeeData.getEmployee?.preferSalary}
                        deskType={employeeData.getEmployee?.deskType}
                        deskTypeRatio={employeeData.getEmployee?.deskTypeRatio}
                        recruitCountries={employeeData.getEmployee?.recruitCountries}
                        onSave={(values) => {
                          saveEmployee({ ...values })
                        }}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[2] = ref as HTMLDivElement)}>
                    <SpecialiseInComponent
                        specializations={employeeData.getEmployee?.specializations || []}
                        onSave={({ specializations }) => saveEmployee({ specializations })}
                        isSaves={updateEmployeeMutation.isLoading}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[3] = ref as HTMLDivElement)}>
                      <BillingComponent
                          salaryHistory={employeeData.getEmployee.salaryHistory}
                          onSave={(salaryHistory) => saveEmployee({ salaryHistory })}
                        />
                  </Card>

                  <Card sx={[cardWraper, {paddingBottom: '32px !important',}]} ref={(ref) => (sectionRefs.current[4] = ref as HTMLDivElement)}>
                      <UploadCv
                        cv={employeeData.getEmployee.cv}
                        type={TypeOfComponent.EDIT}
                        onCvUploaded={onCvUploaded}
                        onCvDeleted={onCvDeleted}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[5] = ref as HTMLDivElement)}>
                      <EmployeeManage
                        onSave={async (values) => {
                          await saveEmployee({ ...values })
                        }}
                        managedTeam={employeeData.getEmployee.managedTeam}
                        teammatesCount={employeeData.getEmployee.teammatesCount}
                        teamAvgSalary={employeeData.getEmployee.teamAvgSalary}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[6] = ref as HTMLDivElement)}>
                    <HideFromModal
                        hiddenFromProfile={employeeData.getEmployee.profile.hiddenFromProfile || []}
                        visibleForProfile={employeeData.getEmployee.profile.visibleForProfile || []}
                        hideFromEveryone={employeeData.getEmployee.profile.hideFromEveryone}
                        onSave={updateEmployeeAfterHideMeSaved}
                        nickName={employeeData.getEmployee.profile.displayName}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[7] = ref as HTMLDivElement)}>
                    <ClientsComponent {...employeeData.getEmployee} onSave={saveEmployee} />
                  </Card>

                </Box>
              </Box>
        </Content>
      </Main>
    </TempWrapperNew>
  )
}

const wrapper = (theme: Theme) => ({
  paddingTop: '0',
  paddingBottom: '0',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('xs')]:{
    '&': {
      '& .MuiBox-root': {
        '& .MuiBox-root.MuiGrid-item': {
          width: '100%',
        }
      },
    },
  }
})

const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  marginBottom: '0',
  // gap: '32px',
  [theme.breakpoints.down('sm')]: {
    // gap: theme.spacing(2.5),
    // marginBottom: theme.spacing(6),
  },
})

const tabWrapper = (theme: Theme) => ({
  display: 'flex', 
  alignItems: 'start',
  height: '100%',
  flexDriection: 'row',
  gap: '24px',
})

const tabContentBox = (theme: Theme) => ({
  width: '82%',
  '& > .MuiCard-root': {
    paddingBottom: theme.spacing(18),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& > .MuiCard-root': {
      paddingBottom: theme.spacing(3),
    }
  },
})

const profileStrengthBox = (theme: Theme) => ({
  mt: theme.spacing(1),
  width: '100%',
  '& .infoStyle': {
    textAlign: 'right',
  },
  [theme.breakpoints.down('md')]: {
    mt: theme.spacing(2),
    '& .infoStyle': {
      textAlign: 'left',
    },
    '& .progressWrapper': {
      '& .MuiTypography-h2': {
        fontSize: '18px',
        lineHeight: '20px',
      }
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .progressWrapper': {
      '& .MuiTypography-h2': {
        fontSize: '16px',
      }
    },
  }
})

const cardWraper = (theme: Theme) => ({
  marginBottom: 6,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 3,
  }
})

export default ProfileEmployee
