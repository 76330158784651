import { InputAdornment, TextField, AutocompleteRenderInputParams } from '@mui/material';
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { ApiTypes } from 'recruticka-frontend-libs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Readiness } from '../icons/readiness';
import { autocompleteField } from './SpecializationsAutocomplete'

export const ReadinessForWorkAutocomplete = () => {
  const { t } = useTranslation();
  const [field,] = useField('jobLocationType');

  return (
    <Field
      name={field.name}
      component={Autocomplete}
      popupIcon={<KeyboardArrowDownIcon />}
      sx={autocompleteField}
      options={Object.values(ApiTypes.JobLocationType)}
      getOptionLabel={(value: ApiTypes.JobLocationType) => value && t(`enum.JobLocationType.${value}`)}
      fullWidth
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            placeholder={'Type of Work'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Readiness />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    >

    </Field>
  )
}