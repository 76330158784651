import { Box, Theme } from '@mui/material'
import { FieldArray, useField } from 'formik'
import Uploader, { Asset } from '../../Uploader/Uploader'
import { IMAGES, DOCUMENTS } from '../../../config/uploaderTypes'
import { Salary } from '../../form-fields/Salary'

export const BudgetForBasicSalaries = () => {
  const [filesField] = useField<Asset[]>('budgetForBasicSalariesFiles')

  return (
    <Box sx={wrapper}>
      <Salary fieldsPrefix='basicSalary.' />

      <FieldArray
        name={filesField.name}
        render={(arrayHelpers) => (
          <>
            <Uploader
              assets={filesField.value}
              gridView
              allowedFileTypes={[...IMAGES, ...DOCUMENTS]}
              multiple
              onFileUploaded={(asset) => arrayHelpers.push(asset)}
              onFileDeleted={({ id }) => {
                const index = filesField.value.findIndex((asset) => asset.id === id)
                arrayHelpers.remove(index)
              }}
            />
          </>
        )}
      />
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  '& .slider': {
    width: '100%'
  },
  '& .MuiSlider-markLabel:nth-child(4)': {
    left: '2%!important',
    color: theme.palette.grey[800], 
  },
  '& .MuiSlider-markLabel:nth-child(6)': {
    left: '98%!important',
    color: theme.palette.grey[800], 
  },
  [theme.breakpoints.down('md')]: {
    '& .slider': {
      width: '84%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .slider': {
      width: '80%',
      marginTop: '15px'
    }
  }
})
