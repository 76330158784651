import React, { useEffect, useMemo, useState } from 'react'
import { Box, Theme, Avatar, IconButton, Typography } from '@mui/material'
import theme from '../../../styles/theme'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import ReplayIcon from '@mui/icons-material/Replay'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { ChatVariant } from '../chat-box'
import { ReplyedMessage } from './replyed-message'
import { FileGallery } from '../../FileGallery'
import { buildAssetUrl } from '../../../utilities/buildAssetUrl'
import { SystemStyleObject } from '@mui/system'
import dayjs from 'dayjs'
import { Message, ReplyMessageTypes } from '../../../hooks/useMessages'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../../../state/atoms/profileState'
import { Asset, assetSelector } from '../../Uploader/Uploader'
import ConversationProfile from './ConversationProfile'
import { ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import Linkify from 'linkify-react';
import { LastMessage } from '../../../hooks/useConversations'


interface Props {
  variant: ChatVariant
  message?: Message
  replyMessage?: (message: Message | undefined) => void
  editMessage?: (message: Message | undefined) => void
  participants?: any
  deleteMessage?: (id: string) => void
  deleteAsset?: (id: string) => void
  conversation?: any
  lastMessage?: LastMessage
  authorLastMessage?: string,
  isCompany: boolean,
  isInSidebar: boolean,
}
export interface JobTypes {
  name: string
}

export interface Participant {
  id: string
  name: string
  displayName: string | undefined
  profileId: string
  jobTypes: JobTypes[]
  isHidden?: boolean
  profile: {
    deletedAt: null | ApiGraphQLTypes['DateTime']
  }
  avatar: Asset
  firstname: string
  lastname: string,
  role: ApiTypes.Role,
  
  company: {
    name: string,
    logo: Asset,
    jobTypes: JobTypes[]
  }
}

interface AuthorMessage {
  content: string,
  assets?: Asset[],
  id: string,
  createdAt: Date | unknown,
  author: {
    // profileId?: string,
    profile: {
      role: ApiTypes.Role
      deletedAt?: ApiGraphQLTypes['DateTime'] | null,
      companies: null,
      displayName?: string | undefined
      company: {
        name: string,
        logo?: Asset
        id: string,
        jobTypes?: JobTypes[]
      } | undefined,
      id: string,
      isHidden?: boolean,
      employee?: {
        profileId: string
        firstname?: string | undefined,
        lastname?: string | undefined,
        avatar?: Asset,
      } | undefined
    }
  },
  replyedTo?: ReplyMessageTypes[]
}

interface Style {
  gridItemSxLeft?: SystemStyleObject
  gridContainer?: SystemStyleObject
  gridItemSxRight?: SystemStyleObject
  boxWrapper?: SystemStyleObject
  boxInfo?: SystemStyleObject
  boxDesc?: SystemStyleObject
  date?: SystemStyleObject
  name?: SystemStyleObject
  authorStyle?: SystemStyleObject
  desc?: SystemStyleObject
  wrapper: SystemStyleObject
  popupBtns?: SystemStyleObject
  chatCompanionAvatar?: SystemStyleObject
  styleReplyMessage?: SystemStyleObject
  replyedMessage?: SystemStyleObject
  assetGallery?: SystemStyleObject
  chatList?: boolean
}

export const ChatCompanion = ({
  isCompany,
  isInSidebar,
  variant,
  editMessage,
  message,
  participants,
  replyMessage,
  deleteMessage,
  conversation,
  lastMessage,
  authorLastMessage,
  deleteAsset }: Props) => {
  const [popupShow, setPopupShow] = useState<boolean>(false)
  const [participant, setParticipant] = useState<Participant>()
  const profile = useRecoilValue(profileState)
  const {
    gridItemSxLeft,
    gridContainer,
    gridItemSxRight,
    boxWrapper,
    boxInfo,
    boxDesc,
    date,
    name,
    authorStyle,
    desc,
    chatCompanionAvatar,
    styleReplyMessage,
    popupBtns,
    wrapper,
    replyedMessage,
    assetGallery,
    chatList
  } = useMemo<Style>(() => {
    if (variant === ChatVariant.CHAT_WIDGET) {
      return stylesForWidget(theme)
    } else if (variant === ChatVariant.CHAT_LIST) {
      return stylesForLeftSide(theme)
    }
    return stylesForRightSide(theme)
  }, [variant])

  const handleOpenPopup = () => {
    return setPopupShow(!popupShow)
  }

  const handleLeave = () => {
    setPopupShow(false)
  }

  const handleDeleteMessage = async () => {
    setPopupShow(false)
    if (message?.id) {
      if (deleteMessage) {
        deleteMessage(message.id)
      }
      // await deleteMessage.mutateAsync(message.id)
      // await queryClient.invalidateQueries()
    }
  }

  const handleReplyMessage = () => {
    setPopupShow(false)
    if (replyMessage) {
      replyMessage(message)
    }
  }

  const handleUpdateMessage = () => {
    setPopupShow(false)
    if (editMessage)
      editMessage(message)
  }
  const authorMessage: AuthorMessage | undefined = useMemo(() => {
    if (variant !== ChatVariant.CHAT_LIST && message) {
      const deletedAt = message.author.profile.deletedAt as ApiGraphQLTypes['DateTime']
      if (message?.author?.profile?.companies?.length) {
        return {
          ...message,
          author: {
            profile: {
              role: message?.author.profile.role,
              employee: undefined,
              company: { ...conversation?.company, },
              companies: null,
              firstname: undefined,
              lastname: undefined,
              id: message?.author?.profileId
            }
          },
        }
      } else {
        return {
          ...message,
          author: {
            profile: {
              ...message?.author?.profile,
              companies: null,
              deletedAt: deletedAt,
              company: undefined,
            }
          },
        }
      }
    }
  }, [message])

  useEffect(() => {
    if (variant === ChatVariant.CHAT_LIST) {
      participants.map((el: any) => {
        if (el.employee) {
          if (el.id !== profile.id) {
            setParticipant({ ...el.employee, isHidden: el.isHidden, role: el.role, displayName: el.displayName })
          }
        } else {
          if (el.id !== profile.id) {
            setParticipant({ ...el, company: { ...conversation.company }, role: el.role, isHidden: el.isHidden })
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (message?.content === '' && message?.assets?.length === 0) {
      handleDeleteMessage()
    }
  }, [message])


  return (
    <Box
      sx={wrapper}
      onMouseLeave={handleLeave}
    >
      <Box sx={[styleEditPopup, popupShow && { display: 'block!important' }]} className={'showPopup'}>
        <IconButton onClick={handleReplyMessage}>
          <ReplayIcon fontSize={'small'} />
          <Typography variant={'body1'}>Reply</Typography>
        </IconButton>
        <IconButton disabled={message?.author.profileId !== profile.id} onClick={handleUpdateMessage}>
          <EditIcon fontSize={'small'} />
          <Typography variant={'body1'}>Edit</Typography>
        </IconButton>
        <IconButton disabled={message?.author.profileId !== profile.id} onClick={handleDeleteMessage}>
          <DeleteForeverIcon fontSize={'small'} />
          <Typography variant={'body1'}>Delete</Typography>
        </IconButton>
      </Box>
      <Box sx={gridContainer}>
        <Box sx={gridItemSxLeft}>
          {participant ?
            <ConversationProfile participant={participant} chatList={chatList} />
            :
            <>
              {
                authorMessage?.author?.profile?.role === ApiTypes.Role.EMPLOYER ?
                  <Box sx={chatCompanionAvatar}>
                    {authorMessage?.author?.profile.company?.logo ?
                      <img src={buildAssetUrl(authorMessage?.author.profile.company?.logo.url)} alt={''} />
                      :
                      <Avatar />
                    }
                  </Box>
                  :
                  <Box sx={chatCompanionAvatar}>
                    {(authorMessage?.author?.profile?.employee?.avatar &&
                      !authorMessage?.author?.profile?.isHidden ||
                      profile.id === authorMessage?.author?.profile.id) ?
                      authorMessage?.author?.profile?.employee?.avatar && !authorMessage?.author.profile.deletedAt ?
                      <img src={buildAssetUrl(authorMessage?.author?.profile?.employee?.avatar?.url)} alt={''} />
                        :
                        <Avatar />
                      :
                      <Avatar />
                    }
                  </Box>
              }
            </>
          }
        </Box>
        <Box sx={gridItemSxRight}>
          <Box sx={boxWrapper}>
            <Box sx={boxInfo}>
              {participant ?
                <Typography sx={authorStyle} noWrap={true}>
                  {(participant?.role === ApiTypes.Role.EMPLOYEE) ?
                    !participant.isHidden ?
                      !participant.profile.deletedAt ?
                        `${participant.firstname} ${participant.lastname}`
                        :
                        'deleted account'
                      :
                      !participant.displayName ?
                      'Anonymous Candidate' : participant.displayName
                    :
                    participant?.company?.name
                  }
                </Typography>
                :
                <Typography sx={authorStyle}>
                  { !authorMessage?.author?.profile?.deletedAt ?
                    authorMessage?.author?.profile?.employee?.firstname
                    :
                    'deleted account'
                  }
                </Typography>
              }
              <Typography sx={date}>{dayjs(conversation?.lastMessage?.createdAt as Date).format('MMM DD')}</Typography>
            </Box>
            <Box sx={boxDesc}>
              {!isInSidebar &&
                <Typography sx={name}>
                  {
                    !authorLastMessage ?
                      authorMessage?.author?.profile.role === ApiTypes.Role.EMPLOYER ?
                        authorMessage?.author?.profile.company?.name
                        :
                        (!authorMessage?.author?.profile?.isHidden || profile?.id === authorMessage?.author?.profile.id) ?
                          !authorMessage?.author?.profile?.deletedAt ?
                            `${authorMessage?.author?.profile?.employee?.firstname} ${authorMessage?.author?.profile?.employee?.lastname}`
                            :
                            'deleted account'
                          :
                          authorMessage?.author?.profile?.displayName
                      : lastMessage && `${authorLastMessage}:`
                  }
                  <span
                    style={
                      (!authorMessage?.author?.profile.company?.name
                        && !authorMessage?.author?.profile?.employee?.firstname
                      && !authorMessage?.author?.profile?.displayName
                      && !authorMessage?.author?.profile?.deletedAt) ?
                        {marginLeft: 0} : {}
                    }
                  >
                    {dayjs(authorMessage?.createdAt as Date).format('hh:mm A')}
                  </span>
                </Typography>
              }
              <Box sx={replyedMessage}>
                {
                  !!authorMessage?.replyedTo?.length &&
                  authorMessage.replyedTo.map((replyedMessage: ReplyMessageTypes) => (
                    <ReplyedMessage key={replyedMessage.id} style={styleReplyMessage} message={replyedMessage} />
                  ))
                }
              </Box>
              { authorMessage?.content &&
                <Typography
                  sx={desc}
                  noWrap={variant === ChatVariant.CHAT_LIST}
                >
                  <Linkify options={{target: '_blank'}} >
                    {authorMessage?.content}
                  </Linkify>
                </Typography>
              }
              { lastMessage &&
                <Typography
                  sx={desc}
                  noWrap={variant === ChatVariant.CHAT_LIST}
                >
                  {lastMessage.content ? lastMessage.content : 'Attachment'}
                </Typography>
              }
              <Box sx={assetGallery}>
                {!!authorMessage?.assets &&
                  <FileGallery variant={'gallery-view'} handleDeleteAsset={deleteAsset} assets={authorMessage?.assets} />
                }
              </Box>
            </Box>
            <IconButton sx={popupBtns} onClick={handleOpenPopup}>
              <MoreVertIcon fontSize={'small'} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const stylesForRightSide = (theme: Theme) => ({
  wrapper: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      '& .showPopup': {
        display: 'block'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        '& .showPopup': {
          display: 'none'
        }
      },
      '& .showPopup': {
        display: 'none'
      },
    }
  },
  assetGallery: {
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]:{
      marginLeft: '-60px'
    }
  },
  gridContainer: {
    paddingX: '23px',
    paddingY: '16px',
    alignItems: 'flex-start',
    justifyContent: 'start',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      paddingX: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '24px',
      paddingRight: '16px'
    }
  },
  gridItemSxRight: {
    paddingLeft: '15px',
    display: 'flex',
    width: '100%'
  },
  boxWrapper: {
    width: '100%',
    height: '100%',
    padding: 0,
    display: 'flex'
  },
  boxInfo: {
    padding: 0,
    justifyContent: 'start',
    alignItems: 'center'
  },
  boxDesc: {
    padding: 0,
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%'
  },
  date: {
    display: 'none'
  },
  authorStyle: {
    display: 'none'
  },
  name: {
    fontWeight: 600,
    margin: 0,
    padding: 0,
    marginY: '8px',
    color: '#000000',
    fontSize: '14px',
    lineHeight: '17px',
    '& span': {
      color: 'rgba(20, 29, 36, 0.4)',
      fontSize: '12px',
      marginLeft: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: '8px',
      '& span': {
        color: 'rgba(20, 29, 36, 0.4)',
        fontSize: '12px',
        marginLeft: 'unset',
        marginBottom: '6px'
      }
    }
  },
  desc: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    lineHeight: '20px',
    margin: 0,
    padding: 0,
    color: '#000'
  },
  popupBtns: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'start',
      marginLeft: 'auto',
      height: '36px'
    }
  },
  chatCompanionAvatar: {
    marginTop: '12px',
    '& img': {
      borderRadius: '50%',
      objectFit: 'cover',
      width: '40px',
      height: '40px'
    }
  },
  styleReplyMessage: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 0,
    margin: 'auto 0 17px',
    '& .reply-wrapper': {
      paddingY: '4px'
    }
  },
  chatList: false,
})

const stylesForLeftSide = (theme: Theme) => ({
  wrapper: {
    position: 'relative',
    '& .showPopup': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    }
  },
  gridContainer: {
    paddingX: '23px',
    paddingY: '16px',
    alignItems: 'center',
    // justifyContent: 'space-between',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      paddingX: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid',
      borderColor: '#F2F5F8'
    }
  },
  replyedMessage: {
    display: 'none'
  },
  assetGallery: {
    display: 'none'
  },
  chatCompanionAvatar: {
    '& img': {
      width: '52px',
      height: '52px',
      objectFit: 'cover',
      borderRadius: '50%'
    }
  },
  gridItemSxRight: {
    paddingLeft: '14px',
    width: '100%'
  },
  boxWrapper: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  boxInfo: {
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex'
  },
  boxDesc: {
    padding: 0,
    display: 'grid'
  },
  date: {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '13px',
    margin: 0,
    color: 'rgba(20, 29, 36, 0.4)',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  authorStyle: {
    fontWeight: 600,
    fontSize: '16px',
    margin: 0,
    padding: 0,
    lineHeight: '20px',
    width: '138px',
    color: '#141D24',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      width: '125px',
      display: 'inline'
    }
  },
  name: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    margin: 0,
    padding: 0,
    marginY: '5px',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    },
    '& span': {
      display: 'none'
    }
  },
  desc: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    margin: 0,
    padding: 0,
    color: 'rgba(20, 29, 36, 0.4)',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  popupBtns: {
    display: 'none'
  },
  gridItemSxLeft: {
    width: '52px'
  },
  chatList: true
})

const stylesForWidget = (theme: Theme) => ({
  chatList: false,
  wrapper: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      '& .showPopup': {
        display: 'block'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        '& .showPopup': {
          display: 'none'
        }
      }
    }
  },
  gridContainer: {
    paddingX: '23px',
    paddingY: '16px',
    alignItems: 'flex-start',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      paddingY: '18px',
      paddingLeft: '24px',
      paddingRight: '16px',
    }
  },
  assetGallery: {
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]:{
      marginLeft: '-60px'
    }
  },
  gridItemSxRight: {
    paddingLeft: '14px',
    width: '100%'
  },
  boxWrapper: {
    padding: 0,
    display: 'flex',
    alignItems: 'start',
    width: '100%'
  },
  chatCompanionAvatar: {
    marginTop: '12px',
    width: '40px',
    height: '40px',
    '& img': {
      width: '40px',
      height: '40px',
      objectFit: 'cover',
      borderRadius: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiAvatar-root': {
        width: '32px',
        height: '32px',
      },
      '& img': {
        width: '32px',
        height: '32px',
        borderRadius: '50%'
      }
    }
  },
  boxInfo: {
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex'
  },
  boxDesc: {
    padding: 0,
    width: '100%'
  },
  date: {
    display: 'none'
  },
  authorStyle: {
    display: 'none'
  },
  name: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '12px',
    margin: 0,
    padding: 0,
    marginY: '5px',
    color: '#000000',
    '& span': {
      color: 'rgba(20, 29, 36, 0.4)',
      fontSize: '12px',
      marginLeft: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: '6px',
      '& span': {
        color: 'rgba(20, 29, 36, 0.4)',
        fontSize: '12px',
        marginLeft: 'unset',
        marginBottom: '6px'
      }
    }
  },
  desc: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
    whiteSpace: 'pre-wrap',
    margin: 0,
    padding: 0,
    color: '#000',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  popupBtns: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      height: '36px'
    }
  },
  styleReplyMessage: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 0,
    margin: 'auto 0 17px'
  }
})

const styleEditPopup = (theme: Theme) => ({
  boxSizing: 'bor-box',
  backgroundColor: '#fff',
  boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 6px 10px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.02)',
  borderRadius: '6px',
  padding: '2px 10px',
  position: 'absolute',
  right: '40px',
  top: '-19px',
  zIndex: '2',
  pointerEvents: 'all',
  display: 'none',
  '& p': {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    flexDirection: 'column',
    padding: 'unset',
    top: '-15px',
    bottom: 'unset',
    '& p': {
      display: 'block',
      color: '#141D24',
      fontFamily: 'Roboto',
      weight: '400'
    },
    '& button': {
      display: 'flex',
      borderRadius: 'unset',
      width: '116px',
      paddingX: '16px',
      justifyContent: 'inherit',
      '&:not(:last-child)': {
        borderBottom: '1px solid rgb(224 224 224)'
      },
      '& svg': {
        marginRight: '16px'
      }
    }
  }
})
// values: {
//   xs: 0,
//     sm: 768,
//     md: 1024,
//     lg: 1450,
//     xl: 1920,
// },
