import { Formik, Form, Field } from 'formik'
import Yup, { TextareaValidation } from '../../../validation'
import { Box, Card, FormControl, FormControlLabel, Radio, Theme, Typography, useMediaQuery, Divider } from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { RadioGroup, TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

const employeeSelector = ApiSelector('Employee')({
  canBringExistingClients: true,
  clientIndustries: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

interface Props extends EmployeeFields {
  onSave: (values: EmployeeFields) => void
}

export const ClientsComponent = ({ onSave, ...values }: Props) => {
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const initialValues: EmployeeFields = {
    canBringExistingClients: values.canBringExistingClients || false,
    clientIndustries: values.clientIndustries || '',
  }

  console.log(initialValues)

  return (
    <Formik enableReinitialize validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSave}>
      {({ errors, isSubmitting, setFieldValue }) => {
        console.log('errors', errors)
        return (
          <Form>
            <Box sx={wrapper}>
              <Box sx={headWrapper}>
                <Typography sx={(theme) => ({
                    marginBottom: theme.spacing(3)
                  })} variant={'h2'}>My clients</Typography>
                  <Divider sx={(theme) => ({
                    marginBottom: theme.spacing(1.5)
                  })} />
              </Box>
              <Box sx={contentWrapper}>
                {/* <Typography variant={'body1'}>{t('formFields.clientIndustriesTitle')}</Typography> */}
                <Field
                  component={TextField}
                  name='clientIndustries'
                  sx={{ height: 'auto' }}
                  multiline
                  rows={5}
                  disabled={isSubmitting}
                  fullWidth
                  label= {t('formFields.clientIndustriesTitle')}
                />
              </Box>

              <Divider sx={(theme) => ({
                    marginTop: theme.spacing(1.5)
                  })}/>

              <Box sx={contentWrapper}>
                <Typography variant={'subtitle2'}>{t('formFields.canBringExistingClientsTitle')}:</Typography>
                <FormControl>
                  <Field
                    component={RadioGroup}
                    name='canBringExistingClients'
                    sx={deskStyles}
                    onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                      setFieldValue('canBringExistingClients', value === 'true')
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('yes')}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('no')}
                      disabled={isSubmitting}
                    />
                  </Field>
                </FormControl>
              </Box>
              <FooterButton  disabled={Object.keys(errors).length !== 0} />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  clientIndustries: TextareaValidation.required(),
  canBringExistingClients: Yup.bool().required(),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
})

const headWrapper = () => ({
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'space-between',
})

const contentWrapper = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})
const deskStyles = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(3),
})
