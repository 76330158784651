import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { Box, Card, Divider, Theme, Typography, useMediaQuery } from '@mui/material'
import { ApiSelector, ApiInputType, ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { Salary } from '../../form-fields/Salary'
import { DeskType } from '../../form-fields/DeskType'
import { useCreateOrUpdateSalary } from '../../../hooks/useCreateOrUpdateSalary'
import { CountryAutocomplete } from '../../form-fields/CountryAutocomplete'
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

const employeeSelector = ApiSelector('Employee')({
  // preferSalary: salarySelector,
  recruitCountries: {
    id: true,
    name: true,
    flagEmoji: true,
  },
  deskType: true,
  deskTypeRatio: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>
type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

interface FormFields extends Partial<EmployeeFields> {
  preferSalary?: SalaryFields
}

interface Props extends FormFields {
  onSave: (values: FormFields) => void
}

export const EmployeePreferences = ({
  preferSalary,
  recruitCountries,
  deskType,
  deskTypeRatio,
  onSave,
}: Props) => {
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))

  const initialValues = {
    ...preferSalary,
    recruitCountries: recruitCountries || [],
    deskType: deskType || ApiTypes.DeskType.CONTRACT,
    deskTypeRatio: deskTypeRatio || 1,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const { recruitCountries, deskType, deskTypeRatio, ...preferSalary } = values

        if (preferSalary.currency?.id && preferSalary.from && preferSalary.to && preferSalary.schedule) {
          const data = await createOrUpdateSalaryMutation.mutateAsync(preferSalary as SalaryFields)

          Object.assign(preferSalary, data)
        }

        const salary = preferSalary as SalaryFields

        await onSave({
          recruitCountries,
          deskType,
          deskTypeRatio,
          preferSalary: salary,
        })

        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, errors, isSubmitting }) => {
        return (
          <Form>
            <Box sx={wrapper}>
              <Box sx={headerWrapper}>
              <Typography sx={(theme) => ({
                            marginBottom: theme.spacing(2.5)
                          })} variant={'h2'} >
                  Additional Information
                </Typography>
                <Divider />
              </Box>

              <Box sx={contentWrapper}>
                <Typography variant={'subtitle2'} sx={{ mb: '24px' }}>
                  Desired Salary:
                </Typography>
                <Salary />
              </Box>
              
              <Divider />

              <Box sx={contentWrapper}>
                <Typography textAlign={smWidth? 'start' : 'start'} variant={'subtitle2'}>
                  What’s your desk type?
                </Typography>
                <Box sx={deskWrapper}>
                  <DeskType values={values} setFieldValue={setFieldValue} errors={errors} isSubmitting={isSubmitting} />
                </Box>
              </Box>
                  
              <Divider />

              <Box sx={contentWrapper}>
                <Typography variant={'subtitle2'}>Recruiting into:</Typography>

                <CountryAutocomplete name='recruitCountries' />
              </Box>
             
              <FooterButton disabled={!!Object.keys(errors).length} />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  // salaryAmount: Yup.array().test({
  //   name: "second",
  //   message: "must be greater than 0",
  //   test: (val) =>
  //     val!.every((item, index) => {
  //       if (index === 1) {
  //         return item > 1;
  //       }
  //       return true;
  //     }),
  // }),
  // salaryTime: Yup.string().required(),
  // currency: Yup.string().required(),
  // desk: Yup.string().required("please choose one of them"),
  // permanent: Yup.number().when("desk", {
  //   is: "dual",
  //   then: Yup.number().required(),
  //   otherwise: Yup.number().nullable(),
  // }),
  // recruitingIn: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       value: Yup.string(),
  //       label: Yup.string(),
  //     })
  //   )
  //   .min(1),
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})

const headerWrapper = () => ({
  // display: 'flex',
  // justifyContent: 'space-between',
  // alignItems: 'center',
})
const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '99%',
  gap: '10px',
  '& .slider': {
    width: '95%'
  },
  [theme.breakpoints.down('md')]: {
    '& .slider': {
      width: '90%'
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .slider': {
      width: '80%'
    },
  }
})

const deskWrapper = (theme: Theme) => ({
  '& .MuiSlider-markLabel:nth-of-type(4)':{
    left: '5%!important'
  },
  '& .MuiSlider-markLabel:nth-of-type(6)':{
    left: '96%!important'
  },
  [theme.breakpoints.down('md')]:{
    '& .MuiSlider-markLabel:nth-of-type(4)':{
      left: '6%!important'
    },
  },
  [theme.breakpoints.down('sm')]:{
    '& .MuiSlider-markLabel:nth-of-type(4)':{
      left: '0%!important'
    },
    '& .MuiSlider-markLabel:nth-of-type(6)':{
      left: '100%!important'
    },
    '& .MuiFormControlLabel-root':{
      margin: 0
    }
  }
})


