import { Box, Chip, TableCell, TableRow, Typography, Theme, ChipTypeMap, useMediaQuery } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import { Click } from '../icons/click'
import { ApiSelector, ApiGraphQLTypes, ApiInputType, ApiTypes } from 'recruticka-frontend-libs'
import { useDate } from '../../hooks/useDate'
import { useTranslation } from 'react-i18next'
import theme from '../../styles/theme'
import { useOpenJobPage } from '../../hooks/useJob'

export const applicationListSelector = ApiSelector('Application')({
  id: true,
  status: true,
  rejectMessage: true,
  createdAt: true,
  job: {
    title: true,
    slug: true,
  },
})

export type ApplicationListItemInterface = ApiInputType<ApiGraphQLTypes['Application'], typeof applicationListSelector>

type Props = {
  application: ApplicationListItemInterface
  onClickRejected: (id: string) => void
}

const statusColorMap: Record<ApiTypes.ApplicationStatus, ChipTypeMap['props']['color']> = {
  [ApiTypes.ApplicationStatus.ACTIVE]: 'info',
  [ApiTypes.ApplicationStatus.REJECTED]: 'error',
  [ApiTypes.ApplicationStatus.FOUND_ANOTHER_JOB]: 'warning',
  [ApiTypes.ApplicationStatus.HIRED]: 'success',
  [ApiTypes.ApplicationStatus.INVITED]: 'success',
  [ApiTypes.ApplicationStatus.PENDING]: 'primary',
  [ApiTypes.ApplicationStatus.ADMIN_REJECTED]: 'error'
}

export const ApplicationListItem = ({ application, onClickRejected }: Props) => {

  console.log(application);
  const date = useDate(application.createdAt as Date)
  const { t } = useTranslation()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { openJobPage } = useOpenJobPage()

  const handleGoToJob = () => {
      if (application.job.slug){
        openJobPage(application.job.slug)
      }
  }
  return (
    <>
      <TableRow hover tabIndex={-1} key={application.id}>
        <TableCell component='th' scope='row' sx={tableCellWrapper}>
         <Box sx={[tableCellBox, matchSm && {order: 1}]}>
           <Typography
             onClick={handleGoToJob}
             sx={{
               cursor: 'pointer',
               display: '-webkit-box',
             '-webkit-line-clamp': '2',
             '-webkit-box-orient': 'vertical',
             textOverflow: 'ellipsis',
             overflow: 'hidden',
             }}
           >{application.job.title}</Typography>
           {
             matchSm &&
             <Box sx={ matchSm ? { ml: '30px' } : {} }>
               {application.status === ApiTypes.ApplicationStatus.HIRED && (
                 <Box sx={iconStyle}>
                   <ChatIcon />
                 </Box>
               )}
               {application.status === ApiTypes.ApplicationStatus.REJECTED && (
                 <Box sx={iconStyle} onClick={() => onClickRejected(application.id)}>
                   <Click />
                 </Box>
               )}
             </Box>
           }
         </Box>
        </TableCell>
        <TableCell sx={[tableCellWrapper, matchSm && {order: 3}]}>
         <Box sx={{display: 'flex'}}>
           { matchSm &&
             <span
               style={{
                color: '#a1a5a7',
                marginRight: '5px'
               }}
             >
               Date:
             </span>
           }
           <Typography variant={'body2'}>{date}</Typography>
         </Box>
        </TableCell>
        <TableCell sx={[tableCellWrapper, matchSm && {order: 2}]}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '94px',
            }}
          >
            <Box sx={{ maxWidth: '80px', width: '100%' }}>
              {/* { t(`enum.ApplicationStatus.PENDING`) } */}
              {/* {application.status} */}
              <Chip
                label={t(`enum.ApplicationStatus.${application.status}`)}
                color={statusColorMap[application.status]}
                sx={{
                  height: '24px ',
                  borderRadius: '16px',
                }}
              />
            </Box>
            { !matchSm &&
               <Box>
                {application.status === ApiTypes.ApplicationStatus.HIRED && (
                  <Box sx={iconStyle}>
                    <ChatIcon />
                  </Box>
                )}
                {application.status === ApiTypes.ApplicationStatus.REJECTED && (
                  <Box sx={iconStyle} onClick={() => onClickRejected(application.id)}>
                    <Click />
                  </Box>
                )}
              </Box>
            }
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

const tableCellBox = (theme: Theme) => ({
  '& p':{
    fontSize: '14px',
    fontWeight: 400
  },
  [theme.breakpoints.down('sm')]:{
    display: 'flex',
    justifyContent: 'space-between',
    '& p':{
      color: '#0F64E2',
      fontSize: '18px',
      fontWeight: 700
    }
  }
})

const tableCellWrapper = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.primary,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]:{
    borderBottom: 'unset',
    paddingY: '5px'
  }
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
