import React, { FormEvent, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { Field, useField } from 'formik';

export const PasswordField = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [_field, meta, helpers] = useField('password')
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
        <Field
            component={TextField}
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            error={meta.error}
            onChange={(e: FormEvent<HTMLInputElement>) => helpers.setValue(e.currentTarget.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {!showPassword ? <RemoveRedEyeRoundedIcon /> : <VisibilityOffRoundedIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};
