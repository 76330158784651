import { Box, Card, Grid, MenuItem, Theme, Typography, useMediaQuery, Divider } from '@mui/material'
import Uploader, { Asset } from '../Uploader/Uploader'
import { Field } from 'formik'
import { TextField, Select } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ExperienceSelect } from '../form-fields/ExperienceSelect'
import { SectorsAndSpecializationsAutocomplete } from '../form-fields/SectorsAndSpecializationsAutocomplete'
import { TextEditorField } from '../form-fields/TextEditorField'
import theme from '../../styles/theme'
import { IMAGES } from '../../config/uploaderTypes'
import { ApiTypes } from 'recruticka-frontend-libs'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../state/atoms/profileState'

export interface JobBasicInfoProps {
  coverImage?: Asset
  onCoverImageUploaded?: (file: Asset) => void
  onCoverImageDeleted?: (file: Asset) => void
}

export const JobBasicInfo = ({ coverImage, onCoverImageUploaded, onCoverImageDeleted }: JobBasicInfoProps) => {
  const profile = useRecoilValue(profileState)
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  return (

    <Box sx={wrapper}>
      <Typography sx={(theme) => ({ marginBottom: theme.spacing(3) })} variant={'h2'}>Basic Info</Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(5)
      })} />
      <Box sx={Boxwrapper}>
        <Box sx={contentWrapper}>
          {/* <Typography variant={'subtitle2'}>{t('formFields.jobTitle')}</Typography> */}
          <Field component={TextField} name='title' label='New Job Tittle' placeholder={t('formFields.jobTitle')} fullWidth />
        </Box>
        <Box sx={contentWrapper}>
          {/* <Typography variant={'subtitle2'}>{t('shortDescription')}</Typography> */}
          <Field
            component={TextField}
            name='shortDescription'
            label={t('shortDescription')}
            fullWidth
            placeholder={t('formFields.jobShortDescriptionPlaceholder')}
          />
        </Box>
        <Box sx={contentWrapper}>
          {/* <Typography variant={'subtitle2'}>{t('formFields.jobDescriptionTitle')}</Typography> */}
          <TextEditorField name='description' placeholder={t('formFields.jobDescriptionPlaceholder')} />
        </Box>

        <Divider sx={(theme) => ({ marginBottom: theme.spacing(0) })} />

        <Box sx={contentWrapper}>
          <Typography variant={'subtitle2'}>{t('formFields.companyName')}</Typography>
          <Field name={'companyId'} component={Select} fullWidth>
            {profile?.companies?.filter(company => company.status === ApiTypes.CompanyStatus.ACTIVE)
              .map((company) => (
                <MenuItem value={company.id} key={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Field>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={matchesMd ? 12 : 6} sx={requirementsBlockStyle}>
            <Uploader
              assets={coverImage}
              label={'Cover Image'}
              fullPreview={true}
              allowedFileTypes={IMAGES}
              onFileUploaded={onCoverImageUploaded}
              onFileDeleted={onCoverImageDeleted}
            />
          </Grid>
          <Grid item xs={matchesMd ? 12 : 6} sx={requirementsBlockStyle}>
            <Box sx={rightBlockStyle} >
              <SectorsAndSpecializationsAutocomplete
                showTitles
                titleProps={{
                  variant: 'subtitle2',
                }}
              />
              <Box sx={contentWrapper}>
                {/* <Typography variant={'subtitle2'}>Experience Level</Typography> */}
                <ExperienceSelect />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({

})

const Boxwrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  boxShadow: 'none',
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    '& .textEditor': {
      minHeight: '40px',
      maxHeight: '250px',
      height: '100%'
    },
    '& .textEditorField': {
      minHeight: '40px',
      maxHeight: '250px',
      height: '100%'
    }
  }
})

const requirementsBlockStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
})


const rightBlockStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  pt: 4.5,
})