import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { authMutation, authRequest, useAuthQueryRequest } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'
import { useInfiniteQuery, useMutation } from 'react-query'
import { queryClient } from '../react-query.client'
import { Conversation } from './useConversations'

export const replyMessageSelector = ApiSelector('Message')({
  createdAt: true,
  conversationId: true,
  conversation: {
    company: {
      name: true,
      logo: assetSelector
    }
  },
  author: {
    profileId: true,
    profile: {
      deletedAt: true,
      displayName: true,
      id: true,
      isHidden: true,
      employee: {
        profileId: true,
        firstname: true,
        lastname: true,
        avatar: assetSelector
      }
    }
  },
  id: true,
  content: true
})
export type ReplyMessageTypes = ApiInputType<ApiGraphQLTypes['Message'], typeof replyMessageSelector>


export const messagesSelector = ApiSelector('Message')({
  content: true,
  assets: assetSelector,
  id: true,
  createdAt: true,
  conversationId: true,
  conversation: {
    company: {
      name: true,
      logo: assetSelector
    },
  },
  author: {
    profileId: true,
    profile: {
      role: true,
      deletedAt: true,
      displayName: true,
      firstname: true,
      lastname: true,
      companies: {
        name: true,
        logo: assetSelector
      },
      id: true,
      isHidden: true,
      employee: {
        profileId: true,
        firstname: true,
        lastname: true,
        avatar: assetSelector
      }
    }
  },
  replyedTo: replyMessageSelector
})
export type Message = ApiInputType<ApiGraphQLTypes['Message'], typeof messagesSelector>


export const useGetMessages = (profileId: string, conversationId?: string) => {

  return useInfiniteQuery(['messages', conversationId], (cursor) => {

    return authRequest({
      getMessages: [
        {
          ...(cursor.pageParam ? {
            cursor: {
              id: cursor.pageParam
            }
          } : {}),
          take: 10,
          ...(cursor.pageParam ? {
            skip: 1
          } : {}),
          where: {
            conversationId: {
              equals: conversationId
            },
            conversation: {
              is: {
                participants: {
                  some: {
                    id: {
                      equals: profileId
                    }
                  }
                }
              }
            }
          }
        }, {
          ...messagesSelector,
          replyedTo: {
            ...messagesSelector.replyedTo,
            author: {
              ...messagesSelector.replyedTo.author,
              profile: {
                ...messagesSelector.replyedTo.author.profile,
                isHidden: [
                  { profileId },
                  true
                ]
              }
            }
          },
          author: {
            ...messagesSelector.author,
            profile: {
              ...messagesSelector.author.profile,
              isHidden: [
                { profileId },
                true
              ]
            }
          }
        }
      ]
    })
  }, {
    getNextPageParam: (lastPage, pages) => lastPage?.getMessages?.at(-1)?.id
  })
}

export const useDeleteMessage = (profileId?: string) => {
  return useMutation(async (id: string) => {
    const data = await authMutation({
      deleteMessage: [{ id }, true]
    })
    const cacheDataConversations = queryClient.getQueryData<{
      pages: {
        getProfileConversations: Conversation[]
      }[]
    }>(['conversations', profileId, null])

    if (cacheDataConversations?.pages.map(pages => {

      return pages.getProfileConversations.find(el => el.lastMessage?.id === id)
    })) {
      return await queryClient.refetchQueries()
    }
  })
}

