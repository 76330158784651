import { useMutation } from 'react-query'
import { authMutation } from '../services/api'

export const useDeleteAsset = () => {
  return useMutation(async (id: string) => {
    const { deleteAsset } = await authMutation({
      deleteAsset: [
        {
          data: {
            id,
          },
        },
        true,
      ],
    })

    return deleteAsset
  })
}
