import { AutocompleteRenderInputParams, Box, Chip, CircularProgress, TextField, Theme, Typography } from '@mui/material'
import { Field, FieldProps, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useDebounce } from 'use-debounce'
import { useQueryRequest } from '../../services/api'
import Flag from 'react-world-flags'

const countrySelector = ApiSelector('Country')({
  id: true,
  name: true,
  flagEmoji: true,
})

type CountryFields = ApiInputType<ApiGraphQLTypes['Country'], typeof countrySelector>

interface Props extends Partial<FieldProps> {
  name: string
  label?: string
}

export const CountryAutocomplete = ({ name, label }: Props) => {
  const [field, meta, fieldHelper] = useField(name)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [searchCountryName, setSearchCountryName] = useState<string>('')
  const [countrySearch] = useDebounce(searchCountryName, 100)

  const { data, isLoading } = useQueryRequest(
    ['searchCountry', countrySearch],
    {
      getCountries: [
        {
          take: 20,
          where: {
            name: {
              startsWith: countrySearch,
              mode: ApiTypes.QueryMode.insensitive,
            },
          },
        },
        countrySelector,
      ],
    },
    {
      enabled: countrySearch.length > 1,
    },
  )

  const invalidateSearchCountry = () => queryClient.invalidateQueries('searchCountry')
  const handleRemove = (id: string) => {
    fieldHelper.setValue(field.value.filter((country : CountryFields) => country.id !== id))
  }
  return (
    <Field
      loading={isLoading}
      name={field.name}
      component={Autocomplete}
      onInputChange={(event: any, newInputValue: string) => {
        setSearchCountryName(newInputValue)
      }}
      multiple
      noOptionsText={t('startTyping')}
      options={data?.getCountries || []}
      getOptionDisabled={() => field?.value?.length > 10}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option: CountryFields, value: CountryFields) => option.id === value.id}
      getOptionLabel={(option: CountryFields) => {
        if (option.id) {
          return `${option.name}`
        }

        return ''
      }}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: CountryFields) => (
        <Box component={'span'} {...props} key={option.id} sx={{ whiteSpace: 'nowrap', gap: '5px' }}>
          <Flag code={option.id} style={{width: '13px', height: '13px'}} />
          {option.name}
          {' '}
        </Box>
      )}
      renderTags={(props: CountryFields[]) => {
       return (props.map(option => (
         <Chip
           key={option.id}
           sx={chipStyle}
           label={
             <>
               <Flag code={option.id} style={{width: '16px', height: '16px'}} />
               <Typography noWrap={true}>{option.name}</Typography>
             </>
           }
           onDelete={() => handleRemove(option.id)}
         />
          )
        ))
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          label={label ?? t('formFields.countriesPlaceholder')}
          variant='outlined'
          onBlur={() => invalidateSearchCountry()}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

const chipStyle = (theme: Theme) => ({
  marginRight: 1,
  '& span':{
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  [theme.breakpoints.down('sm')]:{
    marginTop: 1
  }
})
