import { ApiTypes } from 'recruticka-frontend-libs'
import { ASSETS_BASE_URL } from '../config/urls';
import { Asset } from '../types/asset'
import ImageKit from 'imagekit-javascript'

const imagekit = new ImageKit({
  urlEndpoint: ASSETS_BASE_URL
});

type Transformation = {
  width?: string,
  height?: string,
}

export const buildAssetUrl = (asset?: Asset, transformation: Transformation[] = []) => {
  if (!asset) {
    return undefined;
  }

  if (asset.type === ApiTypes.AssetType.YOUTUBE) {
    return asset.url;
  }

  return imagekit.url({
    path: asset.url,
    transformation: transformation,
    transformationPosition: 'query'
  })
}
