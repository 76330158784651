import { Formik, Form, Field } from 'formik'
import Yup from '../../../validation'
import { Box, Button, Theme, Typography, Divider, useMediaQuery } from '@mui/material'
import { SectorsAndSpecializationsAutocomplete } from '../../form-fields/SectorsAndSpecializationsAutocomplete'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from './position-information'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { TextField } from 'formik-mui'
import theme from '../../../styles/theme'

const specializationeSelector = ApiSelector('Specialization')({
  id: true,
  name: true,
  sector: {
    id: true,
    name: true,
  },
})

type SpecializationFields = ApiInputType<ApiGraphQLTypes['Specialization'], typeof specializationeSelector>

interface Props {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  specializations?: SpecializationFields[]
  recruitRoles?: string
  clientIndustries?: string
  onSave: (specializations: SpecializationFields[], recruitRoles: string, clientIndustries: string) => void
}

export const SpecialiseInformation = ({ isSaving, onSave, currentStep, backStep, specializations, recruitRoles, clientIndustries}: Props) => {
  const { t } = useTranslation()
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  const initialValues = {
    sectors: [],
    specializations: specializations || [],
    recruitRoles: recruitRoles || '',
    clientIndustries: clientIndustries || '',
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSave(values.specializations, values.recruitRoles, values.clientIndustries)
      }}
    >
      {({ values, setFieldValue, handleBlur, errors, handleChange, touched }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <Box sx={wrapper}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  What do you specialize in?
                </Typography>

                <SectorsAndSpecializationsAutocomplete multiple allowAddNewSpecialization />
              </Box>
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Box sx={wrapper}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  {t('formFields.recruitRolesTitle')}
                </Typography>

                <Field
                  component={TextField}
                  name='recruitRoles'
                  helperText={errors.recruitRoles}
                  placeholder={t('formFields.recruitRolesTitle')}
                  fullWidth
                  sx={{
                    height: 'auto',
                  }}
                  multiline
                  rows={matchesSm ? 7 : 10}
                />
              </Box>
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Box sx={wrapper}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  {t('formFields.clientIndustriesTitle')}
                </Typography>
                <Field
                  component={TextField}
                  name='clientIndustries'
                  helperText={errors.clientIndustries}
                  placeholder={t('formFields.clientIndustriesTitle')}
                  fullWidth
                  sx={{
                    height: 'auto',
                  }}
                  multiline
                  rows={matchesSm ? 7 : 8}
                />
              </Box>
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                variant='contained'
                loading={isSaving}
                sx={buttonStyle}
                type='submit'
                size={'medium'}
                endIcon={<ArrowForwardIosIcon />}
                disabled={!values.specializations.length || !values.recruitRoles || !values.clientIndustries.length}
              >
                {t('saveNext')}
              </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}



const validationSchema = Yup.object().shape({
  specializations: Yup.array().required(),
  recruitRoles: Yup.string().required().max(500),
  clientIndustries: Yup.string().max(500).required(),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  mb: '0px',
  [theme.breakpoints.down('sm')]:{
    gap: '12px',
    '& .MuiOutlinedInput-root': {
      paddingY: '6.5px!important'
    },
    mb: '0px'
  }
})

const titleTextStyle = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  [theme.breakpoints.down('sm')]:{
    marginBottom: '24px'
  }
})