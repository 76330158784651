import { Box, Card, Divider, Grid, Typography, Theme, FormControl } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '../ui-kits/button/button'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { Field, FieldArray, FieldArrayRenderProps, useField } from 'formik'
import { Switch, TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import Uploader, { assetSelector } from '../Uploader/Uploader'
import { CityAutocomplete, citySelector } from '../form-fields/CityAutocomplete'
import { SetNullable } from '../../interfaces/nullable'
import { DeleteForeverRounded } from '@mui/icons-material'
import { IMAGES, VIDEO } from '../../config/uploaderTypes'

const officeAssetSelector = ApiSelector('OfficeAsset')({
  id: true,
  asset: assetSelector,
})

const officeSelector = ApiSelector('Office')({
  id: true,
  address: true,
  city: citySelector,
  isMain: true,
  assets: officeAssetSelector,
})

type OfficeFields = SetNullable<Partial<ApiInputType<ApiGraphQLTypes['Office'], typeof officeSelector>>, 'city'>
type OfficeAssetFields = SetOptional<ApiInputType<ApiGraphQLTypes['OfficeAsset'], typeof officeAssetSelector>, 'id'>

const OFFICE_FIELD_NAME = 'offices'

export const SetLocations = () => {
  const [officesField] = useField<OfficeFields[]>(OFFICE_FIELD_NAME)
  const addOffice = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({
      city: null,
      assets: [],
      isMain: false,
      address: '',
    } as OfficeFields)
  }

  const onDelete = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    arrayHelpers.remove(index)
  }

  const handleMakeMain = ( index: number) => {
    officesField.value.map((value, idx) => {
      if (idx !== index){
        value.isMain = false
      }
    })
  }

  return (
    <Box sx={wrapper}>
      <Typography sx={(theme) => ({ 
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1.5),
        },
        })} variant={'h2'}>Office locations</Typography>
      <Divider />

      <FieldArray
        name={OFFICE_FIELD_NAME}
        render={(arrayHelpers) => (
          <>
            {officesField.value.map((_, index) => (
              <OfficeForm key={index} makeItMain={handleMakeMain} index={index} onDelete={(index) => onDelete(arrayHelpers, index)} />
            ))}
            <Button
              size='squareSmall'
              startIcon={<AddIcon />}
              sx={(theme) => ({
                marginTop: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                  marginTop: theme.spacing(2.5),
                },
              })}
              onClick={() => addOffice(arrayHelpers)}
            >
              Add Location
            </Button>
          </>
        )}
      />
    </Box>
  )
}

interface OfficeFormProps {
  index: number
  onDelete: (index: number) => void
  makeItMain: (index: number) => void
}

const OfficeForm = ({ index, onDelete, makeItMain }: OfficeFormProps) => {
  const { t } = useTranslation()
  const [addressField] = useField(`${OFFICE_FIELD_NAME}.${index}.address`)
  const [cityField] = useField(`${OFFICE_FIELD_NAME}.${index}.city`)
  const [isMainField, _, setIsMainField] = useField(`${OFFICE_FIELD_NAME}.${index}.isMain`)
  const [officeAssetsField] = useField<OfficeAssetFields[]>(`${OFFICE_FIELD_NAME}.${index}.assets`)

  const handleSwitch = () => {
    makeItMain(index)
    setIsMainField.setValue(!isMainField.value)
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={(theme) => ({
          marginTop: theme.spacing(5),
          [theme.breakpoints.down('sm')]: {
            mt: theme.spacing(1.5),
          },
        })}
      >
        <Grid item sm={12} xs={12}>
          <Box sx={contentWrapper}>

            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
              <FormControl sx={{ width: '100%' }}>
                <CityAutocomplete name={cityField.name} />
              </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl sx={{ width: '100%' }}>
                  <Field component={TextField} name={addressField.name} label={t('address')} fullWidth />
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Field
                component={Switch}
                onChange={handleSwitch}
                name={isMainField.name}
                type='checkbox'
              />
              <Typography variant={'body1'}>{t('formFields.officeIsMain')}</Typography>
              <DeleteForeverRounded
                color={'error'}
                sx={(theme) => ({
                  display: 'none',
                  [theme.breakpoints.down('sm')]: { display: 'block', marginLeft: 'auto' },
                })}
                onClick={() => onDelete(index)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <FieldArray
            name={officeAssetsField.name}
            render={(arrayHelpers) => (
              <>
                <Uploader
                  label={'Images and Video'}
                  assets={officeAssetsField.value.map((item) => item.asset)}
                  allowedFileTypes={[...IMAGES, ...VIDEO]}
                  multiple
                  onFileUploaded={(asset) => {
                    arrayHelpers.push({ asset })
                  }}
                  onFileDeleted={(file) => {
                    const index = officeAssetsField.value.findIndex(({ asset }) => asset.id === file.id)

                    arrayHelpers.remove(index)
                  }}
                />
              </>
            )}
          />

          <Button
            color={'error'}
            size={'small'}
            sx={(theme) => ({ marginLeft: 'auto', [theme.breakpoints.down('sm')]: { display: 'none' } })}
            onClick={() => onDelete(index)}
          >
            Delete
          </Button>
        </Grid>
      </Grid>

      <Divider variant='middle' sx={{ marginTop: '32px' }} />
    </>
  )
}

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
})

const wrapper = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
})
