import { Formik, Form, Field } from 'formik'
import Yup, { TextareaValidation } from '../../../validation'
import { Box, Theme, Typography, useMediaQuery, Divider } from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { JobTypesAutocomplete } from '../../form-fields/JobTypesAutocomplete'
import { JobCriteriasAutocomplete } from '../../form-fields/JobCriteriasAutocomplete'
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

// Footer Button
import FooterButtonClient from '../../footerButtonClient'

const jobTypeSelector = ApiSelector('JobType')({
  id: true,
  name: true,
})

const jobCriteriaSelector = ApiSelector('JobCriteria')({
  id: true,
  name: true,
})

const employeeSelector = ApiSelector('Employee')({
  jobTypes: jobTypeSelector,
  jobCriterias: jobCriteriaSelector,
  recruitRoles: true,
  reasonForLeavingRecentEmployer: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

interface CareerNarrativeProps extends EmployeeFields {
  onSave: (values: EmployeeFields) => void
}

export const CareerNarrative = ({ onSave, ...values }: CareerNarrativeProps) => {
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const initialValues = {
    recruitRoles: values.recruitRoles || '',
    reasonForLeavingRecentEmployer: values.reasonForLeavingRecentEmployer || '',
    jobTypes: values.jobTypes || [],
    jobCriterias: values.jobCriterias || [],
  }
  

  return (
    <Formik enableReinitialize validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSave}>
      {({ errors, isSubmitting }) => {
        return (
          <Form>
            <Box sx={wrapper}>
              <Box sx={headWrapper}>
                <Typography sx={(theme) => ({
                            marginBottom: theme.spacing(2.5)
                          })} variant={'h2'} >Career Narrative</Typography>
                  <Divider />
              </Box>

              <Box sx={contentWrapper}>
                {/* <Typography variant={'body1'}>{t('formFields.jobTypesTitle')}</Typography> */}
                <JobTypesAutocomplete  label='What position are you looking for?'/>
              </Box>

              <Divider />

              <Box sx={contentWrapper}>
                {/* <Typography variant={'body1'}>{t('formFields.jobCriteriasTitle')}</Typography> */}
                <JobCriteriasAutocomplete />
              </Box>
                  
              <Divider />
              
              <Box sx={contentWrapper}>
                {/* <Typography variant={'body1'}>{t('formFields.recruitRolesTitle')}</Typography> */}
                <Field
                  component={TextField}
                  name='recruitRoles'
                  fullWidth
                  sx={{ height: 'auto' }}
                  multiline
                  rows={5}
                  label = {t('formFields.recruitRolesTitle')}
                />
              </Box>

              <Divider />

              <Box sx={contentWrapper}>
                 {/* <Typography variant={'body1'}>{t('formFields.reasonForLeavingRecentEmployerTitle')}</Typography> */}
                <Field
                  component={TextField}
                  name='reasonForLeavingRecentEmployer'
                  placeholder={t('formFields.reasonForLeavingRecentEmployerTitle')}
                  fullWidth
                  sx={{ height: 'auto' }}
                  multiline
                  rows={5}
                  label= {t('formFields.reasonForLeavingRecentEmployerTitle')}
                />
              </Box>

              <FooterButton disabled={Object.keys(errors).length > 0 || isSubmitting} />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

// jobTypes: jobTypeSelector,
// jobCriterias: jobCriteriaSelector,
// recruitRoles: true,
// reasonForLeavingRecentEmployer: true

const validationSchema = Yup.object().shape({
  jobTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .max(5)
    .min(1),
  jobCriterias: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .max(5)
    .min(1),
  recruitRoles: TextareaValidation,
  reasonForLeavingRecentEmployer: TextareaValidation,
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  height: 'auto',
  backgroundColor: '#fff',
})

const headWrapper = (theme: Theme) => ({
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'space-between',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const contentWrapper = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})
