
import { Content } from '../components/common-files/common-styles'
import { Theme } from '@mui/material'
import TempWrapperNew from '../components/tempWrapperNew'

// PageTitle
import {PageTitle} from '../components/pageTitle'

// CompanyCard
import {CompanyCard} from '../components/CompanyCard'

const CompanyPage = () => {

  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}>
        <PageTitle title='Company'/>
        <CompanyCard /> 
      </Content>
    </TempWrapperNew>
  )
}


const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  marginBottom: '128px',
  gap: '32px',
  [theme.breakpoints.down('sm')]: {
    // marginTop: '28px',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
  },
})

export default CompanyPage