import { Formik, Form, Field } from 'formik'
import Yup from '../../../validation'
import { Box, Button, Theme, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Checkbox } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from '../steps/position-information'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface Props {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  termsAndConditionsAccepted?: boolean
  onSave: (termsAndConditionsAccepted: boolean) => void
  linkForTerms: string
}

export const ReadAcceptStep = ({
  termsAndConditionsAccepted,
  isSaving,
  onSave,
  linkForTerms,
  currentStep,
  backStep,
}: Props) => {

  const { t } = useTranslation()
  const initialValues = {
    termsAndConditionsAccepted: termsAndConditionsAccepted || false,
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSave(Boolean(values.termsAndConditionsAccepted))
        setSubmitting(false)
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <Box sx={wrapper}>
              <Box sx={innerWrapper}>
                <Field
                  disabled={isSubmitting}
                  component={Checkbox}
                  type='checkbox'
                  name='termsAndConditionsAccepted'
                  sx={{ mr: '0px' }}
                />
                <Typography sx={typographyStyle}>
                  I have read and accept the{' '}
                  <a href={linkForTerms} target='_blank' rel='noreferrer'>
                    terms and conditions
                  </a>{' '}
                  in full.
                </Typography>
              </Box>
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>

              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  disabled={!values.termsAndConditionsAccepted}
                >
                  {t('iAgree')}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  termsAndConditionsAccepted: Yup.boolean().required(),
})

const innerWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
  },
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  gap: '30px',
  alignItems: 'start',
  margin: '0 auto',
  pt: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    pt: theme.spacing(6),
    // flexDirection: 'column',
    // marginTop: 'auto',
  },
})

const typographyStyle = (theme: Theme) => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  // maxWidth: '223px',
  width: '100%',
  '& a': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('xs')]: {
    lineHeight: '24px',
  }
})
