import { Formik, Form, Field } from 'formik'
import Yup from '../../../validation'
import {
  AutocompleteRenderInputParams,
  Box,
  Card,
  FormControlLabel,
  IconButton,
  Switch,
  FormHelperText,
  TextField as MuiTextField,
  Theme,
  Typography,
  CircularProgress,
  Tooltip, TextField, Stack, Alert, AlertTitle, Snackbar, Divider
} from '@mui/material'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { ApiSelector, ApiTypes, ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { authMutation, useQueryRequest } from '../../../services/api'
import { useState } from 'react'
import { Autocomplete } from 'formik-mui'
import * as React from 'react'
import { Avatar } from '../../Avatar'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../state/atoms/profileState'
import theme from '../../../styles/theme'
import { SearchIcon } from '../../icons/search-icon'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import FooterButton from '../../footerButton'
import { getName } from '../../../utilities/generateRandomName'
const profileSelector = ApiSelector('Profile')({
  id: true,
  companies: {
    id: true,
    profileId: true,
    name: true,
    status: true,
    logo: {
      url: true
    },
    isMain: true,
    sisterCompanies: {
      profileId: true,
      name: true,
      isMain: true,
      status: true
    }
  }
})

type ProfileFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof profileSelector>

interface Props {
  onSave: (hiddenFromProfile: ProfileFields[],
           visibleForProfile: ProfileFields[],
           hideFromEveryone: boolean,
           displayName: string | undefined,
           ) => void
  hiddenFromProfile: ProfileFields[]
  visibleForProfile: ProfileFields[]
  hideFromEveryone: boolean
  nickName: string | undefined
}

type UpdateProfileProps = {
  profiles: ProfileFields[],
  displayName: string | undefined,
  hideFromEveryone: boolean
}

const searchProfilesSelector = ApiSelector('Company')({
  name: true,
  logo: {
    url: true
  },
  isMain: true,
  id: true,
  status: true,
  profileId: true,
  sisterCompanies: {
    profileId: true,
    name: true,
    isMain: true,
    status: true
  },
  mainCompany: {
    id: true,
    profileId: true,
    name: true,
    logo: {
      url: true
    },
  }
})

interface Option {
  id: string,
  company: {
    name: string,
    logo: {
      url: string
    },
    isMain: boolean,
    id: string,
    profileId: string
  }
}

const searchProfiles = ApiSelector('Profile')({
  id: true,
  companies: searchProfilesSelector
})

type SearchProfilesSelector = ApiInputType<ApiGraphQLTypes['Company'], typeof searchProfilesSelector>

{/*  ${sisterCompanies?.map(({ name }) => name).join(', ') ?? ''} */}

export const HideFromModal = ({ onSave, hiddenFromProfile, visibleForProfile, hideFromEveryone, nickName }: Props) => {
  
  const { id: profileId } = useRecoilValue(profileState)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [visibleError, setVisibleError] = useState<boolean>(false)
  const [searchCompanyName, setSearchCompanyName] = useState<string>('')
  const [companySearch] = useDebounce(searchCompanyName, 100)
  const profileMutation = useMutation(async ({ profiles, hideFromEveryone, displayName }: UpdateProfileProps) => {
    try {
      const data = await authMutation({
        updateProfile: [
          {
            id: profileId,
            input: {
              ...(hideFromEveryone ? {
                visibleForProfile: {
                  set: profiles.map(({ id }) => ({ id }))
                }
              } : {
                hiddenFromProfile: {
                  set: profiles.map(({ id }) => ({ id }))
                },
              }),
              displayName: displayName,
              hideFromEveryone
            }
          },
          {
            hideFromEveryone: true,
            displayName: true,
            hiddenFromProfile: profileSelector,
            visibleForProfile: profileSelector
          }
        ]
      })
      return data.updateProfile
    } catch (e){
      await setVisibleError(true)
    }
  })

  const { data: searchCompanyData, isLoading: isSearchCompanyDataLoading } = useQueryRequest(
    ['searchCompany', companySearch],
    {
      getCompanies: [
        {
          take: 20,
          where: {
            name: {
              startsWith: companySearch,
              mode: ApiTypes.QueryMode.insensitive
            }
          }
        },
        searchProfilesSelector
      ]
    },
    {
      enabled: companySearch.length > 0
    }
  )

  const initialValues = {
    profiles: hideFromEveryone ? visibleForProfile : hiddenFromProfile,
    hideFromEveryone,
    displayName: nickName
  }

  const invalidateSearchLanguage = () => queryClient.invalidateQueries('searchLanguage')
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={async (values, { setSubmitting }) => {
        const profile = await profileMutation.mutateAsync({
          profiles: values.profiles,
          hideFromEveryone: values.hideFromEveryone,
          displayName: values.displayName
        })
       if (profile){
         await onSave(
           profile.hiddenFromProfile || [],
           profile.visibleForProfile || [],
           profile.hideFromEveryone,
           profile.displayName,
         )
       }

        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {

        return (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px'
              }}
            >
              <Snackbar
                autoHideDuration={10000}
                open={visibleError}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => {
                  setVisibleError(false)
                  errors.displayName = undefined
                }}
              >
                <Stack sx={{ width: '100%', flexDirection: 'column', gap: 2 }} spacing={2}>
                  <Alert severity='error'>
                    <AlertTitle>{ visibleError && 'Profile with the same nickname already exists'}</AlertTitle>
                  </Alert>
                </Stack>
              </Snackbar>
              <Box>
                <Typography  sx={(theme) => ({
                    marginBottom: theme.spacing(3)
                  })} variant={'h2'}>Hide me from</Typography>
                  <Divider sx={(theme) => ({
                    marginBottom: theme.spacing(1.5)
                  })} />
              </Box>
              <Box>
                {/* <Typography variant={'body1'} sx={{mb: '10px'}}>
                  Your real name will be replaced everywhere with the nickname you&apos;ve set for everyone or selected companies.
                </Typography> */}
                <Field
                  component={TextField}
                  name='displayName'
                  defaultValue={values.displayName}
                  label='Your real name will be replaced everywhere with the nickname you&apos;ve set for everyone or selected companies.'
                  sx={
                    visibleError || errors.displayName ?
                      {'& fieldset':
                          { borderColor: 'rgb(211 47 47)'}
                      } : {}
                  }
                  fullWidth
                  placeholder='Displaying the name'
                  onChange={(event: { target: { value: string } }) => setFieldValue('displayName' ,event.target.value)}
                />
                <FormHelperText disabled>By leaving the field blank the system will assign an ID automatically.</FormHelperText>
              </Box>
              <Divider/>
              <Box>
                <Typography sx={typographyStyle}>
                  You can choose to browse the platform either anonymously or with your visible identity by adjusting this setting. Additionally, you have the option to selectively hide your identity from specific companies.
                </Typography>
                <FormControlLabel
                  control={<Switch />}
                  checked={values.hideFromEveryone}
                  label="From everyone"
                  onChange={(_, value) => {
                    setFieldValue('hideFromEveryone', value)
                    if(value && !values.displayName) {
                      setFieldValue('displayName', getName())
                    }
                    setFieldValue('profiles', value ? visibleForProfile : hiddenFromProfile)
                  }}
                />
              </Box>
              
              { !values.hideFromEveryone && 
                <>
                <Box>
                  {values.hideFromEveryone && <Typography variant={'h6'} sx={(theme) => ({
                      marginBottom: theme.spacing(1)
                    })}>Except from</Typography>}
                  <Field
                    loading={isSearchCompanyDataLoading}
                    label= 'Search peoples'
                    name="profiles"
                    component={Autocomplete}
                    defaultValue={values.profiles}
                    onInputChange={(event: any, newInputValue: string) => setSearchCompanyName(newInputValue)}
                    // noOptionsText={t('startTyping')}
                    options={(searchCompanyData?.getCompanies || []).map((company) => {
                      return ({
                        id: company.profileId,
                        company: {
                          name: company.name,
                          logo: company.logo,
                          id: company.id,
                          status: company.status,
                          isMain: company.isMain
                        },
                        companies: [company.isMain ? company : company.mainCompany, ...company.sisterCompanies]
                      })
                    })}
                    multiple
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onChange={(event: any, newValue: SearchProfilesSelector[]) => setFieldValue('profiles', newValue)}
                    isOptionEqualToValue={(option: Option, value: SearchProfilesSelector) => {
                      return option.id === value.id
                    }}
                    renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                      <Box
                        component={'li'}
                        sx={{
                          display: 'flex',
                          gap: '16px',
                          alignItems: 'center'
                        }}
                        {...props}
                      >
                        <Avatar
                          src={option.company?.logo?.url}
                          alt={option.company?.name}
                          sx={{ width: '50px', height: '50px' }}
                        />
                        <Typography sx={companyNameWrapper}>{option.company?.name}</Typography>
                      </Box>
                    )}
                    getOptionLabel={(option: Option) => {
                      return option.company?.name
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        name="profiles"
                        error={touched.profiles && !!errors.profiles}
                        helperText={''}
                        placeholder={t('startTyping')}
                        variant="outlined"
                        onBlur={() => invalidateSearchLanguage()}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <SearchIcon />
                          ),
                          endAdornment: (
                            <>
                              {isSearchCompanyDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Box>

                {values.profiles.length > 0 && (
                  <Box
                    sx={[seachList, {
                      maxHeight: '148px',
                      overflow: 'auto'
                    }]}
                  >
                    {
                      values.profiles?.map((profile) => {
                        const company = profile?.companies && profile?.companies[0]
                        const sisterCompanies = profile?.companies?.filter(company => company.status === ApiTypes.CompanyStatus.ACTIVE)
                        return (
                          <Box
                            key={company?.id}
                            component={'li'}
                            sx={companyWrapper}
                          >
                            <Avatar
                              src={company?.logo?.url}
                              alt={company?.name}
                              sx={{ width: '50px', height: '50px' }}
                            />
                            <Typography sx={companyNameWrapper}>{company?.name}</Typography>
                            {!!sisterCompanies?.length &&
                              <Tooltip
                                placement={'top'}
                                title={
                                  'You will remain undisclosed to any affiliated sister companies within this organization, if they exist.'
                                }
                                arrow>
                                <InfoRoundedIcon sx={{ color: '#1E3296', }} />
                              </Tooltip>
                            }
                            <IconButton
                              onClick={() => {
                                setFieldValue('profiles', values.profiles.filter(el => el.id !== profile?.id))
                              }}
                              sx={{ marginLeft: 'auto' }}
                            >
                              <DeleteRoundedIcon sx={{ color: '#D32F2F', }} />
                            </IconButton>
                          </Box>
                        )
                      })
                    }
                  </Box>
                )}
                </>
              }

              <FooterButton loading={profileMutation.isLoading} />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  profiles: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string()
      })
    )
    .min(0),
  hideFromEveryone: Yup.boolean(),
  displayName: Yup.string().test({
    name: 'qwerty',
    test (value: any){
      const parent = this.parent
      if (parent.hideFromEveryone || !!parent.profiles.length){
        if  (!value){
          return this.createError({
            message: 'Name field is required',
          })
        }
      }
      return true
    }
  }).max(40)
})

const typographyStyle = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: theme.spacing(3)
})

const companyNameWrapper = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: theme.spacing(2.5)
})

const companyWrapper = (theme: Theme) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  paddingBottom: '10px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  '&:not(:first-of-type)': {
    marginTop: '26px'
  }
})

const seachList = (theme: Theme) => ({
  '&': {
    '& li:last-of-type': {
      borderBottom: 'none',
    }
  }
})