import { ChangeEvent } from 'react'

export type CompanyProfileSetupDrops = {
  type?: EDragDrops
  onChange?: (event: ChangeEvent<any>) => void
  name?: string
}

export enum EDragDrops {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  IMAGES_VIDEO = 'IMAGES_VIDEO',
  YOUTUBE = 'YOUTUBE',
}
