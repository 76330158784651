import { Box, Card, Theme, Typography, Divider } from '@mui/material'
import { TabData, VerticalTabsSetup } from './ui-kits/vertical-tabs-setup/vertical-tabs-setup'
import { ProfilePerksSetup } from './profile-perks-setup'
import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { ApiSelector, ApiInputType, ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { useTranslation } from 'react-i18next'
import { useField, FieldArray } from 'formik'
import { $enum } from 'ts-enum-util'
import { sortBy } from 'lodash'
import { assetSelector } from '../Uploader/Uploader'

const textBlockAssetSelector = ApiSelector('TextBlockAsset')({
  id: true,
  asset: assetSelector,
})

const textBlockSelector = ApiSelector('TextBlock')({
  id: true,
  title: true,
  description: true,
  textBlockAsset: textBlockAssetSelector,
})

const perkSelector = ApiSelector('CompanyPerks')({
  id: true,
  type: true,
  description: true,
})

type TextBlockFieds = SetOptional<ApiInputType<ApiGraphQLTypes['TextBlock'], typeof textBlockSelector>, 'id'>
interface CompanyPerksFieds
  extends SetOptional<ApiInputType<ApiGraphQLTypes['CompanyPerks'], typeof perkSelector>, 'id'> {
  textBlocks: SetOptional<TextBlockFieds, 'id'>[]
}

interface Props {
  scrollItems: {
    id: string
    label: string
  },
  activeTab: ApiTypes.CompanyPerkType
}

const companyPerksOrderPosition: Record<ApiTypes.CompanyPerkType, number> = {
  [ApiTypes.CompanyPerkType.LEARNING_DEVELOPMENT]: 1,
  [ApiTypes.CompanyPerkType.TIME_OFF]: 2,
  [ApiTypes.CompanyPerkType.FLEXIBLE_WORKING]: 3,
  [ApiTypes.CompanyPerkType.FINANCIAL_BENEFITS]: 4,
  [ApiTypes.CompanyPerkType.INCLUSION_DIVERSITY]: 5,
  [ApiTypes.CompanyPerkType.HEALTS_WELLNESS]: 6,
  [ApiTypes.CompanyPerkType.OFFICE_ENVIRONMENT]: 7,
  [ApiTypes.CompanyPerkType.MENTAL_HEALTS_SUPPORT]: 8,
}

export const SetupPerks = ({ scrollItems, activeTab }: Props) => {
  const { t } = useTranslation()
  // const [currentTab, setCurrentTab] = useState(activeTab || ApiTypes.CompanyPerkType.LEARNING_DEVELOPMENT)
  // const handleChange = (event: SyntheticEvent, newValue: ApiTypes.CompanyPerkType) => {
  //   setCurrentTab(newValue)
  // }

  const [perksField, , perksHelpers] = useField<CompanyPerksFieds[]>('perks')

  useEffect(() => {
    let haveChanges = false
    const perks = perksField.value

    // add missed
    $enum(ApiTypes.CompanyPerkType)
      .getKeys()
      .forEach((type) => {
        const perkExists = perks.find((perk) => perk.type === type)

        if (!perkExists) {
          haveChanges = true
          perks.push({
            type: ApiTypes.CompanyPerkType[type],
            description: '',
            textBlocks: [],
          })
        }
      })

    perks.forEach((perk) => {
      if (!perk.textBlocks?.length) {
        haveChanges = true
        perk.textBlocks = [
          {
            description: '',
            title: '',
            textBlockAsset: [],
          },
        ]
      }
    })

    if (haveChanges) {
      perksHelpers.setValue(sortBy(perks, ({ type }) => (type ? companyPerksOrderPosition[type] : 0)))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perksField.value])

  const tabsData = useMemo<TabData[]>(() => {
    return perksField.value.map(({ type, description }) => ({
      id: type,
      title: description?.trim() ? description : t(`enum.CompanyPerkType.${type}`),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perksField.value])

  return (
    <Box sx={wrapper}>
      {/* <VerticalTabsSetup tabsData={tabsData} value={currentTab} handleChange={handleChange}> */}
        <FieldArray
          name={perksField.name}
          render={() => (
            <>
              {perksField.value.map((perk, index) => (
                <TabPanel value={activeTab} index={perk.type} key={perk.type}>
                  <Typography variant={'h2'}>
                    {perk.description?.trim() ? perk.description : t(`enum.CompanyPerkType.${perk.type}`)}
                  </Typography>
                  <Divider sx={(theme) => ({
                    marginBottom: theme.spacing(2)
                  })} />
                  <ProfilePerksSetup index={index} />
                </TabPanel>
              ))}
            </>
          )}
        />
      {/* </VerticalTabsSetup> */}
    </Box>
  )
}

interface TabPanelProps {
  children?: ReactNode
  index: ApiTypes.CompanyPerkType
  value: ApiTypes.CompanyPerkType
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      sx={tabsPanelStyle}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={tabsPanelChildWrapper}>{children}</Box>}
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '& .uppy-Dashboard-files [role=presentation]':{
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down(1261)]:{
      display: 'block',
      width: 'unset'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
})

const tabsPanelStyle = (theme: Theme) => ({
  // backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  width: '100%',
  borderRadius: '6px',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'initial',
    borderRadius: 0,
    padding: 0,
  },
})

const tabsPanelChildWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
  },
})
