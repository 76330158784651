import { createTheme } from '@mui/material'

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    responsive: true
    responsiveNotSmall: true
    squareLarge: true
    squareMedium: true
    squareSmall: true
    circleLarge: true
    circleMedium: true
    circleSmall: true
    circleResponsiveMedium: true
  }
  interface ButtonPropsVariantOverrides {
    hovered: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    default?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true
  }
}

export const radiusOfComponents = '4px'

export const buttonResponsiveStyles = {
  large: {
    padding: '16px 48px',
    fontSize: '18px',
    borderRadius: '8px',
    lineHeight: '24px',
    height: '58px',
  },
  medium: {
    padding: '8px 24px',
    fontSize: '16px',
    borderRadius: '4px',
    lineHeight: '24px',
    height: '42px',
  },
  small: {
    padding: '4px 12px',
    fontSize: '12px',
    borderRadius: '4px',
    lineHeight: '18px',
    height: '36px',
  },
}

const initialTheme = createTheme({
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: '#1E3296',
      dark: 'rgb(30, 50, 150, 0.80)',
      light: '#4B93FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFBE40',
      dark: 'rgb(255, 190, 64, 0.80)',
      light: '#1E3296',
      contrastText: '#533A09',
    },
    success: {
      main: '#2e7d32',         
      contrastText: '#ffffff',
    },
    default: {
      main: '#ffffff',
    },
    grey: {
      800: 'rgba(0, 0, 0, 0.6)',
    },
    background: {
      default: '#F2F5F8',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.88)',
      secondary: 'rgba(20, 29, 36, 0.4)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: 'Roboto !important',
  },
  breakpoints: {
    values: {
      xs: 490,
      sm: 769,
      md: 1025,
      lg: 1450,
      xl: 1920,
    },
  },
})

const theme = createTheme(initialTheme, {
  ...initialTheme,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '32px',
          boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
          height: '100%',
          borderRadius: radiusOfComponents,
          overflow: 'unset',
          boxSizing: 'border-box',
          [initialTheme.breakpoints.down('md')]: {
            padding: initialTheme.spacing(3),
          },
          [initialTheme.breakpoints.down('xs')]: {
            padding: initialTheme.spacing(2),
          }
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          width: '20px',
          height: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '20px',
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '18px',
          },
        },
        label: {
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '20px',
          color: initialTheme.palette.text.primary,
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '18px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto !important',
          fontStyle: 'normal',
          fontWeight: 400,
          height: initialTheme.spacing(3),
          fontSize: '13px',
          lineHeight: '18px',
          letterSpacing: '0.16px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'responsive' },
          style: {
            ...buttonResponsiveStyles.large,
            [initialTheme.breakpoints.down('lg')]: {
              ...buttonResponsiveStyles.medium,
            },
            [initialTheme.breakpoints.down('sm')]: {
              ...buttonResponsiveStyles.small,
            },
          },
        },
        {
          props: { size: 'responsiveNotSmall' },
          style: {
            ...buttonResponsiveStyles.large,
            [initialTheme.breakpoints.down('lg')]: {
              ...buttonResponsiveStyles.medium,
            },
            [initialTheme.breakpoints.down('sm')]: {
              ...buttonResponsiveStyles.small,
            },
          },
        },
        {
          props: { size: 'squareLarge' },
          style: {
            ...buttonResponsiveStyles.large,
            padding: '16px',
          },
        },
        {
          props: { size: 'squareMedium' },
          style: {
            ...buttonResponsiveStyles.medium,
            padding: '8px 24px',
          },
        },
        {
          props: { size: 'squareSmall' },
          style: {
            ...buttonResponsiveStyles.small,
            padding: '6px 16px',
          },
        },
        {
          props: { size: 'circleLarge' },
          style: {
            ...buttonResponsiveStyles.large,
            borderRadius: '64px',
          },
        },
        {
          props: { size: 'circleMedium' },
          style: {
            ...buttonResponsiveStyles.medium,
            px: initialTheme.spacing(2),
            height: '40px',
            borderRadius: '64px',
          },
        },
        {
          props: { size: 'circleSmall' },
          style: {
            ...buttonResponsiveStyles.small,
            height: '34px',
            borderRadius: '64px',
          },
        },
        {
          props: { size: 'circleResponsiveMedium' },
          style: {
            ...buttonResponsiveStyles.medium,
            px: initialTheme.spacing(2),
            height: '40px',
            borderRadius: '64px',
            [initialTheme.breakpoints.down('sm')]: {
              ...buttonResponsiveStyles.small,
              height: '34px',
              borderRadius: '64px',
            },
          },
        },
        {
          props: { variant: 'hovered' },
          style: {
            ...buttonResponsiveStyles.large,
            backgroundColor: 'rgba(15, 100, 226, 0.08)',
            '&:hover': {
              backgroundColor: initialTheme.palette.primary.main,
              color: initialTheme.palette.primary.contrastText,
              'svg path': {
                fill: initialTheme.palette.primary.contrastText,
              },
            },
          },
        },
        {
          props: { variant: 'hovered', size: 'medium' },
          style: {
            ...buttonResponsiveStyles.medium,
          },
        },
        {
          props: { variant: 'hovered', size: 'small' },
          style: {
            ...buttonResponsiveStyles.small,
          },
        },
      ],
      styleOverrides: {
        contained: {
          boxShadow: '4px 4px 16px -2px rgba(15, 100, 226, 0.24)',
        },
        containedPrimary: {
          boxShadow: '4px 4px 16px -2px rgba(15, 100, 226, 0.24)',
          'svg path': {
            fill: initialTheme.palette.primary.contrastText,
          },
        },
        caption: {
          fontSize: '12px',
        },
        root: {
          fontFamily: 'Roboto !important',
          textTransform: 'uppercase',
          fontWeight: '500',
        },
        startIcon: {
          marginRight: initialTheme.spacing(1),
          marginLeft: initialTheme.spacing(0),
        },
        endIcon: {
          marginLeft: initialTheme.spacing(1),
        },
        containedSizeLarge: buttonResponsiveStyles.large,
        containedSizeMedium: buttonResponsiveStyles.medium,
        containedSizeSmall: buttonResponsiveStyles.small,
        outlinedSizeLarge: buttonResponsiveStyles.large,
        outlinedSizeMedium: buttonResponsiveStyles.medium,
        outlinedSizeSmall: buttonResponsiveStyles.small,
        textSizeLarge: buttonResponsiveStyles.large,
        textSizeMedium: buttonResponsiveStyles.medium,
        textSizeSmall: buttonResponsiveStyles.small,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          [initialTheme.breakpoints.down('md')]: {
            minWidth: 'unset',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          [initialTheme.breakpoints.down('md')]: {
            display: 'flex',
          },
        },
        actions: {
          marginLeft: 0,
        },
        spacer: {
          [initialTheme.breakpoints.down('sm')]: {
            flex: 'unset',
          },
        },
        displayedRows: {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '15px',
        },
        selectLabel: {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '15px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          ...buttonResponsiveStyles.medium,
          fontWeight: '600',
          padding: '8px',
          minWidth: '38px',
          height: 'auto',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          padding: initialTheme.spacing(1),
          backgroundColor: initialTheme.palette.primary.contrastText,
        },
        ul: {
          '& > li:nth-of-type(odd)': {
            '.MuiPaginationItem-root': {
              backgroundColor: initialTheme.palette.background.default,
            },
          },
          '& > li:nth-of-type(even)': {
            '.MuiPaginationItem-root': {
              // backgroundColor: initialTheme.palette.grey['800'],
            },
          },
          '.MuiPaginationItem-root.Mui-selected': {
            color: initialTheme.palette.primary.main,
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: initialTheme.palette.error.main,
          marginLeft: '14px',
          marginRight: '14px',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: initialTheme.palette.text.secondary,
        },
        input: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingLeft: '6px',
        },
        root: {
          paddingLeft: '19px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          height: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              // borderRadius: '6px',
            },
          },
        },
        root: {
          height: '100%',
        },
        input: {
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: 'cover',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        MuiInputLabel: {
          fontSize: '12px',
        },
        MuiFormHelperText: {
          fontSize: '12px',
        },
        MuiTooltip: {
          fontSize: '10px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [initialTheme.breakpoints.down('md')]: {
            padding: 0,
            marginLeft: 'auto',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: '14px',
          fontWeight: '400',
          fontStyle: 'normal',
          [initialTheme.breakpoints.down('lg')]: {
            fontSize: '14px',
          },
        },
        subtitle1: {
          fontSize: '16px',
          fontWeight: '500',
          [initialTheme.breakpoints.down('lg')]: {
            fontSize: '14px',
          },
        },
        overline: {
          fontSize: '12px',
          lineHeight: '18px',
        },
        subtitle2: {
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '18px',
        },
        body1: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '400',
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '12px',
          },
        },
        h1: {
          fontSize: '66px',
          fontWeight: '800',
          lineHeight: '80px',
          [initialTheme.breakpoints.down('lg')]: {
            fontSize: '46px',
            lineHeight: '56px',
          },
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '34px',
            lineHeight: '41px',
          },
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '26px',
            lineHeight: '31px',
          },
        },
        h2: {
          fontSize: '22px',
          fontWeight: '400',
          lineHeight: '26.82px',
          [initialTheme.breakpoints.down('xs')]: {
            fontSize: '16px',
            lineHeight: '22px',
          }
        },
        h3: {
          fontSize: '48px',
          fontWeight: '800',
          [initialTheme.breakpoints.down('lg')]: {
            fontSize: '34px',
          },
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '28px',
          },
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '24px',
          },
        },
        h4: {
          fontWeight: '400',
          fontSize: '34px',
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '28px',
          },
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '26px',
          },
        },
        h5: {
          fontSize: '22px',
          fontWeight: '500',
          [initialTheme.breakpoints.down('md')]: {
            fontSize: '16px',
          },
        },
        h6: {
          fontSize: '18px',
          fontWeight: '400',
          [initialTheme.breakpoints.down('lg')]: {
            fontSize: '16px',
          },
          [initialTheme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        },
        caption: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '15px',
        },
      },
    },
  },
})

export type Theme = typeof theme
export default theme
