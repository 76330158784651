const numberFormat = new Intl.NumberFormat(undefined, {
  useGrouping: true,
  maximumFractionDigits: 0,
})

export const useNumberFormatter = () => {
  const format = (amount: number) => numberFormat.format(amount)

  return { format }
}
