import { ApiSelector } from 'recruticka-frontend-libs'
import { useQueryRequest } from '../services/api'

export const currencySelector = ApiSelector('Currency')({
  id: true,
  name: true,
  symbol: true,
  rate: true,
})

export const useCurencies = (enabled?: boolean) => {
  return useQueryRequest(
    ['currencies'],
    {
      getCurrencies: [{ take: 100 }, currencySelector],
    },
    {
      cacheTime: Infinity,
    },
  )
}

export const useDefaultCurrency = () => {
  const { data: currenciesData } = useCurencies()

  return currenciesData?.getCurrencies.find(({ rate }) => rate === 1)
}
