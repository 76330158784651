import { Box, Card, Divider, Theme, Typography, Alert, AlertTitle, Snackbar, Stack } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { LinearProgressWithLabel } from '../ui-kits/linear-progress-with-label/linear-progress-with-label'
import { Button } from '../ui-kits/button/button'
import { useFormikContext } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { usePreviewCompany } from '../../hooks/useCompany'
import { useState } from 'react';

import { SaveIcon } from './../icons/saveIcon'

type Props = {
  onPublish: () => void
  isCompanyActive: boolean
  companyStrength: number
  companyId?: string
}

export const CompanyProfileHeader = ({ onPublish, isCompanyActive, companyStrength, companyId }: Props) => {

  const { isSubmitting, submitForm, isValid, errors } = useFormikContext()
  const { t } = useTranslation()
  const { openPreview } = usePreviewCompany();
  const [haveErrors, setHaveErrors] = useState(false)

  const submit = async (): Promise<boolean> => {
    if (!isValid) {

      setHaveErrors(() => true);
      return false;
    }

    await submitForm()

    return true;
  }

  const onPreview = async () => {
    if (await submit() &&  companyId) {
      openPreview(companyId)
    }
  }

  const saveAndPublish = async () => {
    if (await submit()) {
      onPublish()
    }
  }

  return (
    <Box sx={cardWrapper}>
     
      <Box sx={wrapper}>
        <Typography sx={(theme) => ({ [theme.breakpoints.down('sm')]: {display: 'none',} })} variant={'h4'}>{'Company'}</Typography> 
        <Box
          sx={(theme) => ({
            display: 'none',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            },
          })}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <Typography variant={'h4'}>Company</Typography>
            <Button variant={'outlined'} size={'responsive'} onClick={onPreview}>
              <RemoveRedEyeIcon />
            </Button>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <LoadingButton  endIcon={<SaveIcon fill='#fff' />} color='success' variant='contained' size={'small'} loading={isSubmitting} onClick={submit}>
            {t('save')}
          </LoadingButton>
          <Button
            variant={'outlined'}
            endIcon={<RemoveRedEyeIcon />}
            size={'small'}
            onClick={onPreview}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          >
          {isCompanyActive ? 'View profile' : 'Preview'}
          </Button>
          {!isCompanyActive && (
            <LoadingButton
              variant='contained'
              loading={isSubmitting}
              endIcon={<RocketLaunchIcon />}
              size={'squareSmall'}
              onClick={saveAndPublish}
            >
              Set Profile Live
            </LoadingButton>
           )} 
        </Box>
      </Box>
      <Box>
        <Snackbar
          autoHideDuration={5000}
          open={haveErrors}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => setHaveErrors(false)}
          sx={{ top: '100px !important' }}
        >
          <Alert severity='error'>
            <AlertTitle>Validation error!</AlertTitle>
            You have errors in the fields, fix them before saving.
          </Alert>
        </Snackbar>
      </Box>
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Typography variant={'h6'}>Profile Strength</Typography>
          <LinearProgressWithLabel value={companyStrength} />
        </Box>
    </Box>
  )
}

const cardWrapper = (theme: Theme) => ({
  zIndex: 2,
  // top: 0,
  // position: 'sticky',
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    width: '100%',
    padding: 0,
    backgroundColor: 'initial',
    boxShadow: 'none',
  },
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
})
