import { FilterListOutlined } from '@mui/icons-material'
import { SvgProps } from '../types/icon'

export const Gallery = ({ width = '18px', height = '18px', fill= 'rgb(0 0 0 / 54%)' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path d="M17.3333 12.3334V2.33335C17.3333 1.41669 16.5833 0.666687 15.6666 0.666687H5.66663C4.74996 0.666687 3.99996 1.41669 3.99996 2.33335V12.3334C3.99996 13.25 4.74996 14 5.66663 14H15.6666C16.5833 14 17.3333 13.25 17.3333 12.3334ZM8.16663 9.00002L9.85829 11.2584L12.3333 8.16669L15.6666 12.3334H5.66663L8.16663 9.00002ZM0.666626 4.00002V15.6667C0.666626 16.5834 1.41663 17.3334 2.33329 17.3334H14V15.6667H2.33329V4.00002H0.666626Z" fill={fill} />
    </svg>
  )
}
