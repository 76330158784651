import { LoadingButton } from '@mui/lab'
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilterOptionsState,
  TextField as MuiTextField,
  Typography,
  TypographyProps,
  Divider
} from '@mui/material'
import { Field, Form, Formik, useField } from 'formik'
import { Autocomplete, TextField } from 'formik-mui'
import { SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { useCreateSpecialization } from '../../hooks/useSpecialization'
import { useQueryRequest } from '../../services/api'
import { profileState } from '../../state/atoms/profileState'

const sectorSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})

const specializationSelector = ApiSelector('Specialization')({
  id: true,
  name: true,
  sector: sectorSelector,
})

type SectorFields = ApiInputType<ApiGraphQLTypes['Sector'], typeof sectorSelector>
type SpecializationFields = ApiInputType<ApiGraphQLTypes['Specialization'], typeof specializationSelector>

const ADD_NEW_ID = 'addNew'

interface Props {
  showTitles?: boolean
  multiple?: boolean
  allowAddNewSpecialization?: boolean
  sectorName?: string
  specializationName?: string
  titleProps?: TypographyProps
}

export const SectorsAndSpecializationsAutocomplete = ({
  sectorName = 'sectors',
  specializationName = 'specializations',
  showTitles,
  multiple = true,
  allowAddNewSpecialization,
  titleProps = {},
}: Props) => {
  const { id: profileId } = useRecoilValue(profileState)
  const [sectorField, sectorMeta, sectorHelpers] = useField<SectorFields[]>(sectorName)
  const [specializationField, specializationMeta, specializationHelpers] =
    useField<SpecializationFields[]>(specializationName)
  const { t } = useTranslation()
  const [loadSpecializations, setLoadSpecializations] = useState(false)
  const [loadSectors, setLoadSectors] = useState(false)
  const [isNewSpecializationOpen, setIsNewSpecializationOpen] = useState(false)
  const createSpecializationMutation = useCreateSpecialization()

  const getSectorIds = () => sectorField.value.map(({ id }) => id)

  const [dialogValue, setDialogValue] = useState<{ sector: SectorFields | null; specializationName: string }>({
    sector: null,
    specializationName: '',
  })

  const handleClose = () => {
    setDialogValue({
      sector: null,
      specializationName: '',
    })
    setIsNewSpecializationOpen(false)
  }

  const { data: sectorsData, isLoading: sectorsDataLoading } = useQueryRequest(
    ['sectors'],
    {
      getSectors: [{ take: 100 }, sectorSelector],
    },
    {
      enabled: loadSectors,
    },
  )

  const {
    data: specializationsData,
    isLoading: specializationsDataLoading,
    refetch,
  } = useQueryRequest(
    ['specializations', getSectorIds()],
    {
      getSpecializations: [
        {
          take: 100,
          where: {
            sectorId: {
              in: getSectorIds(),
            },
            forProfileId: {
              equals: profileId,
            },
          },
        },
        specializationSelector,
      ],
    },
    {
      enabled: loadSpecializations,
    },
  )

  if (!sectorField.value?.length && specializationField.value?.length) {
    specializationField.value.forEach(({ sector }) => {
      if (!sectorField.value.find(({ id }) => id === sector.id)) {
        sectorField.value.push(sector)
      }
    })
  }

  const specializationsOptions = useMemo(() => {
    if (specializationsData?.getSpecializations.length) {
      return specializationsData?.getSpecializations.filter((option, index, self) =>
        index === self.findIndex((item) => (
          item.name === option.name
        ))
      )
    }
  }, [specializationsData?.getSpecializations.length])

  return (
    <>
      <Box sx={contentWrapper}>
        {/* {showTitles && (
          <Typography variant={'body1'} {...titleProps}>
            {t('formFields.sectorsTitle')}:
          </Typography>
        )} */}
        <Field
          disabled={false}
          loading={sectorsDataLoading}
          name={sectorField.name}
          onOpen={() => setLoadSectors(true)}
          multiple={multiple}
          onChange={(event: SyntheticEvent, sectors: SectorFields[]) => {
            // const sectors = Array.isArray(values) ? values : values ? [values] : [];
            const sectorsIds = sectors.map(({ id }) => id)

            specializationHelpers.setValue(
              specializationField.value.filter(({ sector }) => {
                return sectorsIds.includes(sector.id)
              }),
            )

            sectorHelpers.setValue(sectors)
          }}
          component={Autocomplete}
          noOptionsText={t('startTyping')}
          options={sectorsData?.getSectors || []}
          autoComplete
          isOptionEqualToValue={(option: SectorFields, value: SectorFields) => option.id === value.id}
          getOptionLabel={(option: SectorFields) => (option.id ? `${option.name}` : '')}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name='sectors'
              error={sectorMeta.touched && !!sectorMeta.error}
              label={t('formFields.sectorsTitle')}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {sectorsDataLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>

      <Divider sx={(theme) => ({
                            my: theme.spacing(4)
                          })} />

      <Box sx={contentWrapper}>
        {/* {showTitles && (
          <Typography variant={'body1'} {...titleProps}>
            {t('formFields.specializationsTitle')}:
          </Typography>
        )} */}
        <Field
          freeSolo
          disabled={sectorField.value.length === 0}
          loading={specializationsDataLoading}
          name={specializationField.name}
          onOpen={() => setLoadSpecializations(true)}
          multiple={multiple}
          component={Autocomplete}
          defaultValue={specializationField.value}
          noOptionsText={t('startTyping')}
          options={specializationsOptions || []}
          autoComplete
          onChange={(event: Event, newValue: SpecializationFields[] & string[]) => {
            if (allowAddNewSpecialization && (typeof newValue.at(-1) === 'string' || newValue.find(({ id }) => id === ADD_NEW_ID))) {
              setTimeout(() => {
                setIsNewSpecializationOpen(true)
                setDialogValue({
                  sector: sectorField.value[sectorField.value.length - 1],
                  specializationName: '',
                })
              })
            } else {
              specializationHelpers.setValue(newValue)
            }
          }}
          filterOptions={(options: SpecializationFields[], state: FilterOptionsState<SpecializationFields>) => {
            const sectorsIds = sectorField.value.map(({ id }) => id)

            const result = options.filter((specialization) => {
              return sectorsIds.includes(specialization.sector.id) && specialization.name.toLowerCase().includes(state.inputValue.toLowerCase())
            })

            if (allowAddNewSpecialization) {
              result.push({
                id: ADD_NEW_ID,
                name: state.inputValue ? `${t('add')} "${state.inputValue}"` : `${t('add')} new specialization`,
                sector: {
                  id: ADD_NEW_ID,
                  name: '',
                },
              })
            }

            return result
          }}
          isOptionEqualToValue={(option: SpecializationFields, value: SpecializationFields) => option.id === value.id}
          getOptionLabel={(option: SpecializationFields) => (option.id ? `${option.name}` : '')}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              name='specializations'
              error={specializationMeta.touched && !!specializationMeta.error}
              label={t('formFields.specializationsTitle')}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {specializationsDataLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>

      {allowAddNewSpecialization && (
        <Dialog open={isNewSpecializationOpen} onClose={handleClose}>
          <Formik
            initialValues={dialogValue}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              if (values.sector) {
                const newSpecialization = (await createSpecializationMutation.mutateAsync({
                  input: {
                    name: values.specializationName,
                    sectorId: values.sector.id,
                  },
                  select: specializationSelector,
                })) as SpecializationFields

                refetch()

                specializationHelpers.setValue([...specializationField.value, newSpecialization])

                setIsNewSpecializationOpen(false)
                setSubmitting(false)
              }
            }}
          >
            <Form>
              <DialogTitle>Add a new specialization</DialogTitle>
              <DialogContent sx={contentWrapper}>
                <DialogContentText sx={{ paddingBottom: '1rem' }}>
                  Did you miss any specialization in our list? Please, add it!
                </DialogContentText>
                <Box sx={contentWrapper}>
                  <Field
                    disabled={false}
                    loading={sectorsDataLoading}
                    name='sector'
                    onOpen={() => setLoadSectors(true)}
                    component={Autocomplete}
                    noOptionsText={t('startTyping')}
                    options={sectorsData?.getSectors || []}
                    autoComplete
                    isOptionEqualToValue={(option: SectorFields, value: SectorFields) => option.id === value.id}
                    getOptionLabel={(option: SectorFields) => (option.id ? `${option.name}` : '')}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MuiTextField
                        {...params}
                        name='sector'
                        error={sectorMeta.touched && !!sectorMeta.error}
                        label={t('formFields.sectorsTitle')}
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {sectorsDataLoading ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Field component={TextField} name='specializationName' placeholder={'Specialization name'} fullWidth />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton type='submit' loading={createSpecializationMutation.isLoading}>
                  Add
                </LoadingButton>
              </DialogActions>
            </Form>
          </Formik>
        </Dialog>
      )}
    </>
  )
}

const contentWrapper = () => ({
  display: 'flex',
  flexDirection: 'column',
  // gap: '10px',
})
