import { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { dateTime, date as formatDate, fromNow } from '../utilities/dateFormat'

export const useDateTime = (date?: Date | Dayjs | string) => {
  return useMemo(() => dateTime(date), [date])
}

export const useDate = (date?: Date | Dayjs | string) => {
  return useMemo(() => formatDate(date), [date])
}

export const useFromNow = (date?: Date | Dayjs | string) => {
  return useMemo(() => fromNow(date), [date])
}
