import { ApiSelector, ApiGraphQLTypes, ApiInputType, ApiTypes } from 'recruticka-frontend-libs'

export const ASSET_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL as string

const assetSelector = ApiSelector('Asset')({
  id: true,
  url: true,
  type: true,
})

type Asset = ApiInputType<ApiGraphQLTypes['Asset'], typeof assetSelector>

export const buildAssetUrl = (source?: string | Asset): string | undefined => {
  if (!source) {
    return undefined
  }

  if (typeof source === 'string') {
    return `${ASSET_BASE_URL}/${source}`
  }

  if (source.type === ApiTypes.AssetType.YOUTUBE) {
    return source.url
  }

  return `${ASSET_BASE_URL}/${source.url}`
}