import { Formik, Form, Field } from 'formik'
import Yup, { TextareaValidation } from '../../../validation'
import { CityAutocomplete, CityFields } from '../../form-fields/CityAutocomplete'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from './position-information'
import { Box, Typography, Button, Divider, Theme, useMediaQuery } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { LanguageAutocomplete } from '../../form-fields/LanguageAutocomplete'
import { TextField } from 'formik-mui'
import theme from '../../../styles/theme'

const languageSelector = ApiSelector('Language')({
  id: true,
  name: true,
})

type LanguageFields = ApiInputType<ApiGraphQLTypes['Language'], typeof languageSelector>

interface Props {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  onSave: (city: CityFields, languages: LanguageFields[], reasonForLeavingRecentEmployer: string) => void
  linkedin?: string,
  currentCity?: CityFields,
  languages?: LanguageFields[],
  reasonForLeavingRecentEmployer?: string
}

export const BasedInformation = ({ onSave, isSaving, currentStep, backStep, linkedin, currentCity, languages, reasonForLeavingRecentEmployer}: Props) => {
  const { t } = useTranslation()
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  const initialValues = {
    linkedin: linkedin || '',
    city: currentCity || null,
    languages: languages || [],
    reasonForLeavingRecentEmployer: reasonForLeavingRecentEmployer || '',
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (values.city) {
          onSave(values.city, values.languages, values.reasonForLeavingRecentEmployer)
        }
      }}
    >
      {({ values, errors }) => {
        return (
          <Form>
            {/* <Box className='stepFieldWrapper'>
              <Field
                  component={TextField}
                  inputProps={{ maxLength: 190 }}
                  name='linkedin'
                  placeholder={'Link to LInkedIn profile'}
                  fullWidth
                />
            </Box>
            <Divider className='formDivider' /> */}
            <Box className='stepFieldWrapper'>
              <CityAutocomplete name='city' label={t('formFields.locationPlaceholder')} />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <LanguageAutocomplete name='languages' multiple={true} />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Box sx={wrapper}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  {t('formFields.reasonForLeavingRecentEmployerTitleShort')}
                </Typography>
                <Field
                  component={TextField}
                  name='reasonForLeavingRecentEmployer'
                  helperText={errors.reasonForLeavingRecentEmployer}
                  placeholder={t('formFields.reasonForLeavingRecentEmployerTitleShort')}
                  fullWidth
                  sx={{
                    height: 'auto',
                  }}
                  multiline
                  rows={matchesSm ? 7 : 8}
                />
              </Box>
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  variant='contained'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={!values.city || !values.languages.length || !values.reasonForLeavingRecentEmployer.length}
                >
                  {t('saveNext')}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  city: Yup.object().nullable().required(),
  languages: Yup.array().required().min(1),
  reasonForLeavingRecentEmployer: TextareaValidation.required(),
  // linkedin: Yup.string().url().nullable(),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  marginBottom: '0px',
  [theme.breakpoints.down('sm')]:{
    alignItems: 'center',
    marginBottom: '0px'
  }
})

const titleTextStyle = () => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})
