import { useEffect, useState } from 'react'
import { Card, Theme, Box, Grid, CircularProgress, Typography } from '@mui/material'
import { SignStepper } from '../components/not-cms/sign-stepper'
import { Main, Content } from '../components/common-files/common-styles'
import { PositionInformation } from '../components/not-cms/steps/position-information'
import { BillingInformation } from '../components/not-cms/steps/billing-information'
import { BasedInformation } from '../components/not-cms/steps/based-information'
import { SpecialiseInformation } from '../components/not-cms/steps/specialise-information'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BringExisting } from '../components/not-cms/steps/bring-existing'

import { Final } from '../components/not-cms/steps/final'
import { ProfileStrength } from '../components/not-cms/profile-strength'
import TempWithoutSidebar from '../components/tempWithoutSidebar'
import { ReadAcceptStep } from '../components/not-cms/employer-steps/read-accept-step'
import { useAuthQueryRequest } from '../services/api'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { SaveEmployeeValues, useUpdateEmployee } from '../hooks/useEmployee'
import { useQueryClient } from 'react-query'
import { useSetupSteps } from '../hooks/useSetupSteps'
import { useRecoilValue } from 'recoil'
import { profileState, ProfileStateFields } from '../state/atoms/profileState'
import { useAcceptTermsProfile, useCompleteProfile } from '../hooks/useProfile'
import { citySelector } from '../components/form-fields/CityAutocomplete'

enum Steps {
  PositionInformation = 'PositionInformation',
  BillingInformation = 'BillingInformation',
  BasedInformation = 'BasedInformation',
  SpecialiseInformation = 'SpecialiseInformation',
  BringExisting = 'BringExisting',
  EmailInformation = 'EmailInformation',
}

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

const employeeSelector = ApiSelector('Employee')({
  id: true,
  jobTypes: {
    id: true,
    name: true,
  },
  jobCriterias: {
    id: true,
    name: true,
  },
  preferSalary: salarySelector,
  currentCity: citySelector,
  salaryHistory: {
    id: true,
    schedule: true,
    currency: {
      id: true,
      name: true,
      symbol: true,
    },
    history: {
      id: true,
      from: true,
      to: true,
      year: true,
    }
  },
  languages: {
    id: true,
    name: true,
  },
  specializations: {
    id: true,
    name: true,
    sector: {
      id: true,
      name: true,
    },
  },
  recruitRoles: true,
  experience: true,
  recruitCountries: {
    id: true,
    name: true,
    flagEmoji: true,
  },
  deskType: true,
  deskTypeRatio: true,
  clientIndustries: true,
  reasonForLeavingRecentEmployer: true,
  canBringExistingClients: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>
type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

type StepChecker = {
  [key in keyof typeof Steps]: (employee: EmployeeFields & ProfileStateFields) => boolean
}

const stepChecker: Partial<StepChecker> = {
  [Steps.PositionInformation]: (employee) => Boolean(employee?.jobTypes?.length),
  [Steps.BillingInformation]: (employee) => Boolean(employee?.salaryHistory?.id),
  [Steps.BasedInformation]: (employee) => Boolean(employee?.currentCity?.id),
  [Steps.SpecialiseInformation]: (employee) => Boolean(employee?.specializations?.length),
  [Steps.BringExisting]: (employee) => typeof employee?.canBringExistingClients === 'boolean',
  [Steps.EmailInformation]: (profile) => Boolean(profile?.termsAndConditionsAccepted),
}

const OnboardingEmployee = () => {

  const [currentStep, setCurrentStep] = useState(0)
  const profile = useRecoilValue(profileState)
  const employeeId = profile?.employee?.id
  const profileStatus = profile?.status
  const queryClient = useQueryClient()
  const updateEmployeeMutation = useUpdateEmployee(employeeSelector)
  const completeProfileMutation = useCompleteProfile()
  const acceptTermsProfileMutation = useAcceptTermsProfile()


  const { data: employeeData, isLoading } = useAuthQueryRequest(
    'employee',
    {
      getEmployee: [{ id: employeeId as string }, employeeSelector],
    },
    {
      enabled: employeeId !== undefined,
    },
  )

  const { activeStep, goToNextStep, goToPrevStep, stepsKeys, stepsValidated } = useSetupSteps({
    steps: Steps,
    stepChecker: stepChecker,
    stepCheckerData:
      employeeData?.getEmployee && profile
        ? {
          ...employeeData.getEmployee,
          ...profile,
        }
        : undefined,
  })

  useEffect(() => {
    setCurrentStep(Math.max(0, stepsKeys.indexOf(activeStep)))
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [activeStep, stepsKeys])

  const onSave = async (values: Partial<SaveEmployeeValues>, toNextStep = true) => {
    if (!employeeId) {
      return
    }

    const data = await updateEmployeeMutation.mutateAsync({
      employeeData: employeeData?.getEmployee || {},
      values: {
        id: employeeId,
        ...values,
      },
    })

    if (data) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...data,
        },
      })
    }

    if (toNextStep) {
      goToNextStep()
    }
  }

  const updatePreferSalaryInEmployee = (preferSalary: SalaryFields) => {
    queryClient.setQueryData('employee', {
      getEmployee: {
        ...employeeData?.getEmployee,
        preferSalary,
      },
    })

    goToNextStep()
  }

  const updateCurrentSalaryInEmployee = (currentSalary: SalaryFields) => {
    queryClient.setQueryData('employee', {
      getEmployee: {
        ...employeeData?.getEmployee,
        currentSalary,
      },
    })

    goToNextStep()
  }

  const acceptTermsAndMarkProfileAsCompleted = async (termsAndConditionsAccepted: boolean) => {
    if (termsAndConditionsAccepted && profile) {
      if (!profile?.termsAndConditionsAccepted) {
        await acceptTermsProfileMutation.mutateAsync(profile.id)
      }

      await completeProfileMutation.mutateAsync(profile.id)
    }

    goToNextStep()
  }

  if (isLoading || !employeeData?.getEmployee || stepsValidated === undefined) {
    return <CircularProgress />
  }

  return (
    <TempWithoutSidebar hideFooter>
      <Main sx={wrapper}>
        <Content>
          <Grid container spacing={1} justifyContent="center">
            <Grid item md={10} xs={12}>
              
              <ProfileStrength value={profile?.employee?.strength ? profile?.employee?.strength : 0} showBar={profileStatus === ApiTypes.ProfileStatus.COMPLETED} isClient={false} isProfileHidden={profile?.hideFromEveryone} />

              {profileStatus !== ApiTypes.ProfileStatus.COMPLETED && (
                <SignStepper currentStep={currentStep} activeStep={activeStep} steps={stepsKeys} />
              )}

              <Card sx={contentWrapper}>

                <Box sx={stepContentWrapper}>
                  {profileStatus === ApiTypes.ProfileStatus.COMPLETED && <Final />}

                  {profileStatus !== ApiTypes.ProfileStatus.COMPLETED && (
                    <>
                      {activeStep === Steps.PositionInformation && (
                        <PositionInformation
                          isSaving={updateEmployeeMutation.isLoading}
                          jobTypes={employeeData?.getEmployee?.jobTypes}
                          jobCriterias={employeeData?.getEmployee?.jobCriterias}
                          {...employeeData?.getEmployee?.preferSalary}
                          employeeId={employeeData.getEmployee.id}
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          onSave={(jobTypes, jobCriterias, salaryData) => {
                            onSave({ jobTypes, jobCriterias })
                            updatePreferSalaryInEmployee(salaryData);
                          }}
                        />
                      )}
                      
                      {activeStep === Steps.BillingInformation && (
                        <>
                          <Box sx={noteWrapper}>
                            <InfoOutlinedIcon color={'primary'} />
                            <Typography variant={'body2'}>NOTE: Make your profile stand out by sharing detailed billing information from as many previous years as possible. This will increase your chances of catching the eye of hiring managers and getting more interviews.</Typography>
                          </Box>
                          <BillingInformation
                            salaryHistory={employeeData.getEmployee.salaryHistory}
                            countries={employeeData?.getEmployee?.recruitCountries}
                            deskType={employeeData?.getEmployee?.deskType}
                            deskTypeRatio={employeeData?.getEmployee?.deskTypeRatio}
                            isSaving={updateEmployeeMutation.isLoading}
                            currentStep={currentStep}
                            backStep={goToPrevStep}
                            onSave={(salaryHistory, recruitCountries, deskType, deskTypeRatio) => {
                              onSave({ salaryHistory, recruitCountries, deskType, deskTypeRatio })
                            }}
                          />
                        </>
                      )}

                      {activeStep === Steps.BasedInformation && (
                        <BasedInformation
                          currentCity={employeeData?.getEmployee?.currentCity}
                          languages={employeeData?.getEmployee?.languages}
                          linkedin={''}
                          reasonForLeavingRecentEmployer={employeeData?.getEmployee?.reasonForLeavingRecentEmployer}
                          isSaving={updateEmployeeMutation.isLoading}
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          onSave={(city, languages, reasonForLeavingRecentEmployer) => {
                            onSave({ currentCity: city, languages, reasonForLeavingRecentEmployer })
                          }}
                        />
                      )}

                      {activeStep === Steps.SpecialiseInformation && (
                        <SpecialiseInformation
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          specializations={employeeData?.getEmployee?.specializations}
                          recruitRoles={employeeData?.getEmployee?.recruitRoles}
                          clientIndustries={employeeData?.getEmployee?.clientIndustries}
                          isSaving={updateEmployeeMutation.isLoading}
                          onSave={(specializations, recruitRoles, clientIndustries) => {
                            onSave({ specializations, recruitRoles, clientIndustries })
                          }}
                        />
                      )}
                      
                      {activeStep === Steps.BringExisting && (
                        <BringExisting
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          canBringExistingClients={employeeData?.getEmployee?.canBringExistingClients}
                          experience={employeeData?.getEmployee?.experience}
                          isSaving={updateEmployeeMutation.isLoading}
                          onSave={(canBringExistingClients, experience) => {
                            onSave({ canBringExistingClients, experience })
                          }}
                        />
                      )}

                      {activeStep === Steps.EmailInformation && (
                        <ReadAcceptStep
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          linkForTerms={'https://recruitica.io/terms-of-business-candidates'}
                          termsAndConditionsAccepted={profile?.termsAndConditionsAccepted}
                          isSaving={updateEmployeeMutation.isLoading || completeProfileMutation.isLoading}
                          onSave={(termsAndConditionsAccepted) => {
                            acceptTermsAndMarkProfileAsCompleted(termsAndConditionsAccepted)
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Main>
    </TempWithoutSidebar>
  )
}



const wrapper = (theme: Theme) => ({
  paddingTop: '0',
  paddingBottom: '128px',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('xs')]:{
    '&': {
      '& .MuiBox-root': {
        '& .MuiBox-root.MuiGrid-item': {
          width: '100%',
        }
      },
    },
  }
})

const contentWrapper = (theme: Theme) => ({
  width: '100%',
  height: 'auto',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
  marginBottom: '20px',
  py: '0',
  [theme.breakpoints.down('md')]:{
    py: '0',
  },
  [theme.breakpoints.down('sm')]:{
    py: '0',
  },
  [theme.breakpoints.down('xs')]:{
    py: '0',
  }
})


const stepContentWrapper = (theme: Theme) => ({
  display: 'flex',
  width: '100%',
  alignSelf: 'center',
  // alignItems: 'center',
  minHeight: '600px',
  '&': {
    position: 'relative',
    paddingBottom: '140px',
    flexWrap:'wrap',
  },
  '& > *': {
    width: '100%',
    minHeight: '281px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  '& .stepFieldWrapper' : {
    padding: '64px 0%',
    maxWidth: '800px',
    margin: '0 auto',
    width: '100%',
  },
  '& .stepFooter' : {
    borderTop: '1px solid #cfcfcf',
    padding: '24px 10px',
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 0,
    bottom: 0,
    
    // '& button' : {
    //   width: '210px'
    // }
  },
  [theme.breakpoints.down('md')]:{
    'form': {
      // '& .stepFieldWrapper:first-of-type' : {
      //   paddingTop: theme.spacing(2),
      // }  
    },
    '& .stepFieldWrapper' : {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    '& .stepFooter' : {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      px: theme.spacing(0),
    }
  },
  [theme.breakpoints.down('sm')]:{
    '&': {
      paddingBottom: '110px',
    },
    '& > *': {
      minHeight: '190px',
    },
    '& .stepFooter' : {
      '& .MuiButton-containedPrimary': {
        paddingLeft: '14px !important',
        paddingRight: '14px !important',
      }
    }
  }
})

const noteWrapper = (theme: Theme) => ({
  margin: '48px auto 0',
  display: 'flex',
  padding: '14px 18px',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
  borderRadius: '6px',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  width: '100%',
  maxWidth: '700px',
  minHeight: 'auto !important',
  flexDirection: 'row !important',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})


export default OnboardingEmployee
