import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Button } from '../ui-kits/button/button'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { usePreviewJob } from '../../hooks/useJob'
import theme from '../../styles/theme'
import { SaveIcon } from '../icons/saveIcon'
import { PreviewIcon } from '../icons/previewIcon'
import { RocketIcon } from '../icons/rocketIcon'

interface Props {
  title: string
  onPublish: () => void
  jobId: string
  isJobActive: boolean
  touched: boolean
}

export const AddEditJobHeader = ({ title, onPublish, jobId, isJobActive, touched }: Props) => {
  const { isSubmitting, submitForm, isValid } = useFormikContext<boolean>()
  const { t } = useTranslation()
  const { openPreview } = usePreviewJob()
  // const matchesMd = useMediaQuery(theme.breakpoints.down('md'))
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))

  const saveAndPreview = async () => {
    if (isValid) {
      await submitForm()
      openPreview(jobId)
    }
  }

  const saveAndPublish = async () => {
    if (isValid) {
      await submitForm()
      onPublish()
    }
  }

  return (
    <Box sx={titleWrapper}>
      <Typography variant={'h4'}>{title}</Typography> 
      <Box sx={buttonWrap}>
          <LoadingButton
            endIcon={<SaveIcon fill='#fff' />}
            size={'squareSmall'}
            variant={'contained'}
            color='success'
            loading={isSubmitting}
            type="submit"
            disabled={!touched}
          >
            {t('save')}
          </LoadingButton>
        {!isJobActive && <>
          <Button
            fullWidth={matchesSm}
            size={'small'}
            variant={'outlined'}
            endIcon={!matchesSm && <PreviewIcon />}
            onClick={saveAndPreview}
          >
            Preview
          </Button>
          <Button
            fullWidth={matchesSm}
            size={'small'}
            endIcon={!matchesSm && <RocketIcon />}
            onClick={saveAndPublish}
          >
            Set Live
          </Button>
        </>}
      </Box>
    </Box>
  )
}

const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(2),
  }
})

const buttonWrap = (theme: Theme) => ({
  display: 'flex', 
  gap: '10px', 
  [theme.breakpoints.down('sm')]: {
    '& a:last-of-type': {
      marginRight: '0px'
    }
  }
})


const disableButton = (theme: Theme) => ({
  '& svg path': {
    fill: 'rgba(0, 0, 0, 0.26)!important',
  }
})

