import { Route, Routes } from 'react-router-dom'
import AddJobPage from '../pages/add-job-page'
import Applications from '../pages/Applications'
import EmployeeProfile from '../pages/employee-profile'
import JobOverview from '../pages/job-overview'
import MyCandidates from '../pages/my-candidates'
import MyJobs from '../pages/my-jobs'
import SettingsPage from '../pages/settings-page'
import OnboardingPage from '../pages/onboarding-page'
import { ThankYouPage } from '../pages/thankyou-page'
import { ProfileRoute } from './ProfileRoute'
import { ChatBox } from '../components/chat/chat-box'
import TestPage from '../pages/test-page'
import { RedirectToProfile } from './RedirectToProfile'
import ProfileCompany from '../pages/profile-company'
import CompanyPage from '../pages/company-page'
import DashboardPage from '../pages/dashboard-page'
import CandidateProfileSetup from '../pages/profile-employee'
// import PublicRoute from './PublicRoute'
// import SignInCandidate from '../pages/auth/sign-in-candidate'
// import SignUpCandidate from '../pages/auth/sign-up-candidate'
// import SignInClient from '../pages/auth/sign-in-client'
// import SignUpClient from '../pages/auth/sign-up-client'
// import {ForgetPassword} from '../pages/auth/forget-password'
// import ForgetPassword from '../pages/auth/forget-password'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RedirectToProfile />} />

      <Route path='companies' element={<CompanyPage />} />

      {/* <Route path='/' element={PublicRoute(<SignInCandidate />)} /> */}
      {/* <Route path='/sign-in' element={PublicRoute(<SignInCandidate />)} />
      <Route path='/sign-up' element={PublicRoute(<SignUpCandidate />)} />
      <Route path='/sign-in-client' element={PublicRoute(<SignInClient />)} />
      <Route path='/sign-up-client' element={PublicRoute(<SignUpClient />)} />
      <Route path='/forget-password' element={PublicRoute(<ForgetPassword />)} /> */}

      <Route path='/cookie' element={<TestPage />} />
      <Route path=':profileId' element={<ProfileRoute />}>
        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='messages' element={<ChatBox />} />
        <Route path='setup' element={<OnboardingPage />} />
        <Route path='setup/thankyou' element={<ThankYouPage />} />
        <Route path='account-settings' element={<SettingsPage />} />
        <Route path='candidates' element={<MyCandidates />} />

        <Route path='employee/:employeeId' element={<EmployeeProfile />} />
        <Route path='profile' element={<CandidateProfileSetup />} />
        <Route path='companies/:companyId' element={<ProfileCompany />} />
        <Route path='applications' element={<Applications />} />
        <Route path='jobs' element={<MyJobs />} />
        <Route path='jobs/:jobId' element={<JobOverview />} />
        <Route path='jobs/:jobId/edit' element={<AddJobPage />} />
      </Route>
    </Routes>
  )
}

export enum PAGES {
  DASHBOARD = '/:profileId/dashboard',
  EMPTY_PROFILE = ':profileId',
  SETUP = '/:profileId/setup',
  ACCOUNT_SETTINGS = '/:profileId/account-settings',
  CANDIDATES = '/:profileId/candidates',
  EMPLOYEE = '/:profileId/employee/:employeeId',
  JOB_EDIT = '/:profileId/jobs/:jobId/edit',
  JOB = '/:profileId/jobs/:jobId',
  JOBS = '/:profileId/jobs',
  APPLICATIONS = '/:profileId/applications',
  PROFILE = '/:profileId/profile',
  COMPANIES = '/:profileId/companies/:companyId',
  MESSAGES = '/:profileId/messages',
  SISTER_COMPANY = '/:profileId/sister-company',
}
