import { Box, Card, Divider, Grid, Typography, Theme, Link, Avatar } from '@mui/material'
import { Button } from '../ui-kits/button/button'
import AddIcon from '@mui/icons-material/Add'
import AlertDialog from './ui-kits/alert-dialog/alert-dialog'
import { useState } from 'react'
import SistersCompanyAlert from './ui-kits/sisters-company-alert'
import { Field, FieldArray, useField } from 'formik'
import Logo from '../../images/mastercardLogo.png'
import { MediaItem } from '../ui-kits/media-item/media-item'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Delete from '../icons/delete'
import Copy from '../icons/copy'
import Edit from '../icons/edit'
import { generatePath, Link as RouterLink, useNavigate } from 'react-router-dom'
import { PAGES } from '../../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState, ProfileStateFields } from '../../state/atoms/profileState'
import { buildAssetUrl } from '../../utilities/buildAssetUrl'
import {
  CompanyFields,
  useCreateSisiterCompany,
  useDeleteSisterCompany,
  useDuplicateCompany
} from '../../hooks/useCompany'
import { useQueryClient } from 'react-query'
import { queryClient } from '../../react-query.client'

export const SistersCompany = () => {
  const [isOpen, setIsOpen] = useState(false)
  const profile = useRecoilValue(profileState)
  const { id: companyId } = useRecoilValue(mainCompanyState)
  const history = useNavigate()
  const queryClient = useQueryClient()
  const duplicateSisterCompany = useDuplicateCompany()
  const createSisterCompany = useCreateSisiterCompany()
  const handleDuplicate = async (companyId: string) => {
    const data = await duplicateSisterCompany.mutateAsync(companyId)
    history(generatePath(PAGES.COMPANIES, {
      profileId: profile?.id,
      companyId: data.createSisterCompany?.id
    }))
    return data
  }

  const handleCreateSisterCompany = async () => {
    const data = await createSisterCompany.mutateAsync()
    history(generatePath(PAGES.COMPANIES, {
      profileId: profile?.id,
      companyId: data.createSisterCompany?.id
    }))
    return data
  }

  return (
    <Box sx={wrapper}>
      <Typography sx={(theme) => ({ 
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1.5),
        },
        })} variant={'h2'}>Sister Companies</Typography>
        <Divider />
      <FieldArray
        name={'sisters-company'}
        render={(arrayHelpers) => (
          <>
            {profile?.companies?.filter(company => !company.isMain).map((company, index) => (
              <>
                <CompanyItem key={company.id} company={company} />
                {profile?.companies?.length !== (index + 1) && <Divider sx={{ marginTop: '32px' }} />}
              </>
            ))}
            <Button
              size="squareSmall"
              startIcon={<AddIcon />}
              sx={(theme) => ({
                marginTop: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                  marginTop: theme.spacing(2.5)
                }
              })}
              onClick={() => setIsOpen(true)}
            >
              Add a Sister Company
            </Button>
          </>
        )}
      />
      <SistersCompanyAlert
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={() => handleCreateSisterCompany()}
        title={'Create sister company'}
        onDublicate={() => handleDuplicate(companyId)}
      />
    </Box>
  )
}

export const CompanyItem = ({ company }: any) => {
  const profile = useRecoilValue(profileState)
  const deleteSisterCompany = useDeleteSisterCompany()
  const queryClient = useQueryClient()
  const duplicateSisterCompany = useDuplicateCompany()
  const history = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = async () => {
    await deleteSisterCompany.mutateAsync(company.id)
     const data = profile?.companies?.filter(el => el.id !== company.id)
    const updatedData = {...profile, companies: data}
    queryClient.setQueryData(['profile', profile.id], () => ({
      getProfile: updatedData,
    }))
  }

  const handleCreateSisterCompany = async () => {
    const data = await duplicateSisterCompany.mutateAsync(company.id)
      history(generatePath(PAGES.COMPANIES, {
        profileId: profile?.id,
        companyId: data.createSisterCompany?.id
      }))
    //  const newData = {...profile, companies: [profile.companies, data.createSisterCompany]}
    // queryClient.setQueryData(['profile', profile.id], {
    //   getProfile: newData,
    // })
    return data
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            mt: theme.spacing(1.5)
          }
        })}
      >
        <Grid item lg={9.5} sm={8.5} xs={12}>
          <Box sx={contentWrapper}>
            {company?.logo?.url ?
              <img src={buildAssetUrl(company?.logo?.url)} alt="" />
              :
              <Avatar alt={''}/>
            }
            <Typography variant={'h5'}>{company.name}</Typography>
          </Box>
        </Grid>
        <Grid item lg={2.5} sm={3.5} xs={12} sx={buttonsWrapper}>
          <Button
            size={'small'}
            sx={{
              backgroundColor: 'rgba(15, 100, 226, 0.04)',
              boxShadow: 'unset',
              width: '48px',
              height: '48px',
              padding: 0,
              minWidth: 'unset'
            }}
            variant={'text'}
            onClick={handleCreateSisterCompany}
          >
            <Copy />
          </Button>
          <Link
            sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
            component={RouterLink}
            to={generatePath(PAGES.COMPANIES, {
              profileId: profile?.id,
              companyId: company.id
            })}
          >
            <Button
              size={'small'}
              sx={{
                backgroundColor: 'rgba(15, 100, 226, 0.04)',
                boxShadow: 'unset',
                width: '48px',
                height: '48px',
                padding: 0,
                minWidth: 'unset'
              }}
              variant={'text'}
            >
              <Edit />
            </Button>
          </Link>
          <Button
            sx={{
              backgroundColor: 'rgba(211, 47, 47, 0.04)',
              boxShadow: 'unset',
              width: '48px',
              height: '48px',
              padding: 0,
              minWidth: 'unset',
              '&:hover': {
                '& path': {
                  fill: '#fff'
                }
              }
            }}
            onClick={() => setIsOpen(true)}
            color={'error'}
            size={'small'}
          >
            <Delete />
          </Button>
        </Grid>
      </Grid>
      <AlertDialog
        isLoading={deleteSisterCompany.isLoading}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={() => handleDelete()}
        successMessage={'Yes'}
        description={ company.name ? `Are you sure want to delete "${company.name}"?`
          : 'Are you sure want to delete this sister company?'}
      />
    </>
  )
}


const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiAvatar-root':{
    width: '60px',
    height: '60px',
  },
  '& img': {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover'
  },
  '& h5': {
    fontSize: '18px',
    marginLeft: '16px'
  }
})

const wrapper = (theme: Theme) => ({
  '& .MuiPaper-root': {
    width: '900px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

const buttonsWrapper = (theme: Theme) => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'flex-start',
  }
})
