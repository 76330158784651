import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Card, Theme, Typography, Link, TextField } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import { CompanyProfileSetupDrops } from '../../types/company-profile-setup-types'
import { Button } from '../../../ui-kits/button/button'

export const DragDrop = ({ type }: CompanyProfileSetupDrops) => {
  const [url, setUrl] = useState<string>('')

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop })

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ))

  const title =
    type === 'IMAGE'
      ? 'Cover Image'
      : type === 'VIDEO'
      ? 'Video Cover'
      : type === 'IMAGES_VIDEO'
      ? 'Images and Video'
      : 'Youtube video'

  const description = type === 'IMAGE' ? 'JPG, PNG' : type === 'VIDEO' ? 'MP4, AVI' : 'JPG, PNG, MP4, AVI'

  return (
    <Card sx={wrapper}>
      {type && <Typography variant={'subtitle2'}>{title}</Typography>}
      {type === 'YOUTUBE' ? (
        <Box sx={contentWrapper}>
          <Typography variant={'body2'}>
            Want to use a YouTube video? Paste the URL below and well do the rest:
          </Typography>
          <TextField
            sx={{ marginTop: '8px', marginBottom: '8px' }}
            placeholder={'https://www.youtube.com/watch?v=niolpl56'}
            defaultValue={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
          />
          <Button disabled={!url} size={'small'}>
            Save Video
          </Button>
        </Box>
      ) : (
        <Box {...getRootProps()} sx={contentWrapper}>
          <input {...getInputProps()} />
          <ArrowCircleUpIcon
            sx={(theme: Theme) => ({
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
            })}
          />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <Typography variant={'body2'}>
              To add {type === 'VIDEO' ? 'a video' : type === 'IMAGES_VIDEO' ? 'an images and video' : 'an image'}, drag
              the file here or
            </Typography>
          )}
          <Link sx={linkStyle}>choose from your computer</Link>
          {type && (
            <Typography variant={'caption'} sx={descriptionStyle}>
              Supported file types: {description}
            </Typography>
          )}
        </Box>
      )}
      {!!acceptedFiles.length && (
        <aside>
          <Typography variant={'body1'}>Files</Typography>
          <ul>{files}</ul>
        </aside>
      )}
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  padding: theme.spacing(3),
  border: '1px dashed rgba(20, 29, 36, 0.4)',
  display: 'flex',
  borderRadius: '6px',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
})

const contentWrapper = (theme: Theme) => ({
  maxWidth: '356px',
  width: '100%',
  alignSelf: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const linkStyle = (theme: Theme) => ({
  textDecoration: 'underline',
})

const descriptionStyle = (theme: Theme) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
})
