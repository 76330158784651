import { SxProps } from '@mui/material'

export const rootSX: SxProps = {
  '& .map-container': {
    width: 1,
    height: 1,
    minHeight: 400,
  },
}

export const placesAutocompleteSX: SxProps = {
  width: 1,
  maxWidth: 400,
  position: 'absolute',
  left: '50%',
  top: 10,
  transform: 'translateX(-50%)',
  '& .MuiOutlinedInput-root': {
    bgcolor: 'common.white',
  },
}

export const markerSX: SxProps = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  maxWidth: 400,
}
