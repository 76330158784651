import { Card, Theme, Typography, Box, Divider } from '@mui/material'
import { useField, FieldArray } from 'formik';
import Uploader, { Asset } from '../Uploader/Uploader'
import { TextEditorField } from '../form-fields/TextEditorField'
import { IMAGES } from '../../config/uploaderTypes'
import { TextBlockAsset } from '../../hooks/useTextBlockAsset';
import { SetOptional } from 'type-fest'

export const JobBenefits = () => {
  const [benefitDescriptionField] = useField('benefit.description')
  const [textBlockAssetField] = useField<SetOptional<TextBlockAsset, 'id' | 'isMain'>[]>('benefit.textBlockAsset')

  return (
    <Box sx={wrapper}>
      <Typography variant={'h2'}>Benefits</Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(2)
      })} />
      <TextEditorField name={benefitDescriptionField.name} placeholder='Add your job benefits here...' />

      <FieldArray
        name={textBlockAssetField.name}
        render={(arrayHelpers) => (
          <>
            <Uploader
              label={'Benefits images'}
              description={'Select main image below'}
              assets={textBlockAssetField.value?.map((item) => item.asset) || []}
              multiple
              allowedFileTypes={IMAGES}
              onFileUploaded={(asset) => arrayHelpers.push({ asset })}
              onFileDeleted={(file) => {
                const index = (textBlockAssetField.value || []).findIndex(({ asset }) => asset.id === file.id)

                arrayHelpers.remove(index)
              }}
            />
          </>
        )}
      />
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    '& .col-8': {
      width: '66%'
    },
    '& .col-4': {
      width: '33%'
    },
    '& .add-more': {
      marginTop: '5px',
      '& span': {
        margin: 0
      },
      fontSize: 0,
      width: '100%'
    }
  }
})
