import { SvgProps } from '../types/icon'

export const UpArrow = ({ width = '18px', height = '18px' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.43431 7.24408C8.7227 6.94367 9.17649 6.92057 9.49032 7.17475L9.56569 7.24408L12.7657 10.5774C13.0781 10.9028 13.0781 11.4305 12.7657 11.7559C12.4773 12.0563 12.0235 12.0794 11.7097 11.8252L11.6343 11.7559L9 9.0125L6.36569 11.7559C6.0773 12.0563 5.62351 12.0794 5.30968 11.8252L5.23431 11.7559C4.94593 11.4555 4.92374 10.9828 5.16776 10.6559L5.23431 10.5774L8.43431 7.24408Z' fill='#92929D'/>
    </svg>
  )
}

