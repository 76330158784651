import { SvgProps } from '../types/icon'

export const Readiness = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height}>
      <path
        d='M14.5455 5.45455H16.3636V3.63636H14.5455V5.45455ZM14.5455 9.09091H16.3636V7.27273H14.5455V9.09091ZM14.5455 12.7273H16.3636V10.9091H14.5455V12.7273ZM14.5455 16.3636V14.5455H18.1818V1.81818H10V3.09091L8.18182 1.77273C8.18182 1.28788 8.36 0.871212 8.71636 0.522727C9.07212 0.174242 9.5 0 10 0H18.1818C18.6818 0 19.11 0.177879 19.4664 0.533636C19.8221 0.89 20 1.31818 20 1.81818V14.5455C20 15.0455 19.8221 15.4736 19.4664 15.83C19.11 16.1858 18.6818 16.3636 18.1818 16.3636H14.5455ZM1.81818 16.3636C1.31818 16.3636 0.890303 16.1858 0.534546 15.83C0.178182 15.4736 0 15.0455 0 14.5455V8.20455C0 7.90151 0.0681818 7.62121 0.204545 7.36364C0.340909 7.10606 0.530303 6.89394 0.772727 6.72727L5.31818 3.47727C5.63636 3.25 5.98485 3.13636 6.36364 3.13636C6.74242 3.13636 7.09091 3.25 7.40909 3.47727L11.9545 6.72727C12.197 6.89394 12.3864 7.10606 12.5227 7.36364C12.6591 7.62121 12.7273 7.90151 12.7273 8.20455V14.5455C12.7273 15.0455 12.5494 15.4736 12.1936 15.83C11.8373 16.1858 11.4091 16.3636 10.9091 16.3636H1.81818ZM1.81818 14.5455H4.54545V10H8.18182V14.5455H10.9091V8.18182L6.36364 5L1.81818 8.18182V14.5455Z'
        fill='rgba(20, 29, 36, 0.4)'
      />
    </svg>
  )
}
