import { Formik, Form, Field } from 'formik'
import Yup from '../../validation'
import { Box, Card, Theme, Typography } from '@mui/material'
import { Button } from '../ui-kits/button/button'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { UpdateSettings } from './settings'
import { PasswordField } from '../form-fields/PasswordField'
import { TextField } from 'formik-mui'
import { LoadingButton } from '@mui/lab'

interface Props {
  onClose: () => void,
  onSave: (values: UpdateSettings) => void,
  email: string,
  isLoading?: boolean
}

export const SettingsModal = ({ email, onSave, onClose, isLoading }: Props) => {
  const initialValues = {
    email,
    password: '',
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => onSave(values)}
      enableReinitialize
    >
      {({ errors, touched }) => {
        return (
          <Form>
            <Card sx={wrapper}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h2'}>Contact Details</Typography>
                <Box onClick={onClose}>
                  <CloseRoundedIcon sx={iconStyle} />
                </Box>
              </Box>
              <Box sx={contentWrapper}>
                <Typography variant={'body1'}>Change Email:</Typography>
                <Field
                  component={TextField}
                  name='email'
                  helperText={touched.email && errors.email}
                  placeholder={'Email'}
                  fullWidth
                />
              </Box>
              <Box sx={contentWrapper}>
                <Typography variant={'body1'}>Change Password:</Typography>
                <PasswordField />
              </Box>

              <LoadingButton variant='contained' loading={isLoading} sx={{ mx: 'auto' }} type='submit' size={'medium'} disabled={!!Object.keys(errors).length}>
                Save
              </LoadingButton>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(8).nullable(),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})
const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
