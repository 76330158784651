import { Box, Theme, Typography } from '@mui/material'
import { Field, FieldArray, Form, useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField } from 'formik-mui'
import { CompanyRatingsField } from '../../../hooks/useCompany'
import { ApiTypes } from 'recruticka-frontend-libs'
import React, { useEffect, useState } from 'react'
import { CompanyRatingField } from './companyRating-field'


const initialRatings = [
  {
    type: ApiTypes.CompanyRatingType.GLASSDOOR,
    url: ''
  },
  {
    type: ApiTypes.CompanyRatingType.TRUSTPILOT,
    url: ''
  }
]

interface Props {
  fieldsPrefix?: string
}

export const CompanyRatings = ({fieldsPrefix = ''} : Props) => {
  const [companyRatings, meta ,companyRatingsHelper] = useField('companyRatingsFields')

  useEffect(() => {
    if (companyRatings.value.length){
      if (companyRatings.value.length < 2 && companyRatings.value.length > 0){
        const rating = companyRatings.value.find((rate : CompanyRatingsField) => rate)
        companyRatingsHelper.setValue(initialRatings.map(rate => rate.type === rating.type ? rating : rate))
      }
    } else {
      companyRatingsHelper.setValue(initialRatings)
    }
  },[meta.initialValue, meta.value, companyRatings.value.length])

  return (
      <Box sx={wrapper}>
        <FieldArray
          name={'companyRatingsFields'}
          render={arrayHelper => (
            companyRatings.value.sort((a : CompanyRatingsField ,b : CompanyRatingsField) => a.type > b.type ? 1 : -1)
              .map((rating: CompanyRatingsField, idx: number) => {
              return (
                <CompanyRatingField key={idx} idx={idx} rating={rating} />
              )
            })
          )}
          />
      </Box>
  )
}


const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]:{
    gap: 2
  }
})
