import { useCallback, useEffect, useState } from 'react'
import { useProfile } from './useProfile'

type Props = {
  steps: { [key in string]: string }
  stepChecker: { [key in any]: (data: any) => boolean }
  stepCheckerData?: unknown
}

export const useSetupSteps = ({ steps, stepChecker, stepCheckerData }: Props) => {
  const [stepsValidated, setStepsValidated] = useState<boolean | undefined>()
  const [activeStep, setActiveStep] = useState<string>(Object.keys(steps)[0])
  const [stepsKeys, setStepsKeys] = useState<string[]>([])
  const { refetch: refetchProfile } = useProfile()

  useEffect(() => {
    setStepsKeys(Object.keys(steps))
  }, [steps])

  const goToPrevStep = () => {
    const prevStepIndex = Math.max(0, stepsKeys.indexOf(activeStep) - 1)

    goToStep(prevStepIndex)
  }

  const goToNextStep = async () => {
    const nextStepIndex = Math.min(stepsKeys.length, stepsKeys.indexOf(activeStep) + 1)

    await refetchProfile()

    goToStep(nextStepIndex)
  }

  const goToStep = useCallback(
    (step: number) => {
      setActiveStep(steps[stepsKeys[Math.min(stepsKeys.length - 1, step)]])
    },
    [steps, stepsKeys],
  )

  useEffect(() => {
    if (stepsValidated || !stepsKeys.length || !stepCheckerData) {
      return
    }

    let stoppedOnType: string | null = null

    stepsKeys.some((type) => {
      const fn = stepChecker[type]

      if (typeof fn === 'function') {
        const result = fn(stepCheckerData)

        if (result) {
          stoppedOnType = type
        }
      }

      return false
    })

    if (stoppedOnType) {
      goToStep(Math.min(stepsKeys.length, stepsKeys.indexOf(stoppedOnType) + 1))
    }

    setStepsValidated(true)
  }, [stepCheckerData, stepsKeys, stepsValidated, stepChecker, goToStep])

  return {
    activeStep,
    stepsKeys,
    goToPrevStep,
    goToNextStep,
    goToStep,
    stepsValidated,
  }
}
