import { Box, Modal, Theme } from '@mui/material'
import MyJobTable, { HeadCell } from '../not-cms/my-job-table'
import { ApplicationRejectedPopup } from '../not-cms/application-rejected-popup'
import { useCallback, useState } from 'react'
import { ApplicationListItem, ApplicationListItemInterface } from './ApplicationListItem'
import { ApiTypes } from 'recruticka-frontend-libs'

type Props = {
  applications: ApplicationListItemInterface[]
  page: number
  rowsPerPage: number
  count: number
  headCells: HeadCell[]
  onPageChange: (page: number) => void
  onRowsPerPageChange: (page: number) => void
  onSortChange?: (cell: string, direction: ApiTypes.SortOrder) => void
}

export const ApplicationList = ({
  applications,
  page,
  rowsPerPage,
  count,
  headCells,
  onPageChange,
  onSortChange,
  onRowsPerPageChange,
}: Props) => {
  const [rejectMessage, setRejectMessage] = useState('')

  const onClickRejected = useCallback(
    (id: string) => {
      const application = applications.find((application) => application.id === id)

      if (application?.rejectMessage) {
        setRejectMessage(application.rejectMessage)
      }
    },
    [applications],
  )

  const handleCloseModalRejectModal = () => setRejectMessage('')

  return (
    <Box sx={applicationWrapper}>
      <MyJobTable
        headCells={headCells}
        onSortChange={onSortChange}
        pagination={{ rowsPerPage, page, onPageChange, onRowsPerPageChange, count }}
      >
        {applications.map((application) => {
          return (
            <ApplicationListItem key={application.id} application={application} onClickRejected={onClickRejected} />
          )
        })}
      </MyJobTable>
      <Modal open={rejectMessage.length > 0} onClose={handleCloseModalRejectModal}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '864px',
            width: '100%',
            maxHeight: '90vh',
            overflowY: 'auto',
            outline: 'none',
            height: 'auto',
            '&::-webkit-scrollbar': {
              width: '0px',
              height: '0px',
            },
          }}
        >
          <ApplicationRejectedPopup onClose={handleCloseModalRejectModal} explanation={rejectMessage} />
        </Box>
      </Modal>
    </Box>
  )
}

// const headCells: HeadCell[] = [
//   { id: 'title', label: 'Job Title' },
//   { id: 'date', label: 'Date', isSortable: true },
//   { id: 'status ', label: 'Status' },
// ]

const applicationWrapper = (theme: Theme) => ({

  [theme.breakpoints.down('sm')]:{
    '& td':{
      paddingX: 0
    },
    '& th':{
      paddingX: 0
    },
    '& tr':{
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      paddingY: '10px'
    },
    '& .MuiTableHead-root':{
      display: 'none'
    },
    '& .MuiTableRow-root':{
      display: 'flex',
      flexDirection: 'column',
    }
  }
})