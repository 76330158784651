import { Main, Content } from '../components/common-files/common-styles'
import { Theme } from '@mui/material'
import { Settings } from '../components/not-cms/settings'
import TempWrapperNew from '../components/tempWrapperNew'
// Sidebar
import {Sidebar} from '../components/Sidebar'

const SettingsPage = () => {
  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}>
        <Settings />
      </Content>
    </TempWrapperNew>
  )
}

const wrapper = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(8),
})

const contentWrapper = () => ({
  display: 'flex',
  flexDirection: 'column',
})

export default SettingsPage
