import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Field, useField } from 'formik'
import { Select } from 'formik-mui';
import { useTranslation } from 'react-i18next'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { useQueryRequest } from '../../services/api'
import { citySelector } from './CityAutocomplete'
import { useState } from 'react'

const officeSelector = ApiSelector('Office')({
  id: true,
  address: true,
  city: citySelector,
})

type OfficeFields = ApiInputType<ApiGraphQLTypes['Office'], typeof officeSelector>

interface Props extends Record<string, unknown> {
  name: string
  companyId: string
}

export const JobLocationSelect = ({ companyId, name, disabled, ...props }: Props) => {
  const [field, _, helpers] = useField<OfficeFields[]>(name)
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { data: officesData } = useQueryRequest(
    ['companyOffices'],
    {
      getCompany: [
        { where: { id: companyId } },
        {
          id: true,
          offices: [{ take: 100 }, officeSelector],
        },
      ],
    }
  )

  return (
    <Field
      disabled={disabled}
      value={field.value.map((office) => office.id)}
      name={field.name}
      component={Select}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      label={t('formFields.jobLocationPlaceholder')}
      fullWidth
      multiple
      formControl={{
        sx: {
          width: '100%'
        }
      }}
      {...props}
      onChange={(e: SelectChangeEvent<string[]>) => {
        helpers.setValue(officesData?.getCompany.offices?.filter((office) => e.target.value.includes(office.id)) || []);
        setIsOpen(false)
      }}
    >
      {officesData?.getCompany?.offices?.length ?
        officesData?.getCompany.offices?.map((office) => (
          <MenuItem value={office.id} key={office.id}>
            {office.city?.name} {office.city?.country.name}
          </MenuItem>
        ))
        :
        <MenuItem disabled>
          The locations list is empty. Set locations <br /> in the profile
        </MenuItem>
      }
    </Field>
  )
}
