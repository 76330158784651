import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { FeedRounded, LogoutRounded, PersonRounded, SettingsRounded } from '@mui/icons-material'
import { styled, Box, Link, Theme, Divider, CardMedia, Typography, IconButton, Stack, Badge, AppBar,   Toolbar, CssBaseline, List, ListItem, ListItemButton, ListItemIcon, ListItemText  } from '@mui/material'

import { Main } from '../common-files/common-styles'
import { NavLink, generatePath, Link as RouterLink } from 'react-router-dom'
import { authMutation, logout } from '../../services/api'
import { PAGES } from '../../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../../state/atoms/profileState'
import { ApiTypes } from 'recruticka-frontend-libs'
import { Avatar } from '../Avatar'
import RecruitickaLogo from '../../images/svg-files/recruiticka-logo.svg'
import { siteUrl } from '../../config/urls'
import { redirectToSite } from '../../services/redirectToSite'
// import { canDeleteAccount, chatEnabled } from '../../config/features'
import MenuIcon from '@mui/icons-material/Menu';


function useOutsideAlerter(ref: RefObject<HTMLDivElement> | null, closeHandler: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!ref?.current?.contains(event.target)) {
        closeHandler()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref])
}

function notificationsLabel(count: number) {
  if (count === 0) {
    return 'no notifications';
  }
  if (count > 99) {
    return 'more than 99 notifications';
  }
  return `${count} notifications`;
}

interface IProps {
  handleDrawerOpen: () => void;
  hideMenu?: boolean;
}

export const HeaderNew = ({ handleDrawerOpen, hideMenu } : IProps) => {

  const profile = useRecoilValue(profileState)
  const wrapperRef = useRef(null)
  const mainCompany = useRecoilValue(mainCompanyState)
 
  
  const deleteUser = async () => {
    await authMutation({ deleteUser: true })

    document.location.reload()
  }

  const onLogout = async () => {
    await logout()

    redirectToSite()
  }

  const canShowMenuItems = useMemo(() => {
    return profile?.status === ApiTypes.ProfileStatus.ACTIVATED
  }, [profile])

  const [isOpenProfileDropDown, setIsOpenProfileDropDown] = useState<boolean>(false)
  useOutsideAlerter(wrapperRef, () => setIsOpenProfileDropDown(false))

  return (
    <Main sx={main} className={'main-header'}>
      <CssBaseline />
      <AppBar sx={adminHeader} position="fixed" >
        <Toolbar sx={headerToolbar}>
          <HeaderWrapper>
            <Box sx={logoWrapper}>
            {!hideMenu && (
              <Box>
                <IconButton
                  sx={{ color: 'rgb(0 0 0 / 54%)', ml: '0',}}
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  >
                  <MenuIcon />
                </IconButton>
              </Box>
              )}
              <Box sx={logoBox}>
                  <Link href={siteUrl}>
                    <CardMedia image={RecruitickaLogo} sx={logo} />
                  </Link>
              </Box>
            </Box>
            <Box sx={contentWrapper}>
              <Box ref={wrapperRef}>
                <Box sx={{display: 'flex', alignItems:'center', gap:'16px', }}>
                  {/* <IconButton>
                      <Badge variant="dot" anchorOrigin={{vertical: 'top',horizontal: 'right',}} color="secondary">
                        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0 10.2171C1.45222e-06 9.95056 0.108294 9.69541 0.300045 9.5102L1.02031 8.81452C1.2945 8.5497 1.44884 8.18452 1.4477 7.80333L1.44107 5.59622C1.4318 2.50823 3.93252 0 7.02052 0C10.102 0 12.6 2.49801 12.6 5.57947L12.6 7.8201C12.6 8.1914 12.7475 8.5475 13.01 8.81005L13.71 9.51005C13.8957 9.6957 14 9.9475 14 10.2101C14 10.7568 13.5568 11.2 13.01 11.2H9.8C9.8 12.7464 8.5464 14 7 14C5.4536 14 4.2 12.7464 4.2 11.2H0.982855C0.440039 11.2 0 10.76 0 10.2171ZM5.6 11.2C5.6 11.9732 6.2268 12.6 7 12.6C7.7732 12.6 8.4 11.9732 8.4 11.2H5.6ZM11.2 7.8201C11.2 8.56271 11.495 9.2749 12.0201 9.8L2.01495 9.8C2.54955 9.27218 2.84995 8.55124 2.84769 7.79913L2.84107 5.59202C2.83412 3.27887 4.70736 1.4 7.02052 1.4C9.32878 1.4 11.2 3.27121 11.2 5.57947L11.2 7.8201Z" fill="#92929D"/>
                        </svg>
                      </Badge>
                  </IconButton> */}
                  {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                  <IconButton sx={profileButton} onClick={() => setIsOpenProfileDropDown(!isOpenProfileDropDown)} aria-haspopup="true">
                    <Stack sx={profileBox}>
                      <Avatar
                        sx={avatarStyle}
                        src={profile?.employee?.avatar?.url ?? mainCompany.logo?.url}
                        alt={'avatar'}
                      />
                      {profile?.role === ApiTypes.Role.EMPLOYER && (<Typography variant={'h6'}>
                        {profile?.companies?.[0]?.name || profile.email}
                      </Typography>)}
                      {profile?.role === ApiTypes.Role.EMPLOYEE && (<Typography variant={'h6'}>
                        {profile?.companies?.[0]?.name || `${profile.employee?.firstname} ${profile.employee?.lastname}` || profile.email}
                      </Typography>)}
                    </Stack>
                  </IconButton>
                </Box>

                {isOpenProfileDropDown && (
                  <Box sx={dropDownWrapper}>
                    <List sx={{padding: '0',}}>  

                      {canShowMenuItems && (
                        <>
                          <ListItem sx={listBox} className="">
                            <ListItemButton sx={menuLink}>
                                <NavLink
                                  to={profile.role === ApiTypes.Role.EMPLOYEE ?
                                    generatePath(PAGES.PROFILE, {
                                      profileId: profile?.id
                                    })
                                    :
                                    generatePath(PAGES.COMPANIES, {
                                      profileId: profile?.id,
                                      companyId: mainCompany?.id
                                    })
                                  }
                                  className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                                  >
                                  <ListItemIcon>
                                      <PersonRounded />
                                  </ListItemIcon>
                                  <ListItemText primary={'Profile'}/>
                                </NavLink>
                              </ListItemButton>
                          </ListItem>
                          <Divider sx={dividerStyle} />

                          {profile?.role === ApiTypes.Role.EMPLOYEE && (
                            <>
                            <ListItem sx={listBox} className="">
                              <ListItemButton sx={menuLink}>
                                <NavLink
                                  to={generatePath(PAGES.APPLICATIONS, {
                                    profileId: profile?.id
                                  })}
                                  className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                                >
                                  <ListItemIcon>
                                    <FeedRounded />
                                  </ListItemIcon>
                                  <ListItemText primary={'Applications'}/>
                                </NavLink>
                              </ListItemButton>
                            </ListItem>
                            </>
                          )}
                        </>
                      )}
                      
                      <ListItem sx={listBox} className="">
                        <ListItemButton sx={menuLink}>
                          <NavLink to={generatePath(PAGES.ACCOUNT_SETTINGS, {
                            profileId: profile?.id,
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                          >
                            <ListItemIcon>
                              <SettingsRounded />
                            </ListItemIcon>
                            <ListItemText primary={'Settings'}/>
                          </NavLink>
                        </ListItemButton>
                      </ListItem>
                      
                      <ListItem sx={listBox} className="">
                        <ListItemButton sx={menuLink}>
                          <Link sx={linkWrapper} onClick={onLogout}>
                            <ListItemIcon>
                              <LogoutRounded />
                            </ListItemIcon>
                            <ListItemText primary={'Logout'}/>
                          </Link>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                )}
              </Box>
            </Box>
          </HeaderWrapper>
        </Toolbar>
      </AppBar>
    </Main>
  )
}




const adminHeader = (theme: Theme) => ({
  backgroundColor: '#fff',
})

const headerToolbar = (theme: Theme) => ({
  marginLeft: '5px !important',
  [theme.breakpoints.down('md')]: {
    px: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    px: theme.spacing(1),
  }
})

const logoBox = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
})

const profileButton = (theme: Theme) => ({
  borderRadius: '6px',
})

const userIcon = (theme: Theme) => ({
})


const profileBox = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: ' center',
  gap: '10px',
  '&': {
    '& .MuiTypography-h6' : {
      color: '#1E1E1E',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '130px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiTypography-h6' : {
      display: 'none',
    },
  }
})


const main = (theme: Theme) => ({
  background: 'white',
  position: 'fixed',
  top: '0',
  width: '100vw',
  height: 'auto',
  zIndex: '99999',
})


const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(6),
  alignItems: 'center',
  '& > a': {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  [theme.breakpoints.down(1250)]: {
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    // display: 'none'
  }
})

const avatarStyle = (theme: Theme) => ({
  height: '40px',
  width: '40px',
  // marginLeft: theme.spacing(2)
})



const logoWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px'
})


const HeaderWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent:'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    color: '#141D24;',
    height: '80px'
  },
  [theme.breakpoints.down('md')]: {
    height: '73px'
  }
}))

const logo = (theme: Theme) => ({
  height: '28px',
  width: '120px',
  cursor: 'pointer',
  // [theme.breakpoints.down('xl')]: {
  //   height: '34px',
  //   width: '190px'
  // },
  // [theme.breakpoints.down('sm')]: {
  //   height: '32px',
  //   width: '137px'
  // }
})

const dropDownWrapper = (theme: Theme) => ({
  position: 'absolute',
  boxSizing: 'border-box',
  width: '195px',
  marginLeft: '0px',
  py: theme.spacing(2),
  background: theme.palette.background.paper,
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  zIndex: 3,
  [theme.breakpoints.down('md')]: {
    right: '16px',
  }
})

const linkWrapper = (theme: Theme) => ({
  fontFamily: 'Roboto !important',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  color: theme.palette.text.primary,
  px: theme.spacing(2),
  py: theme.spacing(1),
  lineHeight: '150%',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    color: theme.palette.action.active
  }
})

const dividerStyle = (theme: Theme) => ({
  my: theme.spacing(1)
})




const listBox = (theme: Theme) => ({
  padding: '0 8px',
  height: '40px',
  '& .MuiListItemButton-root': {
    padding: '0px',
    height: '100%',
  }
})

const menuLink = (theme: Theme) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: '0 8px',
      height: '32px',
      position: 'relative',
      textDecoration: 'none',
      '&.menu-link-active': {
        backgroundColor: '#f2f5f8 !important',
        '& .MuiListItemIcon-root svg path': {
          fill: '#808080 !important',
        },
        '& .MuiTypography-root': {
          color: '#282828 !important',
        }
      },

      '& .MuiListItemIcon-root': {
        minWidth: '40px',
        justifyContent:'start',
      },
      '& .MuiListItemText-root': {
        margin: '0',
        minWidth: '0',
        '& .MuiTypography-root': {
          color: 'rgb(0 0 0 / 86%)',
          fontWeight: '400',
        }
      }
    }
})
