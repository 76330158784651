import { AutocompleteProps, AutocompleteRenderInputParams, CircularProgress, TextField, Theme } from '@mui/material'
import { Field, FormikProps, FieldProps, FieldAttributes, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetRequired } from 'type-fest'
import { useQueryRequest } from '../../services/api'

const jobTypeSelector = ApiSelector('JobType')({
  id: true,
  name: true,
})

type JobTypeFields = ApiInputType<ApiGraphQLTypes['JobType'], typeof jobTypeSelector>

interface Props extends FieldAttributes<any> {
  placeholder?: string
  label?: string
}

export const JobTypesAutocomplete = ({ placeholder, label, ...props }: Props) => {
  const [field, meta] = useField('jobTypes')
  const { t } = useTranslation()
  const [loadJobTypes, setLoadJobTypes] = useState(false)
  const { data: jobTypesData, isLoading: jobTypesDataLoading } = useQueryRequest(
    ['jobTypes'],
    {
      getJobTypes: [{ take: 100 }, jobTypeSelector],
    },
    {
      enabled: loadJobTypes,
    },
  )

  return (
    <Field
      loading={jobTypesDataLoading}
      name={field.name}
      onOpen={() => setLoadJobTypes(true)}
      onClose={() => setLoadJobTypes(true)}
      multiple
      getOptionDisabled={() => field.value.length > 4}
      component={Autocomplete}
      noOptionsText={t('startTyping')}
      options={jobTypesData?.getJobTypes || []}
      autoComplete
      includeInputInList
      // sx={{height: '120px'}}
      filterSelectedOptions
      isOptionEqualToValue={(option: JobTypeFields, value: JobTypeFields) => option.id === value.id}
      getOptionLabel={(option: JobTypeFields) => (option.id ? `${option.name}` : '')}
      {...props}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          placeholder={placeholder}
          sx={jobTypeStyle}
          label={label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {jobTypesDataLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export const jobTypeStyle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]:{
    // minHeight: '72px',
    height: '100%',
    '& label':{
      whiteSpace: 'normal',
      paddingRight: '50px',
      fontSize: '14px',
      top: '-7px'
    },
    '& .MuiOutlinedInput-root':{
      paddingTop: '15px'
    }
  }
})
