import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Box, FormControl, IconButton, Input, Theme } from '@mui/material'
import Uploader, { Asset } from '../../Uploader/Uploader'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import { Button } from '../../ui-kits/button/button'
import SendIcon from '@mui/icons-material/Send'
import theme from '../../../styles/theme'
import Picker from 'emoji-picker-react'
import { ProgressFile, UploadSlider } from './upload-slider'
import { ChatVariant } from '../chat-box'
import { Formik, Form } from 'formik'
import { useAuthQueryRequest } from '../../../services/api'
import Yup, { TextareaValidation, TextValidation } from '../../../validation'
import { useCreateMessage, useUpdateMessage } from '../../../hooks/useCreateMessage'
import { ReplyedMessage } from './replyed-message'
import { Message, ReplyMessageTypes } from '../../../hooks/useMessages'
import { DOCUMENTS, IMAGES, VIDEO } from '../../../config/uploaderTypes'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../state/atoms/profileState'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

interface Props {
  variant: ChatVariant
  replyMessage?: ReplyMessageTypes
  setReplyMessage?: (arg: Message | undefined) => void
  setEditMessage?: (arg: Message | undefined) => void
  conversationId?: string
  editMessage?: Message
  isDeletedParticipant?: boolean
}

interface MessageFormStyle {
  messageForm: object
  emojiBox: object
  emojiBoxClose?: object
  iconWrapper?: object
  styleReplyMessage?: object
}

export interface Progress {
  name?: string,
  size?: number,
  progress: {
    bytesUploaded: number,
    bytesTotal: number
  },
  file: {
    name: string,
    id: string,
    type: string
  },
  url: string,
  id: string,
  type: string
}

export const MessageForm = ({
                              variant,
                              editMessage,
                              setEditMessage,
                              replyMessage,
                              setReplyMessage,
                              isDeletedParticipant,
                              conversationId
}: Props) => {
  const [text, setText] = useState<string>('')
  const [showEmoji, setShowEmoji] = useState<boolean>(false)
  const [progresses, setProgresses] = useState<boolean>(false)
  const [filesArray, setFilesArray] = useState<Asset[]>([])
  const profile = useRecoilValue(profileState)
  const sendMessage = useCreateMessage(profile.id)
  const updateMessage = useUpdateMessage(profile.id, conversationId)

  const {
    messageForm,
    emojiBox,
    emojiBoxClose,
    iconWrapper,
    styleReplyMessage
  } = useMemo<MessageFormStyle>(() => {
    if (variant === ChatVariant.CHAT_WIDGET) {
      return widgetChatStyle(theme)
    }
    return mainChatStyle(theme)
  }, [])
  const openEmojiBox = () => {
    setShowEmoji(!showEmoji)
  }
  const closeEmojiBox = () => {
    setShowEmoji(false)
  }
  const onEmojiClick = (event: SyntheticEvent, emojiObject: any) => {
    setShowEmoji(false)
    setText((prevText) => prevText + emojiObject.emoji)
  }
  const { data: me } = useAuthQueryRequest('me', {
    me: {
      userProfile: {
        id: true
      }
    }
  })

  const handleClearReplyMessage = () => {
    if (setReplyMessage) {
      setReplyMessage(undefined)
    }
    if (setEditMessage){
      setEditMessage(undefined)
      setText('')
    }
  }

  useEffect(() => {
    if (editMessage) {
      setText(editMessage.content)
    }
    if (replyMessage){
      setText('')
    }
  }, [editMessage?.id, replyMessage])

  const handeSubmit = async () => {
    if (me?.me?.userProfile) {
      if (editMessage?.content) {
        await updateMessage.mutateAsync({
          id: editMessage.id,
          content: text,
        })
      } else if (conversationId) {
        await sendMessage.mutateAsync({
          authorId: me.me.userProfile[0].id,
          assets: filesArray,
          content: text,
          replayToId: replyMessage?.id,
          conversationId: conversationId
        })
      }
    }

    setFilesArray([])
    setText('')
    handleClearReplyMessage()
    if (setEditMessage) {
      setEditMessage(undefined)
    }
  }

  const handleNewLine = (event: React.KeyboardEvent) => {
    if (!isDeletedParticipant){
      if (event.key === 'Enter' && !event.shiftKey) {
        if (text.trimStart()) {
          handeSubmit()
          event.preventDefault()
        }
      }
    }
  }

  useEffect(() => {
    setText('')
    setFilesArray([])
    if (setEditMessage) {
      setEditMessage(undefined)
    }
    if (setReplyMessage) {
      setReplyMessage(undefined)
    }
  },[conversationId])

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        // content: ''
        // name: companyData?.getCompany.name || '',
        description: ''
      }}
      onSubmit={handeSubmit}
    >
      <Box
        sx={{
          margin: 0,
          padding: 0
        }}
      >
        {(editMessage?.content || replyMessage?.content) && styleReplyMessage
          && (replyMessage?.conversationId === conversationId || editMessage?.conversationId === conversationId) &&
          <ReplyedMessage
            message={replyMessage}
            style={styleReplyMessage}
            editMessage={editMessage?.content}
            onClick={handleClearReplyMessage}
          />
        }
        <Box sx={messageForm}>
          <Form>
            <Input placeholder="Write a message..."
              value={text}
              multiline={true}
              sx={inputStyle}
              onChange={(e) => setText(e.target.value.trimStart())}
              onKeyPress={handleNewLine}
              disableUnderline={true} fullWidth={true} />
            {(!!filesArray.length || progresses) &&
              <UploadSlider assets={filesArray} progresses={progresses} setAssets={setFilesArray} />
            }
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <Box
                sx={{
                  display: 'flex'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end'
                  }}
                >
                  {editMessage ?
                    <IconButton disabled sx={attachButton}>
                      <AttachFileIcon />
                    </IconButton>
                    :
                    <Uploader
                      type={'button'}
                      assets={filesArray}
                      fullPreview={true}
                      multiple={true}
                      setProgress={setProgresses}
                      allowedFileTypes={[...IMAGES, ...VIDEO, ...DOCUMENTS]}
                      onFileUploaded={(asset) => {
                        if (asset) {
                          setFilesArray(prev => [...prev, asset])
                        }
                      }}
                      button={
                        <IconButton sx={attachButton}>
                          <AttachFileIcon />
                        </IconButton>
                      }
                    />
                  }
                </Box>
                <Box sx={iconWrapper}>
                  <IconButton onClick={openEmojiBox} sx={iconButton}>
                    <SentimentSatisfiedAltIcon />
                  </IconButton>
                  {showEmoji &&
                    <Box sx={emojiBox}>
                      <Box sx={emojiBoxClose} onClick={closeEmojiBox} />
                      <Picker
                        disableSearchBar={true}
                        onEmojiClick={onEmojiClick}
                        pickerStyle={{ width: '360px' }}
                      />
                    </Box>
                  }
                </Box>
              </Box>
              {
                !isDeletedParticipant && (text || filesArray.length) ?
                  <Button type={'submit'} sx={submitButton}>
                    {editMessage ?
                      <SaveRoundedIcon fontSize={'small'} /> : <SendIcon fontSize={'small'} />
                    }
                  </Button> :
                  <Button disabled={true} sx={submitButton} variant={'outlined'}>
                    {editMessage ?
                      <SaveRoundedIcon fontSize={'small'} /> : <SendIcon fontSize={'small'} />
                    }
                  </Button>
              }
            </Box>
          </Form>
        </Box>
      </Box>
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  name: TextValidation,
  description: TextareaValidation.nullable()
  // shortDescription: Yup.string().nullable(),
})

const attachButton = (theme: Theme) => ({
  transform: 'rotate(45deg)',
  height: '18px',
  width: '18px',
  '& svg': {
    height: '18px',
    width: '18px'
  }
})

const submitButton = (theme: Theme) => ({
  padding: '10px 22px',
  height: '38px'
})

const iconButton = (theme: Theme) => ({
  width: '18px',
  height: '18px',
  marginLeft: '15px',
  '& svg': {
    width: '18px',
    height: '18px'
  }
})

const inputStyle = (theme: Theme) => ({
  '& textarea': {
    maxHeight: '78px',
    overflow: 'auto!important',
    paddingLeft: '0!important'
  }
})

const mainChatStyle = (theme: Theme) => ({
  messageForm: {
    padding: '16px 28px 32px',
    margin: 'auto 0 0',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '10px 20px 11px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px 25px'
    }
  },
  styleReplyMessage: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '12px 29px 12px',
    margin: 'auto 0 0'
  },
  emojiBox: {
    position: 'absolute',
    bottom: '90px',
    left: '20px',
    zIndex: '2',
    '& .emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar': {
      width: '0'
    },
    '& emoji-scroll-wrapper': {
      paddingX: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      left: 0,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& aside': {
        width: '100%!important',
        zIndex: '5'
      }
    }
  },
  emojiBoxClose: {
    position: 'absolute',
    left: '-100%',
    right: '-100vw',
    bottom: '-40%',
    top: '-100%',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      backgroundColor: '#475a6e8f',
      width: '100%',
      height: ' calc(100% - 320px)'
    }
  }
})

const widgetChatStyle = (theme: Theme) => ({
  messageForm: {
    margin: 'auto 0 0',
    padding: '10px 25px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 24px'
    }
  },
  styleReplyMessage: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '12px 29px 12px',
    margin: 'auto 0 0'
  },
  emojiBox: {
    position: 'absolute',
    zIndex: '2',
    bottom: '60px',
    left: '-220px',
    '& .emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar': {
      width: '0'
    },
    [theme.breakpoints.down('md')]: {
      right: '-100px'
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      height: '100vh',
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      right: 0,
      '& aside': {
        width: '100%!important',
        zIndex: '5'
      }
    }
  },
  iconWrapper: {
    // position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'initial'
    }
  },
  emojiBoxClose: {
    position: 'absolute',
    left: 0,
    right: '-19vw',
    bottom: '-10vw',
    top: '-10vw',
    [theme.breakpoints.down('lg')]: {
      right: '-30vw'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      backgroundColor: '#475a6e8f',
      width: '100%',
      height: ' calc(100% - 320px)',
      position: 'initial'
    }
  }
})
