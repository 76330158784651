import { SvgProps } from '../types/icon'

export const HidePrivacyIcon = ({ width = '22px', height = '18px', fill='#92929D' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
        <path d='M17.8034 12.6149H17.1267C17.0845 12.5444 17.0633 12.4505 16.9999 12.3801C16.725 11.9576 16.2809 11.6759 15.7945 11.6055C14.5891 11.4646 13.1723 11.4646 11.9669 11.6055C11.4805 11.6524 11.0364 11.9576 10.7404 12.4036C10.7192 12.4271 10.7192 12.4505 10.7192 12.4505C10.2329 12.2627 9.70419 12.2627 9.21781 12.4505C9.19666 12.4271 9.19666 12.4036 9.19666 12.4036C8.92175 11.9576 8.47767 11.6759 7.97014 11.6055C6.76477 11.4646 5.34793 11.4646 4.14256 11.6055C3.65618 11.6524 3.23324 11.9341 2.93718 12.3801C2.89489 12.4505 2.85259 12.5444 2.8103 12.6149H2.15475C1.92213 12.6149 1.68952 12.8027 1.66837 13.0843C1.64722 13.3895 1.85869 13.6477 2.1336 13.6477H2.66227C2.66227 13.7181 2.66227 13.812 2.68342 13.8825L2.85259 14.751C3.1275 16.2534 4.31173 17.3097 5.68628 17.3332H6.42642C7.75867 17.3332 8.92175 16.3238 9.2601 14.8919L9.47157 13.9529C9.47157 13.9059 9.49272 13.8825 9.49272 13.8355C9.53501 13.5773 9.72533 13.366 9.9791 13.366C10.0002 13.366 10.0002 13.366 10.0214 13.366C10.2752 13.366 10.4655 13.5773 10.5078 13.8355C10.5078 13.8825 10.5289 13.9294 10.5289 13.9529L10.7404 14.8919C11.0576 16.3473 12.2207 17.3332 13.5741 17.3332H14.3142C15.6676 17.3097 16.873 16.2299 17.1479 14.751L17.3171 13.8825C17.3382 13.812 17.3382 13.7181 17.3382 13.6477H17.8669C18.1418 13.6477 18.3533 13.3895 18.3321 13.0843C18.2687 12.8027 18.0361 12.6149 17.8034 12.6149Z' fill={fill}/>
        <path d='M15.7368 2.29929C15.3232 1.11205 14.1693 0.447199 13.0373 0.732136L10.5336 1.3495C10.1853 1.44448 9.83696 1.44448 9.48863 1.3495L6.985 0.732136C5.85293 0.447199 4.72085 1.11205 4.28544 2.29929L2.50024 7.33317H17.5002L15.7368 2.29929Z' fill={fill}/>
        <path d='M19.8064 9.69712L17.9967 8.1665H2.0038L0.194075 9.67161C-0.16366 10.0032 0.00468593 10.6665 0.446595 10.6665H19.5539C19.9958 10.6665 20.1641 10.0032 19.8064 9.69712Z' fill={fill}/>
    </svg>
  )
}
