import React, { useState } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { Button } from './ui-kits/button/button'
import AlertDialog from './not-cms/ui-kits/alert-dialog/alert-dialog'
import { Avatar } from './Avatar'

interface Props {
  confirm: () => void
  displayName?: string
}

export const HideButtonAndAlert = ({ confirm, displayName }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Box>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography variant={'h2'}>{displayName}</Typography>
        <Avatar alt={'avatar'} sx={mobileAvatarWrapper} />
      </Box>
      <Button
        onClick={() => setIsOpen(true)}
        sx={visibleButton}
        variant={'outlined'}
        size={'small'}
        startIcon= {<RemoveRedEyeIcon fontSize={'small'} />}
      >
        Make visible
      </Button>
      <AlertDialog
        title={'Make Visible'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={confirm}
        description={<>This candidate wished to remain anonymous.
          <br/> Make a request for him to display detailed information?</>}
      />
    </Box>
  )
}

const visibleButton = (theme: Theme) => ({
  // width: '168px',
  // padding: '14px 17px',
  justifyContent: 'space-between',
  // height: '38px',
  marginY: '15px'
})

const mobileAvatarWrapper = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '50px',
    height: '50px',
    marginLeft: '0',
    marginRight: '0',
  }
})
