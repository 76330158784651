import { Box, IconButton, SvgIconTypeMap } from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import { FormatItalic, FormatUnderlined } from '@mui/icons-material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useMemo } from 'react'

type Props = {
  onToggle: (style: string) => void
  checkStyles: (style: string) => boolean
}

export const Toolbar = ({ onToggle, checkStyles }: Props) => {
  const inlineStyles = useMemo(() => {
    const styles = []

    for (const [style, icon] of Object.entries(INLINE_STYLES)) {
      styles.push({
        style,
        icon,
      })
    }

    return styles
  }, [])

  return (
    <Box
      sx={{
        paddingY: 0.7,
        display: 'flex',
        justifyContent: 'center',
        background: '#F8F8F8',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}
    >
      {inlineStyles.map(({ style, icon: Icon }) => (
        <IconButton
          key={style}
          color={checkStyles(style) ? 'primary' : 'default'}
          size='small'
          onMouseDown={(e) => {
            e.preventDefault()
            onToggle(style)
          }}
        >
          <Icon />
        </IconButton>
      ))}
    </Box>
  )
}

export const INLINE_STYLES: Record<string, OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>> = {
  BOLD: FormatBoldIcon,
  ITALIC: FormatItalic,
  UNDERLINE: FormatUnderlined,
}
