import { Formik, Form, Field } from 'formik'
import Yup from '../validation'
import { Box, Button, Link, Theme, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Checkbox } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from '../components/not-cms/steps/position-information'
import { generatePath, useNavigate } from 'react-router-dom'

export const ThankYouPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Form>
      <Box sx={wrapper}>
        <Box sx={innerWrapper}>
          <Typography sx={typographyStyle}>Thanks for applying</Typography>
          <Typography sx={typographyStyle}>
            We will review your application and you’ll be notified once it’s approved or declined. It usually takes a
            few minutes.
          </Typography>
        </Box>
      </Box>

      <Box className='stepFooter'>
        <Box sx={{justifyContent:'center', }}>
          <LoadingButton variant='contained' color='primary' sx={buttonStyle} size={'medium'} onClick={() => {navigate('dashboard')}}>
            OK
          </LoadingButton>
        </Box>
      </Box>
    </Form>
  )
}

const validationSchema = Yup.object().shape({
  termsAndConditionsAccepted: Yup.boolean().required(),
})

const innerWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  maxWidth: '400px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px',
  },
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  gap: '30px',
  alignItems: 'center',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: '10px',
    alignItems: 'center',
  },
})

const typographyStyle = (theme: Theme) => ({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  // maxWidth: '223px',
  width: '100%',
})
