import { SvgProps } from '../types/icon'

export const SearchIcon = ({ width = '20px', height = '20px', className }: SvgProps) => {
  return (
    <svg height={height} width={width} className={className}>
      <path
        d='M17.6262 19.0403C18.0168 19.4308 18.6499 19.4308 19.0405 19.0403C19.431 18.6498 19.431 18.0166 19.0405 17.6261L17.6262 19.0403ZM9.16669 15.6665C5.57684 15.6665 2.66669 12.7564 2.66669 9.1665H0.666687C0.666687 13.8609 4.47227 17.6665 9.16669 17.6665V15.6665ZM2.66669 9.1665C2.66669 5.57665 5.57684 2.6665 9.16669 2.6665V0.666504C4.47227 0.666504 0.666687 4.47208 0.666687 9.1665H2.66669ZM9.16669 2.6665C12.7565 2.6665 15.6667 5.57665 15.6667 9.1665H17.6667C17.6667 4.47208 13.8611 0.666504 9.16669 0.666504V2.6665ZM15.6667 9.1665C15.6667 10.9617 14.9402 12.5853 13.7629 13.7627L15.1771 15.1769C16.7142 13.6398 17.6667 11.5134 17.6667 9.1665H15.6667ZM13.7629 13.7627C12.5855 14.9401 10.9619 15.6665 9.16669 15.6665V17.6665C11.5136 17.6665 13.64 16.714 15.1771 15.1769L13.7629 13.7627ZM19.0405 17.6261L15.1771 13.7627L13.7629 15.1769L17.6262 19.0403L19.0405 17.6261Z'
        fill='#0F64E2'
      />
    </svg>
  )
}
