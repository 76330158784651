import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  Slider,
  Divider,
  Autocomplete
} from '@mui/material'
import { Field, useField, useFormikContext } from 'formik'
import { RadioGroup, TextField } from 'formik-mui'
import { SyntheticEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'
import { CurrencyAutocomplete } from '../form-fields/CurrencyAutocomplete'
import theme from '../../styles/theme'

import { Salary } from '../../components/form-fields/Salary'

import { SliderField } from '../ui-kits/slider-field/slider-field'

export const   JobSalaryInfo = () => {
  const [fieldSalarySchedule, , helpersSalarySchedule] = useField<ApiTypes.SalaryPeriod>('salary.schedule')
  const [, metaSalaryFrom] = useField<number>('salary.from')
  const [, metaSalaryTo] = useField<number>('salary.to')
  const [fieldSalaryType] = useField<ApiTypes.JobSalaryType>('salaryType')
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDisabledFields = useMemo(() => {
    return fieldSalaryType.value === ApiTypes.JobSalaryType.GET_IN_TOUCH
  }, [fieldSalaryType.value])
  const { t } = useTranslation()
  const { isSubmitting } = useFormikContext()

  const handleChange = (event: SyntheticEvent, newValue: ApiTypes.SalaryPeriod) => {
    helpersSalarySchedule.setValue(newValue)
  }

  const [fieldWfhDays, , helperWfhDays] = useField<number>('location.wfhDays')
  
  
  return (
    <Box sx={wrapper}>
    <Typography sx={(theme) => ({ marginBottom: theme.spacing(3) })} variant={'h2'}>Salary Info</Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(2)
      })} />
      <Box sx={Boxwrapper}>
        <Box sx={sliderWrapper}>
          {/* <Slider
              track="inverted"
              aria-labelledby="track-inverted-range-slider"
              step={1}
              defaultValue={[15000, 20000]}
              valueLabelDisplay='on'
            /> */}

        </Box>
        <Box sx={headStyle}>
          <Tabs value={fieldSalarySchedule.value} onChange={handleChange}>
            <Tab
              sx={{ textTransform: 'none' }}
              value={ApiTypes.SalaryPeriod.MONTH}
              label={t('enum.SalaryPeriod.MONTH')}
            />
            <Tab
              sx={{ textTransform: 'none' }}
              value={ApiTypes.SalaryPeriod.ANNUAL}
              label={t('enum.SalaryPeriod.ANNUAL')}
            />
          </Tabs>
          <FormControl sx={checkboxesWrapper}>
            <Field
              component={RadioGroup}
              name='salaryType'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <FormControlLabel
                value={ApiTypes.JobSalaryType.CONTRACT}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobSalaryType.CONTRACT')}
                disabled={isSubmitting}
              />
              <FormControlLabel
                value={ApiTypes.JobSalaryType.GET_IN_TOUCH}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobSalaryType.GET_IN_TOUCH')}
                disabled={isSubmitting}
              />
            </Field>
          </FormControl>
        </Box>
        <Grid container spacing={2}>

          <Grid item xs={matchesMd ? 12 : 4}>
            <CurrencyAutocomplete name='salary.currency' disabled={isDisabledFields} />
          </Grid>
          <Grid item xs={matchesMd ? 12 : 4}>
            <Field
              type={'number'}
              disabled={isDisabledFields}
              label={'From'}
              component={TextField}
              name='salary.from'
              helperText={metaSalaryFrom.error}
              fullWidth
            />
          </Grid>
          <Grid item xs={matchesMd ? 12 : 4}>
            <Field
              type={'number'}
              disabled={isDisabledFields}
              label={'To'}
              component={TextField}
              name='salary.to'
              helperText={metaSalaryTo.error}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* <Divider />
        <Box sx={{ textAlign: 'center', }}>
          <FormControl sx={checkboxesWrapper}>
            <Field
              component={RadioGroup}
              name='salaryType'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <FormControlLabel
                value={ApiTypes.JobSalaryType.CONTRACT}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobSalaryType.CONTRACT')}
                disabled={isSubmitting}
              />
              <FormControlLabel
                value={ApiTypes.JobSalaryType.GET_IN_TOUCH}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobSalaryType.GET_IN_TOUCH')}
                disabled={isSubmitting}
              />
            </Field>
          </FormControl>
        </Box> */}
      </Box>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  boxShadow: 'none',
})

const Boxwrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  boxShadow: 'none',
  maxWidth: '600px',
  margin: '40px auto',
})

const headStyle = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse'
  }
})

const checkboxesWrapper = (theme: Theme) => ({
  // borderRadius: '6px',
  // backgroundColor: theme.palette.background.default,
  // paddingLeft: '16px',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#fff',
    alignItems: 'baseline'
  }
})

const sliderWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})
