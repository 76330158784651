import { Card, Theme, Typography, Box, Divider } from '@mui/material'
import { useField } from 'formik'
import { TextEditorField } from '../form-fields/TextEditorField'

export const JobRequirements = () => {
  const [field] = useField('requirement.description')

  return (
    <Box sx={wrapper}>
      <Typography variant={'h2'}>Requirements</Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(2)
      })} />
      <TextEditorField name={field.name} placeholder='Add your job requirements here...' />
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  boxShadow: 'none',
})
