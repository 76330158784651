import { generatePath, Navigate } from 'react-router-dom'
import { LoaderLarge } from '../components/LoaderLarge'
import { useAuthQueryRequest } from '../services/api'
import { PAGES } from './AppRoutes'
import { redirectToSite } from '../services/redirectToSite'

export const RedirectToProfile = () => {
  const { data, isLoading, isError } = useAuthQueryRequest('me', {
    me: {
      userProfile: {
        profileId: true,
      },
    },
  })

  if (isLoading) {
    return <LoaderLarge />
  }

  if (isError || !data?.me?.userProfile) {
    redirectToSite()
    // return <Navigate to={'/sign-in'} />
    return null
  }

  const [firstUserProfile] = data.me.userProfile

  return <Navigate to={generatePath(PAGES.SETUP, { profileId: firstUserProfile.profileId })} replace />
}
