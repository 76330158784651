export const cabinetUrl = process.env.REACT_APP_CABINET_URL as string
export const siteUrl = process.env.REACT_APP_SITE_URL as string

export const apiUrl = process.env.REACT_APP_API_URL as string
export const apiGraphqlUrl = apiUrl + '/graphql'

export const siteAllJobsPage = siteUrl + '/jobs'
export const siteJobPreviewPage = siteUrl + '/api/jobPreview'

export const siteAllCompaniesPage = siteUrl + '/companies'
export const siteCompanyPreviewPage = siteUrl + '/api/companyPreview'

export const siteLogoutApi = siteUrl + '/api/logout'
export const siteLoginApi = siteUrl + '/api/login'

export const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL as string;

