import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional, PartialDeep } from 'type-fest'
import { authMutation } from '../services/api'
// import { TextBlock, textBlockSelector, useCreateOrUpdateTextBlock, useDeleteTextBlock } from './useTextBlock'
import { TextBlock, textBlockSelector, useCreateOrUpdateCompanyPerk, useDeleteTextBlock } from './useCompanyPerk'

export const perkSelector = ApiSelector('CompanyPerks')({
  id: true,
  type: true,
  description: true,
  textBlocks: textBlockSelector,
})

// type PerkFields = ApiInputType<ApiGraphQLTypes['CompanyPerks'], typeof perkSelector>
export interface Perk extends Omit<ApiInputType<ApiGraphQLTypes['CompanyPerks'], typeof perkSelector>, 'textBlocks'> {
  textBlocks?: SetOptional<TextBlock, 'id'>[]
}
type CreateOrUpdatePerkFields = SetOptional<Perk, 'id'>

type MutationProps = {
  companyId: string
  values: CreateOrUpdatePerkFields
  oldValues?: PartialDeep<CreateOrUpdatePerkFields>
}

export const useCreateOrUpdatePerk = (selector: Partial<typeof perkSelector>) => {
  const textBlockMutation = useCreateOrUpdateCompanyPerk({ id: true })
  const deleteTextBlockMutation = useDeleteTextBlock()

  return useMutation(async ({ companyId, values, oldValues }: MutationProps) => {
    const { id, textBlocks, ...input } = values
    const newTextlockIds: string[] = []

    if (textBlocks) {
      for await (const textBlock of textBlocks) {
        // TODO: Update only if need
        const { id: officeAssetId } = (await textBlockMutation.mutateAsync({
          values: textBlock,
          oldValues: oldValues?.textBlocks?.find((oldTextBlock) => oldTextBlock?.id === textBlock.id),
        })) as TextBlock

        newTextlockIds.push(officeAssetId)
      }
    }

    if (id) {
      const deleteIds: string[] = []

      oldValues?.textBlocks?.forEach((oldTextBlock) => {
        const textBlock = textBlocks?.find((asset) => asset.id === oldTextBlock?.id)

        if (!textBlock && oldTextBlock?.id) {
          deleteIds.push(oldTextBlock.id)
        }
      })

      for await (const deleteId of deleteIds) {
        await deleteTextBlockMutation.mutateAsync(deleteId)
      }

      const { updateCompanyPerk } = await authMutation({
        updateCompanyPerk: [
          {
            id,
            input: {
              ...input,
              textBlocks: {
                connect: newTextlockIds.map((id) => ({ id })),
              },
            },
          },
          selector,
        ],
      })

      return updateCompanyPerk
    }

    const { createCompanyPerk } = await authMutation({
      createCompanyPerk: [
        {
          input: {
            ...input,
            company: {
              connect: {
                id: companyId,
              },
            },
            textBlocks: {
              connect: newTextlockIds.map((id) => ({ id })),
            },
          },
        },
        selector,
      ],
    })

    return createCompanyPerk
  })
}

export const useDeletePerk = () => {
  return useMutation(async (id: Perk['id']): Promise<boolean> => {
    await authMutation({
      deleteCompanyPerk: [{ id }, true],
    })

    return true
  })
}
