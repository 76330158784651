import i18n from 'i18next'
import translation from './en/translation'
import { initReactI18next } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'

export const defaultNS = 'ns1'
export const resources = {
  en: {
    ns1: translation,
  },
} as const

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['ns1'],
  defaultNS,
  resources,
})
