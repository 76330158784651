import { Box, Card, Chip, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/dashboard-employee'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter'

const employeeSelector = ApiSelector('Employee')({
  preferSalary: {
    id: true,
    from: true,
    to: true,
    schedule: true,
    currency: {
      id: true,
      name: true,
      symbol: true,
    },
  },
  recruitCountries: {
    id: true,
    name: true,
    flagEmoji: true,
  },
  deskType: true,
  deskTypeRatio: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

interface Props extends Partial<EmployeeFields> {
  onChange?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditPreferences = ({
  onChange,
  type,
  deskType,
  deskTypeRatio,
  preferSalary,
  recruitCountries,
}: Props) => {
  const { t } = useTranslation()
  const from = useCurrencyFormatter((preferSalary?.from as number) || 0, preferSalary?.currency.id)
  const to = useCurrencyFormatter((preferSalary?.to as number) || 0, preferSalary?.currency.id)

  return (
    <Card sx={wrapper}>
      <Box sx={headStyle}>
        <Typography variant={'h6'}>Additional Information:</Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onChange}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      {preferSalary && (
        <Box sx={infoStyle}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            Desired Salary:
          </Typography>
          <Typography sx={typographyStyle}>
            <>
              {from} - {to}
            </>
          </Typography>
          <Divider />
        </Box>
      )}
      {deskType && (
        <Box sx={infoStyle}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            Desk Type:
          </Typography>
          <Typography sx={typographyStyle}>{t(`enum.DeskType.${deskType}`)}</Typography>
          <Divider />
        </Box>
      )}
      {recruitCountries && (
        <Box sx={infoStyle}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            Recruiting into:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {recruitCountries.map(({ id, name }) => (
              <Chip key={id} label={name} color={'primary'} />
            ))}
          </Box>
          <Divider />
        </Box>
      )}
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    },
    '& p':{
      fontSize: '12px'
    }
  }
})

const headStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const infoStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& .MuiChip-root': {
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  }
})

const typographyStyle = (theme: Theme) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})
