import React, { useMemo, useState } from 'react'
import { 
  Chip, FormControl, 
  InputLabel, OutlinedInput, 
  MenuItem, Select, Link, 
  Theme, Box, Typography, Grid, 
  TextField, useMediaQuery, 
  TableRow, TableCell, Tooltip 
} from '@mui/material'
// import { CandidateListCard, EmployeeListItem, employeeListItemSelector } from '../components/not-cms/CandidateListCard'
import { useNavigate, useSearchParams, generatePath, Link as RouterLink } from 'react-router-dom'
import { useDateTime } from '../hooks/useDate'
import { PAGES } from '../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../state/atoms/profileState'
import { PER_PAGE, useList } from '../hooks/useList'
import { ApiTypes, ApiGraphQLTypes, ApiSelector, ApiInputType } from 'recruticka-frontend-libs'
import { useAuthQueryRequest } from '../services/api'
import MyCandidateTable, { HeadCandidateCell } from '../components/not-cms/my-candidate-table'
import theme from '../styles/theme'
import { MenuNavigation } from '../components/not-cms/ui-kits/menu/menu-navigation'
import { assetSelector } from './Uploader/Uploader'
import { citySelector } from './form-fields/CityAutocomplete'
import { Avatar } from './Avatar'
import _ from 'lodash'
import { $enum } from 'ts-enum-util'
import { Form, Formik, useField } from 'formik'

const employeeListItemSelector = ApiSelector('Employee')({
  id: true,
  profile: {
    displayName: true,
    isHidden: true,
    id: true,
    conversations: [
      { profileId: '' },
      {
        id: true
      }
    ],
    status: true
  },
  createdAt: true,
  profileId: true,
  experience: true,
  firstname: true,
  lastname: true,
  avatar: assetSelector,
  jobTypes: {
    id: true,
    name: true
  },
  preferSalary: {
    from: true,
    to: true,
    schedule: true,
    currency: {
      id: true
    }
  },
  locationType: true,
  currentCity: citySelector,
  specializations: {
    id: true,
    name: true
  }
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeListItemSelector>

const statusLabel = {
  'ACTIVATED' : <Chip label='Approved' sx={{ bgcolor: 'green', color: 'white' }} />,
  'REJECTED' : <Chip label='Not approve' sx={{ bgcolor: 'red', color: 'white' }} />,
  'CREATED' : <Chip label='Created' sx={{ bgcolor: 'blue', color: 'white' }} />,
  'COMPLETED' : <Chip label='Completed' sx={{ bgcolor: 'blue', color: 'white' }} />,
  'PENDING' : <Chip label='Pending' sx={{ bgcolor: 'blue', color: 'white' }} />,
  'ADMIN_REJECTED' : <Chip label='Admin Rejected' sx={{ bgcolor: 'red', color: 'white' }} />
}

interface FilterFields {
  search?: string,
  status?: ApiTypes.ProfileStatus | null
}

interface queryFields {
  search?: string,
  status?: string,
}


export const CandidateList: React.FC = () =>  {

  const { id: profileId } = useRecoilValue(profileState)
  const { candidatesPage: page, candidatesSkip: skip, updateCandidatesPage: updatePage, perPage, updatePerPage } = useList()
  // const { id: mainCompanyId } = useRecoilValue(mainCompanyState)
  // const [companyId, setCompanyId] = useState<string>(mainCompanyId)
  const [query, setQuery] = useState<queryFields | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const [alert, setAlert] = useState<IAlert>(initialAlert)
  const [status, setStatus] = useState<ApiTypes.ProfileStatus>(ApiTypes.ProfileStatus.ACTIVATED)
  const [search, setSearch] = useState<string>('')

  const applyFilter = (filter: FilterFields) => {
    
    setStatus(filter.status as ApiTypes.ProfileStatus);

    setQuery((query) => {
      const {...data} = (query || {})
      
      return {
        ...data,
        search: filter.search || '',
        status: filter.status || status
      }
    })
  }
  
  const rate = searchParams.get('rate')
  const where: ApiGraphQLTypes['EmployeeWhereInput'] = {

    ...(searchParams.get('search') ? {
      OR: [
        {
          firstname: {
            contains: searchParams.get('search') as string,
            mode: ApiTypes.QueryMode.insensitive
          }
        },
        {
          lastname: {
            contains: searchParams.get('search') as string,
            mode: ApiTypes.QueryMode.insensitive
          }
        }
      ]
    } : {}),
    
  }

  const { 
    data: candidatesData, 
    isLoading : candidateDataLoading, 
    isRefetching: candidateDataRefetching 
  } = useAuthQueryRequest(
    ['canditates', { page, where }],
    {
      getCandidatesAppliedToMyCompany: [
        { take: PER_PAGE, skip, where },
        {
          ...employeeListItemSelector,
          profile: {
            ...employeeListItemSelector.profile,
            isHidden: [
              { profileId },
              true
            ],
            conversations: [
              { profileId },
              {
                id: true
              }
            ]
          }
        }
      ],
      countEmployees: [
        { where : { ...where, deletedAt: null }},
        true
      ],
    },
    {
      cacheTime: 0,
    },
  )

  const initialValues: FilterFields = {
    search: searchParams.get('search') as string || '',
    status: searchParams.get('filter') as ApiTypes.ProfileStatus | null
  }


  const headCellsCandidate = useMemo<HeadCandidateCell[]>(() => {
    const [name, direction] = (searchParams.get('orderBy') || '').split(':')

    return [
      {
        id: 'name',
        label: 'Full Name',
        isSortable: true,
        active: true,
        direction: name === 'name' ? (direction as ApiTypes.SortOrder) : undefined
      },
      {
        id: 'job',
        label: 'Job',
        isSortable: false,
        active: false,
        // direction: name === 'job' ? (direction as ApiTypes.SortOrder) : undefined
      },
      {
        id: 'status',
        label: 'Status',
        isSortable: true,
        active: false,
        direction: name === 'status' ? (direction as ApiTypes.SortOrder) : undefined
      },
      {
        id: 'location',
        label: 'Location',
        // isSortable: true,
        // active: false,
        // direction: name === 'location' ? (direction as ApiTypes.SortOrder) : undefined
      }
    ]
  }, [searchParams])

  const headCellsDropDownCandidate = useMemo<HeadCandidateCell[]>(() => {

    return [
      {
        id: 'firstname',
        directionId: 'firstnameDesc',
        label: 'Full Name',
        isSortable: true,
        active: true,
        direction: ApiTypes.SortOrder.desc
      },
      {
        id: 'firstname',
        directionId: 'firstnameAsc',
        label: 'Full Name',
        isSortable: true,
        active: true,
        direction: ApiTypes.SortOrder.asc
      },
      // {
      //   id: 'publishedAt',
      //   directionId: 'publishedAtDesc',
      //   label: 'Start date',
      //   isSortable: true,
      //   active: false,
      //   direction: ApiTypes.SortOrder.desc
      // },
      // {
      //   id: 'publishedAt',
      //   directionId: 'publishedAtAsc',
      //   label: 'Start date',
      //   isSortable: true,
      //   active: false,
      //   direction: ApiTypes.SortOrder.asc
      // },
    ]
  }, [searchParams])


  const onSortChange = (cell: string, direction: ApiTypes.SortOrder) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      orderBy: `${cell}:${direction}`
    })
  }

  const showDeleteModal = (id: string) => {
    setAlert({
      isOpen: true,
      id
    })
  }

  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={mainWrapper}>
      {/* <Typography variant='h5' component='h5' mb={2} sx={{ fontWeight: 400, }}>
          Candidates List
      </Typography> */}
      <Grid container spacing={1} >

        {/* <Grid item sm={4} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}> 
          <TextField id='outlined-search' label='Search' type='search' onChange={(e) => setSearch(e.target.value)}/>
        </Grid> */}
        {/* <Grid item sm={8} xs={12} sx={{ display: 'flex', gap: '24px', justifyContent: 'flex-end'}}> 
          <Box sx={fieldBoxWrap}>
              <Box
                component='form'
                sx={fieldBox}>
                  <FormControl sx={{ m: 1, width: 200 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      sx={{ m: 1, width: 200 }}
                      value={status}
                      onChange={(e) => applyFilter({ status : e.target.value as ApiTypes.ProfileStatus })}
                      input={<OutlinedInput label="Status" />}
                    >
                        {$enum(ApiTypes.ProfileStatus)
                        .getKeys()
                        .map((item) => 
                          <MenuItem key={item} value={item}>
                            {item }
                          </MenuItem>
                        )}
                    </Select>
                  </FormControl>
              </Box>
          </Box>
        </Grid> */}
       
        <Grid item xs={12}>
          <Box sx={{ width: '100%', pt:'32px' }}>
          {!candidateDataRefetching && (
              <MyCandidateTable
                headCells={headCellsCandidate}
                headCellsDropDown={headCellsDropDownCandidate}
                pagination={{
                  rowsPerPage: perPage,
                  page,
                  onPageChange: updatePage,
                  onRowsPerPageChange: updatePerPage,
                  count: candidatesData?.countEmployees || 0
                }}
                onSortChange={onSortChange}
              >
                {(candidatesData?.getCandidatesAppliedToMyCompany || []).map((employee, index) => {
                  return (
                    <CandidateTableRow
                      profileId={profileId}
                      key={index}
                      employee={employee}
                      onDelete={showDeleteModal}
                    />
                  )
                })}
              </MyCandidateTable>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}


interface CandidateTableRowProps {
  employee: EmployeeFields
  profileId: string
  onDelete: (id: string) => void
}

const CandidateTableRow = ({ profileId, employee, onDelete }: CandidateTableRowProps) => {

  const publishedAt = useDateTime(employee.createdAt as Date)
  const navigate = useNavigate()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <TableRow sx={table} hover tabIndex={-1}>
      {/* {JSON.stringify(employee)} */}
      <TableCell component="th" scope="row" sx={tableCellWrapper}>
        <Box sx={tableTitle}>
          <Box sx={{display: 'flex', gap: '10px', alignItems: 'center', }}>
            <Link
                sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                component={RouterLink}
                to={generatePath(PAGES.EMPLOYEE, {
                  profileId: profileId,
                  employeeId: employee.id
                })}
              >
            {!employee.profile.isHidden ?
              <Avatar src={employee.avatar?.url} alt={'avatar'} sx={avatarWrapper} />
              :
              <Avatar alt={'avatar'} sx={avatarWrapper} />
            }
            </Link>
            <Typography noWrap={true} title={employee.firstname}>
              <Link
                sx={(theme: Theme) => ({ color: theme.palette.text.primary })}
                component={RouterLink}
                to={generatePath(PAGES.EMPLOYEE, {
                  profileId: profileId,
                  employeeId: employee.id
                })}
              >
                { (employee.profile.isHidden && employee.profile.displayName) ?  employee.profile.displayName : '' }
                { !employee.profile.isHidden && `${employee.firstname} ${employee.lastname}` }
              </Link>
            </Typography>
          </Box>
          {/* { matchSm &&
            <Box>
              <MenuNavigation
                options={[
                  {
                    name: 'Edit',
                    onChange: () => {
                      navigate(
                        generatePath(PAGES.JOB_EDIT, {
                          profileId: profileId,
                          jobId: employee.id
                        })
                      )
                    }
                  },
                  { name: 'Delete', onChange: () => onDelete(employee.id) }
                ]}
              />
            </Box>
          } */}
        </Box>
      </TableCell>
   
      <TableCell sx={tableCellWrapper}>
        <Box sx={tableBoxWrapper}>
          <span>Job:</span>
          <Typography variant={'body2'}>{_.map(employee?.jobTypes, 'name') }</Typography>
        </Box>
      </TableCell>
      
      <TableCell sx={tableCellWrapper}>
        <Box sx={tableBoxWrapper}>
          <span>Status:</span>
          
        </Box> 
        { statusLabel[employee?.profile?.status] }  
      </TableCell>
      <TableCell sx={[tableCellWrapper]}>
        <Box sx={tableBoxWrapper}>
          <span>Location:</span>
          <Typography variant={'body2'}>{employee?.currentCity?.country?.name}</Typography>
          {/* { !matchSm &&
            <Box>
                <MenuNavigation
                  options={[
                    {
                      name: 'Edit',
                      onChange: () => {
                        navigate(
                          generatePath(PAGES.JOB_EDIT, {
                            profileId: profileId,
                            jobId: employee.id
                          })
                        )
                      }
                    },
                    { name: 'Delete', onChange: () => onDelete(employee.id) }
                  ]}
              />
            </Box>
          } */}
        </Box>
        
      </TableCell>

    </TableRow>
  )
}

interface IAlert {
  isOpen: boolean
  id?: string
}

const initialAlert: IAlert = {
  isOpen: false,
  id: undefined
}


const avatarWrapper = (theme: Theme) => ({
  width: '40px',
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
})

const mainWrapper = (theme: Theme) => ({
  width: '100%',
  [theme.breakpoints.down('xs')]: {
    '& .MuiGrid-item': {
      width: '100%',
    }
  }
});

const fieldBoxWrap = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    width:'100%',
    flexWrap: 'wrap',
  }
});

const fieldBox = (theme: Theme) => ({
  width: '25%', 
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  '& .MuiFormControl-root': {
    height: '48px',
    width: '100%',
    margin: '0',
  },
  '& .MuiInputBase-root': {
    height: '48px',
    margin: '0',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    paddingRight: '48px',
  },
  '& .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon': { 
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&.Mui-focused': {
      '& .MuiFormLabel-root': {
        zIndex: 99,
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        backgroundColor: '#fff',
        minWidth: '240px',
        height: '100%',
        padding: '10px 16px 16px 10px',
        borderRadius: '6px',
        textAlign: 'left',
        zIndex:'9',
        boxShadow:'10px 10px 40px rgba(0, 0, 0, 0.05)',
        '& >div': {
          height: '24px !important',
          fontSize: '12px',
          zIndex:'999',
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          }
        },
      }
    },
    '& .MuiIconButton-root': {
      zIndex:'99',
      backgroundColor: theme.palette.primary.contrastText,
    }
  },
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  }
});

const tableBoxWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& span':{
    display: 'none'
  },
  [theme.breakpoints.down('sm')]:{
    justifyContent: 'start',
    '& span':{
      display: 'initial',
      color: '#a1a5a6',
      marginRight: '5px'
    }
  }
})

const table = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '10px',
    paddingBottom: '10px',
    // px: theme.spacing(2),
  }
})

const tableTitle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& a':{
      color: '#0F64E2',
      fontSize: '18px',
      fontWeight: 700
    }
  }
})

const tableCellWrapper = (theme: Theme) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontStyle: 'normal',
  py: '0px',
  fontWeight: 400,
  maxWidth: '312px',
  height: '52px',
  fontSize: '14px',
  lineHeight: '20px',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    borderBottom: 'unset',
    height: 'unset',
    paddingX: 'unset',
    marginBottom: '5px'
  }
})

