import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional } from 'type-fest'
import { authMutation } from '../services/api'

const workExperienceSelector = ApiSelector('WorkExperience')({
  id: true,
  companyName: true,
  current: true,
  startAt: true,
  endAt: true,
  description: true,
  title: true,
})

type WorkExperienceFields = ApiInputType<ApiGraphQLTypes['WorkExperience'], typeof workExperienceSelector>
type CreateOrUpdateWorkExperienceFields = SetOptional<WorkExperienceFields, 'id' | 'endAt'>

export const useCreateOrUpdateWorkExperience = (selector: PartialDeep<typeof workExperienceSelector>) => {
  return useMutation(
    async ({ employeeId, values }: { employeeId: string; values: CreateOrUpdateWorkExperienceFields }) => {
      const { id, ...input } = values

      if (id) {
        const data = await authMutation({
          updateWorkExperience: [
            {
              id,
              input,
            },
            selector,
          ],
        })

        return data.updateWorkExperience
      }

      const data = await authMutation({
        createWorkExperience: [
          {
            input: {
              ...input,
              employee: {
                connect: {
                  id: employeeId,
                },
              },
            },
          },
          selector,
        ],
      })

      return data.createWorkExperience
    },
  )
}

export const useDeleteWorkExperience = () => {
  return useMutation(async (id: string): Promise<boolean> => {
    await authMutation({
      deleteWorkExperience: [{ id }, { id: true }],
    })

    return true
  })
}
