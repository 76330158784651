import { useMemo } from 'react'
import { Link, 
  Theme, 
  Drawer, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
  Divider,
 } from '@mui/material'

import { NavLink, generatePath, Link as RouterLink } from 'react-router-dom'
import { PAGES } from '../router/AppRoutes'
import { chatEnabled } from '../config/features'
import { Company } from '../components/icons/company'
import { Job } from '../components/icons/job'
import { Candidates } from '../components/icons/candidates'
import { Message } from '../components/icons/message'
import { Dashboard } from '../components/icons/dashboard'
import { mainCompanyState, profileState } from '../state/atoms/profileState'
import { useRecoilValue } from 'recoil'
import { ApiTypes } from 'recruticka-frontend-libs'


interface IProps {
  open: boolean,
}

export const Sidebar = ({open} : IProps) => {

  const mainCompany = useRecoilValue(mainCompanyState)
  const profile = useRecoilValue(profileState)

  const canShowMenuItems = useMemo(() => {
    return profile?.status === ApiTypes.ProfileStatus.ACTIVATED
  }, [profile])

  return (
      <>
        <Drawer 
          sx={!open ? drawerBox : drawerClose} 
          variant="permanent"
        >
          <Divider sx={{height:'88px', border: 'none'}}/>
          <List sx={{padding: '0',}}>                    
            {canShowMenuItems && (
              <>
                {profile?.role === ApiTypes.Role.EMPLOYER && (
                  <>
                    <ListItem sx={listBox} className="">
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.DASHBOARD, {
                            profileId: profile?.id,
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Dashboard fill='rgb(0 0 0 / 54%)' width='20px'/>
                          </ListItemIcon>
                          <ListItemText primary={'Dashboard'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem>

                    <ListItem sx={listBox}>
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.COMPANIES, {
                            profileId: profile?.id,
                            companyId: mainCompany?.id
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Company fill='rgb(0 0 0 / 54%)'/>
                          </ListItemIcon>
                          <ListItemText primary={'Company'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={listBox}>
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.JOBS, {
                            profileId: profile?.id
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Job fill='rgb(0 0 0 / 54%)' />
                          </ListItemIcon>
                          <ListItemText primary={'Jobs'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={listBox}>
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.CANDIDATES, {
                            profileId: profile?.id,
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Candidates width='22px' fill='rgb(0 0 0 / 54%)' />
                          </ListItemIcon>
                          <ListItemText primary={'Candidates'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem> 
                    {chatEnabled && 
                    <>
                      <ListItem sx={listBox}>
                        <ListItemButton sx={menuLink}>
                          <NavLink
                            to={generatePath(PAGES.MESSAGES, {
                              profileId: profile?.id
                            })}
                            className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                          >
                            <ListItemIcon>
                              <Message fill='rgb(0 0 0 / 54%)' />
                            </ListItemIcon>
                            <ListItemText primary={'Messages'} sx={!open ? showLabel : hideLabel} />
                          </NavLink>
                        </ListItemButton>
                      </ListItem>
                    </>
                    }
                  </>
                )}
                {profile?.role === ApiTypes.Role.EMPLOYEE && (
                  <>
                    <ListItem sx={listBox} className="">
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.DASHBOARD, {
                            profileId: profile?.id,
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Dashboard fill='rgb(0 0 0 / 54%)' width='20px'/>
                          </ListItemIcon>
                          <ListItemText primary={'Dashboard'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={listBox} className="">
                      <ListItemButton sx={menuLink}>
                        <Link href={`${process.env.REACT_APP_SITE_URL}/companies`} target="_blank" rel="noopener">
                          <ListItemIcon>
                            <Company fill='rgb(0 0 0 / 54%)'/>
                          </ListItemIcon>
                          <ListItemText primary={'Companies'} sx={!open ? showLabel : hideLabel} />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                    <ListItem sx={listBox} className="">
                      <ListItemButton sx={menuLink}>
                        <Link href={`${process.env.REACT_APP_SITE_URL}/jobs`} target="_blank" rel="noopener">
                          <ListItemIcon>
                            <Job fill='rgb(0 0 0 / 54%)' />
                          </ListItemIcon>
                          <ListItemText primary={'Jobs'} sx={!open ? showLabel : hideLabel} />
                        </Link>
                      </ListItemButton>
                    </ListItem>
                    {chatEnabled && <ListItem sx={listBox}>
                      <ListItemButton sx={menuLink}>
                        <NavLink
                          to={generatePath(PAGES.MESSAGES, {
                            profileId: profile?.id
                          })}
                          className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                        >
                          <ListItemIcon>
                            <Message fill='rgb(0 0 0 / 54%)' />
                          </ListItemIcon>
                          <ListItemText primary={'Messages'} sx={!open ? showLabel : hideLabel} />
                        </NavLink>
                      </ListItemButton>
                    </ListItem>}
                  </>
                )}
              </>
            )}
            
            {canShowMenuItems && (
              <>
                {/* <ListItem sx={listBox}>
                  <ListItemButton sx={menuLink}> 
                    <NavLink 
                      to={profile.role === ApiTypes.Role.EMPLOYEE ?
                        generatePath(PAGES.PROFILE, {
                          profileId: profile?.id
                        })
                        :
                        generatePath(PAGES.COMPANIES, {
                          profileId: profile?.id,
                          companyId: mainCompany?.id
                        })
                      }
                      className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                    >
                      <ListItemIcon>
                        <PersonRounded sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </ListItemIcon>
                      <ListItemText primary={'Profile'} sx={!open ? showLabel : hideLabel} />
                    </NavLink>
                  </ListItemButton>
                </ListItem> */}
                {/* 
                <ListItem sx={listBox}>
                  <ListItemButton sx={menuLink}>
                    <NavLink to={generatePath(PAGES.APPLICATIONS, {
                      profileId: profile?.id,
                    })}
                    className={({ isActive }) => (isActive ? 'menu-link-active' : 'menu-link')}
                    >
                      <ListItemIcon>
                        <FeedRounded sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                      </ListItemIcon>
                      <ListItemText primary={'Applications'} sx={!open ? showLabel : hideLabel} />
                    </NavLink>
                  <ListItemButton>
                </ListItem>
                */}
              </>
            )}
          
          </List>
        </Drawer>
      </>
  )
}



const drawerBox = (theme: Theme) => ({
  width: '240px',
  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  whiteSpace: 'nowrap',
  flexShrink: '0',
  overflowX: 'hidden',
  '& .MuiDrawer-paper': {
    width: '240px',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  [theme.breakpoints.down(1250)]: {
    '&': {
      position: 'absolute',
      left: '0',
      height: '100%',
      top: '0',
    }
  }
})

const drawerClose = (theme: Theme) => ({
  width: '88px',
  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  '& .MuiDrawer-paper': {
    overflow: 'hidden',
    width: '88px',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  [theme.breakpoints.down(1250)]: {
    position: 'absolute',
    left: '0',
    height: '100%',
    top: '0',
    width: '0px',
    '& .MuiDrawer-paper': {
      width: '0px',
    }
  }
})

const showLabel = (theme: Theme) => ({
  transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
})

const hideLabel = (theme: Theme) => ({
  opacity: '0',
  transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
})

const listBox = (theme: Theme) => ({
  padding: '8px',
  height: '56px',
  '& .MuiListItemButton-root': {
    padding: '0px',
    height: '100%',
  }
})

const MenulistBox = (theme: Theme) => ({
 padding: '8px 16px',
 display: 'flex',
 justifyContent: 'space-between',
 width: '100%',
 alignItems: 'center',
 minHeight: '56px',
 '& .MuiTypography-h6': {
  transition: 'all 0.5s ease-in-out',
 },
 '& .MuiIconButton-root': {
    margin: '0 0 0 auto',
    transition: 'all 0.5s ease-in-out',
 }
})

const hideMenu = (theme: Theme) => ({
  minHeight:'56px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography-h6': {
    opacity: '0',
    fontSize: '0',
  },
  '& .MuiIconButton-root': {
    margin: '0 auto',
  }
})


const menuLink = (theme: Theme) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: '0 16px',
      height: '48px',
      position: 'relative',
      textDecoration: 'none',
      '&.menu-link-active': {
        backgroundColor: '#5c67ea !important',
        '& .MuiListItemIcon-root svg path': {
          fill: '#fff !important',
        },
        '& .MuiTypography-root': {
          color: '#fff !important',
        }
      },

      '& .MuiListItemIcon-root': {
        minWidth: '40px',
        justifyContent:'center',
      },
      '& .MuiListItemText-root': {
        margin: '0',
        minWidth: '0',
        '& .MuiTypography-root': {
          color: 'rgb(0 0 0 / 86%)',
          fontWeight: '400',
        }
      }
    }
})


