import { Formik, Form } from 'formik';
import Yup from '../../../validation'
import { Box, Theme, Typography, Divider } from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import type { SetOptional } from 'type-fest'
import { SetNullable } from '../../../interfaces/nullable'
import { SalaryHistory } from '../../form-fields/SalaryHistory';
import { useDefaultCurrency } from '../../../hooks/useCurrency';
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

const salaryHistorySelector = ApiSelector('SalaryHistory')({
  id: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
  history: {
    id: true,
    from: true,
    to: true,
    year: true,
  }
})

type SalaryHistoryFields = ApiInputType<ApiGraphQLTypes['SalaryHistory'], typeof salaryHistorySelector>

interface Props {
  onSave: (values: SetOptional<SalaryHistoryFields, 'id'>) => void,
  salaryHistory?: SalaryHistoryFields
}

export const BillingComponent = ({ onSave, salaryHistory }: Props) => {
  
  const defaultCurrency = useDefaultCurrency();
  const initialValues: SetNullable<SetOptional<SalaryHistoryFields, 'id'>, 'currency'> = {
    id: salaryHistory?.id,
    history: salaryHistory?.history || [],
    schedule: salaryHistory?.schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: salaryHistory?.currency || defaultCurrency || null
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const currency = values.currency

        if (currency) {
          return onSave({
            ...values,
            history: values.history?.map((record) => ({ ...record, to: record.from, year: parseInt(String(record.year)) })),
            currency
          })
        }
      }}
    >
      {({ errors }) => {
        return (
          <Form>
            <Box
              sx={billWrapper}
            >
              <Box>
                  <Typography sx={(theme) => ({
                            marginBottom: theme.spacing(3)
                          })} variant={'h2'}>
                    Billings
                  </Typography>
                  <Divider />
              </Box>
              
              <Box>
                  <Typography variant={'body2'} sx={(theme) => ({
                            marginBottom: theme.spacing(2)
                          })}>
                    Enter data for the last 2 - 10 years
                  </Typography>
                  
                <SalaryHistory />

              </Box>

              <FooterButton disabled={Object.keys(errors).length > 0}  />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  schedule: Yup.string().required(),
  currency: Yup.object().shape({
    id: Yup.string().required(),
  }),
  history: Yup.array(Yup.object({
    year: Yup.number().required(),
    from: Yup.number().required().positive(),
    // to: Yup.number().required().positive(),
  })).optional()
})

const billWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  mb: '72px',
  backgroundColor: '#fff',
  [theme.breakpoints.down('md')]: {
    gap: '14px',
    marginBottom: '14px'
  }
})
