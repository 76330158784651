import { Box, Theme, Typography } from '@mui/material'
import { FC } from 'react'
import { Button } from '../../ui-kits/button/button'
import CloseIcon from '@mui/icons-material/Close'
import { SystemStyleObject } from '@mui/system'
import { Message, ReplyMessageTypes } from '../../../hooks/useMessages'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../state/atoms/profileState'


interface Props {
  style?: SystemStyleObject
  onClick?: () => void
  message?: ReplyMessageTypes
  editMessage?: string
}

export const ReplyedMessage: FC<Props> = ({ style, editMessage, message, onClick }) => {

  const profile = useRecoilValue(profileState)
  return (
    <Box sx={style}>
      <Box
        sx={{
          borderLeft: '3px solid #0F64E2',
          paddingLeft: '19px'
        }}
        className={'reply-wrapper'}
      >
        <Typography
          variant={'subtitle2'}
          sx={{
            color: '#0F64E2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            lineHeight: '100%',
            mb: '4px'
          }}
        >
          {!editMessage ?
            !message?.author?.profile.employee ?
              message?.conversation?.company?.name
              :
              (!message?.author?.profile?.isHidden || profile?.id === message?.author?.profileId)  ?
                !message?.author?.profile?.deletedAt ?
                  message?.author?.profile?.employee?.firstname
                  :
                  'deleted account'
                :
                message?.author?.profile?.displayName
            :
            'Edit message'
          }
          {
            onClick &&
            <Button
              sx={button}
              size={'small'}
              onClick={onClick}
            >
              <CloseIcon fontSize={'small'} />
            </Button>
          }
        </Typography>
        <Typography variant={'body2'} noWrap={true} sx={replyContent}>{!editMessage ? message?.content : editMessage}</Typography>
      </Box>
    </Box>
  )
}

const replyContent = (theme: Theme) => ({
  width: '90%',
  [theme.breakpoints.down('md')]: {
    width: '85%'
  }
})

const button = {
  boxShadow: 'unset',
  backgroundColor: 'unset',
  width: '12px',
  height: '12px',
  padding: 0,
  minWidth: 0,
  '& svg': {
    '& path': {
      fill: 'rgba(20, 29, 36, 0.4)'
    }
  },
  '&:hover': {
    boxShadow: 'unset',
    backgroundColor: 'unset'
  }
}
