import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { authMutation } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'

export const textBlockAssetSelector = ApiSelector('TextBlockAsset')({
  id: true,
  isMain: true,
  asset: assetSelector,
})

export type TextBlockAsset = ApiInputType<ApiGraphQLTypes['TextBlockAsset'], typeof textBlockAssetSelector>
type CreateOrUpdateTextBlockAsset = SetOptional<TextBlockAsset, 'id'>

type MutationProps = {
  textBlockId: string
  values: CreateOrUpdateTextBlockAsset
}

export const useCreateOrUpdateTextBlockAsset = (selector: Partial<typeof textBlockAssetSelector>) => {
  return useMutation(async ({ textBlockId, values }: MutationProps) => {
    const { id, asset, ...input } = values

    if (id) {
      const { updateTextBlockAsset } = await authMutation({
        updateTextBlockAsset: [
          {
            id,
            input: input,
          },
          selector,
        ],
      })

      return updateTextBlockAsset
    }

    const { createTextBlockAsset } = await authMutation({
      createTextBlockAsset: [
        {
          input: {
            ...input,
            asset: {
              connect: {
                id: asset.id,
              },
            },
            textBlock: {
              connect: {
                id: textBlockId,
              },
            },
          },
        },
        selector,
      ],
    })

    return createTextBlockAsset
  })
}

export const useDeleteTextBlockAsset = () => {
  return useMutation(async (id: TextBlockAsset['id']): Promise<boolean> => {
    await authMutation({
      deleteTextBlockAsset: [{ id }, true],
    })

    return true
  })
}
