import { Box, Theme } from '@mui/material'
import { MediaItem } from './ui-kits/media-item/media-item'

interface Props {
  content: any
}

export const BgInDetails = ({ content }: Props) => {
  return (
    <Box sx={wrapper}>
      <MediaItem content={content} loop playing muted />
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  width: '100%',
  height: '100%',
  '& > img': {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  '& > *': {
    width: '100% !important',
    '& > video': {
      objectFit: 'cover',
    },
  },
})
