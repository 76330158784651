import { Box, Card, Divider, Theme, Typography, Link, Button, Tooltip } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import CreateIcon from '@mui/icons-material/Create'
import { Portfolio } from '../icons/portfolio'
import { useMemo, useState } from 'react'
import { ApiSelector, ApiGraphQLTypes, ApiInputType, ApiTypes } from 'recruticka-frontend-libs'
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter'
import { useDateTime } from '../../hooks/useDate'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { PAGES } from '../../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../state/atoms/profileState'
import { useTranslation } from 'react-i18next'
import { radiusOfComponents } from '../../styles/theme'
import { citySelector } from '../form-fields/CityAutocomplete'

const jobSelector = ApiSelector('Job')({
  id: true,
  title: true,
  description: true,
  salaryType: true,
  createdAt: true,
  updatedAt: true,
  salary: {
    schedule: true,
    from: true,
    to: true,
    currency: {
      id: true,
      symbol: true,
    },
  },
  location: {
    type: true,
    wfhDays: true,
    offices: {
      city: citySelector,
    },
  },
  sectors: {
    name: true,
  },
})

type JobFieds = ApiInputType<ApiGraphQLTypes['Job'], typeof jobSelector>

interface Props {
  job: JobFieds
}

export const JobOverviewHeader = ({ job }: Props) => {
  const { t } = useTranslation()
  const profile = useRecoilValue(profileState)
  const [isMore, setIsMore] = useState<boolean>(false)
  const from = useCurrencyFormatter((job.salary?.from as number) || 0, job.salary?.currency.id)
  const to = useCurrencyFormatter((job.salary?.to as number) || 0, job.salary?.currency.id)
  const createdAt = useDateTime(job.createdAt as Date)
  const updatedAt = useDateTime(job.updatedAt as Date)

  const cities = useMemo<string>(() => {
    const citiesName = (job.location?.offices || []).map((office) => {
      return office.city?.name as string
    })

    return citiesName.join(', ')
  }, [job.location?.offices])

  const sectors = useMemo<string>(() => {
    const names = (job.sectors || []).map((sector) => sector.name)

    return names.join(', ')
  }, [job.sectors])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Card sx={[wrapper, headWrapper]}>
        <Box sx={contentWrapper}>
          <Tooltip title={job.title ?? ''} arrow placement={'top'}>
            <Typography
              variant={'h2'}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {job.title}
            </Typography>
          </Tooltip>

          <Button
            component={RouterLink}
            to={generatePath(PAGES.JOB_EDIT, {
              profileId: profile.id,
              jobId: job.id,
            })}
            sx={editButton}
            variant={'outlined'}
            size={'small'}
            endIcon={<CreateIcon />}
          >
            Edit
          </Button>
        </Box>
        <Divider sx={dividerStyle} />
      </Card>
      <Card sx={[wrapper, bottomWrapper]}>
        <Box sx={aboutBlock}>
          {job.salary && (
            <Box sx={itemBlock}>
              <Box sx={svgBlock}>
                <AttachMoneyIcon fontSize={'small'} />
              </Box>
              <Box sx={labelWrapper}>
                <Box sx={typographyStyle}>
                  {job.salaryType === ApiTypes.JobSalaryType.CONTRACT ? (
                    <>
                      {from} - {to}
                    </>
                  ) : null}

                  {job.salaryType === ApiTypes.JobSalaryType.GET_IN_TOUCH ? (
                    <>{t(`enum.JobSalaryType.${job.salaryType}`)}</>
                  ) : null}
                </Box>
                <Typography variant={'body2'}>
                  {job.salary.schedule === ApiTypes.SalaryPeriod.MONTH && 'Monthly'}
                  {job.salary.schedule === ApiTypes.SalaryPeriod.ANNUAL && 'Yearly'} Salary
                </Typography>
              </Box>
              <Divider sx={labelDivider} />
            </Box>
          )}
          <Box sx={itemBlock}>
            <Box sx={svgBlock}>
              <Portfolio />
            </Box>
            <Box sx={labelWrapper}>
              <Tooltip title={sectors ?? ''} arrow placement={'top'}>
                <Box sx={typographyStyle}>{sectors}</Box>
              </Tooltip>
              <Typography variant={'body2'}>Sector</Typography>
            </Box>
            <Divider sx={labelDivider} />
          </Box>
          {(job.location?.offices || []).length > 0 && (
            <Box sx={itemBlock}>
              <Box sx={svgBlock}>
                <LocationSearchingIcon fontSize={'small'} />
              </Box>
              <Box sx={labelWrapper}>
                <Tooltip title={cities ?? ''} arrow placement={'top'}>
                  <Box sx={typographyStyle}>{cities}</Box>
                </Tooltip>
                <Typography variant={'body2'}>Location</Typography>
              </Box>
              <Divider sx={labelDivider} />
            </Box>
          )}
          <Box sx={itemBlock}>
            <Box sx={svgBlock}>
              <CreateIcon fontSize={'small'} />
            </Box>
            <Box sx={labelWrapper}>
              <Box sx={typographyStyle}>{createdAt}</Box>
              <Typography variant={'body2'}>Created</Typography>
            </Box>
            <Divider sx={labelDivider} />
          </Box>
          <Box sx={itemBlock}>
            <Box sx={svgBlock}>
              <PublishedWithChangesIcon fontSize={'small'} />
            </Box>
            <Box sx={labelWrapper}>
              <Box sx={typographyStyle}>{updatedAt}</Box>
              <Typography variant={'body2'}>Updated</Typography>
            </Box>
            <Divider sx={labelDivider} />
          </Box>
        </Box>
        <Card sx={descriptionWrapper}>
          <Typography variant={'h2'}>Job Description</Typography>
          <Box sx={description}>
            <Typography
              component='div'
              dangerouslySetInnerHTML={{ __html: job.description || '' }}
              variant={'body1'}
              sx={[showSomeLines, isMore && { WebkitLineClamp: 'inherit' }]}
            />
            {!isMore && (
              <Box sx={textHider} className={'text-hider'}>
                <Box />
              </Box>
            )}
            <Typography
              variant={'subtitle2'}
              onClick={() => setIsMore(!isMore)}
              sx={{ lineHeight: '24px', cursor: 'pointer', marginTop: '16px' }}
            >
              {isMore ? '- Hide' : '+ Show more'}
            </Typography>
          </Box>
        </Card>
      </Card>
    </Box>
  )
}

const editButton = (theme: Theme) =>({
  [theme.breakpoints.down('sm')]:{
    fontSize: 0,
    marginLeft: '10px',
    '& span':{
      margin: 0
    }
  }
})

const headWrapper = (theme: Theme) => ({
  pb: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'initial',
  },
})

const bottomWrapper = (theme: Theme) => ({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  [theme.breakpoints.down('md')]: {
    borderTopLeftRadius: radiusOfComponents,
    borderTopRightRadius: radiusOfComponents,
  },
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  fontWeight: '600',
  width: '100%',
  boxShadow: 'none',
})

const labelDivider = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    color: 'black',
    width: '100%',
  },
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    gap: 0,
  },
})

const typographyStyle = (theme: Theme) => ({
  fontSize: '14px',
  lineHeight: '18px',
  whiteSpace: 'nowrap',
  maxWidth: '234px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '160px',
    fontWeight: '600',
    fontSize: '11px',
    lineHeight: '15px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '300px',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
  },
  [theme.breakpoints.down('sm')]:{
    marginLeft: '10px'
  }
})

const labelWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row-reverse',
    alignItems: 'self-end',
    justifyContent: 'space-between',
  },
})

const aboutBlock = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '10px',
  },
})
const itemBlock = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column',
    gap: '10px',
  },
})
const svgBlock = (theme: Theme) => ({
  height: '40px',
  width: '40px',
  border: '1px solid rgba(20, 29, 36, 0.12)',
  borderRadius: '6px',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
})

const dividerStyle = (theme: Theme) => ({
  mx: `-${theme.spacing(4)}`,
  mt: `-${theme.spacing(1)}`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
})

const descriptionWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  border: '1px solid rgba(20, 29, 36, 0.12)',
  boxShadow: 'none',
  borderRadius: '6px',
})

const showSomeLines = (theme: Theme) => ({
  overflow: 'hidden',
  textOverflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
})

const textHider = (theme: Theme) => ({
  position: 'relative',
  width: '100%',
  height: '32px',
  marginTop: `-${theme.spacing(3)}`,
  '& > *': {
    width: '100%',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.57) -27.78%, rgba(255, 255, 255, 0.99) 66.67%)',
    position: 'absolute',
    height: '32px'
  }
})

const description = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    '& .text-hider':{
        height: '122px',
        marginTop: '-111px',
        '& > *': {
          height: '122px'
        }
    },
    '& .MuiTypography-body1':{
      height: '220px'
    }
  }
})
