import React from 'react'
import { Box, CircularProgress, Theme, Typography } from '@mui/material'
import { buildAssetUrl } from '../../../utilities/buildAssetUrl'
import { Asset } from '../../Uploader/Uploader'
import { ProgressFile } from './upload-slider'
import ReactPlayer from 'react-player'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

interface Props {
  asset: Asset
}

export const UploadedImage = ({ asset }: Props) => {

  return (
    <Box sx={fileWrapper}>
      <>
        <img src={buildAssetUrl(asset.url)} alt="" />
      </>
    </Box>
  )
}

export const UploadedVideo = ({ asset }: Props) => {

  return (
    <Box sx={fileWrapper}>
      <Box sx={videoWrapper}>
        <ReactPlayer url={buildAssetUrl(asset.url)} />
      </Box>
    </Box>
  )
}

export const UploadedFile = ({ asset }: Props) => {

  return (
    <Box sx={pdfFile}>
      <Box sx={{ display: 'flex' }}>
        <InsertDriveFileIcon
          sx={{
            color: 'rgba(0, 0, 0, 0.54)',
            padding: '8px 10px',
            backgroundColor: '#fff',
            borderRadius: '6px',
            marginRight: '11px'
          }} />
        <Box>
          <Typography
            sx={{
              width: '169px',
              fontSize: '14px',
              lineHeight: '17px'
            }}
            noWrap={true}
            variant={'subtitle2'}
          >
            {asset.name}
          </Typography>
          {asset.size &&
            <Typography
              sx={{
                fontSize: '12px',
                lineHeigth: '12px'
              }}
              variant={'body2'}
            >
              {(asset.size as number / 1000).toFixed(2)} Kb
            </Typography>
          }
        </Box>
      </Box>
    </Box>
  )
}

const videoWrapper = (theme: Theme) => ({
  '& div':{
    width: '60px!important',
    height: '60px!important'
  }
})

const pdfFile = (theme: Theme) => ({
  backgroundColor: '#F5F5F5',
  display: 'flex',
  padding: '10px 12px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '6px',
  position: 'relative'
})

const fileWrapper = (theme: Theme) => ({
  margin: 0,
  padding: 0,
  height: '60px',
  width: '60px',
  position: 'relative',
  '& img': {
    height: '60px',
    width: '60px',
    objectFit: 'cover',
    borderRadius: '6px'
  },
  '& video': {
    width: '60px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '6px'
  }
})
