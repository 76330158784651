import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { assetSelector } from '../components/Uploader/Uploader'
import { authMutation, useAuthQueryRequest } from '../services/api'
import { MainCompany, mainCompanyState, profileState } from '../state/atoms/profileState'
import { citySelector } from '../components/form-fields/CityAutocomplete'

export const companySelector = ApiSelector('Company')({
  id: true,
  name: true,
  isMain: true,
  status: true,
  logo: assetSelector,
  strength: true,
  hiringInCities: citySelector,
})

export const profileSelector = ApiSelector('Profile')({
  id: true,
  role: true,
  status: true,
  // strength: true,
  email: true,
  termsAndConditionsAccepted: true,
  companies: companySelector,
  hideFromEveryone: true,
  employee: {
    id: true,
    firstname: true,
    lastname: true,
    avatar: assetSelector,
    strength: true,
    profileId: true,
  },
  userProfile: {
    id: true,
    isOwner: true,
  }
})

export const useProfile = () => {
  const { profileId } = useParams()
  const [, setProfile] = useRecoilState(profileState)
  const [, setMainCompany] = useRecoilState(mainCompanyState)

  return useAuthQueryRequest(
    ['profile', profileId],
    {
      getProfile: [{ id: profileId as string }, profileSelector],
    },
    {
      enabled: Boolean(profileId),
      onSuccess(data) {
        if (data.getProfile) {
          setProfile(data.getProfile)
          if (data.getProfile.role === ApiTypes.Role.EMPLOYER && !!data.getProfile?.companies?.length) {
            setMainCompany(data.getProfile?.companies?.find(company => company.isMain) as MainCompany)
          }
        }

        return data
      },
    },
  )
}

export const useCompleteProfile = () => {
  const [profile, setProfile] = useRecoilState(profileState)
  const queryClient = useQueryClient()

  return useMutation(async (profileId: string) => {
    const data = await authMutation({
      completeProfile: [{ id: profileId }, { status: true }],
    })

    if (profile) {
      setProfile({
        ...profile,
        status: data.completeProfile.status,
      })

      queryClient.setQueryData('profile', {
        getProfile: {
          ...profile,
          status: data.completeProfile.status,
        },
      })
    }

    return data.completeProfile
  })
}

export const useAcceptTermsProfile = () => {
  const [profile, setProfile] = useRecoilState(profileState)
  const queryClient = useQueryClient()

  return useMutation(async (profileId: string) => {
    const data = await authMutation({
      updateProfile: [
        {
          id: profileId,
          input: {
            termsAndConditionsAccepted: true,
          },
        },
        { termsAndConditionsAccepted: true },
      ],
    })

    if (profile) {
      setProfile({
        ...profile,
        termsAndConditionsAccepted: data.updateProfile.termsAndConditionsAccepted,
      })

      queryClient.setQueryData('profile', {
        getProfile: {
          ...profile,
          status: data.updateProfile.termsAndConditionsAccepted,
        },
      })
    }

    return data.updateProfile
  })
}

const updateProfileSelector = ApiSelector('Profile')({
  id: true,
  linkedin: true,
})

export type ProfileUpdateFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof updateProfileSelector>

export const useUpdateProfile = (selector: Partial<typeof updateProfileSelector>) => {
  return useMutation(async ({ id, ...input }: ProfileUpdateFields) => {
    const { updateProfile } = await authMutation({
      updateProfile: [
        {
          id,
          input,
        },
        selector,
      ],
    })

    return updateProfile
  })
}


export const useCreateMakeVisibleRequest = () => {
  const { id: currentProfileId } = useRecoilValue(profileState);

  return useMutation(async (employeeProfileId: string) => {
    const response = await authMutation({
      createRequest: [
        {
          input: {
            from: {
              connect: {
                id: currentProfileId
              }
            },
            to: {
              connect: {
                id: employeeProfileId
              }
            },
            type: ApiTypes.RequestType.MAKE_PROFILE_VISIBLE
          }
        },
        {
          id: true
        }
      ]
    });

    return response.createRequest;
  })
}

export const useDeleteProfile = () => {
  return useMutation(async () => {
    const data = await authMutation({
      deleteUser: true,
    })

    return data.deleteUser
  })
}