import { Link, Theme, styled, Card, Box, Typography, CardMedia, Avatar, Grid } from '@mui/material'
import { Button } from '../components/ui-kits/button/button'
import { BoxContent } from '../components/ui-kits/box-content/box-content'

// Icon
import { LocationIcon } from '../components/icons/location'
import { Job } from '../components/icons/job'
import { Account } from '../components/icons/account'
import { LiveJob } from '../components/icons/live-job'



const structure = [
  { id: 0, postimage:'../../images/demo.webp', title: 'Avanti Recruitment', subtitle: 'Cognitive Group', date:'Nov 30, 2023', description:'Find out more about Avanti and what we stand for. If you feel we’re a good match – let’s talk!', link: '', profileImage: '', },

  { id: 1, postimage:'../../images/demo.webp', title: 'Avanti Recruitment', subtitle: 'Cognitive Group', date:'Nov 30, 2023', description:'Find out more about Avanti and what we stand for. If you feel we’re a good match – let’s talk!', link: '', profileImage: '', },

  { id: 2, postimage:'../../images/demo.webp', title: 'Avanti Recruitment', subtitle: 'Cognitive Group', date:'Nov 30, 2023', description:'Find out more about Avanti and what we stand for. If you feel we’re a good match – let’s talk!', link: '', profileImage: '', },
];


export const CompanyCard = () => {


  return (
    <Grid container spacing={2}>
      {structure.map((link, index) => (
        <Grid item sm={4} xs={12} key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Card sx={companyCardBox}>
            <Box sx={wrapper}>
              <Box sx={[cardCoverImg, spacingBetweenItems]}>
                <CardMedia
                  component='img'
                  image={link.postimage}
                  alt='photo'
                  sx={[{height: '100%', objectFit: 'cover'}]}
                />
              </Box>
              <Box display={'flex'} alignItems='center' sx={[spacingBetweenItems, companyLogoBox]}>
                <Avatar src={link.profileImage} alt='MasterCardLogo' sx={avatarStyle} />
                <Box ml={1}>
                  <Typography variant='h5' sx={companyTitleWrapper}>
                    <Link href={'#'} color={'inherit'}>
                      {link.title}
                    </Link>
                  </Typography>
                  <Typography variant='body2' sx={companysubtitle}>{link.subtitle}</Typography>
                </Box>
              </Box>
              <Box sx={contentBox}>
                <Box sx={iconBox}>
                  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path opacity='0.15' d='M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z' fill='#FFBE40'/>
                    <path d='M10.5625 8H13.9375V7.0625C13.9375 6.75781 14.1719 6.5 14.5 6.5C14.8047 6.5 15.0625 6.75781 15.0625 7.0625V8H16C16.8203 8 17.5 8.67969 17.5 9.5V17C17.5 17.8438 16.8203 18.5 16 18.5H8.5C7.65625 18.5 7 17.8438 7 17V9.5C7 8.67969 7.65625 8 8.5 8H9.4375V7.0625C9.4375 6.75781 9.67188 6.5 10 6.5C10.3047 6.5 10.5625 6.75781 10.5625 7.0625V8ZM8.125 12.3125H10V11H8.125V12.3125ZM8.125 13.4375V14.9375H10V13.4375H8.125ZM11.125 13.4375V14.9375H13.375V13.4375H11.125ZM14.5 13.4375V14.9375H16.375V13.4375H14.5ZM16.375 11H14.5V12.3125H16.375V11ZM16.375 16.0625H14.5V17.375H16C16.1875 17.375 16.375 17.2109 16.375 17V16.0625ZM13.375 16.0625H11.125V17.375H13.375V16.0625ZM10 16.0625H8.125V17C8.125 17.2109 8.28906 17.375 8.5 17.375H10V16.0625ZM13.375 11H11.125V12.3125H13.375V11Z' fill='#FFC728'/>
                  </svg>
                  <Typography
                    variant={'body2'}
                    color={(theme: Theme) => theme.palette.text.secondary}
                  >
                  {link.date}
                  </Typography>
                </Box>
                <Box sx={spacingBetweenItems}>
                  <Typography
                      component='div'
                      variant='body2'
                      sx={companyDescription}
                  >
                    {link.description}
                  </Typography>
                </Box>
                <Box sx={[spacingBetweenItems, addressBox]}>
                  <Grid sx={gridWrapper} container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <BoxContent
                        sx={iconwrapper}
                      >
                        <LocationIcon/>
                          <Typography noWrap variant={'subtitle2'} sx={{ flex: 1, width: '200px' }}>Location</Typography>
                      </BoxContent>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <BoxContent
                        sx={iconwrapper}
                      >
                        <Job/>
                          <Typography noWrap variant={'subtitle2'} sx={{ flex: 1, width: '200px' }}>Company</Typography>
                      </BoxContent>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <BoxContent
                        sx={iconwrapper}
                      >
                        <Account/>
                          <Typography noWrap variant={'subtitle2'} sx={{ flex: 1, width: '200px' }}>Employs</Typography>
                      </BoxContent>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <BoxContent
                        sx={iconwrapper}
                      >
                        <LiveJob/>
                          <Typography noWrap variant={'subtitle2'} sx={{ flex: 1, width: '200px' }}>Live Job</Typography>
                      </BoxContent>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box sx={cardActionContent}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Button
                    href={'#'}
                    LinkComponent={Link}
                    sx={iconVisibleStyle}
                    variant='contained'
                    size='circleResponsiveMedium'
                  >
                    More
                  </Button>
                    <Button
                      href={'#'}
                      size='circleResponsiveMedium'
                      variant={'outlined'}
                      
                    >
                      Delete
                    </Button>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}



const companyCardBox = (theme: Theme) => ({
  padding: '0',
  overflow: 'hidden',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  }
});

const gridWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('xs')]: {  
    '& .MuiGrid-item': {
      flexBasis: '50%',
      flexGrow: '0',
      maxWidth: '50%',
    }
  },
});

const cardCoverImg = (theme: Theme) => ({
  height: '150px',
});
const companyLogoBox = (theme: Theme) => ({
  padding: '0 24px 24px',
  borderBottom: '1px solid #EDF2F7',
  marginBottom: '16px !important',
});

const companyDescription = (theme: Theme) => ({
  height: '40px',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color:'#1e1e1e',
});

const companyTitleWrapper = (theme: Theme) => ({
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize:'14px',
});

const companysubtitle = (theme: Theme) => ({

});


const addressBox = (theme: Theme) => ({
});

const spacingBetweenItems = (theme: Theme) => ({
  borderRadius: '6px',
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(3),
  },
});

const avatarStyle = (theme: Theme) => ({
  width: '36px',
  height: '36px',
  border: '1px solid ${theme.palette.background.default}',
});

const iconVisibleStyle = (theme: Theme) => ({
  '& > .MuiButton-endIcon': {
    display: 'none',
  },
  '&:hover > .MuiButton-endIcon': {
    display: 'inherit',
  },
});

const wrapper = { display: 'flex', flexDirection: 'column', height: '100%' };

const cardActionContent = {
  borderTop: '1px solid #EDF2F7',
  padding: '24px 24px',
  marginTop: 'auto',
};

const iconBox = (theme: Theme) => ({
  display:'flex',
  alignItems: 'center',
  marginBottom: '8px',
  gap: '8px',
  '& p': {
    color: '#737373',
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: 600,
  },
});

const iconwrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10pX',
  backgroundColor:'#FAFAFB',
  '& svg': {
    overflow: 'visible',
    '& path': {
      fill: '#FFBE40 !important',
      fillOpacity: '1',
    }
  }
})

const contentBox = (theme: Theme) => ({
  padding: '0 16px',
});
