import { Box, Card, Grid, Theme, Typography, Divider } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import Uploader, { Asset } from '../Uploader/Uploader'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import { DOCUMENTS, IMAGES, VIDEO } from '../../config/uploaderTypes'

const companyGallerySelector = ApiSelector('CompanyGallery')({
  id: true,
  asset: {
    id: true,
    url: true,
    name: true,
    type: true,
    size: true,
    mimeType: true,
  },
})

type CompanyGallery = ApiInputType<ApiGraphQLTypes['CompanyGallery'], typeof companyGallerySelector>

interface Props {
  title: string
  subTitle: string
  note?: string
}

export const CompanyProfileGallery = ({ title, subTitle, note }: Props) => {
  const [galleryField] = useField<CompanyGallery[]>('gallery')

  const addAsset = (arrayHelpers: FieldArrayRenderProps, asset: Asset) => {
    arrayHelpers.push({ asset })
  }

  const removeAsset = (arrayHelpers: FieldArrayRenderProps, { id }: Asset) => {
    const index = galleryField.value.findIndex(({ asset }) => asset.id === id)
    arrayHelpers.remove(index)
  }

  return (
    <Box sx={wrapper}>
      <Typography sx={(theme) => ({ 
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1.5),
        },
        })} variant={'h2'}>{title}</Typography>
      <Divider />

      <Typography variant={'subtitle1'} sx={subtitleStyle}>
        {subTitle}
      </Typography>
      {note && (
        <Box sx={noteWrapper}>
          <InfoOutlinedIcon color={'primary'} />
          <Typography variant={'body2'}>NOTE: {note}</Typography>
        </Box>
      )}
      <Grid container spacing={3} sx={contentWrapper}>
        <Grid sm={12} xs={12} item>
          <FieldArray
            name={galleryField.name}
            render={(arrayHelpers) => (
              <>
                <Uploader
                  label={'Images and Video'}
                  assets={galleryField.value
                    .filter(({ asset }) => asset.type !== ApiTypes.AssetType.YOUTUBE)
                    .map(({ asset }) => asset)}
                  multiple
                  allowedFileTypes={[...IMAGES, ...VIDEO]}
                  onFileUploaded={(asset) => addAsset(arrayHelpers, asset)}
                  onFileDeleted={(asset) => removeAsset(arrayHelpers, asset)}
                />
              </>
            )}
          />
          <Divider sx={{ mt:4}} />
        </Grid>
        <Grid sm={12} xs={12} item>
          <FieldArray
            name={galleryField.name}
            render={(arrayHelpers) => (
              <>
                <Uploader
                  label={'Youtube Video '}
                  assets={galleryField.value
                    .filter(({ asset }) => asset.type === ApiTypes.AssetType.YOUTUBE)
                    .map(({ asset }) => asset)}
                  multiple
                  type={'youtube'}
                  onFileUploaded={(asset) => addAsset(arrayHelpers, asset)}
                  onFileDeleted={(asset) => removeAsset(arrayHelpers, asset)}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  height: 'auto',
})

const noteWrapper = (theme: Theme) => ({
  marginTop: theme.spacing(1.5),
  display: 'flex',
  padding: '14px 18px',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
  borderRadius: '6px',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  width: 'fit-content',
})

const subtitleStyle = (theme: Theme) => ({
  marginTop: theme.spacing(4),
})

const contentWrapper = (theme: Theme) => ({
  paddingTop: theme.spacing(3),
})
