import { Box, Typography, Card, FormControlLabel, Radio, Divider, Theme, ButtonBase } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { Field, useField, useFormikContext } from 'formik';
import { RadioGroup, TextField } from 'formik-mui';
import { ApiTypes } from 'recruticka-frontend-libs';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui-kits/button/button'
import { CurrencyAutocomplete } from '../form-fields/CurrencyAutocomplete'
import { useSalaryConversion } from '../../hooks/useSalary'
import { useEffect } from 'react'

export const SalaryCard = () => {
  const { resetForm, submitForm } = useFormikContext();
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();
  const [periodField] = useField('salaryPeriod');
  const [fromField, ,fromHelpers] = useField('from')
  const [toField, , toHelpers] = useField('to')
  const [currencyField, , currencyHelpers] = useField('currency')

  return (
    <Card sx={salaryRangeDropdownWrapper}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Field
          component={RadioGroup}
          name={periodField.name}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormControlLabel
            value={ApiTypes.SalaryPeriod.MONTH}
            control={<Radio disabled={isSubmitting} />}
            label={t('enum.SalaryPeriod.MONTH')}
            disabled={isSubmitting}
          />
          <FormControlLabel
            value={ApiTypes.SalaryPeriod.ANNUAL}
            control={<Radio disabled={isSubmitting} />}
            label={t('enum.SalaryPeriod.ANNUAL')}
            disabled={isSubmitting}
          />
        </Field>
        <Divider />
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Box sx={{ width: '130px' }}>
            <CurrencyAutocomplete name={'currency'} />
          </Box>
          <Box sx={{ width: '110px' }}>
            <Field
              name='from'
              component={TextField}
              type={'number'}
              label={'From'}
              fullWidth
            />
          </Box>
          <Box sx={{ width: '110px' }}>
            <Field
              name='to'
              component={TextField}
              type={'number'}
              label={'To'}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '36px',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonBase sx={actionWrapper} onClick={() => resetForm()}>
            <Cancel />
            <Typography variant={'body2'}>Clear all</Typography>
          </ButtonBase>
          <Button size={'small'} disabled={!currencyField.value} onClick={() => submitForm()}>
            Apply
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

const salaryRangeDropdownWrapper = (theme: Theme) => ({
  position: 'absolute',
  height: 'auto',
  width: 'auto',
  zIndex: 1,
  padding: theme.spacing(1.5),
  borderRadius: '6px',
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
});

const actionWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  cursor: 'pointer',
  marginLeft: '17px',
  gap: theme.spacing(1),
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  color: theme.palette.text.secondary,
});