import { FastField, FastFieldProps, useField } from 'formik'
import { TextEditor, TextEditorProps } from '../TextEditor/TextEditor'

interface Props extends Omit<TextEditorProps, 'onChange'> {
  name: string
  placeholder?: string
}

export const TextEditorField = ({ name, placeholder, ...textEditoProps }: Props) => {
  const [_, __, helpers] = useField(name)

  return (
    <FastField name={name} placeholder="F">
      {({ field, meta }: FastFieldProps) => (
        <TextEditor
          value={field.value}
          error={Boolean(meta.error?.length)}
          helperText={meta.error}
          placeholder={placeholder}
          onChange={(value) => helpers.setValue(value)}
          {...textEditoProps}
        />
      )}
    </FastField>
  )
}
