import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { Box, Theme, Typography } from '@mui/material'
import { authMutation } from '../../../services/api'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { Salary } from '../../form-fields/Salary'
import { SetOptional } from 'type-fest'
import { useCreateOrUpdateSalary } from '../../../hooks/useCreateOrUpdateSalary'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { SetNullable } from '../../../interfaces/nullable'
import { buttonStyle } from './position-information'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

interface Props extends Partial<SalaryFields> {
  employeeId: string
  onSave: (values: SalaryFields) => void
  // nextStep: () => void;
}

export const SalaryInformation = ({ employeeId, onSave, currency, from, to, id, schedule }: Props) => {
  const { t } = useTranslation()
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const initialValues: SetNullable<SetOptional<SalaryFields, 'id'>, 'currency'> = {
    id,
    from: from || 0,
    to: to || 0,
    schedule: schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: currency || null,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const data = await createOrUpdateSalaryMutation.mutateAsync(values as SalaryFields)

        await authMutation({
          updateEmployee: [
            {
              id: employeeId,
              input: {
                preferSalary: {
                  connect: {
                    id: data.id,
                  },
                },
              },
            },
            {
              id: true,
            },
          ],
        })

        onSave(data)
      }}
    >
      {({ values, setFieldValue, handleBlur, errors, handleChange, touched }) => {
        return (
          <Form>
            <Box
              sx={salaryWrapper}
            >
              <Typography textAlign={'center'} sx={titleTextStyle}>
                What Salary do you want ?{' '}
              </Typography>

              <Salary signUp/>
            </Box>
            <LoadingButton
              variant='contained'
              loading={createOrUpdateSalaryMutation.isLoading}
              sx={buttonStyle}
              type='submit'
              size={'medium'}
              disabled={Object.keys(errors).length > 0}
            >
              {t('save')}
            </LoadingButton>
          </Form>
        )
      }}
    </Formik>
  )
}

export const salaryWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
 [theme.breakpoints.down('sm')]:{
   '& .MuiGrid-root':{
     width: '100%',
     '& .MuiInputBase-root':{
       height: '48px!important'
     }
   }
 }
})

const titleTextStyle = () => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})

const validationSchema = Yup.object().shape({
  from: Yup.number().required(),
  to: Yup.number().required(),
  schedule: Yup.string().required(),
  // salaryAmount: Yup.array().test({
  //   name: "second",
  //   message: "must be greater than 0",
  //   test: (val) =>
  //     val!.every((item, index) => {
  //       if (index === 1) {
  //         return item > 1;
  //       }
  //       return true;
  //     }),
  // }),
  // salaryTime: Yup.string().required(),
  currency: Yup.object().nullable().required(),
})
