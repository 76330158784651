import React from 'react'
import { Avatar, Box } from '@mui/material'
import { buildAssetUrl } from '../../../utilities/buildAssetUrl'
import { Participant } from './chat-companion'
import { Theme } from '../../../styles/theme'
import { ApiTypes } from 'recruticka-frontend-libs'

interface Props{
  participant: Participant,
  chatList?: boolean
}

const ConversationProfile = ({participant, chatList = false}: Props) => {

  return (
    <>
      { participant?.company ?
        <Box sx={chatList ? chatListAvatar : chatCompanionAvatar}>
          {participant.company.logo?.url ?
            <img className={'img'} src={buildAssetUrl(participant.company.logo?.url)} alt={''} />
            :
            <Avatar className={'avatar'} sx={chatList ? chatListAvatar : avatar} />
          }
        </Box>
      :
        <Box sx={chatList ? chatListAvatar : chatCompanionAvatar}>
          {participant?.avatar?.url && !participant?.isHidden  && !participant.profile.deletedAt ?
            <img src={buildAssetUrl(participant?.avatar?.url)} alt={''} />
            :
            <Avatar className={'avatar'} sx={chatList ? chatListAvatar : avatar} />
          }
        </Box>
      }
    </>
  )
}

export default ConversationProfile

const chatListAvatar = (theme: Theme) => ({
  objectFit: 'cover',
  width: '52px',
  height: '52px',
  '& img':{
    width: '52px',
    height: '52px',
    objectFit: 'cover',
    borderRadius: '50%'
  }
})

const avatar = (theme: Theme) => ({
  width: '40px!important',
  height: '40px!important'
  // [theme.breakpoints.down('sm')]:{
  //
  // }
})

const chatCompanionAvatar = (theme: Theme) => ({
  marginTop: '12px',
  '& img': {
    width: '40px',
      height: '40px',
      objectFit: 'cover',
      borderRadius: '50%'
  },
  [theme.breakpoints.down('sm')]:{
    '& img':{
      width: '40px',
      height: '40px',
    }
  }
})