import { AutocompleteRenderInputParams, TextField } from '@mui/material'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { useQueryRequest } from '../../services/api'
import { jobTypeStyle } from './JobTypesAutocomplete'

const jobCriteriaSelector = ApiSelector('JobCriteria')({
  id: true,
  name: true,
})

type JobCriteriaFields = ApiInputType<ApiGraphQLTypes['JobCriteria'], typeof jobCriteriaSelector>

export const JobCriteriasAutocomplete = () => {
  const { t } = useTranslation()
  const [field, meta] = useField('jobCriterias')
  const [loadJobCriterias, setLoadJobCriterias] = useState(false)
  const { data: jobCriteriasData, isLoading: jobCriteriasDataLoading } = useQueryRequest(
    ['jobCriterias'],
    {
      getJobCriteriaes: [{ take: 100 }, jobCriteriaSelector],
    },
    {
      enabled: loadJobCriterias,
    },
  )

  return (
    <Field
      loading={jobCriteriasDataLoading}
      name={field.name}
      onOpen={() => setLoadJobCriterias(true)}
      onClose={() => setLoadJobCriterias(false)}
      multiple
      getOptionDisabled={() => field.value.length > 4}
      component={Autocomplete}
      noOptionsText={t('startTyping')}
      options={jobCriteriasData?.getJobCriteriaes || []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option: JobCriteriaFields, value: JobCriteriaFields) => option.id === value.id}
      getOptionLabel={(option: JobCriteriaFields) => (option.id ? `${option.name}` : '')}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={jobTypeStyle}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          label={t('formFields.jobCriteriasTitle')}
          variant='outlined'
        />
      )}
    />
  )
}
