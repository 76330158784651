import { useMutation, UseQueryOptions } from 'react-query'
import { useRecoilValue } from 'recoil'
import { ApiGraphQLTypes, ApiValueTypes, ApiInputType } from 'recruticka-frontend-libs'
import { authMutation, useQueryRequest } from '../services/api'
import { profileState } from '../state/atoms/profileState'

type CreateSpecializationProps = {
  input: {
    name: ApiGraphQLTypes['Specialization']['name']
    sectorId: ApiGraphQLTypes['Specialization']['id']
  }
  select: ApiValueTypes['Specialization']
}

export const useCreateSpecialization = () => {
  const profile = useRecoilValue(profileState)

  return useMutation(async ({ input, select }: CreateSpecializationProps) => {
    const data = await authMutation({
      createSpecizalizationForProfile: [
        {
          profileId: profile.id,
          input: {
            name: input.name,
            sector: {
              connect: {
                id: input.sectorId,
              },
            },
          },
        },
        select,
      ],
    })

    return data.createSpecizalizationForProfile
  })
}

interface useSectorSpecializationsProps {
  options?: UseQueryOptions
  sectorIds: string[]
  selector: ApiValueTypes['Specialization']
}
