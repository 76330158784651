import { ReactNode } from 'react'

import { Button as MuiButton, ButtonProps } from '@mui/material'

interface Props extends ButtonProps {
  children?: ReactNode
}

export const Button = ({ children, variant = 'contained', ...props }: Props) => {
  return (
    <MuiButton variant={variant} {...props}>
      {children}
    </MuiButton>
  )
}
