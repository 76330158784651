import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { JobTypesAutocomplete } from '../../form-fields/JobTypesAutocomplete'
import { JobCriteriasAutocomplete } from '../../form-fields/JobCriteriasAutocomplete'
import { ApiSelector, ApiInputType, ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Theme, Box, Button, Divider, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useCreateOrUpdateSalary } from '../../../hooks/useCreateOrUpdateSalary'
import { Salary } from '../../form-fields/Salary'
import { titleTextStyle } from '../employer-steps/kind-of-people-information'
import { authMutation } from '../../../services/api'

const jobTypeSelector = ApiSelector('JobType')({
  id: true,
  name: true,
})

type JobTypeFields = ApiInputType<ApiGraphQLTypes['JobType'], typeof jobTypeSelector>

const jobCriteriasSelector = ApiSelector('JobType')({
  id: true,
  name: true,
})

type JobCriteriasFields = ApiInputType<ApiGraphQLTypes['JobType'], typeof jobCriteriasSelector>

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>


interface Props extends Partial<SalaryFields> {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  onSave: (jobTypes: JobTypeFields[], jobCriterias: JobCriteriasFields[], salaryData: SalaryFields) => void
  jobTypes: JobTypeFields[] | undefined
  jobCriterias: JobCriteriasFields[] | undefined
  employeeId: string
}

export const PositionInformation = ({ isSaving, jobTypes, jobCriterias, employeeId, currency, from, to, id, schedule, onSave, currentStep, backStep }: Props) => {
  const { t } = useTranslation()
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const initialValues = {
    jobTypes: jobTypes || [],
    jobCriterias: jobCriterias || [],
    id,
    from: from || 0,
    to: to || 0,
    schedule: schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: currency || null,
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async ({jobTypes, jobCriterias, id, from, to, schedule, currency}) => {

        const salaryData = await createOrUpdateSalaryMutation.mutateAsync({id, from, to, schedule, currency} as SalaryFields)

        await authMutation({
          updateEmployee: [
            {
              id: employeeId,
              input: {
                preferSalary: {
                  connect: {
                    id: salaryData.id,
                  },
                },
              },
            },
            {
              id: true,
            },
          ],
        })

        onSave(jobTypes, jobCriterias, salaryData)
      }}
    >
      {({ errors, values, touched }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <JobTypesAutocomplete label={t('formFields.jobTypesTitle')} />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <JobCriteriasAutocomplete />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Typography textAlign={'center'} sx={titleTextStyle}>
                What Salary do you want ?{' '}
              </Typography>

              <Salary signUp/>
            </Box>

            <Box className='stepFooter'>
              <Box>
                {currentStep !== 0 && 
                  <Button
                    startIcon={<ArrowBackIosIcon />}
                    variant={'outlined'}
                    size={'medium'}
                    sx={buttonStyle}
                    disabled={currentStep === 0}
                    onClick={backStep}
                  >
                    {t('back')}
                  </Button>
                }
              </Box>
              <Box>
                <LoadingButton
                  endIcon={<ArrowForwardIosIcon />}
                  variant='contained'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  disabled={!values.jobTypes.length || !values.jobCriterias.length || Object.keys(errors).length > 0}
                >
                  {t('saveNext')}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  jobTypes: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1),
  jobCriterias: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1),
  from: Yup.number().required(),
  to: Yup.number().required(),
  schedule: Yup.string().required(),
  // salaryAmount: Yup.array().test({
  //   name: "second",
  //   message: "must be greater than 0",
  //   test: (val) =>
  //     val!.every((item, index) => {
  //       if (index === 1) {
  //         return item > 1;
  //       }
  //       return true;
  //     }),
  // }),
  // salaryTime: Yup.string().required(),
  currency: Yup.object().nullable().required(),
})

export const buttonStyle = (theme: Theme) => ({
  mx: 'auto',
  [theme.breakpoints.down('sm')]:{
    marginTop: '0px',
    paddingX: '24px',
    height: '38px',
    fontSize: '14px'
  }
})
