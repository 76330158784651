import { SvgProps } from '../types/icon'

export const Dashboard = ({ width = '18px', height = '18px', fill='black' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path d='M7.99998 16.0002V11.0002H12V16.0002C12 16.5502 12.45 17.0002 13 17.0002H16C16.55 17.0002 17 16.5502 17 16.0002V9.00021H18.7C19.16 9.00021 19.38 8.43021 19.03 8.13022L10.67 0.600215C10.29 0.260215 9.70998 0.260215 9.32998 0.600215L0.969976 8.13022C0.629976 8.43021 0.839976 9.00021 1.29998 9.00021H2.99998V16.0002C2.99998 16.5502 3.44998 17.0002 3.99998 17.0002H6.99998C7.54998 17.0002 7.99998 16.5502 7.99998 16.0002Z' fill={fill}/>
    </svg>
  )
}

