import {SvgProps} from '../types/icon';

export const WorkFromHomeIcon = ({width = '22px', height = '22px'}: SvgProps) => {
    return (
        <svg width={width} height={height} viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2.65625 3.59375C2.65625 3.375 2.84766 3.15625 3.09375 3.15625H4.40625C4.625 3.15625 4.84375 3.375 4.84375 3.59375V4.90625C4.84375 5.15234 4.625 5.34375 4.40625 5.34375H3.09375C2.84766 5.34375 2.65625 5.15234 2.65625 4.90625V3.59375ZM7.90625 3.15625C8.125 3.15625 8.34375 3.375 8.34375 3.59375V4.90625C8.34375 5.15234 8.125 5.34375 7.90625 5.34375H6.59375C6.34766 5.34375 6.15625 5.15234 6.15625 4.90625V3.59375C6.15625 3.375 6.34766 3.15625 6.59375 3.15625H7.90625ZM2.65625 7.09375C2.65625 6.875 2.84766 6.65625 3.09375 6.65625H4.40625C4.625 6.65625 4.84375 6.875 4.84375 7.09375V8.40625C4.84375 8.65234 4.625 8.84375 4.40625 8.84375H3.09375C2.84766 8.84375 2.65625 8.65234 2.65625 8.40625V7.09375ZM7.90625 6.65625C8.125 6.65625 8.34375 6.875 8.34375 7.09375V8.40625C8.34375 8.65234 8.125 8.84375 7.90625 8.84375H6.59375C6.34766 8.84375 6.15625 8.65234 6.15625 8.40625V7.09375C6.15625 6.875 6.34766 6.65625 6.59375 6.65625H7.90625ZM0.25 2.5C0.25 1.54297 1.01562 0.75 2 0.75H9C9.95703 0.75 10.75 1.54297 10.75 2.5V13C10.75 13.9844 9.95703 14.75 9 14.75H2C1.01562 14.75 0.25 13.9844 0.25 13V2.5ZM1.5625 2.5V13C1.5625 13.2461 1.75391 13.4375 2 13.4375H4.1875V11.6875C4.1875 10.9766 4.76172 10.375 5.5 10.375C6.21094 10.375 6.8125 10.9766 6.8125 11.6875V13.4375H9C9.21875 13.4375 9.4375 13.2461 9.4375 13V2.5C9.4375 2.28125 9.21875 2.0625 9 2.0625H2C1.75391 2.0625 1.5625 2.28125 1.5625 2.5Z' fill='#FFBE40'/>
        </svg>
        
    );
};

