import { Card, Grid, SxProps, Typography, Box, Divider, Theme } from '@mui/material'
import { TextField } from 'formik-mui'
import { Field } from 'formik'

export function CompanySocialNetworks() {
  return (
    <Box sx={cardSX}>
      <Typography variant={'h2'}  sx={(theme) => ({ 
          marginBottom: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
          },
          })}>
        Contact us:
      </Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(4.5)
      })} />
      <Grid container sx={rootSX}>

        <Grid item sx={socialBoxSX}>
          <Grid item sm={12} xs={12}>
            <Field name='facebook' component={TextField} fullWidth placeholder='Facebook public profile url' label='Facebook' />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Divider sx={(theme) => ({
              my: theme.spacing(4)
            })} />
            <Field name='instagram' component={TextField} fullWidth placeholder='Instagram public profile url' label='Instagram' />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Divider sx={(theme) => ({
              my: theme.spacing(4)
            })} />
            <Field name='twitter' component={TextField} fullWidth placeholder='Twitter public profile url' label='twitter'/>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const cardSX: SxProps = {
  width: 1,
}

const rootSX: SxProps = {
  flexWrap: { xs: 'wrap', md: 'nowrap' },
  flexDirection: { xs: 'column', md: 'row' },
  gap: { xs: 4, md: 7.5 },
}

const socialBoxSX: SxProps = {
  flexGrow: 1,
  gap: 4,
  flexDirection: { xs: 'column', md: 'row' },
}
