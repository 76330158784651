import React, { MutableRefObject, useEffect, useMemo, useState } from 'react'
import {
  Box, IconButton,
  Theme
} from '@mui/material'
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import { Asset } from './Uploader/Uploader'
import { MediaItem } from './ui-kits/media-item/media-item'
import { UploadedFile, UploadedImage, UploadedVideo } from './chat/chat-components/UploadedAsset'
import { Button } from './ui-kits/button/button'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import theme from '../styles/theme'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { buildAssetUrl } from '../utilities/buildAssetUrl'
import { saveAs } from 'file-saver'
import { SystemStyleObject } from '@mui/system'
import { ApiTypes } from 'recruticka-frontend-libs'
import { useDeleteAsset } from '../hooks/useAsset'
import MoreVertIcon from '@mui/icons-material/MoreVert'


export interface ImagesList {
  assets: Asset[]
  onClick?: (id: string) => void
  variant?: string
  handleDeleteAsset?: (id: string) => void
}

interface FileGalleryView {
  buttonWrapper?: SystemStyleObject
  galleryButton?: SystemStyleObject
  deleteButton?: SystemStyleObject
  imageWrapper: SystemStyleObject
  wrapper: SystemStyleObject,
  assetWrapper?: SystemStyleObject
  videoWrapper?: SystemStyleObject
}

export const FileGallery = ({ variant, onClick, assets, handleDeleteAsset }: ImagesList) => {
  const { buttonWrapper,
    assetWrapper,
    galleryButton,
    deleteButton,
    imageWrapper,
    videoWrapper,
    wrapper } = useMemo<FileGalleryView>(() => {
      if (variant === 'uploaded-slider') {
        return viewForSlider(theme)
      } else if (variant === 'gallery-view') {
        return viewForConversationGallery(theme)
      }
      return gallery(theme)
    }, [variant])

  const deleteAsset = useDeleteAsset()


  const handleDownload = ({ url: assetUrl, name }: Asset) => {
    const url = buildAssetUrl(assetUrl)
    if (url) {
      saveAs(url, name)
    }
  }

  const handleDelete = async (id: string) => {
   await deleteAsset.mutateAsync(id)
    if (handleDeleteAsset){
      handleDeleteAsset(id)
    }
  }

  return (
    <Box sx={wrapper}>
      <Gallery>
        {assets.map((asset: Asset, index) => {
          if (asset.type === ApiTypes.AssetType.DOCUMENT) {
            return (
              <Box sx={assetWrapper} key={asset.id} >
                <IconButton sx={popupBtns}>
                  <MoreVertIcon fontSize={'small'} />
                </IconButton>
                  <Box sx={buttonWrapper} className={'buttonWrapper'}>
                    {!onClick &&
                      <Button onClick={() => handleDownload(asset)} sx={galleryButton}>
                        <CloudDownloadIcon />
                      </Button>
                    }
                    {onClick ?
                      <Button onClick={() => onClick(asset.id)} className={'buttonWrapper'}  sx={deleteButton}>
                        <ClearOutlinedIcon />
                      </Button>
                      :
                      <Button onClick={() => handleDelete(asset.id)} sx={galleryButton}>
                        <DeleteForeverIcon />
                      </Button>
                    }
                  </Box>
                <UploadedFile asset={asset}/>
              </Box>
            )
          } else {
            return (
              <Box sx={assetWrapper}
                   key={asset.id} >
                <IconButton sx={popupBtns}>
                  <MoreVertIcon fontSize={'small'} />
                </IconButton>
                  <Box sx={buttonWrapper} className={'buttonWrapper'}>
                    {!onClick &&
                      <Button onClick={() => handleDownload(asset)} sx={galleryButton}>
                        <CloudDownloadIcon />
                      </Button>
                    }
                    {onClick ?
                      <Button onClick={() => onClick(asset.id)} className={'buttonWrapper'} sx={deleteButton}>
                        <ClearOutlinedIcon />
                      </Button>
                      :
                      <Button sx={galleryButton} onClick={() => handleDelete(asset.id)}>
                        <DeleteForeverIcon />
                      </Button>
                    }
                  </Box>
                <Item content={
                  <MediaItem
                    content={asset.url}
                    controls
                  />
                }
                  original={asset.url}
                  width="1600"
                  height="1600"
                >
                  {({ ref, open }) => (
                    <Box
                      ref={ref as MutableRefObject<HTMLDivElement>}
                      sx={asset.type !== ApiTypes.AssetType.VIDEO ? imageWrapper : videoWrapper}
                      onClick={open}
                    >
                      { asset.type === ApiTypes.AssetType.VIDEO ?
                        <UploadedVideo asset={asset}/>
                        :
                        <UploadedImage asset={asset} />
                      }
                    </Box>
                  )}
                </Item>
              </Box>
            )
          }
        })}
      </Gallery>
    </Box>
  )
}

const gallery = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%'
  },
  imageWrapper: {
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    // marginRight: '12px',
    pointerEvents: 'auto'
  }
})

const popupBtns = (theme: Theme) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]:{
    display: 'block',
    width: '24px',
    height: '24px',
    padding: 0,
    marginLeft: '12px'
  }
})

const viewForConversationGallery = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap'
  },
  assetWrapper: {
    position: 'relative',
    marginRight: '12px',
    marginBottom: '12px',
    '&:hover': {
      '.buttonWrapper': {
        display: 'flex'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse'
    }
  },
  buttonWrapper: {
    display: 'none',
    paddingY: '8px',
    paddingX: '12px',
    position: 'absolute',
    zIndex: '2',
    backgroundColor: '#fff',
    borderRadius: '6px',
    right: '5px',
    top: '5px',
    '& button:hover':{
      backgroundColor: 'unset',
      boxShadow: 'unset'
    },
    [theme.breakpoints.down('sm')]: {
      right: '44px',
      top: '8px',
    }
  }, galleryButton: {
    padding: 0,
    minWidth: 'unset',
    height: 'unset',
    boxShadow: 'unset',
    '&:not(:last-child)': {
      marginRight: '16px'
    },
    backgroundColor: '#fff',
    '& path': {
      fill: 'rgba(0, 0, 0, 0.54)!important'
    }
  },
  videoWrapper: {
    cursor: 'pointer',
    width: '300px!important',
    height: '300px!important',
    pointerEvents: 'auto',
    '& video': {
      width: '300px!important',
      height: '300px!important',
    },
    '& div':{
      width: '300px!important',
      height: '300px!important',
      objectFit: 'cover'
    },
    [theme.breakpoints.down('md')]:{
      width: '250px!important',
      height: '250px!important',
      '& video': {
        width: '250px!important',
        height: '250px!important',
      },
      '& div':{
        width: '250px!important',
        height: '250px!important',
      },
    }
  },
  imageWrapper: {
    cursor: 'pointer',
    maxWidth: '360px!important',
    maxHeight: '200px!important',
    width: '100%!important',
    height: '100%!important',
    pointerEvents: 'auto',
    '& img': {
      maxWidth: '360px!important',
      maxHeight: '200px!important',
      width: '100%!important',
      height: '100%!important',
    },
    '& div':{
      maxWidth: '360px!important',
      maxHeight: '200px!important',
      width: '100%!important',
      height: '100%!important',
      objectFit: 'cover'
    }
  }
})

const viewForSlider = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    paddingY: '10px'
  },
  assetWrapper: {
    position: 'relative',
    marginRight: '12px',
    '&:hover': {
      '.buttonWrapper': {
        display: 'flex'
      }
    }
  },
  deleteButton: {
    display: 'none',
    width: '18px',
    height: '18px',
    padding: 0,
    minWidth: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    zIndex: 1,
    backgroundColor: '#000',
    '& svg': {
      width: '6.6px',
      height: '6.6px'
    }
  },
  imageWrapper: {
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    pointerEvents: 'auto',
    '& img': {
      width: '60px',
      height: '60px'
    },
    '& div': {
      width: '60px!important',
      height: '60px!important',
      objectFit: 'cover'
    }
  },
  videoWrapper: {
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    pointerEvents: 'auto',
    '& video': {
      width: '60px!important',
      height: '60px!important',
      objectFit: 'cover'
    },
    '& div': {
      width: '60px!important',
      height: '60px!important',
      objectFit: 'cover'
    }
  }
})

