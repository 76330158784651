import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional } from 'type-fest'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { authMutation } from '../services/api'
import { OfficeAsset, officeAssetSelector, useCreateOrUpdateOfficeAsset, useDeleteOfficeAsset } from './useOfficeAsset'

export const officeSelector = ApiSelector('Office')({
  id: true,
  address: true,
  city: citySelector,
  isMain: true,
  assets: officeAssetSelector,
})

export interface Office extends Omit<ApiInputType<ApiGraphQLTypes['Office'], typeof officeSelector>, 'assets'> {
  assets?: SetOptional<OfficeAsset, 'id'>[]
}
type CreateOrUpdateOffice = SetOptional<Office, 'id'>

type MutationProps = {
  companyId: string
  values: CreateOrUpdateOffice
  oldValues?: PartialDeep<CreateOrUpdateOffice>
}

export const useCreateOrUpdateOffice = (selector: Partial<typeof officeSelector>) => {
  const officeAssetMutation = useCreateOrUpdateOfficeAsset({ id: true })
  const deleteOfficeAssetMutation = useDeleteOfficeAsset()

  return useMutation(async ({ companyId, values, oldValues }: MutationProps) => {
    const { id, city, assets, ...input } = values

    if (id) {
      const setIds: string[] = []
      const deleteIds: string[] = []

      if (assets) {
        for (const asset of assets) {
          // TODO: Update only if need
          const { id: officeAssetId } = (await officeAssetMutation.mutateAsync({
            officeId: id,
            values: asset,
          })) as OfficeAsset

          setIds.push(officeAssetId)
        }
      }

      oldValues?.assets?.forEach((oldAsset) => {
        const asset = assets?.find((asset) => asset.id === oldAsset?.id)

        if (!asset && oldAsset?.id) {
          deleteIds.push(oldAsset.id)
        }
      })

      for (const officeAssetId of deleteIds) {
        await deleteOfficeAssetMutation.mutateAsync(officeAssetId)
      }

      const { updateOffice } = await authMutation({
        updateOffice: [
          {
            id,
            input: {
              ...input,
              ...(city
                ? {
                    city: {
                      connect: {
                        id: city.id,
                      },
                    },
                  }
                : {}),
              ...(setIds.length
                ? {
                    assets: {
                      connect: setIds.map((id) => ({ id })),
                    },
                  }
                : {}),
            },
          },
          selector,
        ],
      })

      return updateOffice
    }

    const { createOffice } = await authMutation({
      createOffice: [
        {
          input: {
            ...input,
            ...(city
              ? {
                  city: {
                    connect: {
                      id: city.id,
                    },
                  },
                }
              : {}),
            assets: {
              ...(assets
                ? {
                    create: assets.map((asset) => ({
                      ...asset,
                      asset: {
                        connect: {
                          id: asset.asset.id,
                        },
                      },
                    })),
                  }
                : {}),
            },
            company: {
              connect: {
                id: companyId,
              },
            },
          },
        },
        selector,
      ],
    })

    /*  if (assets) {
       for await (const officeAssset of assets) {
         const officeAsssetData = await officeAssetMutation.mutateAsync({
           officeId: createOffice.id,
           values: officeAssset
         }) as OfficeAsset;
 
         if (!createOffice.assets) {
           createOffice.assets = [];
         }
 
         createOffice.assets.push(officeAsssetData);
       }
     } */

    return createOffice
  })
}

export const useDeleteOffice = () => {
  return useMutation(async (id: Office['id']): Promise<boolean> => {
    await authMutation({
      deleteOffice: [{ id }, { id: true }],
    })

    return true
  })
}
