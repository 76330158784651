import { AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { useCurencies } from '../../hooks/useCurrency'

const currencySelector = ApiSelector('Currency')({
  id: true,
  name: true,
  symbol: true,
})

type CurrencyFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof currencySelector>

type Props = {
  name: string
  disabled?: boolean
}

export const CurrencyAutocomplete = ({ name, disabled }: Props) => {
  const [field, meta] = useField(name)
  const { t } = useTranslation()
  const [loadCurrencies, setLoadCurrencies] = useState(false)
  const { data: currenciesData, isLoading: currenciesDataLoading } = useCurencies(loadCurrencies)
  return (
    <Field
      disabled={disabled}
      loading={currenciesDataLoading}
      name={field.name}
      onOpen={() => setLoadCurrencies(true)}
      onClose={() => setLoadCurrencies(false)}
      component={Autocomplete}
      noOptionsText={t('startTyping')}
      options={currenciesData?.getCurrencies || []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableClearable
      isOptionEqualToValue={(option: CurrencyFields, value: CurrencyFields) => option?.id === value?.id}
      getOptionLabel={(option: CurrencyFields) => (option.name && option.symbol) ? `${option.name}, ${option.symbol}` : ''}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.error}
          label={t('formFields.currencyPlaceholder')}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {currenciesDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
