import { AutocompleteRenderInputParams, TextField } from '@mui/material'
import { Field, FieldProps, useField } from 'formik'
import { Autocomplete } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useDebounce } from 'use-debounce'
import { useQueryRequest } from '../../services/api'

const languageSelector = ApiSelector('Language')({
  id: true,
  name: true,
})

type LanguageFields = ApiInputType<ApiGraphQLTypes['Language'], typeof languageSelector>

interface Props extends Partial<FieldProps> {
  multiple?: boolean
  name: string
}

export const LanguageAutocomplete = ({ multiple, name }: Props) => {
  const [field, meta] = useField(name)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [searchLanguageName, setSearchLanguageName] = useState<string>('')
  const [languageSearch] = useDebounce(searchLanguageName, 100)

  const { data, isLoading } = useQueryRequest(
    ['searchLanguage', languageSearch],
    {
      getLanguages: [
        { take: 20, where: { name: { startsWith: languageSearch, mode: ApiTypes.QueryMode.insensitive } } },
        languageSelector,
      ],
    },
    {
      enabled: languageSearch.length > 1,
    },
  )

  const invalidateSearchLanguage = () => queryClient.invalidateQueries('searchLanguage')

  return (
    <Field
      multiple={multiple}
      loading={isLoading}
      name={name}
      component={Autocomplete}
      onInputChange={(event: any, newInputValue: string) => {
        setSearchLanguageName(newInputValue)
      }}
      noOptionsText={t('startTyping')}
      getOptionDisabled={() => field?.value?.length > 4}
      options={data?.getLanguages || []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option: LanguageFields, value: LanguageFields) => option.id === value.id}
      getOptionLabel={(option: LanguageFields) => option.name}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          name={name}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          label={t('formFields.languagePlaceholder')}
          variant='outlined'
          onBlur={() => invalidateSearchLanguage()}
        />
      )}
    />
  )
}
