import { SvgProps } from '../types/icon'

export const CreditCard = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path
        d='M5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14V12ZM10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12V14ZM3 4H17V2H3V4ZM17 16H3V18H17V16ZM2 15V7H0V15H2ZM2 7V5H0V7H2ZM18 5V7H20V5H18ZM18 7V15H20V7H18ZM1 8H19V6H1V8ZM5 14H10V12H5V14ZM3 16C2.44772 16 2 15.5523 2 15H0C0 16.6569 1.34315 18 3 18V16ZM17 18C18.6569 18 20 16.6569 20 15H18C18 15.5523 17.5523 16 17 16V18ZM17 4C17.5523 4 18 4.44772 18 5H20C20 3.34315 18.6569 2 17 2V4ZM3 2C1.34315 2 0 3.34315 0 5H2C2 4.44772 2.44772 4 3 4V2Z'
        fill='#141D24'
        fillOpacity='0.4'
      />
    </svg>
  )
}
