import { Box, Card, Divider, Modal, Theme, Typography } from '@mui/material'
import { SettingsModal } from './settings-modal'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { authMutation, useAuthQueryRequest } from '../../services/api';
import { LoaderSpinner } from '../LoaderSpinner';
import { ApiTypes } from 'recruticka-frontend-libs';
import { useMutation } from 'react-query';

export type UpdateSettings = {
  email: string,
  password?: string
}

export const Settings = () => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const { data, isLoading, refetch } = useAuthQueryRequest('me', {
    me: {
      userProfile: {
        profileId: true,
      },
      account: {
        id: true,
        email: true,
        type: true,
      }
    },
  })

  const userMutation = useMutation((values: UpdateSettings) => {
    return authMutation({
      updateUser: [{
        input: {
          email: values.email,
          password: values.password
        }
      },
      {
        id: true,
      }]
    })
  });

  const emailPasswordAccount = (data?.me?.account || []).find(account => account.type === ApiTypes.AccountType.EMAIL_PASSWORD)
  const onSave = async (values: UpdateSettings) => {
    await userMutation.mutateAsync(values);
    refetch()
    handleClose();
  }

  if (isLoading) {
    return <LoaderSpinner />
  }

  return (
    <Card sx={wrapper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'}>Contact Details</Typography>{' '}
        {emailPasswordAccount?.id && <Box onClick={handleOpen}>
          <EditIcon sx={iconStyle} />
        </Box>}
      </Box>

      {(data?.me.account || []).map((account) => (
        <Box key={account.id} sx={[wrapper, { gap: '10px' }]}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            {account.type === ApiTypes.AccountType.LINKEDIN ? 'Linkedin' : ''} E-mail:
          </Typography>
          <Typography sx={typographyStyle}>{account.email}</Typography>
          <Divider />
        </Box>
      ))}

      {emailPasswordAccount?.email ? <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&::-webkit-scrollbar': {
              width: '0px',
              height: '0px',
            },
            height: 'auto',
            maxWidth: '864px',
            maxHeight: '90vh',
            overflowY: 'auto',
            width: '100%',
            outline: 'none',
          }}
        >
          <SettingsModal
            onClose={handleClose}
            isLoading={userMutation.isLoading}
            email={emailPasswordAccount.email}
            onSave={(values) => onSave(values)}
          />
        </Box>
      </Modal> : null}
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  width: '100%',
})

const typographyStyle = () => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})
