import { Card, Typography, Box, Divider, Theme, Link } from '@mui/material'
import { Avatar } from '../Avatar'
import { ApiTypes } from 'recruticka-frontend-libs'
import { Asset } from '../Uploader/Uploader'
import { useTranslation } from 'react-i18next'
import { Button } from '../ui-kits/button/button'
import { chatEnabled } from '../../config/features'

interface Props {
  avatar?: Asset
  firstname?: string
  lastname?: string
  experience?: ApiTypes.Experience
  locationType?: ApiTypes.JobLocationType
  linkedin?: string
  city?: string
  country?: string
  visible: boolean
  onClick: () => void
}

export const EmployeeProfileMainInfo = ({
  avatar,
  experience,
  firstname,
  lastname,
  linkedin,
  locationType,
  city,
  country,
  visible,
  onClick
}: Props) => {
  const { t } = useTranslation()

  return (
    <Card sx={wrapper}>
      {visible &&
        <Avatar src={avatar?.url} sx={avatarWrapper} alt={'logo'} />
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {visible &&
          <Typography variant={'h5'}>
            {firstname} {lastname}
          </Typography>
        }
        {/* <Typography variant={"h6"}>{positionAndCompany}</Typography> */}

        {visible &&
          <>
            {!!linkedin && (
              <>
                <Divider />
                <Typography variant={'body1'} sx={{ color: 'rgba(20, 29, 36, 0.4)' }}>LinkedIn</Typography>
                <Typography noWrap={true}>
                  <Link href={linkedin} sx={[typographyStyle]} target='_blank'>
                    {linkedin}
                  </Link>
                </Typography>
              </>
            )}
          </>
        }

        {visible &&
          <>
            {!!experience && (
              <>
                <Divider />
                <Typography variant={'body1'} sx={{ color: 'rgba(20, 29, 36, 0.4)' }}>Experience Level</Typography>
                <Typography sx={typographyStyle}>{t(`enum.Experience.${experience}`)}</Typography>
              </>
            )}
          </>
        }

        {!!(locationType && city && country) && (
          <>
            <Divider />
            <Typography variant={'body1'} sx={{ color: 'rgba(20, 29, 36, 0.4)' }}>{t(`enum.JobLocationType.${locationType}`)}</Typography>
            <Typography sx={typographyStyle}>
              {city}, {country}
            </Typography>
          </>
        )}

        <Divider />
      </Box>
      {chatEnabled && <Button
        size={'large'}
        sx={sendButton}
        onClick={onClick}
      >
        Send message
      </Button>}
    </Card>
  )
}

const sendButton = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    height: '48px'
  }
})

const avatarWrapper = (theme: Theme) => ({
  width: '199px',
  height: '199px',
  mx: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: '149px',
    height: '149px'
  }
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    '& h2': {
      fontSize: '18px'
    },
    '& p': {
      fontSize: '16px'
    }
  }
})

const typographyStyle = (theme: Theme) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px'
})
