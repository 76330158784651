import { MenuItem } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { Select } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'

interface Props {
  name: string
  onClick?: () => void
}

export const SalaryPeriodSelect = ({ name, onClick }: Props) => {
  const { t } = useTranslation()

  return (
    <Field name={name} onChange={onClick} component={Select} placeholder={t('formFields.salaryPeriodPlaceholder')} fullWidth>
      {Object.values(ApiTypes.SalaryPeriod).map((value) => (
        <MenuItem value={value} key={value}>
          {t(`enum.SalaryPeriod.${value}`)}
        </MenuItem>
      ))}
    </Field>
  )
}
