import { Box, styled } from '@mui/material'

export const textOverflowDots = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const Main = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
}))

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: '2000px',
  margin: '120px auto auto',
  width: 'calc(100vw - 260px)',
  display: 'flex',
  justifyContent: 'start',
  paddingLeft: '24px',
  paddingRight: '24px',
  boxSizing: 'border-box',
  flexGrow: '1',
  paddingBottom: theme.spacing(4),
  // minHeight: '100vh',
  transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  [theme.breakpoints.down(1250)]: {
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '100px',
    paddingLeft: '24px',
    paddingRight: '24px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
      paddingRight: '16px',
    '& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12' : {
      width: '100%',
    }
  }
}))

export const InfoText = styled('span')(({ theme }) => ({
  fontWeight: '500',
  fontSize: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}))

export const TitleText = styled('p')(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '800',
  fontSize: '44px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '34px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    textAlign: 'center',
  },
}))

export const LinkList = styled('ul')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '600px',
  width: '100%',
  listStyleType: 'none',
  fontWeight: '600',
  fontSize: '18px',
  alignItems: 'center',
  paddingLeft: 0,
  '& > li': {
    cursor: 'pointer',
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  [theme.breakpoints.down('xl')]: {
    maxWidth: '460px',
    fontSize: '14px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    flexDirection: 'column',
    textAlign: 'center',
  },
}))
