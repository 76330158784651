import { useQuery, UseQueryOptions } from 'react-query'
import {
  setupRequest,
  ApiValueTypes,
  ApiInputType,
  ApiGraphQLTypes,
  ApiOperationOptions,
} from 'recruticka-frontend-libs'
import { redirectToSite } from './redirectToSite'
import { apiGraphqlUrl, siteLoginApi, siteLogoutApi } from '../config/urls'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'

export const logout = async () => {
  return fetch(siteLogoutApi, {
    method: 'POST',
    credentials: 'include',
  })
}

const baseQueryApi = setupRequest({
  apiUrl: apiGraphqlUrl,
  onUnauthorizedError: () => redirectToSite(),
})

export const authRequest = baseQueryApi.authRequest
export const authMutation = baseQueryApi.authMutation
export const wsChain = baseQueryApi.wsChain
export const mutation = baseQueryApi.mutation;

/* export function authRequest<O extends 'Query', TData extends ApiValueTypes[O]>(
  query: TData | ApiValueTypes[O],
  zeusOptions?: ApiOperationOptions,
  fetchOptions: RequestInit = {}
) {
  // const { id } = useRecoilValue(profileState);

  return baseQueryApi.authRequest(query, zeusOptions, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      // 'profile-id': id
    }
  });
}

export function authMutation<O extends 'Mutation', TData extends ApiValueTypes[O]>(
  query: TData | ApiValueTypes[O],
  zeusOptions?: ApiOperationOptions,
  fetchOptions: RequestInit = {}
) {
  // const { id } = useRecoilValue(profileState);

  return baseQueryApi.authMutation(query, zeusOptions, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      // 'profile-id': id
    }
  });
} */

export function useAuthQueryRequest<
  O extends 'Query',
  TData extends ApiValueTypes[O],
  TResult = ApiInputType<ApiGraphQLTypes[O], TData>,
>(
  queryKey: string | unknown[],
  query: TData | ApiValueTypes[O],
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
  zeusOptions?: ApiOperationOptions,
) {
  return useQuery(queryKey, () => authRequest(query, zeusOptions) as Promise<TResult>, options)
}

export function useQueryRequest<
  O extends 'Query',
  TData extends ApiValueTypes[O],
  TResult = ApiInputType<ApiGraphQLTypes[O], TData>,
>(
  queryKey: string | unknown[],
  query: TData | ApiValueTypes[O],
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
  zeusOptions?: ApiOperationOptions,
) {
  return useQuery(queryKey, () => authRequest(query, zeusOptions) as Promise<TResult>, options)
}


export const getCabinetProfileUrl = (profileId: string) => {
  return `${process.env.REACT_APP_URL}/${profileId}`
}