import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { authMutation, authRequest, useAuthQueryRequest } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'
import { useInfiniteQuery, useMutation } from 'react-query'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { chatWindowState } from '../state/atoms/chatState'
import { mainCompanyState, profileState } from '../state/atoms/profileState'
import { messagesSelector, replyMessageSelector } from './useMessages'

export const participantsSelector = ApiSelector('Profile')({
  role: true,
  displayName: true,
  companies: {
    id: true,
    name: true,
    jobTypes: {
      name: true
    },
    profileId: true,
    logo: assetSelector
  },
  isHidden: true,
  id: true,
  employee: {
    profile: {
      deletedAt: true
    },
    profileId: true,
    firstname: true,
    lastname: true,
    id: true,
    avatar: assetSelector
  }
})

export const authorSelector = ApiSelector('Profile')({
  profile: {
    firstname: true,
    lastname: true,
    company: {
      name: true,
      logo: assetSelector
    },
    employee: {
      avatar: assetSelector
    }
  }
})

export const lastMessageSelector = ApiSelector('Message')({
  content: true,
  assets: assetSelector,
  id: true,
  createdAt: true,
  conversationId: true,
  author: {
    profileId: true,
    profile: {
      deletedAt: true,
      displayName: true,
      companies: {
        name: true,
        logo: assetSelector
      },
      id: true,
      isHidden: true,
      employee: {
        firstname: true,
        lastname: true,
        profileId: true,
        avatar: assetSelector
      }
    }
  },
  replyedTo: replyMessageSelector
})

export const conversationSelector = ApiSelector('Conversation')({
  id: true,
  companyId: true,
  company: {
    name: true,
    logo: assetSelector,
    jobTypes: {
      name: true,
    },
    id: true,
  },
  participants: participantsSelector,
  lastMessage: lastMessageSelector
})
export type LastMessage = ApiInputType<ApiGraphQLTypes['Message'], typeof lastMessageSelector>

export type Conversation = ApiInputType<ApiGraphQLTypes['Conversation'], typeof conversationSelector>
export type Participants = ApiInputType<ApiGraphQLTypes['Profile'], typeof participantsSelector>

// export const useConversations = (profileId: string) => {
//   return useAuthQueryRequest(
//     ['conversations', profileId],
//     {
//       ...(profileId ? {
//         getProfileConversations: [{ profileId: profileId, take: 10 }, conversationSelector]
//       } : {})
//     }
//   )
// }

export const useConversationById = (profileId: string, conversationId: string) => {
  return useAuthQueryRequest(
    ['conversations', conversationId],
    {
      ...(conversationId ? {
        getProfileConversation: [{
          id: conversationId
        }, {
          ...conversationSelector,
          participants: {
            ...conversationSelector.participants,
            isHidden: [
              { profileId },
              true
            ]
          },
          lastMessage: {
            ...conversationSelector.lastMessage,
            author: {
              ...conversationSelector.lastMessage.author,
              profile: {
                ...conversationSelector.lastMessage.author.profile,
                isHidden: [
                  { profileId },
                  true
                ]
              }
            },
            replyedTo: {
              ...conversationSelector.lastMessage.replyedTo,
              author: {
                ...conversationSelector.lastMessage.replyedTo.author,
                profile: {
                  ...conversationSelector.lastMessage.replyedTo.author.profile,
                  isHidden: [
                    { profileId },
                    true
                  ]
                }
              }
            }
          }
        }]
      } : {})
    }
  )
}

export const useGetConversations = (profileId: string, search?: string) => {
  return useInfiniteQuery(['conversations', profileId, search], (cursor) => {
    return authRequest({
      ...(profileId ? {
        getProfileConversations: [
          {
            profileId: profileId,
            ...(cursor.pageParam ? {
              cursor: {
                id: cursor.pageParam
              }
            } : {}),
            take: 100,
            // ...(search ? {
            //   where: {
            //     participants: {
            //       some: {
            //         OR: [
            //           {
            //             company: {
            //               is: {
            //                 name: {
            //                   mode: ApiTypes.QueryMode.insensitive,
            //                   contains: search
            //                 }
            //               }
            //             }
            //           },
            //           {
            //             OR: [
            //               {
            //                 employee: {
            //                   is: {
            //                     firstname: {
            //                       mode: ApiTypes.QueryMode.insensitive,
            //                       contains: search
            //                     }
            //                   }
            //                 }
            //               },
            //               {
            //                 employee: {
            //                   is: {
            //                     lastname: {
            //                       mode: ApiTypes.QueryMode.insensitive,
            //                       contains: search
            //                     }
            //                   }
            //                 }
            //               }
            //             ]
            //           }
            //         ]
            //       }
            //     }
            //   }
            // } : {}),
            ...(cursor.pageParam ? {
              skip: 1
            } : {})
          }, {
            ...conversationSelector,
            participants: {
              ...conversationSelector.participants,
              isHidden: [
                { profileId },
                true
              ]
            },
            lastMessage: {
              ...conversationSelector.lastMessage,
              author: {
                ...conversationSelector.lastMessage.author,
                profile: {
                  ...conversationSelector.lastMessage.author.profile,
                  isHidden: [
                    { profileId },
                    true
                  ]
                }
              },
              replyedTo: {
                ...conversationSelector.lastMessage.replyedTo,
                author: {
                  ...conversationSelector.lastMessage.replyedTo.author,
                  profile: {
                    ...conversationSelector.lastMessage.replyedTo.author.profile,
                    isHidden: [
                      { profileId },
                      true
                    ]
                  }
                }
              }
            }
          }]
      } : {})
    })
  }, {
    getNextPageParam: (lastPage, pages) => lastPage?.getProfileConversations?.at(-1)?.id
  })
}

type CreateConversationParams = {
  employerProfileId: string
  employeeProfileId: string
  companyId: string
}

export const useCreateConversation = () => {
  return useMutation(async ({ companyId, employeeProfileId, employerProfileId }: CreateConversationParams) => {
    const result = await authMutation({
      createChatBetweenEmployerAndEmployee: [
        {companyId, employerProfileId, employeeProfileId },
        { id: true }
      ]
    })

    return result.createChatBetweenEmployerAndEmployee
  })
}

type ChatOpenParams = {
  withProfileId: string
  conversationId?: string
  companyId?: string
}

export const useChat = () => {
  const { id: profileId, role } = useRecoilValue(profileState)
  const createConversation = useCreateConversation()
  const {id: mainCompanyId } = useRecoilValue(mainCompanyState)
  const setChatState = useSetRecoilState(chatWindowState)
  const reset = useResetRecoilState(chatWindowState)

  const open = async ({ withProfileId, conversationId, companyId }: ChatOpenParams) => {
    let cId = conversationId
    const employeeProfileId = role === ApiTypes.Role.EMPLOYEE ? profileId : withProfileId
    const employerProfileId = role === ApiTypes.Role.EMPLOYER ? profileId : withProfileId

    if (!cId) {
      if (!companyId){
        const companyId = mainCompanyId
        const response = await createConversation.mutateAsync({
          companyId, employeeProfileId, employerProfileId
        })
        cId = response.id
      } else {
        const response = await createConversation.mutateAsync({
          companyId, employeeProfileId, employerProfileId
        })
        cId = response.id
      }

    }
    setChatState({
      isOpened: true,
      conversationId: cId,
      withProfileId
    })
    return cId
  }

  const close = () => reset()

  return { open, close }
}