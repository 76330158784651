import { useRecoilValue } from 'recoil'
import { ApiTypes } from 'recruticka-frontend-libs'
import { profileState } from '../state/atoms/profileState'
import OnboardingCompany from './onboarding-company'
import OnboardingEmployee from './onboarding-employee'

const OnboardingPage = () => {
  const profile = useRecoilValue(profileState)

  if (profile?.role === ApiTypes.Role.EMPLOYEE) {
    return <OnboardingEmployee />
  }

  if (profile?.role === ApiTypes.Role.EMPLOYER) {
    return <OnboardingCompany />
  }

  return null
}

export default OnboardingPage