import { FormControlLabel, Radio, Theme } from '@mui/material'
import { Field, FieldAttributes } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'

interface Props extends FieldAttributes<any> {
  isSubmitting?: boolean
}

export const ConsiderPeopleFromOtherSectorRadio = ({ isSubmitting, ...props }: Props) => {
  const { t } = useTranslation()

  return (
    <Field component={RadioGroup} name='considerPeopleFromOtherSector' className={'consider-radio-wrapper'} sx={contentWrapper} {...props}>
      <FormControlLabel
        value={ApiTypes.ConsiderPeopleFromOtherSector.YES}
        control={<Radio disabled={isSubmitting} />}
        label={t('enum.ConsiderPeopleFromOtherSector.YES')}
        disabled={isSubmitting}
      />
      <FormControlLabel
        value={ApiTypes.ConsiderPeopleFromOtherSector.NO}
        control={<Radio disabled={isSubmitting} />}
        label={t('enum.ConsiderPeopleFromOtherSector.NO')}
        disabled={isSubmitting}
      />
      <FormControlLabel
        value={ApiTypes.ConsiderPeopleFromOtherSector.MAYBE}
        control={<Radio disabled={isSubmitting} />}
        label={t('enum.ConsiderPeopleFromOtherSector.MAYBE')}
        disabled={isSubmitting}
      />
    </Field>
  )
}

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap !important',
  },
})
