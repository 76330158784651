import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material'
import { ReactNode } from 'react'
import { ApiTypes } from 'recruticka-frontend-libs'
import { TableSortDropDown } from './TableSortDropDown'

export interface HeadCell {
  id: string
  label: string
  isSortable?: boolean
  direction?: ApiTypes.SortOrder
  directionId?: string
}

interface EnhancedTableProps {
  headCells: HeadCell[]
  onSortChange?: (cell: string, direction: ApiTypes.SortOrder) => void
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow sx={tableRowWrapper}>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              padding: 0,
              width: '249px',
              fontFamily: 'Roboto !important',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '24px'
            }}
          >
            <Box sx={headCellContentWrapper}>
              {headCell.label}
              {headCell.isSortable && (
                <TableSortLabel
                  active
                  direction={headCell.direction}
                  sx={sortArrowStyle}
                  onClick={() => {
                    props.onSortChange && props.onSortChange(
                      headCell.id,
                      headCell.direction === ApiTypes.SortOrder.desc ? ApiTypes.SortOrder.asc : ApiTypes.SortOrder.desc
                    )
                  }}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface Props {
  headCells: HeadCell[]
  headCellsDropDown?: HeadCell[]
  isSortable?: boolean
  children?: ReactNode
  pagination: {
    rowsPerPage?: number
    page?: number
    onPageChange: (page: number) => void
    onRowsPerPageChange: (rows: number) => void
    count: number
  }
  onSortChange?: (cell: string, direction: ApiTypes.SortOrder) => void
}

export default function MyJobTable({ headCells, pagination, children, onSortChange, headCellsDropDown }: Props) {
  const page = pagination.page ?? 0
  const rowsPerPage = pagination.rowsPerPage ?? 5

  const handleChangePage = (event: unknown, newPage: number) => {
    pagination.onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.onRowsPerPageChange(parseInt(event.target.value, 10))
    pagination.onPageChange(0)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pagination.count) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={tableBox}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead onSortChange={onSortChange} headCells={headCells} />
            {headCellsDropDown && <TableSortDropDown onSortChange={onSortChange} headCellsDropDown={headCellsDropDown}/>}
            <TableBody>
              {children}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={paginationStyle}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pagination.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

const tableBox = (theme: Theme) => ({
  width: '100%', 
  mb: 2, 
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    px: theme.spacing(2),
  } 
})

const paginationStyle = (theme: Theme) => ({

  [theme.breakpoints.down('sm')]: {
    marginX: '-24px',
  }
})

const sortArrowStyle = (theme: Theme) => ({
  '& > *': {
    color: `${theme.palette.action.active} !important`
  }
})

const tableRowWrapper = (theme: Theme) => ({
  '& > *:last-child > *': {
    borderRight: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
})

const headCellContentWrapper = (theme: Theme) => ({
  display: 'flex',
  borderRight: '2px solid #E0E0E0',
  height: '14px',
  alignItems: 'center',
  margin: '21px 0 21px 16px'
})
