import React, { useState, useRef} from 'react'
import {
  Card,
  Theme,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  Snackbar,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { AddEditJobHeader } from '../components/not-cms/add-edit-job-header'
import { JobBasicInfo } from '../components/not-cms/job-basic-info'
import { JobSalaryInfo } from '../components/not-cms/job-salary-info'
import { JobLocationSettings } from '../components/not-cms/job-location-settings'
import { JobRequirements } from '../components/not-cms/job-requirements'
import { JobBenefits } from '../components/not-cms/job-benefits'
import { Content } from '../components/common-files/common-styles'
import TempWrapperNew from '../components/tempWrapperNew'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { authMutation, useAuthQueryRequest } from '../services/api'
import { SetOptional } from 'type-fest'
import { useMutation, useQueryClient } from 'react-query'
import { diff } from 'deep-object-diff'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDefaultCurrency } from '../hooks/useCurrency'
import { useDateTime } from '../hooks/useDate'

import { useCreateOrUpdateSalary } from '../hooks/useCreateOrUpdateSalary'
import { useCreateOrUpdateJobLocation } from '../hooks/useCreateOrUpdateJobLocation'
import { TextBlock, textBlockSelector, useCreateOrUpdateTextBlock } from '../hooks/useTextBlock'
import { useDeleteAsset } from '../hooks/useAsset'
import Yup, { ShortTextareaValidation, TextareaValidation, TextValidation } from '../validation'
import { assetSelector } from '../components/Uploader/Uploader'
import { TextBlockAsset, useCreateOrUpdateTextBlockAsset } from '../hooks/useTextBlockAsset'
import { useOpenJobPage, usePublishJob } from '../hooks/useJob'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import theme from '../styles/theme'
import { JobCardHeaderScrollable } from '../components/JobHeaderScrollable'
// Footer Button
import FooterButtonClient from '../components/footerButtonClient'
import SidebarInternalJob from '../components/SidebarInternalJob'

export const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true
  }
})

export const jobLocationSelector = ApiSelector('JobLocation')({
  id: true,
  type: true,
  wfhDays: true,
  offices: {
    id: true,
    city: citySelector
  }
})

export const jobSelector = ApiSelector('Job')({
  id: true,
  slug: true,
  companyId: true,
  status: true,
  title: true,
  description: true,
  shortDescription: true,
  experience: true,
  salaryType: true,
  views: true,
  createdAt: true,
  updatedAt: true,
  salary: salarySelector,
  location: jobLocationSelector,
  requirement: textBlockSelector,
  benefit: textBlockSelector,
  sectors: {
    id: true,
    name: true
  },
  specializations: {
    id: true,
    name: true,
    sector: {
      id: true,
      name: true
    }
  },
  coverImage: assetSelector
})

type JobFieldsFull = ApiInputType<ApiGraphQLTypes['Job'], typeof jobSelector>
type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>
type JobLocationFields = ApiInputType<ApiGraphQLTypes['JobLocation'], typeof jobLocationSelector>

type ValidationError = {
  field: string
  errors: string[]
}

interface JobFieldsUpdate
  extends Omit<JobFieldsFull,
  'salary' | 'location' | 'views' | 'companyId' | 'createdAt' | 'updatedAt' | 'coverImage' | 'requirement' | 'benefit'> {
  salary: SetOptional<SalaryFields, 'id'>
  location: SetOptional<JobLocationFields, 'id'>
  requirement: SetOptional<TextBlock, 'id'>
  benefit: SetOptional<TextBlock, 'id'>
  coverImage: JobFieldsFull['coverImage']
  companyId: string
}


const AddJobPage = () => {
  const defaultCurrency = useDefaultCurrency()
  const { jobId } = useParams()
  const queryClient = useQueryClient()
  const salaryMutation = useCreateOrUpdateSalary(salarySelector)
  const jobLocationMutation = useCreateOrUpdateJobLocation(jobLocationSelector)
  const textBlockMutation = useCreateOrUpdateTextBlock(textBlockSelector)
  const deleteAssetMutation = useDeleteAsset()
  const textBlockAssetMutation = useCreateOrUpdateTextBlockAsset({ id: true })
  const [publishErrors, setPusblishErrors] = useState<ValidationError[]>([])
  const { openJobPage } = useOpenJobPage()
  const publishMutation = usePublishJob()
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)


  const handleSideDropDown = (index: number) => {
    setCurrentMenuIndex(index);
    srollToElement(index);
  };

  function srollToElement(index : number) {
    const offset = 100; 
    const element = sectionRefs.current[index];
    if (element) {
      const offsetPosition = element.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  const { data: jobData, isLoading: jobDataLoading } = useAuthQueryRequest(
    'job',
    {
      getJob: [{ where: { id: jobId as string } }, jobSelector]
    },
    {
      enabled: jobId !== undefined
    }
  )

  const createdAt = useDateTime(jobData?.getJob.createdAt as Date)
  const updatedAt = useDateTime(jobData?.getJob.updatedAt as Date)

  const createOrUpdateTextBlockAsset = async (
    textBlockId: string,
    textBlockAssetOrig?: Array<TextBlockAsset> | undefined,
    textBlockAsset?: Array<TextBlockAsset> | undefined
  ) => {
    textBlockAsset?.forEach(async (textBlockAsset) => {
      if (
        !textBlockAssetOrig?.length ||
        !textBlockAssetOrig?.find(({ asset }) => asset.id === textBlockAsset.asset.id)
      ) {
        await textBlockAssetMutation.mutateAsync({
          textBlockId,
          values: textBlockAsset
        })
      }
    })

    textBlockAssetOrig?.forEach(async ({ asset: { id: assetId } }: TextBlockAsset) => {
      if (!textBlockAsset?.length || !textBlockAsset?.find(({ asset }) => asset.id === assetId)) {
        await deleteAssetMutation.mutateAsync(assetId)
      }
    })
  }

  const jobMutation = useMutation(async (values: JobFieldsUpdate) => {
    const jobFields: JobFieldsFull | undefined = jobData?.getJob

    if (!jobFields) {
      return
    }

    if (jobFields.coverImage && !values?.coverImage) {
      await deleteAssetMutation.mutateAsync(jobFields.coverImage.id)
    }

    const textBlockAssetsOrig = jobFields?.benefit?.textBlockAsset
    const textBlockAssets = values?.benefit?.textBlockAsset

    const benefitId = (await textBlockMutation.mutateAsync({ values: values.benefit })).id

    await createOrUpdateTextBlockAsset(benefitId, textBlockAssetsOrig, textBlockAssets as TextBlockAsset[])

    const hasDiff = Object.values(diff(jobFields, values)).filter((v) => v).length > 0

    if (!hasDiff) {
      return jobFields
    }

    const { id, salary, location, requirement, sectors, specializations, benefit, coverImage, companyId, ...input } = values

    const salaryId = (await salaryMutation.mutateAsync(salary)).id
    const locationId = (await jobLocationMutation.mutateAsync(location)).id
    const requirementId = (await textBlockMutation.mutateAsync({ values: requirement })).id

    const data = await authMutation({
      updateJob: [
        {
          id,
          input: {
            ...input,

            ...(salaryId && jobData?.getJob?.salary?.id !== salaryId
              ? {
                salary: {
                  connect: {
                    id: salaryId
                  }
                }
              }
              : {}),

            ...(companyId
              ? {
                company: {
                  connect: {
                    id: companyId
                  }
                }
              }
              : {}),

            ...(coverImage
              ? {
                coverImage: {
                  connect: {
                    id: coverImage.id
                  }
                }
              }
              : {}),

            ...(locationId && jobData?.getJob?.location?.id !== locationId
              ? {
                location: {
                  connect: {
                    id: locationId
                  }
                }
              }
              : {}),

            ...(requirementId && jobData?.getJob?.requirement?.id !== requirementId
              ? {
                requirement: {
                  connect: {
                    id: requirementId
                  }
                }
              }
              : {}),

            ...(benefitId && jobData?.getJob?.benefit?.id !== benefitId
              ? {
                benefit: {
                  connect: {
                    id: benefitId
                  }
                }
              }
              : {}),

            ...(sectors
              ? {
                sectors: {
                  set: sectors.map(({ id }) => ({ id }))
                }
              }
              : {}),

            ...(specializations
              ? {
                specializations: {
                  set: specializations.map(({ id }) => ({ id }))
                }
              }
              : {})
          }
        },
        jobSelector
      ]
    })

    queryClient.setQueryData('job', {
      getJob: data.updateJob
    })

    return data.updateJob
  })

  const saveJob = async (values: JobFieldsUpdate) => {
    await jobMutation.mutateAsync(values)
  }

  if (jobDataLoading || !jobData?.getJob.id) {
    return <CircularProgress />
  }

  const onPublish = async () => {
    if (jobData?.getJob.id) {
      try {
        const { status, slug } = await publishMutation.mutateAsync(jobData.getJob.id)

        queryClient.setQueryData('job', {
          getJob: {
            ...queryClient.getQueryData<{ getJob: JobFieldsFull }>('job')?.getJob,
            status
          }
        })

        if (slug) {
          openJobPage(slug)
        }
      } catch (e: any) {
        const errors = e.response?.errors[0]

        setPusblishErrors([{ field: 'All', errors: ['All fields are required'] }])

        /*  if (e.response.errors[0].message === 'VALIDATION_ERROR') {
           setPusblishErrors(errors.extensions.errors);
         } */
      }
    }
  }

  const getPublishError = (error: ValidationError) => {
    return error
    /*
    let field = t(`companyForm.${(error.field)}`);
    let errors = 'Field is required';

    if (error.field.startsWith('offices')) {
      field = t('companyForm.offices');
      errors = 'Country is required'
    }

    if (error.field === 'cover') {
      errors = 'Cover image or Cover video should be filled in.'
    }

    return { field, errors } */
  }

  return (
    <TempWrapperNew>
        <Content sx={contentWrapper}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              id: jobData.getJob.id,
              title: jobData.getJob.title || '',
              description: jobData.getJob.description || '',
              shortDescription: jobData.getJob.shortDescription || '',
              experience: jobData.getJob.experience || ApiTypes.Experience.ENTRY,
              salary: jobData.getJob.salary || {
                from: '',
                to: '',
                currency: defaultCurrency,
                schedule: ApiTypes.SalaryPeriod.MONTH
              },
              companyId: jobData.getJob.companyId || '',
              salaryType: jobData.getJob.salaryType || ApiTypes.JobSalaryType.CONTRACT,
              location: {
                id: jobData.getJob.location?.id,
                type: jobData.getJob.location?.type || ApiTypes.JobLocationType.OFFICE,
                wfhDays: jobData.getJob.location?.wfhDays || 0,
                offices: jobData.getJob.location?.offices || []
              },
              requirement: {
                id: jobData.getJob.requirement?.id,
                description: jobData.getJob.requirement?.description || ''
              },
              benefit: {
                id: jobData.getJob.benefit?.id,
                description: jobData.getJob.benefit?.description || '',
                textBlockAsset: jobData.getJob.benefit?.textBlockAsset || []
              },
              sectors: jobData.getJob.sectors || [],
              specializations: jobData.getJob.specializations || [],
              coverImage: jobData.getJob.coverImage
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const newValues = values as JobFieldsUpdate

              await saveJob(newValues)
              setSubmitting(false)
            }}
          >
            {({
              dirty,
              values: {
                coverImage,
                benefit: { textBlockAsset }
              },
              setFieldValue
            }) => {
              return (
                <Form>
                  <>
                  <AddEditJobHeader
                    title={'Add Job'}
                    touched={dirty}
                    jobId={jobData.getJob.id}
                    onPublish={onPublish}
                    isJobActive={jobData.getJob.status === ApiTypes.JobStatus.ACTIVE}
                  />
                  <Box sx={tabWrapper}>
                    { !matchesSm &&
                      <SidebarInternalJob handleSideDropDown={handleSideDropDown} currentMenuIndex={currentMenuIndex} />
                    }
                    <Box sx={tabContentBox}>
                      {matchesSm &&
                        <JobCardHeaderScrollable
                          touched={dirty}
                          jobId={jobData.getJob.id}
                          isJobActive={jobData.getJob.status === ApiTypes.JobStatus.ACTIVE}
                          onPublish={onPublish}
                        />
                      }
                      <Snackbar
                        autoHideDuration={10000}
                        open={publishErrors.length > 0}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        onClose={() => {
                          setPusblishErrors([])
                        }}
                      >
                        <Stack sx={{ width: '100%', flexDirection: 'column', gap: 2 }} spacing={2}>
                          {publishErrors.map((error, index) => (
                            <Alert key={index} severity="error">
                              <AlertTitle>{getPublishError(error).field as string}</AlertTitle>
                              {getPublishError(error).errors}
                            </Alert>
                          ))}
                        </Stack>
                      </Snackbar>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[0] = ref as HTMLDivElement)}>
                        <JobBasicInfo
                          coverImage={coverImage}
                          onCoverImageUploaded={(file) => {
                            setFieldValue('coverImage', file)
                          }}
                          onCoverImageDeleted={(file) => {
                            setFieldValue('coverImage', null)
                          }}
                        />
                        <FooterButtonClient srollToElement={() => handleSideDropDown(1)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[1] = ref as HTMLDivElement)}>
                        <JobSalaryInfo />
                        <FooterButtonClient srollToElement={() => handleSideDropDown(2)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[2] = ref as HTMLDivElement)}>
                        <JobLocationSettings companyId={jobData.getJob.companyId} />
                        <FooterButtonClient srollToElement={() => handleSideDropDown(3)}/>
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[3] = ref as HTMLDivElement)}>
                        <Box sx={jobRequirementStyle}>
                          <JobRequirements />
                        </Box>
                        <FooterButtonClient srollToElement={() => handleSideDropDown(4)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[4] = ref as HTMLDivElement)}>
                        <Box sx={jobRequirementStyle}>
                          <JobBenefits />
                        </Box>
                        <FooterButtonClient isSave={true} srollToElement={() => handleSideDropDown(0)} />
                      </Card>

                    </Box>
                    </Box>
                  </>
                </Form>
              )
            }}
          </Formik>
        </Content>
    </TempWrapperNew>
  )
}

const validationSchema = Yup.object().shape({
  title: TextValidation.required(),
  shortDescription: ShortTextareaValidation.nullable(),
  description: TextareaValidation.nullable(),
  experience: Yup.string().nullable(),
  salaryType: Yup.string().nullable(),
  salary: Yup.object()
    .nullable()
    .shape({
      from: Yup.number().max(9999999999).nullable(),
      to: Yup.number().max(9999999999).nullable(),
      currency: Yup.object().nullable(),
      schedule: Yup.string().nullable()
    }),
  location: Yup.object().nullable().shape({
    type: Yup.string().nullable(),
    wfhDays: Yup.number().nullable(),
    offices: Yup.array()
  }),
  company: Yup.object().shape({
    id: Yup.string().nullable()
  }),
  requirement: Yup.object().nullable().shape({
    description: TextareaValidation.nullable()
  }),
  benefit: Yup.object().nullable().shape({
    description: TextareaValidation.nullable()
  })
})


const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  paddingTop: 0,
  [theme.breakpoints.down('md')]: {
    order: 2,
    gap: theme.spacing(2),
    paddingTop: '0!important'
  }
})

const jobRequirementStyle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    '& .textEditor': {
      minHeight: '40px',
      maxHeight: '250px',
      height: '100%'
    },
    '& .textEditorField': {
      minHeight: '40px',
      maxHeight: '250px',
      height: '100%'
    }
  }
})

const tabWrapper = (theme: Theme) => ({
  display: 'flex', 
  alignItems: 'start',
  height: '100%',
  flexDriection: 'row',
  gap: '24px',
  width: '100%',
})

const tabContentBox = (theme: Theme) => ({
  width: '82%',
  height: '100%',
  position: 'relative',
  '& > .MuiCard-root': {
    paddingBottom: theme.spacing(18),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& > .MuiCard-root': {
      paddingBottom: theme.spacing(3),
    }
  },
})

const cardWraper = (theme: Theme) => ({
  marginBottom: 6,
  paddingBottom: '32px !important',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 3,
  }
})

export default AddJobPage
