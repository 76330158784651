import React, { useEffect, useState, useRef } from 'react'
import { Box, Theme } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '../../ui-kits/button/button'
import { Asset } from '../../Uploader/Uploader'
import { FileGallery } from '../../FileGallery'
import { LoaderSpinner } from '../../LoaderSpinner'


interface Ref {
  current: object
  offsetWidth: number
}

export interface ProgressFile {
  id: string,
  isLoading: boolean
}

interface Props {
  assets?: Asset[],
  progresses: boolean,
  setAssets: any
}

export const UploadSlider = ({ assets, progresses, setAssets }: Props) => {
  const handleDeleteAsset = (id: string) => {
    setAssets((prev: []) => prev.filter((el: Asset) => el.id !== id))
  }
  const [isShowButtons, setIsShowButtons] = useState(false)
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [rightArrowDisable, setRightArrowDisable] = useState(false)
  const [leftArrowDisable, setLeftArrowDisable] = useState(false)
  const handleHorizantalScroll = ( element : any , speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    }, speed)
  }

  useEffect(() => {
    if (elementRef) {
      setIsShowButtons(elementRef?.current?.scrollWidth !== elementRef?.current?.offsetWidth)
    }
  }, [elementRef?.current?.scrollWidth])

  const handleScroll = () => {
    if (elementRef?.current?.scrollWidth && elementRef?.current?.offsetWidth)
    setRightArrowDisable( elementRef?.current?.scrollWidth - elementRef?.current?.offsetWidth !== Math.floor(elementRef?.current?.scrollLeft))
    setLeftArrowDisable( elementRef?.current?.scrollLeft !== 0)
  }

  useEffect(() => {
    handleScroll()
  },[assets?.length])

  return (
    <Box
      sx={sliderWrapper}
    >
        {isShowButtons && leftArrowDisable &&
          <Button sx={customBtnBack}
                  size={'small'}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, -10)
                  }}
          >
            <ArrowBackIcon />
          </Button>
        }
        <Box sx={uploaderScroll}
             ref={elementRef}
             onScroll={handleScroll}
        >
          {assets &&
            <FileGallery
              onClick={handleDeleteAsset}
              variant={'uploaded-slider'}
              assets={assets}
            />
          }
          {
            progresses &&
            <Box sx={loaderWrapper}>
              <LoaderSpinner />
            </Box>
          }
        </Box>
        {isShowButtons && rightArrowDisable &&
          <Button sx={customBtnForwad}
                  size={'small'}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, 10);
                  }}
          >
            <ArrowForwardIcon />
          </Button>
        }
    </Box>
  )
}

const loaderWrapper = () => ({
  width: '60px',
  height: '60px',
  backgroundColor: 'rgb(226 224 224 / 80%)',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& div': {
    mb: 0
  }
})

const uploaderScroll = (theme: Theme) => ({
  overflowX: 'auto',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]:{
    '&::-webkit-scrollbar': {
      height: '0'
    }
  }
})

const sliderWrapper = (theme: Theme) => ({
    paddingY: '10px',
    position: 'relative',
    // paddingX: '15px',
    boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]:{
      paddingX: 'unset',
    marginRight: '-24px'
  }
  })

const customBtnBack = (theme: Theme) => ({
  padding: '5px 8px',
  maxWidth: '38px',
  minWidth: 'unset',
  position: 'absolute',
  left: '-18px',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: '#fff',
  zIndex: 1,
  '& path':{
    fill: '#0F64E2!important'
  },
  '&:hover': {
    backgroundColor: '#fff'
  },
  [theme.breakpoints.down('sm')]:{
    display: 'none'
  }
})

const customBtnForwad = (theme: Theme) => ({
  padding: '5px 8px',
  maxWidth: '38px',
  minWidth: 'unset',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '-18px',
  backgroundColor: '#fff',
  zIndex: 1,
  '& path':{
    fill: '#0F64E2!important'
  },
  '&:hover': {
    backgroundColor: '#fff'
  },
  [theme.breakpoints.down('sm')]:{
    display: 'none'
  }
})
