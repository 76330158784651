import React from 'react'
import { Box, CircularProgress, Theme } from '@mui/material'

export const LoaderSpinner = () => {
  return (
    <Box sx={circularStyle}>
      <CircularProgress />
    </Box>
  )
}

const circularStyle = (theme: Theme) => ({
  mb: theme.spacing(2.5),
  textAlign: 'center',
})