import React from 'react'
import { SvgProps } from '../types/icon'

const Edit = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 14.5482V17.0815C2.5 17.3148 2.68333 17.4982 2.91667 17.4982H5.45C5.55833 17.4982 5.66667 17.4565 5.74167 17.3732L14.8417 8.28151L11.7167 5.15651L2.625 14.2482C2.54167 14.3315 2.5 14.4315 2.5 14.5482ZM17.2583 5.86484C17.5833 5.53984 17.5833 5.01484 17.2583 4.68984L15.3083 2.73984C14.9833 2.41484 14.4583 2.41484 14.1333 2.73984L12.6083 4.26484L15.7333 7.38984L17.2583 5.86484Z" fill="black" fillOpacity="0.54" />
    </svg>

  )
}

export default Edit