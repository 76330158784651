import { AutocompleteRenderInputParams, Box, CircularProgress, FilterOptionsState, InputAdornment, TextField, Theme } from '@mui/material';
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs';
import { useQueryRequest } from '../../services/api';
import { SectorFields, sectorSelector } from './SectorAutocomplete';
import { Specialization } from '../icons/specialization';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const specializationSelector = ApiSelector('Specialization')({
  id: true,
  name: true,
  sector: sectorSelector
})

export type SpecializationFields = ApiInputType<ApiGraphQLTypes['Specialization'], typeof specializationSelector>

export const SpecializationAutocomplete = () => {
  const [sectorField] = useField<SectorFields[]>('sectors');
  const [specializationField, specializationMeta] = useField<SpecializationFields[]>('specializations');
  const { t } = useTranslation();
  const [loadSpecializations, setLoadSpecializations] = useState(false);
  const sectorIds = useMemo(() => sectorField.value.map(({ id }) => id), [sectorField.value.map(({id})=> id)])

  const { data: specializationsData, isLoading: specializationsDataLoading, refetch } = useQueryRequest(
    ['specializations', sectorIds],
    {
      getSpecializations: [
        {
          take: 100,
          ...(sectorIds.length ? {
            where: {
              sectorId: {
                in: sectorIds
              },
            }
          } : {})

        },
        specializationSelector
      ]
    }, {
    enabled: loadSpecializations
  });

  const specializationsOptions = useMemo(() => {
    if (specializationsData?.getSpecializations.length) {
      return specializationsData?.getSpecializations.filter((option, index, self) =>
          index === self.findIndex((item) => (
            item.name === option.name
          ))
      )
    }
  }, [specializationsData?.getSpecializations.length,
    specializationsData?.getSpecializations.map(({id}) => id),
    sectorIds.length,sectorIds.map(id => id)])

  return (
    <Field
      popupIcon={<KeyboardArrowDownIcon />}
      loading={specializationsDataLoading}
      name={specializationField.name}
      onOpen={() => setLoadSpecializations(true)}
      multiple
      component={Autocomplete}
      limitTags={1}
      sx={autocompleteField}
      noOptionsText={t('startTyping')}
      /* componentsProps={{
        paper: {
          sx: {
            width: 'auto !important'
          }
        }
      }} */
      options={specializationsOptions || []}
      filterOptions={(options: SpecializationFields[], state: FilterOptionsState<SpecializationFields>) => {
        const sectorsIds = sectorField.value.map(({ id }) => id);

          return options
            .filter((specialization) => {
              return specialization.name.toLowerCase().includes(state.inputValue.toLowerCase())
            });

      }}
      autoComplete
      isOptionEqualToValue={(option: SpecializationFields, value: SpecializationFields) => option.id === value.id}
      getOptionLabel={(option: SpecializationFields) => `${option.name}`}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: SpecializationFields) => (
        <Box component={'li'} {...props} key={option.id} sx={{ whiteSpace: 'nowrap' }}>
          {option.name}
          {' '}
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={params?.InputProps?.startAdornment ?
            ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span') ?
              locationStyle
              :
              { height: '100%' }
            )
            : {}}
          error={specializationMeta.touched && !!specializationMeta.error}
          placeholder={!params.InputProps.startAdornment ? t('formFields.specializationsTitle') : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Specialization />
                </InputAdornment>
                <Box className={'selected-chips'}
                  sx={
                    params?.InputProps?.startAdornment ?
                      ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span')
                        || (params?.InputProps?.startAdornment as ReactElement[]).length < 2 ?
                        dropOpenedStyle
                        :
                        dropStyle
                      )
                      : {}
                  }
                >
                  {params.InputProps.startAdornment}
                </Box>
              </>
            ),
            endAdornment: (
              <>
                {specializationsDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export const autocompleteField = (theme: Theme) => ({
  height: '53px',
  width: '100%',
  '&.Mui-focused': {
    height: '100%!important'
  }
})

const locationStyle = (theme: Theme) => ({
  height: '53px',
})

const dropOpenedStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  '& input:':{
    display: 'none!important'
  },
  [theme.breakpoints.down('lg')]:{
    width: 'unset',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})

const dropStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  '& + .MuiOutlinedInput-input':{
    display: 'block',
    width: '100%!important',
  },
  [theme.breakpoints.down('lg')]:{
    width: '100%',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})
