import { Box, MenuItem, Select, Theme, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface SelectOption {
  value: string | number
  label: string
}

interface Props {
  title: string
  selectOptions: Array<SelectOption>
}

export const SortBlock = ({ title, selectOptions }: Props) => {
  return (
    <Box sx={headStyle}>
      <Typography variant={'h4'}>{title}</Typography>
      {selectOptions.length > 0 && (
        <Box sx={sortBlockStyle}>
          <Typography variant={'body2'} color={(theme: Theme) => theme.palette.text.secondary} sx={sortText}>
            Sort by:
          </Typography>
          <Select
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            defaultValue={selectOptions[0].value}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={sortSelectStyle}
          >
            {selectOptions.map((item) => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      )}
    </Box>
  )
}

const sortText = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})

const sortSelectStyle = (theme: Theme) => ({
  textAlign: 'start',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
})

const sortBlockStyle = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4),
  },
})

const headStyle = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
})
