import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, Snackbar, Stack, Theme, Typography } from '@mui/material'
import { Field, useField } from 'formik'
import { TextField } from 'formik-mui'
import { ApiTypes } from 'recruticka-frontend-libs'
import { CompanyRatingsField } from '../../../hooks/useCompany'
import { useTranslation } from 'react-i18next'

interface Props {
  idx: number
  rating: {
    type: ApiTypes.CompanyRatingType
  } | CompanyRatingsField
}


export const CompanyRatingField = ({idx, rating}: Props) => {
  const [companyRatings, ,companyRatingsHelper] = useField('companyRatingsFields')
  const { t } = useTranslation()

  return (
    <Box sx={contentWrapper} key={idx}>
      {/* <Typography variant={'h2'} sx={ratingTitle}>{rating.type.toString()}</Typography> */}
      <Field
        component={TextField}
        name={`companyRatingsFields.${idx}.url`}
        label={t(rating.type === ApiTypes.CompanyRatingType.GLASSDOOR ?
          'formFields.glassdoor' : 'formFields.trustpilot')}
        fullWidth
      />
    </Box>
  )
}

const contentWrapper = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.contrastText,
  // padding: 3,
  borderRadius: 1,
  display: 'grid',
  // gap: .5,
  [theme.breakpoints.down('sm')]:{
    gap: 2,
    // padding: '16px 0'
  }
})

const ratingTitle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]:{
    fontSize: '16px'
  }
})
