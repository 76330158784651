import { useRecoilValue, useSetRecoilState } from 'recoil'
import { chatWindowState } from '../../state/atoms/chatState'
import { ChatBox, ChatVariant } from './chat-box' 
import { useConversationById } from '../../hooks/useConversations'
import { profileState } from '../../state/atoms/profileState'
import { RefObject, useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'

function useOutsideAlerter(ref: RefObject<HTMLDivElement> | null, closeHandler: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!ref?.current?.contains(event.target)) {
        closeHandler()
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref])
}

export const ChatWindow = () => {
  const profile = useRecoilValue(profileState)
  const chatState = useRecoilValue(chatWindowState)
  const setChatState = useSetRecoilState(chatWindowState)
  const {data} = useConversationById(profile.id, chatState.conversationId as string)
  const chatRef = useRef(null)
  const conversation = useMemo(() => {
    if (chatState.conversationId && data?.getProfileConversation){
      return data?.getProfileConversation
    }
  },[data])

  useOutsideAlerter(chatRef, () => setChatState({
    isOpened: true,
  }))
  return (
    <>
      {chatState.isOpened && chatState.conversationId &&
        <Box ref={chatRef}>
          <ChatBox variant={ChatVariant.CHAT_WIDGET} conversations={conversation} />
        </Box>
      }
    </>
  )
}