import { Box, Step, StepLabel, Stepper, Theme, Typography } from '@mui/material'

interface Props {
  currentStep?: number
  activeStep?: string
  steps: string[]
}

export const SignStepper = ({ currentStep = 0, steps = [] }: Props) => {

  return (
    <Box sx={wrapper}>
      <Stepper activeStep={currentStep} alternativeLabel connector={null} sx={stepperStyle}>
        {steps.map((item, index) => (
          <Step className="step" key={item}>
            <Box sx={stepBox}>
              <StepLabel />
              <Typography variant={'body1'} sx={{ fontWeight: 500, lineHeight: 1, }}>
                  Step
              </Typography>
              <Typography variant={'overline'} sx={{ fontWeight: 400, }}>
                {Math.round((index / (steps.length - 1)) * 100 )} %
              </Typography>
            </Box>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

const stepperStyle = (theme: Theme) => ({
  // maxWidth: '544px',
  // width: '100%',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&': {
    position: 'relative',
    '&::before' : {
      content: '""',
      backgroundColor: 'rgb(0 0 0 / 12%)',
      position: 'absolute',
      width: '90%',
      left: '0',
      right: '0',
      top: '40%',
      height: '1px',
      opacity: '1',
      visibility: 'visible',
      margin: '0 auto',
    },
  },
  '& .step' : {
    textAlign:'center',
    '&:first-of-type': {
      textAlign: 'left',
    },
    '&:last-of-type': {
      textAlign: 'right',
    },
    '& .MuiStepLabel-root' : {
      mb: theme.spacing(2)
    }

  },
  [theme.breakpoints.down(1220)]:{
    maxWidth: 'unset',
    overflowX: 'auto',
    '&::-webkit-scrollbar':{
      height: 0
    }
  },
  [theme.breakpoints.down('sm')]:{
    marginRight: '-24px',
  }
})


const stepBox = (theme: Theme) => ({
  display: 'inline-block',
  backgroundColor: '#F2F5F8',
  minWidth: '64px',
  textAlign: 'center',
})

const wrapper = (theme: Theme) => ({
  width: '100%',
  marginBottom: '20px'
})
