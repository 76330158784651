import { Box, Theme } from '@mui/material'
import { FieldArray, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextEditorField } from '../../form-fields/TextEditorField'
import Uploader, { Asset } from '../../Uploader/Uploader'
import { IMAGES, DOCUMENTS } from '../../../config/uploaderTypes'

export const CultureOfBusiness = () => {
  const { t } = useTranslation()
  const [filesField] = useField<Asset[]>('cultureOfBusinessFiles')

  return (
    <Box sx={wrapper}>
      <FieldArray
        name={filesField.name}
        render={(arrayHelpers) => (
          <>
            <Uploader
              assets={filesField.value}
              gridView
              allowedFileTypes={[...IMAGES, ...DOCUMENTS]}
              multiple
              onFileUploaded={(asset) => arrayHelpers.push(asset)}
              onFileDeleted={({ id }) => {
                const index = filesField.value.findIndex((asset) => asset.id === id)

                arrayHelpers.remove(index)
              }}
            />
          </>
        )}
      />

      <TextEditorField
        areaSx={{ height: 'auto' }}
        name={'cultureOfBusiness'}
        placeholder={t('formFields.companyCultureOfBusinessTitleTitle')}
      />
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})
