import { Box, IconButton, Typography } from '@mui/material'
import { InfoWindow, MarkerF as Marker, MarkerProps } from '@react-google-maps/api'
import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

import { PlaceMarkerProps, PlaceType } from './types'
import { markerSX } from './styles'
import { getGeocode } from 'use-places-autocomplete'
import { converLatLng } from './converLatLng'

export const PlaceMarker = (props: PlaceMarkerProps) => {
  const { place, onChangeMarkerPlace, onDeleteMarker } = props
  const { address, lat, long } = place
  const position = converLatLng({ lat, long })
  const [isShowContent, setIsShowContent] = useState(false)

  const handleToggleContent = () => setIsShowContent(!isShowContent)

  const handleDragEnd: MarkerProps['onDragEnd'] = async ({ latLng }) => {
    try {
      const results = await getGeocode({ location: latLng })
      onChangeMarkerPlace({
        address: results[0]['formatted_address'],
        lat: latLng?.lat(),
        long: latLng?.lng(),
      } as PlaceType)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Marker
      visible={!isShowContent}
      position={position}
      draggable
      onClick={handleToggleContent}
      onDragEnd={handleDragEnd}
    >
      <>
        {isShowContent && (
          <InfoWindow position={position} onCloseClick={handleToggleContent}>
            <Box sx={markerSX}>
              <IconButton onClick={onDeleteMarker}>
                <DeleteIcon />
              </IconButton>
              <Typography title={address}>{address}</Typography>
            </Box>
          </InfoWindow>
        )}
      </>
    </Marker>
  )
}
