import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { authMutation } from '../services/api'

const educationSelector = ApiSelector('Education')({
  id: true,
  grade: true,
  school: true,
  current: true,
  startAt: true,
  endAt: true,
  description: true,
  title: true,
})

type EducationFields = ApiInputType<ApiGraphQLTypes['Education'], typeof educationSelector>
type CreateOrUpdateEducationFields = SetOptional<EducationFields, 'id' | 'endAt'>

export const useCreateOrUpdateEducation = (selector: Partial<typeof educationSelector>) => {
  return useMutation(async ({ employeeId, values }: { employeeId: string; values: CreateOrUpdateEducationFields }) => {
    const { id, ...input } = values

    if (id) {
      const data = await authMutation({
        updateEducation: [
          {
            id,
            input,
          },
          selector,
        ],
      })

      return data.updateEducation
    }

    const data = await authMutation({
      createEducation: [
        {
          input: {
            ...input,
            employee: {
              connect: {
                id: employeeId,
              },
            },
          },
        },
        selector,
      ],
    })

    return data.createEducation
  })
}

export const useDeleteEducation = () => {
  return useMutation(async (id: string): Promise<boolean> => {
    await authMutation({
      deleteEducation: [{ id }, { id: true }],
    })

    return true
  })
}
