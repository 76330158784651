import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { Box, Theme, Typography } from '@mui/material'
import { Salary } from '../../form-fields/Salary'
import { ApiSelector, ApiInputType, ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { SetOptional } from 'type-fest'
import { useCreateOrUpdateSalary } from '../../../hooks/useCreateOrUpdateSalary'
import { authMutation } from '../../../services/api'
import { SetNullable } from '../../../interfaces/nullable'
import { LoadingButton } from '@mui/lab'
import { buttonStyle } from '../steps/position-information'
import { titleTextStyle } from './amount-people'

export const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

export type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

interface Props extends Partial<SalaryFields> {
  companyId: string
  isSaving: boolean
  onSave: (values: SalaryFields) => void
}

export const BudgetInformation = ({ companyId, isSaving, onSave, currency, from, to, id, schedule }: Props) => {
  const { t } = useTranslation()
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const initialValues: SetOptional<SetNullable<SalaryFields, 'currency'>, 'id' | 'currency'> = {
    id,
    from: from || 0,
    to: to || 0,
    schedule: schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: currency || null,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const data = await createOrUpdateSalaryMutation.mutateAsync(values as SalaryFields)

        await authMutation({
          updateCompany: [
            {
              id: companyId,
              input: {
                basicSalary: {
                  connect: {
                    id: data.id,
                  },
                },
              },
            },
            {
              id: true,
            },
          ],
        })

        onSave(data)
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Box
              sx={wrapper}
            >
              <Typography sx={titleTextStyle}>{t('formFields.companyBasicSalaryTitle')}</Typography>

              <Salary signUp/>
            </Box>
            <LoadingButton
              variant='contained'
              loading={createOrUpdateSalaryMutation.isLoading}
              sx={buttonStyle}
              type='submit'
              size={'medium'}
              disabled={Object.keys(errors).length > 0}
            >
              {t('save')}
            </LoadingButton>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  from: Yup.number().required(),
  to: Yup.number().required(),
  schedule: Yup.string().required(),
  currency: Yup.object().nullable().required(),
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  mb: '72px',
  [theme.breakpoints.down('sm')]:{
    '& .slider':{
      width: '80%'
    },
    '& .MuiInputBase-root':{
      height: '48px!important'
    }
  }
})
