import { Box, Card, Grid, Theme, Typography, Divider } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Uploader, { Asset } from '../Uploader/Uploader'
import { useField } from 'formik'
import { IMAGES, VIDEO } from '../../config/uploaderTypes'

interface Props {
  title: string
  subTitle: string
  note?: string
  coverError: string | undefined
}

export const CompanyProfileCoverImage = ({ title, subTitle, note, coverError }: Props) => {
  const [coverImageField, , coverImageHelpers] = useField<Asset | null>('coverImage')
  const [coverVideoField, , coverVideoHelpers] = useField<Asset | null>('coverVideo')

  return (
    <Box sx={wrapper}>
      <Typography variant={'h2'} sx={(theme) => ({ 
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1.5),
        },
        })}>{title}</Typography>
      <Divider />
      <Typography variant={'subtitle1'} sx={subtitleStyle}>
        {subTitle}
      </Typography>
      {note && (
        <Box sx={noteWrapper}>
          <InfoOutlinedIcon color={'primary'} />
          <Typography variant={'body2'}>NOTE: {note}</Typography>
        </Box>
      )}
      <Grid container spacing={3} sx={contentWrapper}>
        <Grid sm={12} xs={12} item
          sx={
            coverError ? {
              '& .uppy-Wrapper': {
                borderColor: '#D32F2F'
              }
            } : {}}
          >
          <Uploader
            label={'Cover Image'}
            assets={coverImageField.value}
            fullPreview={true}
            allowedFileTypes={IMAGES}
            onFileUploaded={(asset) => {
              coverImageHelpers.setValue(asset)
            }}
            onFileDeleted={(asset) => {
              coverImageHelpers.setValue(null)
            }}
          />
        </Grid>
        <Grid sm={12} xs={12} item
          sx={
            coverError ? {
              '& .uppy-Wrapper': {
                borderColor: '#D32F2F'
              }
            } : {}}
          >
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Uploader
            label={'Video Cover'}
            assets={coverVideoField.value}
            fullPreview
            allowedFileTypes={VIDEO}
            onFileUploaded={(asset) => {
              coverVideoHelpers.setValue(asset)
            }}
            onFileDeleted={() => {
              coverVideoHelpers.setValue(null)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
})

const noteWrapper = (theme: Theme) => ({
  marginTop: theme.spacing(1.5),
  display: 'flex',
  padding: '14px 18px',
  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
  borderRadius: '6px',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})

const subtitleStyle = (theme: Theme) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})

const contentWrapper = (theme: Theme) => ({
  paddingTop: theme.spacing(3),
})
