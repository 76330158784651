import { Field, Form, Formik } from 'formik'
import Yup from '../../../validation'
import { Box, Card, Grid, Theme, Typography, MenuItem, FormControl, useMediaQuery } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { TextField, Select } from 'formik-mui'
import { LoadingButton } from '@mui/lab'
import Uploader, { assetSelector } from '../../Uploader/Uploader'
import { CityAutocomplete, citySelector } from '../../form-fields/CityAutocomplete'
import theme from '../../../styles/theme'
import { IMAGES } from '../../../config/uploaderTypes'

const employeeSelector = ApiSelector('Employee')({
  firstname: true,
  lastname: true,
  locationType: true,
  experience: true,
  currentCity: citySelector,
  avatar: assetSelector,
  coverImage: assetSelector,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

type EmployeeHeaderInformationFields = EmployeeFields

interface EmployeeHeaderSaveData extends EmployeeFields {
  linkedin?: string
}

export interface EmployeeHeaderInformationProps extends EmployeeHeaderInformationFields {
  linkedin?: string
  isSaves?: boolean
  onSave: (values: EmployeeHeaderSaveData) => void
  onClose: () => void
}

export const EmployeeHeaderInformation = ({
  linkedin,
  onSave,
  onClose,
  isSaves,
  ...values
}: EmployeeHeaderInformationProps) => {
  const { t } = useTranslation()
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))

  const initialValues = {
    ...values,
    linkedin: linkedin || '',
    experience: values.experience || ApiTypes.Experience.ENTRY,
    locationType: values.locationType || undefined,
  }

  return (
    <Formik enableReinitialize validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSave}>
      {({ setFieldValue, errors, values, isSubmitting }) => (
        <Form>
          <Card
            sx={[profileBox, {
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
            }]}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography textAlign={'center'} variant={'h2'}>
                Header information
              </Typography>
              <Box onClick={onClose}>
                <CloseRoundedIcon sx={iconStyle} />
              </Box>
            </Box>
            <Grid container spacing={2.5}>
              <Grid item xs={smWidth? 12 : 4}>
                <Uploader
                  label={'Avatar'}
                  assets={values.avatar}
                  fullPreview={true}
                  allowedFileTypes={IMAGES}
                  onFileUploaded={(file) => {
                    setFieldValue('avatar', file)
                  }}
                  onFileDeleted={() => {
                    setFieldValue('avatar', null)
                  }}
                />
              </Grid>
              <Grid item xs={smWidth? 12 : 8}>
                <Uploader
                  label={'Cover Image'}
                  assets={values.coverImage}
                  fullPreview={true}
                  allowedFileTypes={IMAGES}
                  onFileUploaded={(file) => {
                    setFieldValue('coverImage', file)
                  }}
                  onFileDeleted={() => {
                    setFieldValue('coverImage', null)
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2.5}>
              <Grid item xs={smWidth? 12 : 6}>
                <Field
                  component={TextField}
                  inputProps={{ maxLength: 190 }}
                  name='firstname'
                  placeholder={t('formFields.firstnamePlaceholder')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={smWidth? 12 : 6}>
                <Field
                  component={TextField}
                  inputProps={{ maxLength: 190 }}
                  name='lastname'
                  placeholder={t('formFields.lastnamePlaceholder')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  inputProps={{ maxLength: 190 }}
                  name='linkedin'
                  placeholder={'Link to LInkedIn profile'}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Field name={'experience'} component={Select} placeholder={t('formFields.experiencePlaceholder')} fullWidth>
              {Object.values(ApiTypes.Experience).map((value) => (
                <MenuItem value={value} key={value}>
                  {t(`enum.Experience.${value}`)}
                </MenuItem>
              ))}
            </Field>
            <Grid container spacing={3}>
              <Grid item xs={smWidth? 12 : 6}>
                <CityAutocomplete name='currentCity' label={t('formFields.locationPlaceholder')} />
              </Grid>
              <Grid item xs={smWidth? 12 : 6}>
                <FormControl sx={{ width: '100%' }}>
                  <Field
                    name={'locationType'}
                    component={Select}
                    displayEmpty
                    label={t('formFields.locationTypePlaceholder')}
                    style={{ width: '100%' }}
                  >
                    <MenuItem disabled value=''>
                      {t('formFields.locationTypePlaceholder')}
                    </MenuItem>
                    {Object.values(ApiTypes.JobLocationType).map((value) => (
                      <MenuItem sx={{ width: 400 }} value={value} key={value}>
                        {t(`enum.JobLocationType.${value}`)}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            </Grid>
            <LoadingButton
              loading={isSaves}
              sx={smWidth? { width: '100%' } : { mx: 'auto' }}
              type='submit'
              size={'medium'}
              variant='contained'
              disabled={Object.keys(errors).length > 0 || isSubmitting}
            >
              {t('save')}
            </LoadingButton>
          </Card>
        </Form>
      )}
    </Formik>
  )
}


const profileBox = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiGrid-container': {
      '& .MuiGrid-item': {
        width: '100%',
      }
    }
  }
})

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string(),
  experience: Yup.string().required().oneOf(Object.keys(ApiTypes.Experience)),
  linkedin: Yup.string().url().nullable(),
  currentCity: Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }),
  locationType: Yup.string().required().oneOf(Object.keys(ApiTypes.JobLocationType)),
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  zIndex: '100',
})
