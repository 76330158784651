import { Card, Divider, FormControl, FormControlLabel, Radio, Slider, Theme, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Field, useField, useFormikContext } from 'formik'
import { ApiTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { RadioGroup } from 'formik-mui'
import { JobLocationSelect } from '../form-fields/JobLocationSelect'
import { useEffect } from 'react'

type Props = {
  companyId: string
}

export const JobLocationSettings = ({ companyId }: Props) => {
  const { t } = useTranslation()
  const [fieldSalaryType] = useField<ApiTypes.JobLocationType>('location.type')
  const [fieldWfhDays, , helperWfhDays] = useField<number>('location.wfhDays')
  const { isSubmitting } = useFormikContext()

  useEffect(()=> {
    if (fieldWfhDays.value === 0){
      helperWfhDays.setValue(1)
    }
  },[fieldWfhDays.value])

  return (
    <Box sx={wrapper}>
      <Typography variant={'h2'}>Location Settings</Typography>
      <Divider sx={(theme) => ({
        marginBottom: theme.spacing(2)
      })} />
      <Box sx={locationWrapper}>
        <JobLocationSelect
          sx={inputStyle}
          name='location.offices'
          companyId={companyId}
          disabled={fieldSalaryType.value === ApiTypes.JobLocationType.REMOTE}
        />
        
        <Box sx={{ textAlign:'center', width:'100%', }}>
          <Divider sx={{ marginTop: '8px', marginBottom: '24px' }}/>
          <FormControl sx={checkboxesWrapper}>
            <Field component={RadioGroup} name={fieldSalaryType.name} sx={radioGroupWrapper}>
              <FormControlLabel
                value={ApiTypes.JobLocationType.OFFICE}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobLocationType.OFFICE')}
                disabled={isSubmitting}
              />
              <FormControlLabel
                value={ApiTypes.JobLocationType.REMOTE}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobLocationType.REMOTE')}
                disabled={isSubmitting}
              />
              <FormControlLabel
                value={ApiTypes.JobLocationType.WFH}
                control={<Radio disabled={isSubmitting} />}
                label={t('enum.JobLocationType.WFH')}
                disabled={isSubmitting}
              />
            </Field>
          </FormControl>
        </Box>
      </Box>
      {fieldSalaryType.value === ApiTypes.JobLocationType.WFH && (
        <Box sx={sliderWrapper}>
          <Divider sx={{ marginTop: '16px', marginBottom: '16px' }}/>
          <Typography variant={'subtitle2'}>Working from Home Policy (days per week)</Typography>
          <Slider
            aria-label='Always visible'
            value={fieldWfhDays.value || 1}
            step={1}
            max={5}
            min={1}
            valueLabelDisplay='on'
            marks={[
              {
                value: 1,
                label: '1',
              },
              {
                value: 5,
                label: '5',
              },
            ]}
            onChange={(event: Event, newValue: number | number[]) => {
              helperWfhDays.setValue(newValue as number)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  boxShadow: 'none',
})

const locationWrapper = (theme: Theme) =>({
  display: 'flex',
  gap: '24px',
  flexWrap: 'wrap',
  justifyContent: 'cenrter',
  alignItems: 'center',
  [theme.breakpoints.down('md')]:{
    flexDirection: 'column-reverse'
  }
})

const checkboxesWrapper = (theme: Theme) => ({
  // borderRadius: '6px',
  // backgroundColor: theme.palette.background.default,
  // paddingLeft: '16px',
  alignItems: 'center',
  justifyContent: 'cenrter',
  [theme.breakpoints.down('md')]:{
    backgroundColor: '#fff'
  }
})
const radioGroupWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]:{
    flexWrap: 'nowrap',
    '& label:nth-of-type(3)':{
      '& span:nth-of-type(2)':{
        height: '20px',
        overflow: 'hidden'
      }
    }
  }
})
const inputStyle = (theme: Theme) => ({
  maxWidth: '100%',
  width: '100%',
})

const sliderWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})
