import { InputAdornment, TextField, AutocompleteRenderInputParams } from '@mui/material';
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { ApiTypes } from 'recruticka-frontend-libs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ExperienceIcon } from '../icons/experienceIcon';
import { autocompleteField } from './SpecializationsAutocomplete'

export const ExperienceAutocomplete = () => {
  const { t } = useTranslation();
  const [field,] = useField('experience');

  return (
    <Field
      name={field.name}
      component={Autocomplete}
      sx={autocompleteField}
      popupIcon={<KeyboardArrowDownIcon />}
      options={Object.values(ApiTypes.Experience).map((value) => (
        t(`enum.Experience.${value}`)
      ))}
      fullWidth
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            placeholder={'Experience level'}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <ExperienceIcon />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    >

    </Field>
  )
}