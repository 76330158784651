import { SvgProps } from '../types/icon'

export const BenefitsIcon = ({ width = '18px', height = '18px', fill='rgb(0 0 0 / 54%)' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
     <path d='M2.49992 8.16699C1.58325 8.16699 0.833252 8.91699 0.833252 9.83366V15.667C0.833252 16.5837 1.58325 17.3337 2.49992 17.3337C3.41659 17.3337 4.16659 16.5837 4.16659 15.667V9.83366C4.16659 8.91699 3.41659 8.16699 2.49992 8.16699Z' fill={fill}/>
      <path d='M8.33325 3.41699C8.33325 1.87533 9.54159 0.666992 11.0833 0.666992C11.9499 0.666992 12.7916 1.07533 13.3333 1.70866C13.8749 1.07533 14.7166 0.666992 15.5833 0.666992C17.1249 0.666992 18.3333 1.87533 18.3333 3.41699C18.3333 5.16699 16.2499 7.17533 13.8916 9.32533C13.5749 9.61699 13.0833 9.61699 12.7666 9.32533C10.4166 7.17533 8.33325 5.16699 8.33325 3.41699Z' fill={fill}/>
      <path d='M16.6583 13.167H10.9666C10.8749 13.167 10.7833 13.1503 10.6916 13.117L9.46658 12.692C9.24992 12.617 9.14159 12.3837 9.21659 12.167C9.29159 11.9503 9.53325 11.8337 9.74992 11.917L10.6833 12.2753C10.7749 12.3087 10.8833 12.3337 10.9833 12.3337H13.1749C13.7166 12.3337 14.1583 11.892 14.1583 11.3503C14.1583 10.942 13.8999 10.5753 13.5166 10.4253L7.74992 8.27533C7.56659 8.20033 7.36659 8.16699 7.16659 8.16699H5.83325V15.6837L11.1416 17.192C11.4833 17.292 11.8499 17.2753 12.1833 17.1503L18.3333 14.8337C18.3333 13.9087 17.5833 13.167 16.6583 13.167Z' fill={fill}/>
    </svg>
  )
}
