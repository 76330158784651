import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, Divider, InputAdornment, TextField, Theme, Typography, useMediaQuery } from '@mui/material'
import { ChatCompanion } from './chat-companion'
import { SearchIcon } from '../../icons/search-icon'
import theme from '../../../styles/theme'
import { ChatVariant } from '../chat-box'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Conversation, useGetConversations } from '../../../hooks/useConversations'
import { LoaderSpinner } from '../../LoaderSpinner'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../state/atoms/profileState'
import { ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'

interface Props {
  onClick: any
  profileId: string
  setProfileConversations: (conversations: any) => void
}

const ChatLeftSide: FC<Props> = ({ onClick, profileId, setProfileConversations }) => {
  const [search, setSearch] = useState('')
  const profile = useRecoilValue(profileState)
  const [isChosen, setIsChosen] = useState<Conversation>()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const style = useMemo(() => {
    return styleChatLeftSide(theme)
  }, [styleChatLeftSide])

  const { data, hasNextPage, fetchNextPage, isLoading } = useGetConversations(profileId)

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }
  const openChat = (conversation?: Conversation) => {
    onClick(conversation)
    setIsChosen(conversation)
  }

  const conversations = useMemo(() => {
    return data?.pages.reduce<Conversation[]>((acc, conversations) => {
      acc.push(...(conversations.getProfileConversations as Conversation[]))
      if  (acc.length){
        return acc.sort((a, b) => {
          const createdAt = a?.lastMessage?.createdAt ? a?.lastMessage?.createdAt : 0
          const createdAt1 = b?.lastMessage?.createdAt ? b?.lastMessage?.createdAt : 0
             return +(createdAt1 as ApiGraphQLTypes['DateTime']) - +(createdAt as ApiGraphQLTypes['DateTime'])
        })
      } else {
        return acc
      }
    }, [])
  }, [data?.pages.map(conversations => conversations.getProfileConversations.length)])

  const searched = useMemo<Conversation[] | undefined>(() => {
    const searchedWord = search.toLowerCase()
    if (searchedWord) {
      return conversations?.filter((conversation: Conversation) => {
        return conversation?.participants?.some(participant => {
          if  (participant.employee){
            if (!participant.isHidden && participant.id !== profileId) {
              return (
                participant?.employee?.firstname?.toLowerCase().includes(searchedWord)
                ||
                participant?.employee?.lastname?.toLowerCase().includes(searchedWord)
              )
            }
          }
          else {
            if (participant.id !== profileId) {
              return (
                conversation.company?.name?.toLowerCase().includes(searchedWord)
              )
            }
          }
        })
      })
    } else {
      return conversations
    }
  }, [search, conversations])

  useEffect(() => {
    setProfileConversations(data?.pages[0].getProfileConversations)
  }, [data?.pages])

  return (
    <Box sx={{ margin: 0, padding: 0, height: '100%' }}>
      <Box sx={style.boxContentUserList}>
        <Typography variant="h5">Conversations</Typography>
        {!!data?.pages[0].getProfileConversations.length &&
          < TextField
            id="standard-bare"
            placeholder="Search"
            sx={style?.textFieldLarge}
            fullWidth={true}
            variant={'outlined'}
            size={'medium'}
            value={search}
            onChange={(e) => setSearch(e.target.value.trimStart())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        }
      </Box>
      {isLoading ?
        <LoaderSpinner />
        :
        <Box
          id="scrollableBox"
          sx={scrollAbleBox}
        >
          {data &&
            <InfiniteScroll
              dataLength={data.pages.length}
              next={fetchMore}
              className={'infinity-component'}
              style={{
                height: '100%',
                overflow: 'auto'
              }}
              hasMore={true}
              loader={
                hasNextPage && <LoaderSpinner />
              }
              scrollableTarget="scrollableBox"
            >
              {searched?.filter(conv => {
                if (profile.role !== ApiTypes.Role.EMPLOYEE){
                  return conv
                } else {
                  return conv.lastMessage
                }
              }).map((conversation) =>
                <Box
                  sx={[{
                    padding: 0,
                    margin: 0,
                    cursor: 'pointer'
                  }, !matchSm && (isChosen?.id === conversation?.id && { backgroundColor: 'rgba(15, 100, 226, 0.08)' })]}
                  key={conversation?.id}
                  onClick={() => openChat(conversation)}>
                  <ChatCompanion
                    participants={conversation?.participants}
                    variant={ChatVariant.CHAT_LIST}
                    conversation={conversation}
                    lastMessage={conversation?.lastMessage}
                    isInSidebar={true}
                    isCompany={profile.id == conversation.lastMessage?.author?.profile?.id}
                    authorLastMessage={
                      conversation?.lastMessage?.author?.profile?.employee ?
                        (conversation?.lastMessage?.author?.profile?.isHidden
                          && profileId !== conversation?.lastMessage?.author?.profileId) ?
                          !conversation.lastMessage?.author?.profile?.deletedAt ?
                            conversation?.lastMessage?.author?.profile?.displayName
                            :
                            'deleted account'
                          :
                          !conversation.lastMessage?.author?.profile?.deletedAt ?
                            conversation?.lastMessage?.author?.profile?.id !== profile.id ?
                              conversation?.lastMessage?.author?.profile?.employee?.firstname : 'You'
                            :
                            'deleted account'
                        : conversation.company.name
                    }
                  />
                </Box>
              )}
              {
                data.pages.length < 1 && !hasNextPage &&
                <Typography variant={'h5'} sx={style.styleAttention}>
                  No conversations
                </Typography>
              }
            </InfiniteScroll>
          }
        </Box>
      }
    </Box>
  )
}
export default React.memo(ChatLeftSide)

const styleChatLeftSide = (theme: Theme) => ({
  boxContentUserList: {
    paddingY: 1,
    paddingX: 3,
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingX: 1,
    }
  },
  textFieldLarge: {
    display: 'block',
    width: '100%',
    marginTop: '14px',
    height: '48px',
    borderColor: 'rgba(20, 29, 36, 0.12)',
    '& fieldset': {
      border: '1px solid rgba(20, 29, 36, 0.12)'
    },
    '& svg':{
      marginLeft: '15px!important'
    },
    '& input':{
      paddingLeft: 'unset!important'
    }
  },
  styleAttention: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '16px'
  }
})

const scrollAbleBox = (theme: Theme) =>({
  height: 'calc(100% - 100px)',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]:{
    marginRight: '-24px',
    '& .infinity-component':{
      paddingRight: '24px'
    }
  }
})
