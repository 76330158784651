import { Card, Box, Typography, Theme } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { radiusOfComponents } from '../../styles/theme'
import { Button } from '../ui-kits/button/button'

interface Props {
  onClose: () => void
  explanation: string
}

export const ApplicationRejectedPopup = ({ onClose, explanation }: Props) => {
  return (
    <Card sx={wrapper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mb={(theme) => theme.spacing(2)}>
          Your application was rejected
        </Typography>
        <Box onClick={onClose}>
          <CloseRoundedIcon sx={iconStyle} />
        </Box>
      </Box>
      <Typography variant={'h6'} color={(theme) => theme.palette.primary.main}>
        Graduate Recruitment Consultant
      </Typography>
      <Box sx={explanationWrapper}>
        <Typography variant={'body1'}>{explanation}</Typography>{' '}
      </Box>
      <Button onClick={onClose} size={'medium'} sx={{ mx: 'auto' }}>
        Ok
      </Button>
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const explanationWrapper = (theme: Theme) => ({
  borderRadius: radiusOfComponents,
  border: '1px solid rgba(20, 29, 36, 0.12)',
  padding: theme.spacing(2),
  mb: theme.spacing(2),
})
