import React from 'react'
import { SvgProps } from '../types/icon'

const Delete = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.99999 15.8333C4.99999 16.75 5.74999 17.5 6.66666 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V5.83333H4.99999V15.8333ZM7.64166 10.4917C7.31666 10.1667 7.31666 9.64167 7.64166 9.31667C7.96666 8.99167 8.49166 8.99167 8.81666 9.31667L9.99999 10.4917L11.175 9.31667C11.5 8.99167 12.025 8.99167 12.35 9.31667C12.675 9.64167 12.675 10.1667 12.35 10.4917L11.175 11.6667L12.35 12.8417C12.675 13.1667 12.675 13.6917 12.35 14.0167C12.025 14.3417 11.5 14.3417 11.175 14.0167L9.99999 12.8417L8.82499 14.0167C8.49999 14.3417 7.97499 14.3417 7.64999 14.0167C7.32499 13.6917 7.32499 13.1667 7.64999 12.8417L8.82499 11.6667L7.64166 10.4917ZM15 3.33333H12.9167L12.325 2.74167C12.175 2.59167 11.9583 2.5 11.7417 2.5H8.25832C8.04166 2.5 7.82499 2.59167 7.67499 2.74167L7.08332 3.33333H4.99999C4.54166 3.33333 4.16666 3.70833 4.16666 4.16667C4.16666 4.625 4.54166 5 4.99999 5H15C15.4583 5 15.8333 4.625 15.8333 4.16667C15.8333 3.70833 15.4583 3.33333 15 3.33333Z" fill="#D32F2F"/>
    </svg>
  )
}

export default Delete