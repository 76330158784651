import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional } from 'type-fest'
import { authMutation } from '../services/api'

const jobLocationSelector = ApiSelector('JobLocation')({
  id: true,
  type: true,
  wfhDays: true,
  offices: {
    id: true,
  },
})

type JobLocationFields = ApiInputType<ApiGraphQLTypes['JobLocation'], typeof jobLocationSelector>
type CreateOrUpdateJobLocationFields = SetOptional<JobLocationFields, 'id'>

export const useCreateOrUpdateJobLocation = (selector: PartialDeep<typeof jobLocationSelector>) => {
  return useMutation(async (values: CreateOrUpdateJobLocationFields) => {
    const { id, ...otherData } = values

    if (id) {
      const data = await authMutation({
        updateJobLocation: [
          {
            id,
            input: {
              ...otherData,
              offices: {
                set: otherData.offices?.map(({ id }) => ({ id })) || [],
              },
            },
          },
          selector,
        ],
      })

      return data.updateJobLocation
    }

    const data = await authMutation({
      createJobLocation: [{
        input: {
          ...otherData,
          offices: {
            connect: otherData.offices?.map(({ id }) => ({ id })) || [],
          },
        }
      }, selector],
    })

    return data.createJobLocation
  })
}
