import { useEffect } from 'react'
import { generatePath, Navigate, Outlet, useMatch, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ApiTypes } from 'recruticka-frontend-libs'
import { LoaderLarge } from '../components/LoaderLarge'
import { useProfile } from '../hooks/useProfile'
import { appLoadedState } from '../state/atoms/appLoadedState'
import { profileState } from '../state/atoms/profileState'
import { PAGES } from './AppRoutes'
import { redirectToSite } from '../services/redirectToSite'

export const ProfileRoute = () => {
  const { profileId } = useParams()
  const [, setProfile] = useRecoilState(profileState)
  const [, setAppLoaded] = useRecoilState(appLoadedState)
  const isSetupRoute = useMatch(PAGES.SETUP)
  const isMainProfilePage = useMatch(PAGES.EMPTY_PROFILE)
  const { data, isLoading, isError } = useProfile()
  
  useEffect(() => {
    if (data?.getProfile) {
      setAppLoaded(true)
      setProfile(data.getProfile)
    }
  }, [data, setProfile, setAppLoaded])

  if (isLoading) {
    return <LoaderLarge />
  }

  if (isError || !data?.getProfile) {
    redirectToSite()
    // return <Navigate to={'/sign-in'} />
    return null
    
  }

  if (data.getProfile.status !== ApiTypes.ProfileStatus.ACTIVATED && isSetupRoute === null) {
    return <Navigate to={generatePath(PAGES.SETUP, { profileId })} replace />
  }

  if ((isSetupRoute || isMainProfilePage) && data.getProfile.status === ApiTypes.ProfileStatus.ACTIVATED) {
    if (data.getProfile.role === ApiTypes.Role.EMPLOYER){
      const mainCompany = data?.getProfile?.companies?.find(company => company.isMain);

      return <Navigate to={generatePath(PAGES.COMPANIES, { profileId, companyId: mainCompany?.id })} replace />
    } else {
      return <Navigate to={generatePath(PAGES.PROFILE, { profileId })} replace />
    }
  }

  return <Outlet />
}
