import { useMutation } from 'react-query'
import { ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { authMutation } from '../services/api'

type RejectApplicationProps = {
  id: string
  message?: string
  status: ApiTypes.ApplicationStatus
}

const applicationSelector = ApiSelector('Application')({
  id: true,
  status: true,

  profile: {
    employee: {
      firstname: true,
      lastname: true,
      profileId: true,
      cv: {
        url: true,
      },
      currentCity: citySelector,
    },
  },
})

export const useUpdateApplicationStatus = (selector: Partial<typeof applicationSelector>) => {
  return useMutation(async ({ id, message, status }: RejectApplicationProps) => {
    const data = await authMutation({
      updateApplicationStatus: [{ id, message, status }, selector]
    })

    return data.updateApplicationStatus
  })
}
