import React from 'react'
import { Box, IconButton, Theme, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '../ui-kits/button/button'

const Cookies = () => {
  return (
    <Box sx={CookiesBox}>
      <Box sx={CookiesContainer}>
        <IconButton sx={IconBtn}>
          <CloseIcon />
        </IconButton>
        <Typography variant={'body1'} sx={CookiesText}>
          We use cookies to personalize content and to analyze our traffic. Please decide if you are willing to accept
          cookies from our website.
        </Typography>
        <Box sx={CookiesBtns}>
          <Button variant={'outlined'}>Decline</Button>
          <Button>Accept</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Cookies
const CookiesContainer = (theme: Theme) => ({
  maxWidth: '1070px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '900px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '670px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
  },
})
const CookiesBox = (theme: Theme) => ({
  padding: '16px 0 50px',
  backgroundColor: '#fff',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
})
const CookiesBtns = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '49px',
  '& button': {
    width: '162px',
    fontSize: '18px',
    padding: '28px',
    '&:nth-child(2)': {
      padding: '29px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: 'unset',
      fontSize: 'unset',
      '&:nth-child(2)': {
        padding: 'unset',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
    },
    '&:nth-child(1)': {
      marginRight: '48px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 'unset',
        marginTop: '12px',
      },
    },
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: '35px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
})
const CookiesText = (theme: Theme) => ({
  marginTop: '5px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'unset',
    fontSize: '16px',
  },
})
const IconBtn = (theme: Theme) => ({
  width: '40px',
  margin: '0 -100px 0 auto',
  [theme.breakpoints.down('xl')]: {
    margin: '0 -60px 0 auto',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '0 -40px 0 auto',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 0 0 auto',
  },
})
// values: {
//   xs: 0,
//     sm: 768,
//     md: 1024,
//     lg: 1450,
//     xl: 1920,
// },
