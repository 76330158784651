import { Box, Grid, Theme, Typography } from '@mui/material'
import { LinearProgressWithLabel } from '../ui-kits/linear-progress-with-label/linear-progress-with-label'


interface Props {
  value: number,
  showBar: boolean,
  isClient: boolean,
  isProfileHidden: boolean
}

export const ProfileStrength = ({ value, showBar, isClient, isProfileHidden }: Props) => {
  return (
    <Grid item sx={progressWrapper} className={'progressWrapper'}>
      <Box sx={progressDetailWrapper}>
        <Box>
          <Typography variant={'h2'}>Profile Strength</Typography>
        </Box>
        { !isClient && <Box>
          <Typography variant={'body1'} className={'infoStyle'} sx={infoStyle}>
            { !isProfileHidden ?
            'Your profile is visible to companies and hiring managers. You can choose to maintain your confidentiality and explore the platform completely anonymously by adjusting your settings.' :
            'You remain completely anonymous until you choose to disclose your identity in the settings.' }
          </Typography>
        </Box>
        }
      </Box>
      { showBar && <LinearProgressWithLabel value={value} /> }
    </Grid>
  )
}

const progressWrapper = (theme: Theme) => ({
  height: 'auto',
  boxShadow: 'none',
  marginBottom: '20px'
})

const progressDetailWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]:{
    flexWrap:'wrap',
    flexDirection: 'column',
    textAlign:'left',
    alignItems: 'flex-start',
  }
})

const infoStyle = (theme: Theme) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(2),
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]:{
    paddingLeft: 0,
    paddingTop: theme.spacing(1),
  }
})
