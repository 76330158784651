import {SvgProps} from '../types/icon';

export const LinkedInIcon = ({width = '20px', height = '20px', fill ='white'}: SvgProps) => {
    return (
        <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.48668 18V5.85541H0.600597V18H4.48668Z' fill={fill}/>
            <path d='M6.63761 18H10.5237V11.2179C10.5237 10.8549 10.5489 10.4923 10.6516 10.2328C10.9325 9.50764 11.5719 8.75653 12.6454 8.75653C14.0516 8.75653 14.6142 9.87022 14.6142 11.5028V17.9999H18.5V11.0363C18.5 7.30601 16.5828 5.57032 14.026 5.57032C11.9296 5.57032 11.0092 6.78752 10.4978 7.61656H10.5238V5.85521H6.63769C6.68869 6.99479 6.63769 17.9998 6.63769 17.9998L6.63761 18Z' fill={fill}/>
            <path d='M4.61661 2.13804C4.61661 3.31884 3.69508 4.27607 2.55831 4.27607C1.42153 4.27607 0.5 3.31884 0.5 2.13804C0.5 0.957232 1.42153 0 2.55831 0C3.69508 0 4.61661 0.957232 4.61661 2.13804Z' fill={fill}/>
        </svg>
    );
};
