import React, { SyntheticEvent, useState } from 'react'
import { Autocomplete } from 'formik-mui'
import { AutocompleteRenderInputParams, CircularProgress, TextField as MuiTextField } from '@mui/material'
import { Field, useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { useQueryRequest } from '../../services/api'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { RecruitmentFocusField } from '../../hooks/useCompany'
export const sectorSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})
type SectorFields = ApiInputType<ApiGraphQLTypes['Sector'], typeof sectorSelector>
export const RecruitmentFocusAutocomplete = () => {
  const { t } = useTranslation()
  const [loadSectors, setLoadSectors] = useState(false)
  const [recruitmentFocusFiled, recruitmentMeta, recruitmentFocusHelpers] = useField<SectorFields[]>('recruitmentsFocus')
  const { data: recruitmentFocusData, isLoading: recruitmentFocusDataLoading } = useQueryRequest(
    ['sectors'],
    {
      getSectors: [{ take: 100 }, sectorSelector],
    },
  )
  return (
    <Field
      disabled={false}
      loading={recruitmentFocusDataLoading}
      name={recruitmentFocusFiled.name}
      onOpen={() => setLoadSectors(true)}
      multiple={true}
      onChange={(event: SyntheticEvent, sectors: RecruitmentFocusField[]) => {
        recruitmentFocusHelpers.setValue(sectors)
      }}
      component={Autocomplete}
      noOptionsText={t('startTyping')}
      options={recruitmentFocusData?.getSectors || []}
      autoComplete
      isOptionEqualToValue={(option: RecruitmentFocusField, value: RecruitmentFocusField) => option.id === value.id}
      getOptionLabel={(option: RecruitmentFocusField) => (option.id ? `${option.name}` : '')}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField
          {...params}
          name={recruitmentFocusFiled.name}
          error={recruitmentMeta.touched && !!recruitmentMeta.error}
          label={t('formFields.recruitmentFocusTitle')}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {recruitmentFocusDataLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}