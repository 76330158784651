import { useField } from 'formik'
import { Button } from '../../ui-kits/button/button'
import { Box, Theme, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ApiTypes } from 'recruticka-frontend-libs'
import { $enum } from 'ts-enum-util'
import { useTranslation } from 'react-i18next'

// Define the desired order for the InterviewStage enum values
const desiredOrder = [
    ApiTypes.InterviewStage.TELEPHONE_INTERVIEW,
    ApiTypes.InterviewStage.VIDEO_INTERVIEW,
    ApiTypes.InterviewStage.FACE_TO_FACE_INTERVIEW,
    ApiTypes.InterviewStage.CULTURAL_FIT_INTERVIEWERVIEW,
    ApiTypes.InterviewStage.ONLINE_ASSESSMENT,
    ApiTypes.InterviewStage.BUSINESS_PLAN_PRESENTATION,
    ApiTypes.InterviewStage.TRIAL_DAY,
    ApiTypes.InterviewStage.ROLE_PLAYS
];

export const InterviewProcessStages = () => {
  const { t } = useTranslation()
  const [inverviewStagesField, , inverviewStagesHelpers] = useField<ApiTypes.InterviewStage[]>('inverviewStages')

  return (
    <Box sx={wrapper}>
        <Box sx={stagesWrapper}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup sx={[checkBoxGroup]}>
              {desiredOrder
                .map((item) => {
                const isSelected = inverviewStagesField.value.includes(item as ApiTypes.InterviewStage)
                const text = t(`enum.InterviewStage.${item}`)
                return ( 
                  <Box sx={checkBox} key={item}>
                    <FormControlLabel
                      control={
                      <Checkbox checked={isSelected ? true : false}  name={text}  onChange={() => {
                        if (isSelected) {
                          return inverviewStagesHelpers.setValue(inverviewStagesField.value.filter((elem) => elem !== item))
                        }
                  
                        return inverviewStagesHelpers.setValue([
                          ...inverviewStagesField.value,
                          item as ApiTypes.InterviewStage,
                        ])
                      }} />
                    }
                    label={text} />
                  </Box>
                )
              })}
            </FormGroup>
          </FormControl>
        </Box>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})

const stagesWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '768px',
    width: '100%',
    alignSelf: 'center',
  },
})


const checkBoxGroup = (theme: Theme) => ({
  display: 'flex', 
  width: '100%', 
  maxWidth: '100%',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: '8px'
})

const checkBox = (theme: Theme) => ({
  width: '49%',
  '& .MuiFormControlLabel-root': {
    margin: '0',
    display: 'inline-block',
    '& . MuiFormControlLabel-label': {
      fontWeight: '400',
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
})