import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { JobTypesAutocomplete } from '../../form-fields/JobTypesAutocomplete'
import { RecruitmentFocusAutocomplete } from '../../form-fields/RecruitmentFocusAutocomplete'
import { SliderField } from '../../ui-kits/slider-field/slider-field'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { buttonStyle } from '../steps/position-information'
import { Box, Button, Theme, Divider, Typography } from '@mui/material'
import { RecruitmentFocusField } from '../../../hooks/useCompany'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const jobTypeSelector = ApiSelector('JobType')({
  id: true,
  name: true,
})

type JobTypeFields = ApiInputType<ApiGraphQLTypes['JobType'], typeof jobTypeSelector>

const companySelector = ApiSelector('Company')({
  lookingForPeople: true,
})

type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>
interface Props {
  isSaving: boolean
  jobTypes?: JobTypeFields[]
  recruitmentsFocus?: RecruitmentFocusField[]
  lookingForPeople: CompanyFields['lookingForPeople']
  currentStep?: number
  backStep: () => void
  onSave: (
    jobTypes: JobTypeFields[],
    recruitmentsFocus: RecruitmentFocusField[],
    lookingForPeople: CompanyFields['lookingForPeople'],
  ) => void
}

export const MIN = 1
export const MAX = 200

export const KindOfPeopleInformation = ({ isSaving, onSave, jobTypes, recruitmentsFocus, lookingForPeople, currentStep, backStep, }: Props) => {
  
  const { t } = useTranslation()
  const initialValues = {
    jobTypes: jobTypes || [],
    recruitmentsFocus: recruitmentsFocus || [],
    lookingForPeople: lookingForPeople || 0,
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        await onSave(values.jobTypes, values.recruitmentsFocus, values.lookingForPeople)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <JobTypesAutocomplete label={t('formFields.companyJobTypesTitle')} />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <RecruitmentFocusAutocomplete />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Typography sx={titleTextStyle}>{t('formFields.companyLookingForPeopleTitle')}</Typography>
              <SliderField
                name={'lookingForPeople'}
                value={values.lookingForPeople}
                aria-label='Always visible'
                step={1}
                max={MAX}
                min={MIN}
                valueLabelDisplay='on'
                onChange={(event: Event, newValue: number | number[]) => {
                  setFieldValue('lookingForPeople', newValue)
                }}
                marks={[
                  {
                    value: 1,
                    label: `${MIN}`,
                  },
                  {
                    value: MAX,
                    label: `${MAX}`,
                  },
                ]}
                helperText={errors?.lookingForPeople}
              />
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={!values.jobTypes.length || !values?.recruitmentsFocus.length || !values.lookingForPeople}
                >
                  {t('saveNext')}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  jobTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .min(1),
  recruitmentsFocus: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .min(1),
  lookingForPeople: Yup.number().min(1).max(MAX).required(),
})

export const titleTextStyle = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  textAlign: 'center',
  marginBottom: '36px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
})
