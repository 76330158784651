import {
  Box,
  Divider,
  Theme,
  useMediaQuery
} from '@mui/material'
import { Button } from '../components/ui-kits/button/button'
import { LoadingButton } from '@mui/lab'
import { SaveIcon } from '../components/icons/saveIcon'
import { CancelIcon } from '../components/icons/cancelIcon'
import theme from '../styles/theme'


interface propsI  {
  disabled?:  boolean,
  loading?: boolean
}

export default function footerButton({disabled = false, loading = false} : propsI) {

  const matchXs = useMediaQuery(theme.breakpoints.down('xs'))

  return (
      <Box sx={buttonBoxWrap}>
        <Divider />
        <Box sx={buttonBox}>
          {/* <Button
              sx={buttonStyle}
              endIcon={<CancelIcon />}
              href='#'
              size={'medium'}
              variant={'outlined'}
              >
              Cancel
          </Button> */}
          <LoadingButton
              loading={loading}
              size='squareSmall'
              variant='contained'
              sx={matchXs? fullWidthButton : buttonStyle}
              endIcon={<SaveIcon fill='#fff' />}
              type="submit"
              disabled={disabled}
            >
              Save
          </LoadingButton>
        </Box>
      </Box>
  );
}



const buttonBoxWrap = (theme: Theme) => ({
  position: 'absolute',
  left: '0',
  bottom: '0',
  width: '100%',
  padding: theme.spacing(0 , 4 , 4),
  [theme.breakpoints.down('md')]: {
    position: 'unset',
    padding: '24px 0 0',
  },
})

const buttonBox = (theme: Theme) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  justifyContent: 'end',
  paddingTop: theme.spacing(4),
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  }
})

const fullWidthButton = (theme: Theme) => ({
  width: '100%',
})

const buttonStyle = (theme: Theme) => ({
  '& svg': {
    // marginRight: '8px',
  },
  [theme.breakpoints.down('lg')]: {
  },
  [theme.breakpoints.down('sm')]: {
  },
})