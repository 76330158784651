import { SvgProps } from '../types/icon'

export const InfoIcon = ({ width = '18px', height = '18px', fill='rgb(0 0 0 / 54%)' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path d='M9.00008 0.666992C4.40008 0.666992 0.666748 4.40033 0.666748 9.00033C0.666748 13.6003 4.40008 17.3337 9.00008 17.3337C13.6001 17.3337 17.3334 13.6003 17.3334 9.00033C17.3334 4.40033 13.6001 0.666992 9.00008 0.666992ZM9.00008 13.167C8.54175 13.167 8.16675 12.792 8.16675 12.3337V9.00033C8.16675 8.54199 8.54175 8.16699 9.00008 8.16699C9.45842 8.16699 9.83342 8.54199 9.83342 9.00033V12.3337C9.83342 12.792 9.45842 13.167 9.00008 13.167ZM9.83342 6.50033H8.16675V4.83366H9.83342V6.50033Z' fill={fill} />
    </svg>
  )
}

