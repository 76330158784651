import { Link, Theme, Card, Box, Typography, CardMedia, Avatar, Grid } from '@mui/material'
import { BoxContent } from './ui-kits/box-content/box-content'
import { buildAssetUrl } from '../services/buildAssetUrl';
import { useCurrencyFormatter } from '../hooks/useCurrencyFormatter';
import { JobsHotInformationList } from '../components/jobs-hot-information-list';
import { useTranslation } from 'react-i18next';
import { JobFields } from '../pages/dashboard-employee' 
import { useDate } from '../hooks/useDate'
import { EJobsBoxTypes } from '../types/jobs';
import cardPlaceholder from '../images/CardPlaceholder.jpg'

interface propsI {
  job:  JobFields
}

export const CandidateJobCard = ({ job } : propsI) => {

  const salaryFrom = job.salary?.from ? useCurrencyFormatter(job.salary.from as number, job.salary.currency.id) : null;
  const salaryTo = job.salary?.to ? useCurrencyFormatter(job.salary.to as number, job.salary.currency.id) : null;
  const { t } = useTranslation();
  // const publishedAt = useDateFormat(job.publishedAt as Date, 'MMM	DD, YYYY');
  const publishedAt = useDate(job.publishedAt as Date)
  return (

      <Grid item sm={6} xs={12} key={job.id} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Card sx={companyCardBox}>
          <Box sx={wrapper}>
            <Box sx={[cardCoverImg, spacingBetweenItems]}>
              {job.coverImage ? <CardMedia
                component='img'
                image={buildAssetUrl(job.coverImage)}
                alt='photo'
                sx={[{height: '100%', objectFit: 'cover'}]}
              /> : <CardMedia
                component='img'
                image={cardPlaceholder}
                alt='photo'
                sx={[{height: '100%', objectFit: 'cover'}]}
              /> }
            </Box>
            
            <Box display={'flex'} alignItems='center' sx={[spacingBetweenItems, companyLogoBox]}>
              <Avatar src={buildAssetUrl(job.company.logo)} alt='MasterCardLogo' sx={avatarStyle} />
              <Box ml={1}>
                <Typography variant='h5' sx={companyTitleWrapper}>
                  <Link target="_blank" rel="noopener" href={`${process.env.REACT_APP_SITE_URL}/jobs/${job.slug}`} color={'inherit'}>
                    { job.company.name }
                  </Link>
                </Typography>
                {/* <Typography variant='body2' sx={companysubtitle}>{ job.shortDescription }</Typography> */}
              </Box>

            </Box>
            <Box sx={contentBox}>
              {/* <Box sx={iconBox}>
                <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path opacity='0.15' d='M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z' fill='#FFBE40'/>
                  <path d='M10.5625 8H13.9375V7.0625C13.9375 6.75781 14.1719 6.5 14.5 6.5C14.8047 6.5 15.0625 6.75781 15.0625 7.0625V8H16C16.8203 8 17.5 8.67969 17.5 9.5V17C17.5 17.8438 16.8203 18.5 16 18.5H8.5C7.65625 18.5 7 17.8438 7 17V9.5C7 8.67969 7.65625 8 8.5 8H9.4375V7.0625C9.4375 6.75781 9.67188 6.5 10 6.5C10.3047 6.5 10.5625 6.75781 10.5625 7.0625V8ZM8.125 12.3125H10V11H8.125V12.3125ZM8.125 13.4375V14.9375H10V13.4375H8.125ZM11.125 13.4375V14.9375H13.375V13.4375H11.125ZM14.5 13.4375V14.9375H16.375V13.4375H14.5ZM16.375 11H14.5V12.3125H16.375V11ZM16.375 16.0625H14.5V17.375H16C16.1875 17.375 16.375 17.2109 16.375 17V16.0625ZM13.375 16.0625H11.125V17.375H13.375V16.0625ZM10 16.0625H8.125V17C8.125 17.2109 8.28906 17.375 8.5 17.375H10V16.0625ZM13.375 11H11.125V12.3125H13.375V11Z' fill='#FFC728'/>
                </svg>
                <Typography
                  variant={'body2'}
                  color={(theme: Theme) => theme.palette.text.secondary}
                >
                {link.date}
                </Typography>
              </Box> */}
              {/* <Box sx={badgeBox}>
                <Typography variant={'caption'}>{job.status}</Typography>
              </Box> */}
              <Box sx={{mt: 2, }}>
                <Typography
                    component='div'
                    variant='body2'
                    sx={companyDescription}
                >
                  <Link target="_blank" rel="noopener" href={`${process.env.REACT_APP_SITE_URL}/jobs/${job.slug}`} color={'inherit'}>
                    {job.title}
                  </Link>
                </Typography>
              </Box>
              <Box sx={[spacingBetweenItems, addressBox]}>
              <Grid sx={gridWrapper} container spacing={1}>
                {!!job.sectors?.length &&
                  <Grid item xs={6} sm={6}>
                    <JobsHotInformationList
                      type={EJobsBoxTypes.VACANCY_CATEGORY}
                      label={
                        (job.sectors || [])?.map(({ name }) => name).join(', ')
                      }
                    />
                  </Grid>
                }
                <Grid item xs={6} sm={6}>
                  <JobsHotInformationList
                    type={EJobsBoxTypes.SALARY}
                    label={job.salaryType === 'CONTRACT' ? `${salaryFrom} - ${salaryTo}` : t('enum.JobSalaryType.GET_IN_TOUCH')}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <JobsHotInformationList
                    type={EJobsBoxTypes.LOCATION}
                    label={
                      job.location?.type === 'REMOTE' ?
                        'Remote'
                        :
                        (job.location?.offices || [])?.map(({ city }) => city?.name).join(', ')
                    }
                  />
                </Grid>
                {job.location?.type === 'WFH' &&
                <Grid item xs={6} sm={6}>
                  <BoxContent sx={boxContentBg}>
                    <Box>
                      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect width='25' height='25' rx='12.5' fill='#FFBE40' fillOpacity='0.15'/>
                        <path d='M12.5 12.5002C11.2679 12.5002 10.1429 11.8574 9.52679 10.7859C8.91071 9.74128 8.91071 8.42878 9.52679 7.35735C10.1429 6.31271 11.2679 5.64307 12.5 5.64307C13.7054 5.64307 14.8304 6.31271 15.4464 7.35735C16.0625 8.42878 16.0625 9.74128 15.4464 10.7859C14.8304 11.8574 13.7054 12.5002 12.5 12.5002ZM11.2679 13.7859H13.7054C16.3571 13.7859 18.5 15.9288 18.5 18.5806C18.5 19.0091 18.125 19.3574 17.6964 19.3574H7.27679C6.84821 19.3574 6.5 19.0091 6.5 18.5806C6.5 15.9288 8.61607 13.7859 11.2679 13.7859Z' fill='#FFBE40'/>
                      </svg>
                    </Box>
                    <Typography variant={'subtitle2'}>
                    {job._count?.application || 0}
                    </Typography>
                  </BoxContent>
                  </Grid>
                }
              </Grid>
            </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
  )
}

const boxContentBg = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor:'#FFF4E5',
  gap: theme.spacing(1),
  // width: '48%',
  alignItems:'center',
  '& .MuiBox-root': {
    display: 'flex',
    '& svg':{
      '& path': {
        fill: theme.palette.secondary.main,
        fillOpacity: 1,
      },
    },
  },
  '& h6': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]:{
    // backgroundColor: 'unset',
    // paddingLeft: 'unset',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // borderRadius: 'unset'
  },
  [theme.breakpoints.down('xs')]:{
    '& h6': {
      fontSize: '10px',
      lineHeight: '16px',
    }
  }
});

const companyCardBox = (theme: Theme) => ({
  padding: '0',
  overflow: 'hidden',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  }
});

const gridWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('xs')]: {  
    '& .MuiGrid-item': {
      flexBasis: '50%',
      flexGrow: '0',
      maxWidth: '50%',
    }
  },
});

const cardCoverImg = (theme: Theme) => ({
  height: '150px',
});
const companyLogoBox = (theme: Theme) => ({
  padding: '0 24px 24px',
  borderBottom: '1px solid #EDF2F7',
  marginBottom: '16px !important',
});

const companyDescription = (theme: Theme) => ({
  height: '40px',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color:'#1e1e1e',
});

const companyTitleWrapper = (theme: Theme) => ({
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize:'14px',
});

const companysubtitle = (theme: Theme) => ({

});


const addressBox = (theme: Theme) => ({
  paddingTop: theme.spacing(2),
});

const spacingBetweenItems = (theme: Theme) => ({
  borderRadius: '6px',
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(3),
  },
});

const avatarStyle = (theme: Theme) => ({
  width: '36px',
  height: '36px',
  border: '1px solid ${theme.palette.background.default}',
});

const iconVisibleStyle = (theme: Theme) => ({
  '& > .MuiButton-endIcon': {
    display: 'none',
  },
  '&:hover > .MuiButton-endIcon': {
    display: 'inherit',
  },
});

const wrapper = { display: 'flex', flexDirection: 'column', height: '100%' };

const cardActionContent = {
  borderTop: '1px solid #EDF2F7',
  padding: '24px 24px',
  marginTop: 'auto',
};

const iconBox = (theme: Theme) => ({
  display:'flex',
  alignItems: 'center',
  marginBottom: '8px',
  gap: '8px',
  '& p': {
    color: '#737373',
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: 600,
  },
});

const iconwrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10pX',
  backgroundColor:'#FFF4E5',
  '& svg': {
    overflow: 'visible',
    '& path': {
      fill: '#FFBE40 !important',
      fillOpacity: '1',
    }
  }
})

const contentBox = (theme: Theme) => ({
  padding: '0 16px',
});

const badgeBox = (theme: Theme) => ({
  mb: theme.spacing(1),
  '& .MuiTypography-root': {
    padding: theme.spacing(.5 , 1),
    borderRadius: theme.spacing(10),
    backgroundColor: 'rgb(0 0 0 / 6%)',
    display: 'inline-block',
  }
});