import React, { FC, useMemo, useState, useEffect } from 'react'
import { Avatar, Box, IconButton, Theme, Typography } from '@mui/material'
import { ChatCompanion, Participant } from './chat-companion'
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../ui-kits/button/button'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../../styles/theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChatIcon from '@mui/icons-material/Chat'
import { MessageForm } from './message-form'
import { ChatVariant } from '../chat-box'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Message, useDeleteMessage, useGetMessages } from '../../../hooks/useMessages'
import { LoaderSpinner } from '../../LoaderSpinner'
import { Conversation, Participants } from '../../../hooks/useConversations'
import dayjs from 'dayjs'
import { SystemStyleObject } from '@mui/system'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { profileState } from '../../../state/atoms/profileState'
import ConversationProfile from './ConversationProfile'
import { queryClient } from '../../../react-query.client'
import { chatWindowState } from '../../../state/atoms/chatState'
import { PAGES } from '../../../router/AppRoutes'
import NiceToChat from '../../../images/svg-files/its-nice-to-chat.svg'
import NoMessages from '../../../images/svg-files/no-messages.svg'
import { ApiTypes } from 'recruticka-frontend-libs'

interface Props {
  variant: ChatVariant
  onClick?: object | undefined
  openChat: (b: boolean) => void | boolean
  conversations?: Conversation
}


interface ChatStyle {
  chatBoxWrapper?: SystemStyleObject
  chatUserName?: SystemStyleObject
  mainChatBox?: SystemStyleObject
  messageForm?: SystemStyleObject
  emojiBox?: SystemStyleObject
  chatUserAvatar?: SystemStyleObject
  chatNameCompany?: SystemStyleObject
  widgetsBtn?: SystemStyleObject
  chatView: ChatVariant
  chatMobileBtn?: SystemStyleObject
  widgetBtnLarge?: SystemStyleObject
  widgetBtnSmall?: SystemStyleObject
  chatUserInformation?: SystemStyleObject
  messageFormView: ChatVariant
  styleReplyMessage?: SystemStyleObject
  dateOfChat?: SystemStyleObject
}

const ChatRightSide: FC<Props> = ({ variant, conversations, openChat }) => {
  const [textReplyMessage, setTextReplyMessage] = useState<Message | undefined>()
  const [editMessage, setEditMessage] = useState<Message | undefined>()
  const [participant, setParticipant] = useState<Participant>()
  const profile = useRecoilValue(profileState)
  const { data, hasNextPage, fetchNextPage, isLoading } = useGetMessages(profile.id, conversations?.id)
  const deleteMessage = useDeleteMessage(profile.id)
  const navigate = useNavigate()
  const setChatState = useSetRecoilState(chatWindowState)
  const {
    chatBoxWrapper,
    chatUserName,
    mainChatBox,
    chatUserAvatar,
    chatNameCompany,
    widgetsBtn,
    chatView,
    chatMobileBtn,
    widgetBtnLarge,
    widgetBtnSmall,
    chatUserInformation,
    messageFormView,
    dateOfChat
  } = useMemo<ChatStyle>(() => {
    if (variant === ChatVariant.CHAT_WIDGET) {
      return widgetChatStyle(theme)
    }
    return mainChatStyle(theme)
  }, [variant])

  const handleDeleteMessage = async (id: string) => {
    await deleteMessage.mutateAsync(id)
    const filteredArray = data?.pages.map((messages) =>
      messages.getMessages.filter((message: Message) => message.id !== id)
    ) ?? []

    const newPagesArray = filteredArray.reduce((acc: any, messages, index) => {
      const getMessages = [...messages]
      return [...acc, { getMessages }]
    }, [])

    queryClient.setQueryData(['messages', conversations?.id], ({ pageParams }) => ({
      pages: newPagesArray,
      pageParams: pageParams
    }))
  }

  const fetchMoreData = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const replyMessage = (message: any) => {
    setTextReplyMessage(message)
    setEditMessage(undefined)
  }

  const handleEditMessage = (message: any) => {
    setEditMessage(message)
    setTextReplyMessage(undefined)
  }

  const goBackToAnotherChat = () => {
    openChat(false)
    setTextReplyMessage(undefined)
  }

  useEffect(() => {
    if (conversations?.participants) {
      conversations?.participants?.map((el: any) => {
        if (el.employee) {
          if (el.id !== profile.id) {
            setParticipant({ ...el.employee, isHidden: el.isHidden, displayName: el.displayName })
          }
        } else {
          if (el.id !== profile.id) {
            setParticipant({ ...el, company: { ...el.company, ...conversations.company }, isHidden: el.isHidden })
          }
        }
      })
    }
  }, [conversations?.id])

  const messages = useMemo(() => {
    return data?.pages.reduce<Message[]>((acc, messages) => {
      acc.push(...messages.getMessages)
      return acc
    }, [])
  }, [data?.pages[0].getMessages.length, data?.pages[0].getMessages.map(el => el.assets)])

  const handleClose = () => {
    setChatState({
      isOpened: false,
      conversationId: ''
    })
  }
  const deleteAsset = (id: string) => {
    const filteredArray = data?.pages.map((messages) =>
      messages.getMessages.map((message: Message) =>
        message.assets ?
          { ...message, assets: message.assets.filter(asset => asset.id !== id) }
          :
          message
      )
    ) ?? []

    const newPagesArray = filteredArray.reduce((acc: any, messages, index) => {
      const getMessages = [...messages]
      return [...acc, { getMessages }]
    }, [])

    queryClient.setQueryData(['messages', conversations?.id], ({ pageParams }) => ({
      pages: newPagesArray,
      pageParams: pageParams
    }))
  }

  const handleGoToMessage = () => {
    navigate(
      {
        pathname: generatePath(PAGES.MESSAGES, {
          profileId: profile.id
        }), search: `?conversation_id=${conversations?.id}`
      })
    setChatState({
      isOpened: false,
      conversationId: conversations?.id
    })
  }

  if (isLoading) {
    return <Box sx={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    >
      <LoaderSpinner />
    </Box>
  } else {
  if (variant !== ChatVariant.CHAT_WIDGET && !conversations?.id) {
      return <Box sx={patternWrapper}>
        <Typography sx={title}>Engaging in conversation is always enjoyable.</Typography>
        <Typography variant={'body1'} sx={description}>Select a person from the menu on the left to begin your chat.</Typography>
        <img src={NiceToChat} alt="" />
      </Box>
    } else {
      return (
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
        >
          <Box sx={chatBoxWrapper}>
            <IconButton size={'small'} sx={chatMobileBtn} onClick={goBackToAnotherChat}>
              <ArrowBackIcon fontSize={'small'} />
            </IconButton>
            <Box sx={chatUserAvatar}>
              {participant &&
                <ConversationProfile participant={participant} />
              }
            </Box>
            <Box sx={chatUserInformation}>
              <Typography variant="h5" noWrap={true} sx={chatNameCompany}>
                {
                  !participant?.profile?.deletedAt ?
                    participant?.role === ApiTypes.Role.EMPLOYER ?
                      participant?.company?.name
                      :
                      !participant?.isHidden ?
                        `${participant?.firstname ? participant?.firstname : ''} 
                      ${participant?.lastname ? participant?.lastname : ''}`
                        :
                        participant?.displayName ? participant?.displayName : 'Anonymous Candidate'
                    :
                    'deleted account'
                }
              </Typography>
              {/* <Typography variant="body1" noWrap={true} sx={chatUserName}> */}
              {/*  { !participant?.profile?.deletedAt ? */}
              {/*    participant?.company || participant?.company?.jobTypes ? */}
              {/*      participant?.company.jobTypes[0].name */}
              {/*      : */}
              {/*      '' */}
              {/*    : '' */}
              {/*  } */}
              {/* </Typography> */}
            </Box>
            <Box sx={widgetsBtn}>
              <Button
                onClick={handleGoToMessage}
                sx={widgetBtnLarge}
                variant={'outlined'}
                size={'small'}
              >
                Go to messages
              </Button>
              <Button
                onClick={handleGoToMessage}
                sx={widgetBtnSmall}
                variant={'outlined'}
                size={'small'}
              >
                <ChatIcon />
              </Button>
              <IconButton size={'small'} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {messages?.length ?
            <Box sx={mainChatBox} id="scrollableDiv">
              <InfiniteScroll
                loader={
                  hasNextPage ? <LoaderSpinner />
                    :
                    null
                }
                dataLength={data?.pages.length || 0}
                next={fetchMoreData}
                className={'infinite-scroll'}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  overflowX: 'hidden'
                }}
                inverse={true}
                scrollableTarget="scrollableDiv"
                hasMore={true}
              >
                {
                  !!messages?.length &&
                  messages?.map((message, idx) => {
                    return (
                      <Box sx={{ margin: 0, padding: 0, marginY: '10px' }} key={message.id}>
                        {
                          ((messages?.at(-1)?.id === message.id) ||
                            dayjs(dayjs(message.createdAt as Date)
                              .format('YYYY-MM-DD')).diff(
                              dayjs(messages?.[idx + 1]?.createdAt as Date).format('YYYY-MM-DD'), 'day'
                            ) > 0) &&
                          <Typography sx={dateOfChat} variant={'subtitle2'} align={'center'}>
                            {dayjs(message.createdAt as Date).format('DD MMMM YYYY')}
                          </Typography>
                        }
                        <ChatCompanion
                          isInSidebar={false}
                          isCompany={profile?.id == message?.author?.profileId}
                          variant={chatView}
                          editMessage={handleEditMessage}
                          replyMessage={replyMessage}
                          message={message}
                          deleteAsset={deleteAsset}
                          deleteMessage={handleDeleteMessage}
                          conversation={conversations}
                        />
                      </Box>
                    )
                  })
                }
              </InfiniteScroll>
            </Box>
            :
            <Box
              sx={
                variant !== ChatVariant.CHAT_WIDGET ?
                  patternMain
                  :
                  patternWidget
              }
            >
              <Typography sx={title}>No messages here yet</Typography>
              <img src={NoMessages} alt="" />
            </Box>
          }
          <MessageForm
            isDeletedParticipant={!!participant?.profile?.deletedAt}
            conversationId={conversations?.id}
            variant={messageFormView}
            replyMessage={textReplyMessage}
            setReplyMessage={setTextReplyMessage}
            editMessage={editMessage}
            setEditMessage={setEditMessage}
          />
        </Box>
      )
    }
  }
}
export default React.memo(ChatRightSide)

const mainChatStyle = (theme: Theme) => ({
  chatBoxWrapper: {
    paddingLeft: '24px',
    paddingBottom: '10px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      paddingLeft: '24px',
      paddingY: '23px',
      alignItems: 'center'
    }
  },
  chatUserInformation: {
    display: 'grid',
    marginRight: '10px',
    minHeight: '48px',
  },
  mainChatBox: {
    borderTop: '2px solid #F2F5F8',
    borderBottom: '2px solid #F2F5F8',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column-reverse',
  },
  dateOfChat: {
    marginY: '16px',
    color: 'rgba(20, 29, 36, 0.4)',
    fontSize: '14px'
  },
  chatUserAvatar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: '21px',
      marginLeft: '32px',
      marginTop: '-15px',
      '& img': {
        width: '32px',
        height: '32px',
        borderRadius: '50%'
      }
    }
  },
  chatNameCompany: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center'
    }
  },
  widgetsBtn: {
    display: 'none'
  },
  chatView: ChatVariant.CHAT_MAIN,
  mobileBtn: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  chatMobileBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  messageFormView: ChatVariant.CHAT_MAIN,
  styleReplyMessage: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: '14px 29px 8px',
    margin: 'auto 0 0'
  }
})
const widgetChatStyle = (theme: Theme) => ({
  chatBoxWrapper: {
    padding: '18px 32px 9px 26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: '10px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '18px 24px 13px'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  chatUserName: {
    color: '#000',
    fontWeight: 600,
    fontSize: '14px',
    marginLeft: '5px'
  },
  chatUserInformation: {
    width: '38%',
    marginRight: '17px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '32%',
      marginRight: '5px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  dateOfChat: {
    color: 'rgba(20, 29, 36, 0.4)',
    marginTop: '14px'
  },
  mainChatBox: {
    borderTop: '2px solid #F2F5F8',
    borderBottom: '2px solid #F2F5F8',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column-reverse'
  },
  chatUserAvatar: {
    display: 'block',
    width: '10%',
    marginRight: '12px',
    marginTop: '-15px',
    '& > *': {
      width: '40px',
      height: '40px',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 'unset'
    },
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
    }
  },
  widgetsBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '55%',
    [theme.breakpoints.down('md')]: {
      width: '49%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset'
    }
  },
  widgetBtnLarge: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      paddingX: '12px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  widgetBtnSmall: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '66px',
      marginX: '17px',
      '& svg': {
        width: '17px',
        height: '17px'
      }
    }
  },
  chatNameCompany: {
     color: '#000',
    fontWeight: 600,
    fontSize: '14px!important',
  },
  chatView: ChatVariant.CHAT_WIDGET,
  chatMobileBtn: {
    display: 'none'
  },
  messageFormView: ChatVariant.CHAT_WIDGET
})


const patternWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '350px'
    }
  }
})

const patternWidget = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  borderBottom: '2px solid #F2F5F8',
  borderTop: '2px solid #F2F5F8',
  '& img': {
    width: '200px'
  },
  '& p': {
    fontSize: '16px'
  }
})

const patternMain = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  borderBottom: '2px solid #F2F5F8',
  borderTop: '2px solid #F2F5F8',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '250px'
    }
  }
})

const description = (theme: Theme) => ({
  color: 'rgba(20, 29, 36, 0.4)',
  marginTop: '8px',
  marginBottom: '42px'
})

const title = (theme: Theme) => ({
  color: 'rgba(20, 29, 36, 0.4)',
  fontSize: '22px',
  fontWeight: 700
})
