import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Box, Theme, Typography } from '@mui/material'
import { Button } from '../../../ui-kits/button/button'
import { LoadingButton } from '@mui/lab'
import { ReactNode } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface Props {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  onDublicate: () => void
}

export default function SistersCompanyAlert({
    title,
    isOpen,
    onClose,
    onConfirm,
    isLoading,
    onDublicate,
    }: Props) {
  const handleConfirm = async () => {
    await onConfirm()
    onClose()
  }

  const handleDublicate = () => {
    onDublicate()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ px: '24px' }}
      fullWidth
    >
      <DialogContent>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant={'h2'}>{title}</Typography>
          <Button
            variant={'text'}
            size={'small'}
            sx={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 0,
              width: '30px',
              padding: 0,
              minWidth: 'unset',
              color: 'rgba(20, 29, 36, 0.4)'
            }}
            onClick={onClose}
          >
            <CloseRoundedIcon />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={actionWrapper}>
        <Button variant={'contained'} onClick={handleDublicate} size={'small'}>
          Duplicate Head Company
        </Button>
        <LoadingButton loading={isLoading} variant='contained' autoFocus onClick={handleConfirm} size={'small'}>
          Create New Company
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const actionWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]:{
    flexDirection: 'column',
    gap: 1,
  }
})

const dialogContentWrapper = (theme: Theme) => ({
  maxWidth: '444px',
  width: '100%',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
})
