export const currencyFormatter = (amount: number, currency = 'usd') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    useGrouping: true,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })

  return formatter.format(amount)
}
