import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional, PartialDeep } from 'type-fest'
import { authMutation } from '../services/api'
import {
  TextBlockAsset,
  textBlockAssetSelector,
  useCreateOrUpdateTextBlockAsset,
  useDeleteTextBlockAsset,
} from './useTextBlockAsset'

export const textBlockSelector = ApiSelector('TextBlock')({
  id: true,
  title: true,
  description: true,
  textBlockAsset: textBlockAssetSelector,
})
export interface TextBlock
  extends Omit<ApiInputType<ApiGraphQLTypes['TextBlock'], typeof textBlockSelector>, 'textBlockAsset'> {
  textBlockAsset?: SetOptional<TextBlockAsset, 'id'>[]
}
type CreateOrUpdateTextBlockFields = SetOptional<TextBlock, 'id'>

type MutationProps = {
  values: CreateOrUpdateTextBlockFields
  oldValues?: PartialDeep<CreateOrUpdateTextBlockFields>
}

export const useCreateOrUpdateCompanyPerk = (selector: Partial<typeof textBlockSelector>) => {
  const textBlockAssetMutation = useCreateOrUpdateTextBlockAsset({ id: true })
  const deleteTextBlockAssetMutation = useDeleteTextBlockAsset()

  return useMutation(async ({ values, oldValues }: MutationProps) => {
    const { id, textBlockAsset, ...input } = values

    if (id) {
      const setIds: string[] = []
      const deleteIds: string[] = []

      if (textBlockAsset) {
        for (const asset of textBlockAsset) {
      // TODO: Update only if need
      const { id: officeAssetId } = (await textBlockAssetMutation.mutateAsync({
        textBlockId: id,
        values: asset,
      })) as TextBlockAsset

      setIds.push(officeAssetId)
        }
      }

      oldValues?.textBlockAsset?.forEach((oldAsset) => {
        const asset = textBlockAsset?.find((asset) => asset.id === oldAsset?.id)

        if (!asset && oldAsset?.id) {
          deleteIds.push(oldAsset.id)
        }
      })

      for (const officeAssetId of deleteIds) {
        await deleteTextBlockAssetMutation.mutateAsync(officeAssetId)
      }

      const data = await authMutation({
        updateTextBlock: [
          {
            id,
            input,
          },
          selector,
        ],
      })

      return data.updateTextBlock
    }

    const data = await authMutation({
      createTextBlock: [
        {
          input: {
            ...input,

            textBlockAsset: {
              ...(textBlockAsset
                ? {
                    create: textBlockAsset.map((textBlockAsset) => ({
                      ...textBlockAsset,
                      asset: {
                        connect: {
                          id: textBlockAsset.asset.id,
                        },
                      },
                    })),
                  }
                : {}),
            },
          },
        },
        selector,
      ],
    })

    return data.createTextBlock
  })
}

export const useDeleteTextBlock = () => {
  return useMutation(async (id: TextBlock['id']): Promise<boolean> => {
    await authMutation({
      deleteTextBlock: [{ id }, true],
    })

    return true
  })
}

/* export const useCreateTextBlockAsset = () => {
  return useMutation(async ({ textBlockId, assetId }: CreateTextBlockAssetProps) => {
    const { createTextBlockAsset } = await authMutation({
      createTextBlockAsset: [
        {
          input: {
            textBlock: {
              connect: {
                id: textBlockId
              }
            },
            asset: {
              connect: {
                id: assetId
              }
            }
          }
        },
        {
          isMain: true,
          asset: {
            id: true,
            size: true,
            type: true,
            url: true,
            mimeType: true
          }
        }
      ]
    })
  })
} */
