import { LocationMap } from '../../ui-kits/location-map'
import { FieldArray, useField } from 'formik'
import Uploader, { Asset } from '../../Uploader/Uploader'
import { IMAGES } from '../../../config/uploaderTypes'
import { CompanyLocation } from '../../../hooks/useCompany'
import { Box, SxProps, Divider } from '@mui/material'

export const Location = () => {
  const [filesField] = useField<Asset[]>('locationFiles')
  const [locationsField, , locationsFieldHelpers] = useField<CompanyLocation[]>('companyLocations')

  return (
    <>
      <Box sx={mapSX}>
        <LocationMap data={locationsField.value} onChange={locationsFieldHelpers.setValue} />
      </Box>

      <Divider sx={{ mt: 4 }}/>

      <FieldArray
        name={filesField.name}
        render={(arrayHelpers) => (
          <>
            <Uploader
              assets={filesField.value}
              gridView
              multiple
              allowedFileTypes={IMAGES}
              onFileUploaded={(asset) => arrayHelpers.push(asset)}
              onFileDeleted={({ id }) => {
                const index = filesField.value.findIndex((asset) => asset.id === id)

                arrayHelpers.remove(index)
              }}
            />
          </>
        )}
      />
    </>
  )
}

const mapSX: SxProps = {
  borderRadius: .5,
  overflow: 'hidden',
}
