import { CircularProgress, FormControl, Grid, SliderProps, Stack } from '@mui/material'
import { useField } from 'formik'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useCurencies } from '../../hooks/useCurrency'
import { SliderField } from '../ui-kits/slider-field/slider-field'
import { CurrencyAutocomplete } from './CurrencyAutocomplete'
import { SalaryPeriodSelect } from './SalaryPeriodSelect'
import { useNumberFormatter } from '../../hooks/useNumberFormatter'
import { useMemo } from 'react'

export const currencySelector = ApiSelector('Currency')({
  id: true,
  name: true,
  symbol: true,
  rate: true
})

export type CurrencyFields = ApiInputType<ApiGraphQLTypes['Currency'], typeof currencySelector>

interface Props {
  fieldsPrefix?: string
  multiple?: boolean,
  minMonth?: number
  maxMonth?: number
  minYear?: number
  maxYear?: number
  sliderProps?: SliderProps,
  signUp?: boolean
}

const MIN_MAX_ALL_CURRENCIES = {
  [ApiTypes.SalaryPeriod.ANNUAL]: [12000, 250000, 3000],
  [ApiTypes.SalaryPeriod.MONTH]: [1000, 21000, 1000],
}

const MIN_MAX_ALL_DIRHAM = {
  [ApiTypes.SalaryPeriod.ANNUAL]: [40000, 1000000, 10000],
  [ApiTypes.SalaryPeriod.MONTH]: [3000, 80000, 3000],
}

export const Salary = ({ fieldsPrefix = '',
  minMonth,
  maxMonth,
  minYear,
  maxYear,
  sliderProps,
  signUp,
  multiple = true
}: Props) => {
  const [fromField, , fromHelpers] = useField<number>(`${fieldsPrefix}from`)
  const [toField, , toHelpers] = useField<number>(`${fieldsPrefix}to`)
  const [currencyField, , currencyHelpers] = useField<CurrencyFields>(`${fieldsPrefix}currency`)
  const [scheduleField] = useField<ApiTypes.SalaryPeriod>(`${fieldsPrefix}schedule`)
  const { isLoading: currenciesDataLoading } = useCurencies()
  const { format } = useNumberFormatter()

  const [min, max, step] = currencyField?.value?.id === 'aed' ? MIN_MAX_ALL_DIRHAM[scheduleField.value] : MIN_MAX_ALL_CURRENCIES[scheduleField.value]

  // const { min, max, step } = useSalaryConversion({
  //   minMonth,
  //   maxMonth,
  //   minYear,
  //   maxYear,
  //   from: fromField.value,
  //   to: toField.value,
  //   schedule: scheduleField.value,
  //   currency: currencyField.value,
  //   setCurrency(currency) {
  //     currencyHelpers.setValue(currency)
  //   },
  //   setFrom(from) {
  //     fromHelpers.setValue(from)
  //   },
  //   setTo(to) {
  //     toHelpers.setValue(to)
  //   }
  // })

  const roundHundreds = useMemo(() => {
    if (scheduleField.value === ApiTypes.SalaryPeriod.MONTH) {
      return 100
    } else {
      return 1000
    }
  }, [scheduleField.value])

  if (currenciesDataLoading) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <>
      <SliderField
        value={
          multiple
            ? [parseInt(Math.round(fromField.value / roundHundreds) * roundHundreds as unknown as string),
            parseInt(Math.round(toField.value / roundHundreds) * roundHundreds as unknown as string)]
            : parseInt(fromField.value as unknown as string)
        }
        step={step}
        max={max}
        min={min}
        marks={[
          {
            value: min,
            label: signUp ? (min > 1 && min) : min
          },
          {
            value: max,
            label: max
          }
        ]}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => format(value)}
        onChange={(event: Event, newValue: number | number[]) => {
          const value = Array.isArray(newValue) ? newValue : [newValue, newValue];

          fromHelpers.setValue(Math.round(value[0] / roundHundreds) * roundHundreds)
          toHelpers.setValue(Math.round(value[1] / roundHundreds) * roundHundreds)
        }}
        {...sliderProps}
      />
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <FormControl sx={{ width: '100%' }}>
            <SalaryPeriodSelect name={`${fieldsPrefix}schedule`} />
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl sx={{ width: '100%' }}>
            <CurrencyAutocomplete name={`${fieldsPrefix}currency`} />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}