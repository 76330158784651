import { Field, useField, FieldArray } from 'formik'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TextField } from 'formik-mui'
import Uploader, { Asset } from '../../Uploader/Uploader'
import { IMAGES, DOCUMENTS } from '../../../config/uploaderTypes'
import { TextEditorField } from '../../form-fields/TextEditorField'

export const CommissionStructure = () => {
  const { t } = useTranslation()
  const [filesField] = useField<Asset[]>('commissionAndIncentivesFiles')

  return (
    <Box
      sx={[{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        mb: '32px',
      }]}
    >
      <FieldArray
        name={filesField.name}
        render={(arrayHelpers) => (
          <>
            <Uploader
              assets={filesField.value}
              gridView
              multiple
              allowedFileTypes={[...IMAGES, ...DOCUMENTS]}
              onFileUploaded={(asset) => arrayHelpers.push(asset)}
              onFileDeleted={({ id }) => {
                const index = filesField.value.findIndex((asset) => asset.id === id)

                arrayHelpers.remove(index)
              }}
            />
          </>
        )}
      />
      <TextEditorField
        areaSx={{ height: 'auto' }}
        name={'commissionAndIncentives'}
        placeholder={t('formFields.companyCommissionAndIncentives')}
      />
    </Box>
  )
}
