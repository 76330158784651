import { SvgProps } from '../types/icon'

export const Portfolio = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg height={height} width={width}>
      <path
        d='M13 10C13 10.5523 13.4477 11 14 11C14.5523 11 15 10.5523 15 10H13ZM5 10C5 10.5523 5.44772 11 6 11C6.55228 11 7 10.5523 7 10H5ZM3 7H17V5H3V7ZM18 8V16H20V8H18ZM17 17H3V19H17V17ZM2 16V8H0V16H2ZM3 17C2.44772 17 2 16.5523 2 16H0C0 17.6569 1.34315 19 3 19V17ZM18 16C18 16.5523 17.5523 17 17 17V19C18.6569 19 20 17.6569 20 16H18ZM17 7C17.5523 7 18 7.44772 18 8H20C20 6.34315 18.6569 5 17 5V7ZM3 5C1.34315 5 0 6.34315 0 8H2C2 7.44772 2.44772 7 3 7V5ZM8 3H12V1H8V3ZM13 4V10H15V4H13ZM7 10V4H5V10H7ZM12 3C12.5523 3 13 3.44772 13 4H15C15 2.34315 13.6569 1 12 1V3ZM8 1C6.34315 1 5 2.34315 5 4H7C7 3.44772 7.44772 3 8 3V1Z'
        fill='#141D24'
        fillOpacity='0.4'
      />
    </svg>
  )
}
