import {
    List,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Divider,
    Theme,
    Box,
    Typography
  } from '@mui/material'
  
  // Icon
  import { InfoIcon } from './icons/infoIcon'
  import { SalaryIcon } from './icons/salaryIcon'
  import { LocationIcon } from './icons/location'
  import { RequirementsIcon } from './icons/requirementsIcon'
  import { BenefitsIcon } from './icons/benefitsIcon'


  // Icon
import { CareerIcon } from '../components/icons/careerIcon'
import { IdentyCard } from '../components/icons/identyCard'
import { MedalIcon } from '../components/icons/medalIcon'
import { BillingIcon } from '../components/icons/billingIcon'
import { CvIcon } from '../components/icons/cvIcon'
import { Candidates } from '../components/icons/candidates'
import { HidePrivacyIcon } from '../components/icons/hidePrivacyIcon'
import { ClientGroupIcon } from '../components/icons/clientGroupIcon'
  

  interface propsI {
    handleSideDropDown: (index : number) => void,
    currentMenuIndex: number
  }
  
  export default function SidebarInternalCandidate({ currentMenuIndex, handleSideDropDown } : propsI) {
  
    return (
        <Box sx={tabListBox}>
            <Box sx={titleBox}>
                <Typography variant='h6' component='h6'>Candidates</Typography>
            </Box>

            <List sx={[menuList ,{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0, }]} component="nav" aria-labelledby="nested-list-subheader" >
            <ListItemButton 
                selected={currentMenuIndex === 0}
                onClick={(event) => handleSideDropDown(0)}>
                <ListItemIcon>
                  <CareerIcon />
                </ListItemIcon>
                <ListItemText primary="Career narrative" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 1}
                onClick={(event) => handleSideDropDown(1)}>
                <ListItemIcon>
                  <IdentyCard />
                </ListItemIcon>
                <ListItemText primary="Additional information" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 2}
                onClick={(event) => handleSideDropDown(2)}>
                <ListItemIcon>
                  <MedalIcon />
                </ListItemIcon>
                <ListItemText primary="Specialises in" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 3}
                onClick={(event) => handleSideDropDown(3)}>
                <ListItemIcon>
                  <BillingIcon />
                </ListItemIcon>
                <ListItemText primary="Billings" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 4}
                onClick={(event) => handleSideDropDown(4)}>
                <ListItemIcon>
                  <CvIcon />
                </ListItemIcon>
                <ListItemText primary="CV" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 5}
                onClick={(event) => handleSideDropDown(5)}>
                <ListItemIcon>
                  <Candidates />
                </ListItemIcon>
                <ListItemText primary="Have you managed a team?" />
            </ListItemButton>
            <Divider />

            <ListItemButton 
                selected={currentMenuIndex === 6}
                onClick={(event) => handleSideDropDown(6)}>
                <ListItemIcon>
                  <HidePrivacyIcon />
                </ListItemIcon>
                <ListItemText primary="Hide me from" />
            </ListItemButton>
            <Divider />
            

            <ListItemButton 
                selected={currentMenuIndex === 7}
                onClick={(event) => handleSideDropDown(7)}>
                <ListItemIcon>
                  <ClientGroupIcon />
                </ListItemIcon>
                <ListItemText primary="My clients" />
            </ListItemButton>

            </List>
        </Box>
    );
  }
  
  const tabListBox = (theme: Theme) => ({
    width: '18%',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(.5),
    position: 'sticky',
    top: '96px',
    overflow: 'hidden',
  })
 
  const titleBox = (theme: Theme) => ({
   backgroundColor: '#E0E0E0',
   padding: theme.spacing(1, 2),
 })

  const menuList = (theme: Theme) => ({
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
    '& .MuiListItemButton-root': {
      paddingTop: '6px',
      paddingBottom: '6px',
      '&.Mui-selected': {
        backgroundColor: '#5c67ea !important',
        '& .MuiListItemIcon-root': {
          '& svg path': {
            fill: '#fff !important',
          }
        },  
        '& .MuiTypography-body1': {
          color: '#fff !important',
        },
        '&:hover': {
  
        }
      }
    },
    [theme.breakpoints.down(1250)]: {
      '& .MuiListItemIcon-root': {
        minWidth: '32px',
      }
    }
  })

  