import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemButton,
  Divider,
  Theme,
  Box,
  Typography
} from '@mui/material'

// Icon
import { CoverImg } from '../components/icons/coverImage'
import { CompanyInformationIcon } from '../components/icons/CompanyInformation'
import { Company } from '../components/icons/company'
import { LocationIcon } from '../components/icons/location'
import { Gallery } from '../components/icons/gallery'
import { Phone } from '../components/icons/phone'

import { DownArrow } from '../components/icons/downIcon'
import { UpArrow } from '../components/icons/upIcon'

interface propsI {
  handleSideDropDown: (index : number) => void,
  currentMenuIndex: number,
  isMainCompany: boolean
}

export default function SidebarInternal({ currentMenuIndex, handleSideDropDown, isMainCompany } : propsI) {

  return (
    <Box sx={tabListBox}>
      <Box sx={titleBox}>
        <Typography variant='h6' component='h6'>
          Client
        </Typography>
      </Box>
      <List
            sx={[menuList ,{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0, }]}
            component="nav"
            aria-labelledby="nested-list-subheader" >

              <ListItemButton 
                selected={currentMenuIndex === 0}
                onClick={(event) => handleSideDropDown(0)}>
                <ListItemIcon>
                  <CoverImg />
                </ListItemIcon>
                <ListItemText primary="Cover Image" />
              </ListItemButton>
              <Divider />

              <ListItemButton 
                selected={(currentMenuIndex >= 1 && currentMenuIndex <= 9)}
                onClick={(event) => {
                  handleSideDropDown(1);
                }} 
                sx={subMainMenu}
                >
                <ListItem sx={{p: 0,}}>
                  <ListItemIcon>
                    <CompanyInformationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Company Information" />
                  {(currentMenuIndex >= 1 && currentMenuIndex <= 9) ? <DownArrow /> : <UpArrow/>}
                </ListItem>
              </ListItemButton>

              <Collapse in={(currentMenuIndex >= 1 && currentMenuIndex <= 9)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 1}
                  onClick={(event) => handleSideDropDown(1)}
                  >
                    <ListItemText primary="Image & Description" />
                  </ListItemButton>

                  <ListItemButton sx={submenu}
                  selected={currentMenuIndex === 2}
                  onClick={(event) => handleSideDropDown(2)}
                  >
                    <ListItemText primary="We’re Looking For" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 3}
                  onClick={(event) => handleSideDropDown(3)}
                  >
                    <ListItemText primary="Location" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 4}
                  onClick={(event) => handleSideDropDown(4)}
                  >
                    <ListItemText primary="Budget for Basic Salaries" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 5}
                  onClick={(event) => handleSideDropDown(5)}
                  >
                    <ListItemText primary="Recruitment Focus" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 6}
                  onClick={(event) => handleSideDropDown(6)}
                  >
                    <ListItemText primary="Commission Structure & Incentives" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 7}
                  onClick={(event) => handleSideDropDown(7)}
                  >
                    <ListItemText primary="Interview Process" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 8}
                  onClick={(event) => handleSideDropDown(8)}
                  >
                    <ListItemText primary="Company Culture" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 9}
                  onClick={(event) => handleSideDropDown(9)}
                  >
                    <ListItemText primary="Reviews" />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />

              {isMainCompany &&
                <>
                <ListItemButton
                selected={currentMenuIndex === 10}
                onClick={(event) => handleSideDropDown(10)}
                >
                  <ListItemIcon>
                    <Company/>
                  </ListItemIcon>
                  <ListItemText primary="Sister Companies" />
                </ListItemButton>
                <Divider />
                </>
              }
              <ListItemButton
              selected={currentMenuIndex === 11}
              onClick={(event) => handleSideDropDown(11)}
              >
                <ListItemIcon>
                  <LocationIcon/>
                </ListItemIcon>
                <ListItemText primary="Office Locations" />
              </ListItemButton>
              <Divider />

              <ListItemButton
              selected={currentMenuIndex === 12}
              onClick={(event) => handleSideDropDown(12)}
              >
                <ListItemIcon>
                  <Gallery/>
                </ListItemIcon>
                <ListItemText primary="Gallery" />
              </ListItemButton>
              <Divider />

              <ListItemButton 
                selected={(currentMenuIndex >= 13 && currentMenuIndex <= 20)}
                onClick={(event) => {
                  handleSideDropDown(13);
                }} sx={subMainMenu} >
                <ListItem sx={{p: 0,}}>
                  <ListItemIcon>
                    <CompanyInformationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Perks & Culture" />
                  {(currentMenuIndex >= 13 && currentMenuIndex <= 20) ? <DownArrow /> : <UpArrow/>}
                </ListItem>
              </ListItemButton>
              <Collapse in={(currentMenuIndex >= 13 && currentMenuIndex <= 20)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 13}
                  onClick={(event) => handleSideDropDown(13)}
                  >
                    <ListItemText primary="Learning & Development" />
                  </ListItemButton>

                  <ListItemButton sx={submenu}
                  selected={currentMenuIndex === 14}
                  onClick={(event) => handleSideDropDown(14)}
                  >
                    <ListItemText primary="Time Off" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 15}
                  onClick={(event) => handleSideDropDown(15)}
                  >
                    <ListItemText primary="Flexible Working" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 16}
                  onClick={(event) => handleSideDropDown(16)}
                  >
                    <ListItemText primary="Financial Benefits" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 17}
                  onClick={(event) => handleSideDropDown(17)}
                  >
                    <ListItemText primary="Inclusion & Diversity" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 18}
                  onClick={(event) => handleSideDropDown(18)}
                  >
                    <ListItemText primary="Health & Wellness" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 19}
                  onClick={(event) => handleSideDropDown(19)}
                  >
                    <ListItemText primary="Office Environment" />
                  </ListItemButton>

                  <ListItemButton sx={submenu} 
                  selected={currentMenuIndex === 20}
                  onClick={(event) => handleSideDropDown(20)}
                  >
                    <ListItemText primary="Mental Health Support" />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
              <ListItemButton
              selected={currentMenuIndex === 21}
              onClick={(event) => handleSideDropDown(21)}
              >
                <ListItemIcon>
                  <Phone/>
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>

      </List>
    </Box>
  );
}


const menuList = (theme: Theme) => ({
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
  },
  '& .MuiListItemButton-root': {
    paddingTop: '6px',
    paddingBottom: '6px',
    '&.Mui-selected': {
      backgroundColor: '#5c67ea !important',
      '& .MuiListItemIcon-root': {
        '& svg path': {
          fill: '#fff !important',
        }
      },  
      '& .MuiTypography-body1': {
        color: '#fff !important',
      },
      '&:hover': {

      }
    }
  },
  [theme.breakpoints.down(1250)]: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    }
  }
})

const subMainMenu = (theme: Theme) => ({
  '&.MuiListItemButton-root': {
    '&.Mui-selected': {
      backgroundColor: 'rgba(30 50 150 / 8%) !important',
      '& .MuiListItemIcon-root': {
        '& svg path': {
          fill: 'rgb(0 0 0 / 54%) !important',
        }
      },  
      '& .MuiTypography-body1': {
        color: 'rgba(0, 0, 0, 0.88) !important',
      }
    }
  }
})


const submenu = (theme: Theme) => ({
  pl: 6,
  [theme.breakpoints.down(1250)]: {
  }
})

const tabListBox = (theme: Theme) => ({
  width: '18%',
  backgroundColor: '#fff',
  borderRadius: theme.spacing(.5),
  position: 'sticky',
  top: '96px',
  overflow: 'hidden',
  [theme.breakpoints.down(1250)]: {
    width: '25%',
  }
})

const titleBox = (theme: Theme) => ({
  backgroundColor: '#E0E0E0',
  padding: theme.spacing(1, 2),
})

