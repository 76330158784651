import { diff } from 'deep-object-diff'
import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional } from 'type-fest'
import { authMutation } from '../services/api'

export const salaryHistorySelector = ApiSelector('SalaryHistory')({
  id: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
  history: {
    id: true,
    from: true,
    to: true,
    year: true,
  }
})

type SalaryHistoryFields = ApiInputType<ApiGraphQLTypes['SalaryHistory'], typeof salaryHistorySelector>
type CreateOrUpdateSalaryHistoryFields = SetOptional<SalaryHistoryFields, 'id'>

type MutationProps = {
  values: CreateOrUpdateSalaryHistoryFields,
  oldValues?: SalaryHistoryFields
}

export const useCreateOrUpdateSalaryHistory = (selector: PartialDeep<typeof salaryHistorySelector>) => {
  return useMutation(async ({ values, oldValues }: MutationProps): Promise<SalaryHistoryFields> => {
    const { id, ...salaryHistory } = values

    if (id) {
      const data = await authMutation({
        updateSalaryHistory: [
          {
            id,
            input: {
              currency: {
                connect: {
                  id: salaryHistory.currency.id
                }
              },
              schedule: salaryHistory.schedule,
              history: {
                createMany: {
                  data: salaryHistory.history?.filter(({ id }) => !id)?.map(({ from, to, year }) => ({
                    from, to, year
                  })) || []
                },
                update: salaryHistory.history?.filter(({ id }) => id)?.map(({ id, from, to, year }) => ({
                  data: { from, to, year },
                  where: { id }
                })),
                deleteMany: oldValues?.history?.filter(({ id: oldId }) => {
                  return salaryHistory.history?.some(({ id }) => id === oldId) === false
                }).map(({ id }) => {
                  return {
                    id: {
                      equals: id
                    }
                  }
                }),
              }
            },
          },
          salaryHistorySelector,
        ],
      })

      return data.updateSalaryHistory
    }

    const data = await authMutation({
      createSalaryHistory: [{
        input: {
          currency: {
            connect: {
              id: salaryHistory.currency.id
            }
          },
          schedule: salaryHistory.schedule,
          history: {
            createMany: {
              data: salaryHistory.history?.map(({ from, to, year }) => ({
                from, to, year
              })) || []
            }
          }
        }
      },
        salaryHistorySelector
      ],
    })

    return data.createSalaryHistory
  })
}
