import { LocaleObject } from 'yup'

export const customLocale: LocaleObject = {
  mixed: {
    required: 'This field is required',
    notType: (params) => `Value must be a correct ${params.type}`,
  },
  number: {
    integer: 'Value must be an integer',
    lessThan: (x) => x.less !== undefined ? `Value must be less than ${x.less}` : '',
    moreThan: (x) => x.more !== undefined ? `Value must be more than ${x.more}` : '',
    negative: 'Value must be negative',
    positive: 'Value must be positive',
  },
  string: {
    max: (x) => `You can’t use more than ${x.max} characters`,
    min: (x) => `You can’t use less than ${x.min} characters`,
  },
  array: {
    max: (x) => `You can’t use more than ${x.max} characters`,
    min: (x) => 'This field is required',
  },
  object: {
    noUnknown: 'This field is required',
  },
}
