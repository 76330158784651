import { Box, Theme, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { SliderField } from '../../ui-kits/slider-field/slider-field'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from '../steps/position-information'

const companySelector = ApiSelector('Company')({
  lookingForPeople: true,
})

type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>

interface Props extends CompanyFields {
  isSaving: boolean
  onSave: (lookingForPeople: CompanyFields['lookingForPeople']) => void
}

export const MIN = 1
export const MAX = 200

export const AmountOfPeople = ({ isSaving, onSave, lookingForPeople }: Props) => {
  const { t } = useTranslation()
  const initialValues = {
    lookingForPeople: lookingForPeople || 0,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        await onSave(values.lookingForPeople)
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, handleBlur, errors }) => {
        return (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                mb: '90px',
              }}
            >
              <Typography sx={titleTextStyle}>{t('formFields.companyLookingForPeopleTitle')}</Typography>
              <SliderField
                name={'lookingForPeople'}
                value={values.lookingForPeople}
                aria-label='Always visible'
                step={1}
                max={MAX}
                min={MIN}
                valueLabelDisplay='on'
                onChange={(event: Event, newValue: number | number[]) => {
                  setFieldValue('lookingForPeople', newValue)
                }}
                marks={[
                  {
                    value: 1,
                    label: `${MIN}`,
                  },
                  {
                    value: MAX,
                    label: `${MAX}`,
                  },
                ]}
                helperText={errors?.lookingForPeople}
              />
            </Box>
            <LoadingButton
              variant='contained'
              loading={isSaving}
              sx={buttonStyle}
              type='submit'
              size={'medium'}
              disabled={!values.lookingForPeople}
            >
              {t('save')}
            </LoadingButton>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  lookingForPeople: Yup.number().min(1).max(MAX).required(),
})

export const titleTextStyle = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]:{
    fontSize: '16px'
  }
})
