import { ReactNode } from 'react'
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FieldProps, getIn } from 'formik'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

export interface DatePickerProps<TInputDate, TDate>
  extends FieldProps,
    Omit<MuiDatePickerProps<TInputDate, TDate>, 'name' | 'value' | 'error'> {
  textField?: TextFieldProps
}

export function fieldToDatePicker<TInputDate, TDate>({
  field: { onChange: _onChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched },
  textField: { helperText, onBlur, ...textField } = {},
  disabled,
  label,
  onChange,
  onError,
  renderInput,
  ...props
}: DatePickerProps<TInputDate, TDate>): MuiDatePickerProps<TInputDate, TDate> {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    renderInput:
      renderInput ??
      ((params) => (
        <TextField
          {...params}
          error={showError}
          helperText={showError ? fieldError : helperText}
          label={label}
          onBlur={
            onBlur ??
            function () {
              setFieldTouched(field.name, true, true)
            }
          }
          {...textField}
        />
      )),
    disabled: disabled ?? isSubmitting,
    onChange:
      onChange ??
      function (date: Dayjs) {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        setFieldTouched(field.name, true, false)
        setFieldValue(field.name, date ? date.toDate() : null, true)
      },
    onError: onError ?? createErrorHandler(fieldError, field.name, setFieldError),
    ...field,
    ...props,
  }
}

export function DatePicker<TInputDate, TDate>({ children, ...props }: DatePickerProps<TInputDate, TDate>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker {...fieldToDatePicker(props)}>{children}</MuiDatePicker>
    </LocalizationProvider>
  )
}

DatePicker.displayName = 'FormikMUIDatePicker'

function createErrorHandler(
  fieldError: unknown,
  fieldName: string,
  setFieldError: (field: string, message?: string) => void,
) {
  return (error?: ReactNode) => {
    if (error !== fieldError && error !== '') {
      setFieldError(fieldName, error ? String(error) : undefined)
    }
  }
}
