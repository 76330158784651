import { SvgProps } from '../types/icon'

export const Specialization = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height}>
      <path
        d='M6.88235 0C6.25831 0 5.65983 0.247898 5.21857 0.68916C4.77731 1.13042 4.52941 1.7289 4.52941 2.35294V8.23529H5.70588V2.35294C5.70588 2.04092 5.82983 1.74168 6.05046 1.52105C6.27109 1.30042 6.57033 1.17647 6.88235 1.17647H11.5882V5.29412C11.5882 5.76215 11.7742 6.21101 12.1051 6.54195C12.4361 6.8729 12.8849 7.05882 13.3529 7.05882H17.4706V16.4706C17.4706 16.7826 17.3466 17.0819 17.126 17.3025C16.9054 17.5231 16.6061 17.6471 16.2941 17.6471H13.9412V18.8235H16.2941C16.9182 18.8235 17.5166 18.5756 17.9579 18.1344C18.3992 17.6931 18.6471 17.0946 18.6471 16.4706V6.36941C18.6466 5.90154 18.4605 5.45298 18.1294 5.12235L13.5247 0.51647C13.1939 0.185861 12.7453 9.98604e-05 12.2776 0H6.88235ZM17.2271 5.88235H13.3529C13.1969 5.88235 13.0473 5.82038 12.937 5.71006C12.8267 5.59975 12.7647 5.45013 12.7647 5.29412V1.42L17.2271 5.88235ZM4.52941 10C4.52941 9.84399 4.59139 9.69437 4.7017 9.58405C4.81202 9.47374 4.96164 9.41177 5.11765 9.41177H8.64706C8.80307 9.41177 8.95269 9.47374 9.063 9.58405C9.17332 9.69437 9.23529 9.84399 9.23529 10V11.7647H10.4118C11.0358 11.7647 11.6343 12.0126 12.0755 12.4539C12.5168 12.8951 12.7647 13.4936 12.7647 14.1176V17.6471C12.7647 18.2711 12.5168 18.8696 12.0755 19.3108C11.6343 19.7521 11.0358 20 10.4118 20H3.35294C2.7289 20 2.13042 19.7521 1.68916 19.3108C1.2479 18.8696 1 18.2711 1 17.6471V14.1176C1 13.4936 1.2479 12.8951 1.68916 12.4539C2.13042 12.0126 2.7289 11.7647 3.35294 11.7647H4.52941V10ZM8.05882 10.5882H5.70588V11.7647H8.05882V10.5882ZM3.35294 12.9412C3.04092 12.9412 2.74168 13.0651 2.52105 13.2858C2.30042 13.5064 2.17647 13.8056 2.17647 14.1176V17.6471C2.17647 17.9591 2.30042 18.2583 2.52105 18.479C2.74168 18.6996 3.04092 18.8235 3.35294 18.8235H10.4118C10.7238 18.8235 11.023 18.6996 11.2437 18.479C11.4643 18.2583 11.5882 17.9591 11.5882 17.6471V14.1176C11.5882 13.8056 11.4643 13.5064 11.2437 13.2858C11.023 13.0651 10.7238 12.9412 10.4118 12.9412H3.35294Z'
        fill='rgba(20, 29, 36, 0.4)'
        stroke='rgba(20, 29, 36, 0.4)'
        strokeWidth='0.5'
      />
    </svg>
  )
}
