import React, { useMemo, useState } from 'react'
import {
  Box,
  Card,
  Typography,
  Theme,
  CircularProgress,
  Link,
  Tooltip,
  useMediaQuery,
  Grid,
  CardMedia,
  Avatar
} from '@mui/material'
import { Button } from '../components/ui-kits/button/button'
import { BoxContent } from '../components/ui-kits/box-content/box-content'
import { MyJobHeader } from '../components/not-cms/my-job-header'
import { Content } from '../components/common-files/common-styles'
import { MenuNavigation } from '../components/not-cms/ui-kits/menu/menu-navigation'
import AlertDialog from '../components/not-cms/ui-kits/alert-dialog/alert-dialog'
import TempWrapperNew from '../components/tempWrapperNew'
import { useAuthQueryRequest } from '../services/api'
import { ApiTypes, ApiGraphQLTypes, ApiSelector, ApiInputType } from 'recruticka-frontend-libs'
import { useNavigate, useSearchParams, generatePath, Link as RouterLink } from 'react-router-dom'
import { useDate } from '../hooks/useDate'
import { PAGES } from '../router/AppRoutes'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileCompanyId, profileState } from '../state/atoms/profileState'
import { useArchiveJob, useDeleteJob, useDuplicateJob, useRestoreJob, useCreateJob } from '../hooks/useJob'
import { useQueryClient } from 'react-query'
import { useList } from '../hooks/useList'
import theme from '../styles/theme'
import { Pagination } from '../components/ui-kits/pagination/pagination';
import { LoadingButton } from '@mui/lab'
import { buildAssetUrl } from '../services/buildAssetUrl';
import { assetSelector } from '../types/asset';
import { EJobsBoxTypes } from '../types/jobs';
// Icon
import { LocationIcon } from '../components/icons/location'
import { Company } from '../components/icons/company'
import { Job } from '../components/icons/job'
import { CreditCard } from '../components/icons/credit-card'
import AddIcon from '@mui/icons-material/Add'
import { useCurrencyFormatter } from '../hooks/useCurrencyFormatter';
import { JobsHotInformationList } from '../components/jobs-hot-information-list';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const jobSelector = ApiSelector('Job')({
  id: true,
  title: true,
  slug: true,
  views: true,
  status: true,
  companyId: true,
  publishedAt: true,
  coverImage: assetSelector,
  company: {
    name: true,
    logo: assetSelector
  },
  shortDescription: true,
  sectors: {
    name: true,
  },
  salary: {
    from: true,
    to: true,
    currency: {
      id: true
    }
  },
  salaryType: true,
  location: {
    type: true,
    wfhDays: true,
    offices: {
      city: {
        name: true
      }
    }
  },
  _count: {
    application: true
  }
})

type JobFields = ApiInputType<ApiGraphQLTypes['Job'], typeof jobSelector>


const MyJobs = () => {
  const { id: mainCompanyId } = useRecoilValue(mainCompanyState)
  const duplicateMutation = useDuplicateJob(jobSelector)
  const deleteMutation = useDeleteJob()
  const [companyId, setCompanyId] = useState<string>(mainCompanyId)
  const archiveMutation = useArchiveJob({ id: true })
  const restoreMutation = useRestoreJob({ id: true })
  const [searchParams, setSearchParams] = useSearchParams()
  const status: ApiTypes.JobStatus = (searchParams.get('status') as ApiTypes.JobStatus) || ApiTypes.JobStatus.ACTIVE
  const queryClient = useQueryClient()
  const { page, perPage, orderBy, updatePage, updatePerPage } = useList()
  const matchesSmall = useMediaQuery(theme.breakpoints.down(489));
  

  const setStatus = (status: ApiTypes.JobStatus) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      status
    })
  }

  const [alert, setAlert] = useState<IAlert>(initialAlert)

  const jobsParams = { status, rowsPerPage: perPage, page, orderBy }
  const {
    data: jobsData,
    isLoading: jobDataLoading,
    isRefetching: jobDataRefetching
  } = useAuthQueryRequest(['jobs', companyId, { status, rowsPerPage: perPage, page, orderBy }], {
    getJobs: [
      {
        take: perPage,
        skip: page * perPage,
        where: {
          companyId: {
            equals: companyId
          },
          status: {
            equals: status
          }
        },

        orderBy
      },
      jobSelector
    ],
    countJobs: [
      {
        where: {
          companyId: {
            equals: companyId
          },
          status: {
            equals: status
          }
        }
      },
      true
    ]
  })

  const onSortChange = (cell: string, direction: ApiTypes.SortOrder) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      orderBy: `${cell}:${direction}`
    })
  }

  const removeJobFromCache = (id: string) => {
    queryClient.setQueryData(['jobs', companyId, jobsParams], {
      getJobs: (jobsData?.getJobs || []).filter((job) => job.id !== id),
      countJobs: jobsData?.countJobs ? jobsData?.countJobs - 1 : 0
    })
  }

  const onJobDuplicate = async (id: string) => {
    const newJob = await duplicateMutation.mutateAsync(id)

    queryClient.setQueryData(['jobs', companyId, jobsParams], {
      getJobs: [...(jobsData?.getJobs || []), newJob],
      countJobs: (jobsData?.countJobs || 0) + 1
    })
  }

  const onJobRestore = async (id: string) => {
    await restoreMutation.mutateAsync(id)
    removeJobFromCache(id)
  }

  const onJobArchive = async (id: string) => {
    await archiveMutation.mutateAsync(id)
    removeJobFromCache(id)
  }

  const showDeleteModal = (id: string) => {
    setAlert({
      isOpen: true,
      id
    })
  }

  const onJobDelete = async (id: string) => {
    await deleteMutation.mutateAsync(id)
    removeJobFromCache(id)
  }

  const createJobMutation = useCreateJob({
    id: true,
  })

  const mainCompany = useRecoilValue(mainCompanyState)
  const profile = useRecoilValue(profileState)
  const navigate = useNavigate()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))

  const companies = useMemo(() => {
    return profile?.companies?.filter(company => company.status === ApiTypes.CompanyStatus.ACTIVE)
  },[profile?.companies?.map(company => company.status), profile?.companies?.length])

  const createJob = async () => {
    if (profile?.id && mainCompanyId) {
      const { id } = await createJobMutation.mutateAsync({
        companyId: mainCompanyId,
        title: 'New job',
      })
      queryClient.removeQueries(['job'])
      navigate(
        generatePath(PAGES.JOB_EDIT, {
          profileId: profile.id,
          jobId: id,
        }),
      )
    }
  }


  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}>
          <Box sx={titleWrapper}>
            <Typography variant={'h4'}>{'Jobs'}</Typography> 
            <Box sx={buttonWrap}>
                <LoadingButton
                    loading={createJobMutation.isLoading}
                    variant={matchSm ? 'outlined' : 'contained'}
                    startIcon={
                      <AddIcon
                        sx={
                          !companies?.length ? {'& path': {fill: 'rgba(0, 0, 0, 0.26)!important'}} : {}
                        }
                      />
                    }
                    size={'small'}
                    onClick={createJob}
                    disabled={!companies?.length}
                  >
                    Add New Job
                </LoadingButton>
            </Box>
          </Box>

          <MyJobHeader setCompanyId={setCompanyId} companyId={companyId} status={status} setStatus={setStatus} />
            
          {jobDataRefetching && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}

          {!jobDataRefetching && (
            <>
            <Grid container spacing={2}>
              {(jobsData?.getJobs || []).map((job, index) => {
                  return (<JobCard
                    key={job.id}
                    job={job}
                    onDuplicate={onJobDuplicate}
                    onArchive={onJobArchive}
                    onDelete={showDeleteModal}
                    onRestore={onJobRestore}
                />)
              })}
            </Grid>
            {/* <Pagination
              page={page + 1}
              count={jobsData?.countJobs || 0}
              hidePrevButton
              sx={(matchesSmall && (jobsData?.countJobs || 0) > 4) ? paginationStyle : paginationWrapper}
              onChange={(_, page) => updatePerPage(page - 1)}
            /> */}
              {/* pagination={{
              rowsPerPage: perPage,
              page,
              onPageChange: updatePage,
              onRowsPerPageChange: updatePerPage,
              count: jobsData?.countJobs || 0
            }}
            onSortChange={onSortChange} */}
            </>
          )}

          {alert.isOpen && (
            <AlertDialog
              isLoading={deleteMutation.isLoading}
              isOpen={alert.isOpen}
              onClose={() => setAlert(initialAlert)}
              onConfirm={async () => {
                if (alert.id) {
                  await onJobDelete(alert.id)
                }
              }}
              description={'Deleting a job cannot be undone. Are you sure you want to delete this job?'}
            />
          )}

          {!companies?.length && (
            <Card sx={noRecord}>
                <Box>
                  <Typography variant={'h5'} sx={{ mb: 2, }}> No Jobs Found </Typography>
                  <Typography variant={'body1'}> Create Company Before Post Jobs</Typography>
                  <Button sx={{ mt: 2, }}
                    size='squareSmall'
                    onClick={() => {navigate(
                      generatePath(PAGES.COMPANIES, {
                        profileId: profile?.id,
                        companyId: mainCompany?.id
                      })
                    )
                    }}
                    endIcon={<NavigateNextIcon fill='#fff' />}
                    >
                      Create Company
                </Button>
                </Box> 
            </Card>
          )}
      </Content>
    </TempWrapperNew>
  )
}

interface JobCardProps {
  job: JobFields
  onDuplicate: (id: string) => void
  onArchive: (id: string) => void
  onDelete: (id: string) => void
  onRestore: (id: string) => void
}

const JobCard = ({ job, onArchive, onDelete, onDuplicate, onRestore }: JobCardProps) => {

  const { t } = useTranslation();
  const profile = useRecoilValue(profileState)
  const { id: profileId } = useRecoilValue(profileState)
  const publishedAt = useDate(job.publishedAt as Date)
  const navigate = useNavigate()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const salaryFrom = job.salary?.from ? useCurrencyFormatter(job.salary.from as number, job.salary.currency.id) : null;
  const salaryTo = job.salary?.to ? useCurrencyFormatter(job.salary.to as number, job.salary.currency.id) : null;

  return (
    <Grid item sm={4} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Card sx={jobCardBox}>
        <Box sx={wrapper}>
          <Box sx={[cardCoverImg, spacingBetweenItems]}>
          {!!job.coverImage &&<CardMedia
            component='img'
            image={buildAssetUrl(job.coverImage)}
            alt='photo'
            sx={[{height: '100%', objectFit: 'cover'}]}
          />}
          </Box>
          <Box display={'flex'} alignItems='center' sx={[spacingBetweenItems, companyLogoBox]}>
            <Avatar src={buildAssetUrl(job.company.logo)} alt='MasterCardLogo' sx={avatarStyle} />
            <Box ml={1}>
              <Typography variant='h5' sx={companyTitleWrapper}>
                <Link 
                  component={RouterLink}
                  to={generatePath(PAGES.JOB, {
                    profileId: profile?.id,
                    jobId: job.id
                  })}
                  color={'inherit'}>
                  {job.title}
                </Link>
              </Typography>
              <Typography variant='body2' sx={companysubtitle}>{job.company.name}</Typography>
            </Box>
            
            <Box sx={menuNavigation}>
              <MenuNavigation
                options={[
                  ...(job.status === ApiTypes.JobStatus.DRAFT
                    ? [
                      {
                        name: 'Set Live',
                        onChange: () => {
                          navigate(
                            generatePath(PAGES.JOB_EDIT, {
                              profileId: profileId,
                              jobId: job.id
                            })
                          )
                        }
                      }
                    ]
                    : []),

                  ...(job.status === ApiTypes.JobStatus.ARCHIVED
                    ? [
                      {
                        name: 'Restore to Draft',
                        onChange: () => onRestore(job.id)
                      }
                    ]
                    : []),

                  {
                    name: 'Edit',
                    onChange: () => {
                      navigate(
                        generatePath(PAGES.JOB_EDIT, {
                          profileId: profileId,
                          jobId: job.id
                        })
                      )
                    }
                  },

                  { name: 'Duplicate', onChange: () => onDuplicate(job.id) },

                  ...(job.status !== ApiTypes.JobStatus.ARCHIVED
                    ? [
                      {
                        name: 'Archive',
                        onChange: () => onArchive(job.id)
                      }
                    ]
                    : []),

                  { name: 'Delete', onChange: () => onDelete(job.id) }
                ]}
              />
            </Box>
          
          </Box>
          <Box sx={contentBox}>
            <Box sx={iconBoxWrap}>
              <Box sx={iconBox}>
                <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path opacity='0.15' d='M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z' fill='#FFBE40'/>
                  <path d='M10.5625 8H13.9375V7.0625C13.9375 6.75781 14.1719 6.5 14.5 6.5C14.8047 6.5 15.0625 6.75781 15.0625 7.0625V8H16C16.8203 8 17.5 8.67969 17.5 9.5V17C17.5 17.8438 16.8203 18.5 16 18.5H8.5C7.65625 18.5 7 17.8438 7 17V9.5C7 8.67969 7.65625 8 8.5 8H9.4375V7.0625C9.4375 6.75781 9.67188 6.5 10 6.5C10.3047 6.5 10.5625 6.75781 10.5625 7.0625V8ZM8.125 12.3125H10V11H8.125V12.3125ZM8.125 13.4375V14.9375H10V13.4375H8.125ZM11.125 13.4375V14.9375H13.375V13.4375H11.125ZM14.5 13.4375V14.9375H16.375V13.4375H14.5ZM16.375 11H14.5V12.3125H16.375V11ZM16.375 16.0625H14.5V17.375H16C16.1875 17.375 16.375 17.2109 16.375 17V16.0625ZM13.375 16.0625H11.125V17.375H13.375V16.0625ZM10 16.0625H8.125V17C8.125 17.2109 8.28906 17.375 8.5 17.375H10V16.0625ZM13.375 11H11.125V12.3125H13.375V11Z' fill='#FFC728'/>
                </svg>
                <Typography
                  variant={'body2'}
                  color={(theme: Theme) => theme.palette.text.secondary}
                >
                {publishedAt}
                </Typography>
              </Box>
              <Box sx={iconBox}>
                  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <rect width='25' height='25' rx='12.5' fill='#FFBE40' fillOpacity='0.15'/>
                    <path d='M12.5 12.5002C11.2679 12.5002 10.1429 11.8574 9.52679 10.7859C8.91071 9.74128 8.91071 8.42878 9.52679 7.35735C10.1429 6.31271 11.2679 5.64307 12.5 5.64307C13.7054 5.64307 14.8304 6.31271 15.4464 7.35735C16.0625 8.42878 16.0625 9.74128 15.4464 10.7859C14.8304 11.8574 13.7054 12.5002 12.5 12.5002ZM11.2679 13.7859H13.7054C16.3571 13.7859 18.5 15.9288 18.5 18.5806C18.5 19.0091 18.125 19.3574 17.6964 19.3574H7.27679C6.84821 19.3574 6.5 19.0091 6.5 18.5806C6.5 15.9288 8.61607 13.7859 11.2679 13.7859Z' fill='#FFBE40'/>
                  </svg>
                <Typography
                  variant={'body2'}
                  color={(theme: Theme) => theme.palette.text.secondary}
                >
                  {job._count?.application || 0}
                </Typography>
              </Box>
              {/* <Box sx={iconBox}>
                  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <rect width='25' height='25' rx='12.5' fill='#FFBE40' fillOpacity='0.15'/>
                    <path d='M12.5117 7.27734C14.3769 7.27734 15.8924 8.14001 16.9882 9.16588C18.084 10.1684 18.8068 11.3808 19.1565 12.2202C19.2265 12.4067 19.2265 12.6166 19.1565 12.8031C18.8068 13.6191 18.084 14.8315 16.9882 15.8574C15.8924 16.8833 14.3769 17.7226 12.5117 17.7226C10.6231 17.7226 9.10762 16.8833 8.0118 15.8574C6.91598 14.8315 6.1932 13.6191 5.84348 12.8031C5.77353 12.6166 5.77353 12.4067 5.84348 12.2202C6.1932 11.3808 6.91598 10.1684 8.0118 9.16588C9.10762 8.14001 10.6231 7.27734 12.5117 7.27734ZM9.15425 12.5C9.15425 13.7124 9.78376 14.8082 10.833 15.4144C11.8588 16.0206 13.1412 16.0206 14.1904 15.4144C15.2162 14.8082 15.8691 13.7124 15.8691 12.5C15.8691 11.3109 15.2162 10.2151 14.1904 9.60888C13.1412 9.00268 11.8588 9.00268 10.833 9.60888C9.78376 10.2151 9.15425 11.3109 9.15425 12.5ZM12.5117 11.0078C12.5117 10.8446 12.465 10.7047 12.4184 10.5415C12.3718 10.4249 12.465 10.2617 12.6049 10.2617C13.5375 10.3083 14.4002 10.9612 14.6567 11.9404C14.9831 13.1295 14.2836 14.3419 13.0712 14.6683C11.8821 14.9947 10.6464 14.2719 10.3433 13.0829C10.2967 12.9197 10.2734 12.7564 10.2734 12.6166C10.2501 12.4767 10.4133 12.3834 10.5299 12.43C10.6931 12.4767 10.833 12.5 11.0195 12.5C11.8355 12.5 12.5117 11.8471 12.5117 11.0078Z' fill='#FFC728'/>
                  </svg>
                <Typography
                  variant={'body2'}
                  color={(theme: Theme) => theme.palette.text.secondary}
                >
                {job.views}
                </Typography>
              </Box> */}
            </Box>
            
            <Box sx={spacingBetweenItems}>
              <Tooltip title={job.shortDescription || ''}
              arrow placement={'top'}>
                <Typography
                    component='div'
                    variant='body1'
                    sx={companyDescription}
                >
                  {job.shortDescription}
                </Typography>
              </Tooltip>
              {/* { JSON.stringify(job) } */}
            </Box>
            {/* <Box sx={[spacingBetweenItems, addressBox]}>
              <Grid sx={gridWrapper} container spacing={1}>
                <Grid item xs={6} sm={6}>
                  <BoxContent
                    sx={iconwrapper}
                  >
                    <LocationIcon/>
                      <Typography noWrap variant={'caption'} sx={{ flex: 1, width: '200px' }}>Location</Typography>
                  </BoxContent>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <BoxContent
                    sx={iconwrapper}
                  >
                    <Company/>
                      <Typography noWrap variant={'caption'} sx={{ flex: 1, width: '200px' }}>Company</Typography>
                  </BoxContent>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <BoxContent
                    sx={iconwrapper}
                  >
                    <CreditCard/>
                      <Typography noWrap variant={'caption'} sx={{ flex: 1, width: '200px' }}>Salary</Typography>
                  </BoxContent>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <BoxContent
                    sx={iconwrapper}
                  >
                    <Job/>
                      <Typography noWrap variant={'caption'} sx={{ flex: 1, width: '200px' }}> 5/5 WFH</Typography>
                  </BoxContent>
                </Grid>
              </Grid>
            </Box> */}

            <Box sx={[spacingBetweenItems, addressBox]}>
              <Grid sx={gridWrapper} container spacing={1}>
                {!!job.sectors?.length &&
                  <Grid item xs={6} sm={6}>
                    <JobsHotInformationList
                      type={EJobsBoxTypes.VACANCY_CATEGORY}
                      label={
                        (job.sectors || [])?.map(({ name }) => name).join(', ')
                      }
                    />
                  </Grid>
                }
                <Grid item xs={6} sm={6}>
                  <JobsHotInformationList
                    type={EJobsBoxTypes.SALARY}
                    label={job.salaryType === 'CONTRACT' ? `${salaryFrom} - ${salaryTo}` : t('enum.JobSalaryType.GET_IN_TOUCH')}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <JobsHotInformationList
                    type={EJobsBoxTypes.LOCATION}
                    label={
                      job.location?.type === 'REMOTE' ?
                        'Remote'
                        :
                        (job.location?.offices || [])?.map(({ city }) => city?.name).join(', ')
                    }
                  />
                </Grid>
                {job.location?.type === 'WFH' &&
                <Grid item xs={6} sm={6}>
                    {/* <JobsHotInformationList
                      type={EJobsBoxTypes.LOCATION}
                      label={
                        job.location?.wfhDays.toString()
                      }
                    /> */}
                        <BoxContent sx={boxContentBg}>
                          <Box>
                          <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path d='M8.01367 0.875C10.2012 0.875 11.9785 1.88672 13.2637 3.08984C14.5488 4.26562 15.3965 5.6875 15.8066 6.67188C15.8887 6.89062 15.8887 7.13672 15.8066 7.35547C15.3965 8.3125 14.5488 9.73438 13.2637 10.9375C11.9785 12.1406 10.2012 13.125 8.01367 13.125C5.79883 13.125 4.02148 12.1406 2.73633 10.9375C1.45117 9.73438 0.603516 8.3125 0.193359 7.35547C0.111328 7.13672 0.111328 6.89062 0.193359 6.67188C0.603516 5.6875 1.45117 4.26562 2.73633 3.08984C4.02148 1.88672 5.79883 0.875 8.01367 0.875ZM4.07617 7C4.07617 8.42188 4.81445 9.70703 6.04492 10.418C7.24805 11.1289 8.75195 11.1289 9.98242 10.418C11.1855 9.70703 11.9512 8.42188 11.9512 7C11.9512 5.60547 11.1855 4.32031 9.98242 3.60938C8.75195 2.89844 7.24805 2.89844 6.04492 3.60938C4.81445 4.32031 4.07617 5.60547 4.07617 7ZM8.01367 5.25C8.01367 5.05859 7.95898 4.89453 7.9043 4.70312C7.84961 4.56641 7.95898 4.375 8.12305 4.375C9.2168 4.42969 10.2285 5.19531 10.5293 6.34375C10.9121 7.73828 10.0918 9.16016 8.66992 9.54297C7.27539 9.92578 5.82617 9.07812 5.4707 7.68359C5.41602 7.49219 5.38867 7.30078 5.38867 7.13672C5.36133 6.97266 5.55273 6.86328 5.68945 6.91797C5.88086 6.97266 6.04492 7 6.26367 7C7.2207 7 8.01367 6.23438 8.01367 5.25Z' fill='##FFBE40'/>
                            </svg>
                          </Box>
                          <Typography variant={'subtitle2'}>
                            {job.views}
                          </Typography>
                        </BoxContent>
                  </Grid>
                }
              </Grid>
            </Box>
            
          </Box>
          <Box sx={cardActionContent}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                href={generatePath(PAGES.JOB_EDIT, {
                  profileId: profileId,
                  jobId: job.id
                })}
                LinkComponent={Link}
                sx={btnStyle}
                variant={'outlined'}
                size='squareMedium'
              >
                Edit
              </Button>
                <Button
                  size='squareMedium'
                  variant={'outlined'}
                  sx={btnStyle}
                  onClick={() => onDelete(job.id)}
                >
                  Delete
                </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}
interface IAlert {
  isOpen: boolean
  id?: string
}

const initialAlert: IAlert = {
  isOpen: false,
  id: undefined
}

const cardContentStyle  = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',

  [theme.breakpoints.down('md')]: {
    gap: '8px',
  }
});

const paginationStyle = (theme: Theme) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginX: '-24px'
  }
})

const paginationWrapper = (theme: Theme) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(6),
  },
});

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]:{
    gap: 0,
    pb: theme.spacing(4),
  }
})

const jobCardBox = (theme: Theme) => ({
  padding: '0',
  overflow: 'hidden',
  // height: 'auto',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '0',
  }
});

const gridWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('xs')]: {  
    '& .MuiGrid-item': {
      flexBasis: '50%',
      flexGrow: '0',
      maxWidth: '50%',
    }
  },
});

const cardCoverImg = (theme: Theme) => ({
  height: '150px',
});
const companyLogoBox = (theme: Theme) => ({
  padding: '0 24px 24px',
  borderBottom: '1px solid #EDF2F7',
  marginBottom: '16px !important',
});

const companyDescription = (theme: Theme) => ({
  height: '56px',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: '400',
  color:'#1e1e1e',
  mt: theme.spacing(1),
});

const companyTitleWrapper = (theme: Theme) => ({
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize:'14px',
});

const companysubtitle = (theme: Theme) => ({

});

const addressBox = (theme: Theme) => ({
});

const spacingBetweenItems = (theme: Theme) => ({
  borderRadius: '6px',
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(3),
  },
});

const avatarStyle = (theme: Theme) => ({
  width: '36px',
  height: '36px',
  border: '1px solid ${theme.palette.background.default}',
});

const btnStyle = (theme: Theme) => ({
    padding: '4px 24px',
    height: '36px',

  '& > .MuiButton-endIcon': {
    display: 'none',
  },
  '&:hover > .MuiButton-endIcon': {
    display: 'inherit',
  },
});

const wrapper = { display: 'flex', flexDirection: 'column', height: '100%' };

const cardActionContent = {
  borderTop: '1px solid #EDF2F7',
  padding: '24px 24px',
  marginTop: 'auto',
};

const iconBoxWrap = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
});

const iconBox = (theme: Theme) => ({
  display:'flex',
  alignItems: 'center',
  marginBottom: '8px',
  gap: '8px',
  '& p': {
    color: '#737373',
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: 600,
  },
});

const iconwrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor:'#FFF4E5',
  padding: '8px 10px',
  borderRadius: '4px',
  '& svg': {
    overflow: 'visible',
    width: '20px',
    height: '20px',
    '& path': {
      fill: '#FFBE40 !important',
      fillOpacity: '1',
    }
  }
})

const contentBox = (theme: Theme) => ({
  padding: '0 16px',
});

const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    mb: theme.spacing(1),
    // flexWrap: 'wrap',
    // flexDirection: 'column',
    // alignItems: 'start',
    gap: theme.spacing(2),
  }
})

const buttonWrap = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    '& a:last-of-type': {
      marginRight: '0px',
      marginTop: '16px',
    }
  }
})

const menuNavigation = (theme: Theme) => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  '& .MuiIconButton-root': {
    backgroundColor: '#F6F7FB',
    borderRadius: '4px',
    padding: '4px',
  }
})

const noRecord = (theme: Theme) => ({
  // maxWidth: '400px',
  // margin: '0 auto',
  width: '100%',
  textAlign: 'center',
  '& .MuiBox-root': {
    padding: '32px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
  }
})

const boxContentBg = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor:'#FFF4E5',
  gap: theme.spacing(1),
  // width: '48%',
  alignItems:'center',
  '& .MuiBox-root': {
    display: 'flex',
    '& svg':{
      '& path': {
        fill: theme.palette.secondary.main,
        fillOpacity: 1,
      },
    },
  },
  '& h6': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]:{
    // backgroundColor: 'unset',
    // paddingLeft: 'unset',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // borderRadius: 'unset'
  },
  [theme.breakpoints.down('xs')]:{
    '& h6': {
      fontSize: '10px',
      lineHeight: '16px',
    }
  }
});


export default MyJobs
