import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { ApiTypes } from 'recruticka-frontend-libs'

export const PER_PAGE = 6

export const useList = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = useMemo(() => {
    const searchParamsPage = searchParams.get('page')

    if (searchParamsPage) {
      return parseInt(searchParamsPage)
    }

    return 0
  }, [searchParams])

  const perPage = useMemo(() => {
    const searchParamsPerPage = searchParams.get('perPage')

    if (searchParamsPerPage) {
      return parseInt(searchParamsPerPage)
    }

    return 5
  }, [searchParams])

  const updatePage = (newPage: number) => {
    if (page === newPage) {
      return
    }

    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(newPage),
    })
  }

  const candidatesPage = useMemo(() => {
    const searchParamsPage = searchParams.get('page')

    if (searchParamsPage) {
      return parseInt(searchParamsPage)
    }

    return 1
  }, [searchParams])

  const updatePerPage = (newPerPage: number) => {
    if (newPerPage === perPage) {
      return
    }

    setSearchParams({
      ...Object.fromEntries(searchParams),
      perPage: String(newPerPage),
    })
  }

  const updateCandidatesPage = (newPage: number) => {
    if (page === newPage) {
      return
    }

    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: String(newPage),
    })
  }

  const orderBy = useMemo(() => {
    const key = searchParams.get('orderBy')

    if (!key) {
      return []
    }

    const [name, direction] = key.split(':')

    if (name === 'application') {
      return [
        {
          [name]: {
            _count: direction as ApiTypes.SortOrder
          }
        }
      ]
    }

    return [
      {
        [name]: direction as ApiTypes.SortOrder,
      },
    ]
  }, [searchParams])

  const orderByApplication = useMemo(() => {
    const key = searchParams.get('orderBy')

    if (!key) {
      return []
    }

    const [name, direction] = key.split(':')

    return [
      {
        [name]: direction as ApiTypes.SortOrder,
      },
    ]
  }, [searchParams])

  const calcTotalPages = (totalCount: number) => Math.ceil(totalCount / PER_PAGE)

  return {
    page,
    perPage,
    orderBy,
    skip: page * PER_PAGE,
    candidatesSkip: (candidatesPage - 1) * PER_PAGE,
    candidatesPage,
    updatePage,
    updatePerPage,
    updateCandidatesPage,
    calcTotalPages,
    orderByApplication,
  }
}
