import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/theme'
import { QueryClientProvider } from 'react-query'
import './i18n/config'
import { queryClient } from './react-query.client'
import { ReactQueryDevtools } from 'react-query/devtools'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { AppRoutes } from './router/AppRoutes'
import { ChatWindow } from './components/chat/ChatWindow'

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRoutes />
            <ChatWindow />
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </RecoilRoot>
    </ThemeProvider>
  )
}

export default App
