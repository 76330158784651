import { Field, useField } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import {ReactElement, SyntheticEvent, useState} from 'react';
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs';
import { useQueryRequest } from '../../services/api';
import { autocompleteField, SpecializationFields } from './SpecializationsAutocomplete'
import { useTranslation } from 'react-i18next';
import {CircularProgress, TextField, InputAdornment, Chip, Theme, Box} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Portfolio } from '../icons/portfolio';

export const sectorSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})

export type SectorFields = ApiInputType<ApiGraphQLTypes['Sector'], typeof sectorSelector>

export const SectorAutocomplete = () => {
  const { t } = useTranslation();
  const [sectorField, sectorMeta, sectorHelpers] = useField<SectorFields[]>('sectors');
  const [specializationField, , specializationHelpers] = useField<SpecializationFields[]>('specializations');
  const [loadSectors, setLoadSectors] = useState(false);
  const { data: sectorsData, isLoading: sectorsDataLoading } = useQueryRequest(
    ['sectors'],
    {
      getSectors: [{ take: 100 }, sectorSelector],
    },
    {
      enabled: loadSectors,
    },
  )

  return (
    <Field
      popupIcon={<KeyboardArrowDownIcon />}
      fullWidth
      multiple
      loading={sectorsDataLoading}
      name={sectorField.name}
      onOpen={() => setLoadSectors(true)}
      onChange={(event: SyntheticEvent, sectors: SectorFields[]) => {
        const sectorsIds = sectors.map(({ id }) => id);

        specializationHelpers.setValue(specializationField.value.filter(({ sector }) => {
          return sectorsIds.includes(sector.id)
        }))

        sectorHelpers.setValue(sectors);
      }}
      component={Autocomplete}
      noOptionsText={t('startTyping')}
      options={sectorsData?.getSectors || []}
      autoComplete
      limitTags={1}
      sx={autocompleteField}
      isOptionEqualToValue={(option: SectorFields, value: SectorFields) => option.id === value.id}
      getOptionLabel={(option: SectorFields) => option.name}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={params?.InputProps?.startAdornment ?
            ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span')  ?
                locationStyle
                :
                {height: '100%'}
            )
            : {}}
          error={sectorMeta.touched && !!sectorMeta.error}
          placeholder={!params.InputProps.startAdornment ? t('formFields.sectorsTitle') : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Portfolio />
                </InputAdornment>
                <Box className={'selected-chips'}
                     sx={
                       params?.InputProps?.startAdornment ?
                         ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span')
                           || (params?.InputProps?.startAdornment as ReactElement[]).length < 2 ?
                             dropOpenedStyle
                             :
                             dropStyle
                         )
                         : {}
                     }
                >
                  {params.InputProps.startAdornment}
                </Box>
              </>
            ),
            endAdornment: (
              <>
                {sectorsDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

const locationStyle = (theme: Theme) => ({
  height: '53px',
})

const dropOpenedStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  '& input:':{
    display: 'none!important'
  },
  [theme.breakpoints.down('lg')]:{
    width: 'unset',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})

const dropStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  '& + .MuiOutlinedInput-input':{
    display: 'block',
    width: '100%!important',
  },
  [theme.breakpoints.down('lg')]:{
    width: '100%',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})
