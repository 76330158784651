import { Box, LinearProgress, Typography } from '@mui/material'

interface Props {
  value: number
}

export function LinearProgressWithLabel({ value }: Props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>
          {value}%
        </Typography>
      </Box>
    </Box>
  )
}
