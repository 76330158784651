import { Card, Theme, Typography, Box, CircularProgress } from '@mui/material'

import Woman from '../images/svg-files/woman-in-loading.svg'
import { Main, Content } from './common-files/common-styles'
import { radiusOfComponents } from '../styles/theme'

export const LoaderLarge = () => {
  return (
    <Main sx={wrapper}>
      <Content>
        <Card sx={cardWrapper}>
          <Box sx={contentWrapper}>
            <Typography sx={titleStyle}>Wait, please</Typography>
            <Box sx={circularStyle}>
              <CircularProgress />
            </Box>
            <img src={Woman} alt={'loading'} />
          </Box>
        </Card>
      </Content>
    </Main>
  )
}

const wrapper = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: '68px',
  paddingBottom: '128px',
  [theme.breakpoints.down('sm')]:{
    paddingY: 'unset',
    height: '100vh'
  }
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  mx: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& img':{
      width: '279px'
    }
  }
})

const cardWrapper = (theme: Theme) => ({
  display: 'flex',
  width: '100%',
  boxShadow: 'none',
  borderRadius: radiusOfComponents,
  [theme.breakpoints.up('lg')]: {
    pt: '61px',
    pb: '22px',
  },
})

const titleStyle = (theme: Theme) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '22px',
  lineHeight: '27px',
  mb: theme.spacing(2.5),
  [theme.breakpoints.up('md')]: {
    mb: theme.spacing(3.5),
  },
  [theme.breakpoints.up('lg')]: {
    mb: theme.spacing(4.5),
  },
  [theme.breakpoints.down('md')]:{
    fontSize: '18px'
  }
})

const circularStyle = (theme: Theme) => ({
  mb: theme.spacing(2.5),
  textAlign: 'center',
})
