import { useMutation } from 'react-query'
import { authMutation } from '../services/api'
import { Asset } from '../components/Uploader/Uploader'
import { queryClient } from '../react-query.client'
import { Message, messagesSelector } from './useMessages'
import { Conversation } from './useConversations'

type CreateMessageProps = {
  authorId: string,
  assets: Asset[],
  content: string,
  conversationId: string,
  replayToId?: string
}

type UpdateMessage = {
  id: string
  content: string
}

export const useCreateMessage = (profileId: string) => {

  return useMutation(async (props: CreateMessageProps) => {

    const data = await authMutation({
      createMessage: [
        {
          input: {
            author: {
              connect: { id: props.authorId }
            },
            assets: {
              connect: props.assets.map(({ id }) => ({ id }))
            },
            conversation: { connect: { id: props.conversationId } },
            content: props.content,
            ...(props.replayToId ? {
              replyedTo: { connect: [{ id: props.replayToId }] }
            } : {})
          }
        },
        {
          ...messagesSelector,
          replyedTo: {
            ...messagesSelector.replyedTo,
            author: {
              ...messagesSelector.replyedTo.author,
              profile: {
                ...messagesSelector.replyedTo.author.profile,
                isHidden: [
                  { profileId },
                  true
                ]

              }
            }
          },
          author: {
            ...messagesSelector.author,
            profile: {
              ...messagesSelector.author.profile,
              isHidden: [
                { profileId },
                true
              ]
            }
          }
        }
      ]
    })
    const cacheData = queryClient.getQueryData<{ pages: { getMessages: Message[] }[] }>(['messages', props.conversationId])
    const messages = cacheData?.pages
    const newArray = messages?.map((messages, idx) => {
      if (idx === 0) {
        messages.getMessages.unshift(data.createMessage)
      }
      return messages
    })
    queryClient.setQueryData(['messages', props.conversationId], ({ pageParams }) => ({
      pages: newArray,
      pageParams: pageParams
    }))

    const cacheDataConversations = queryClient.getQueryData<{
      pages: {
        getProfileConversations: Conversation[]
      }[]
    }>(['conversations', profileId, null])

    const conv = cacheDataConversations?.pages.map(pages => {
      return pages.getProfileConversations.map(item => {
        if (item.lastMessage?.conversationId === data.createMessage.conversationId) {
          return { ...item, lastMessage: { ...data.createMessage } }
        } else {
          return item
        }
      })
    })

    const newConversationsArray = conv?.reduce((acc: any, conversation, index) => {
      const getProfileConversations = [...conversation]
      return [...acc, { getProfileConversations }]
    }, [])

    queryClient.setQueryData(['conversations', profileId, null], ({ pageParams }) => ({
      pages: newConversationsArray,
      pageParams: pageParams
    }))

  })
}
export const useUpdateMessage = (profileId: string, conversationId?: string) => {
  return useMutation(async (props: UpdateMessage) => {

    const data = await authMutation({
      updateMessage: [
        {
          id: props.id,
          input: {
            content: props.content
          }
        },
        {
          ...messagesSelector,
          replyedTo: {
            ...messagesSelector.replyedTo,
            author: {
              ...messagesSelector.replyedTo.author,
              profile: {
                ...messagesSelector.replyedTo.author.profile,
                isHidden: [
                  { profileId },
                  true
                ]
              }
            }
          },
          author: {
            ...messagesSelector.author,
            profile: {
              ...messagesSelector.author.profile,
              isHidden: [
                { profileId },
                true
              ]
            }
          }
        }
      ]
    })
    const messages = useGetMessageCacheData(conversationId)
    const updatedArray = messages?.map(messages => {
      return messages.getMessages.map(message => {
        if (message.id === props.id) {
          return data.updateMessage
        } else if (message.replyedTo && message.replyedTo[0]?.id === props.id){
          return {...message, replyedTo: [{ ...message.replyedTo[0], content: data.updateMessage.content}]}
        }
        return message
      })
    })
    const newPagesArray = updatedArray?.reduce((acc: any, messages, index) => {
      const getMessages = [...messages]
      return [...acc, { getMessages }]
    }, [])

    queryClient.setQueryData(['messages', conversationId], ({ pageParams }) => ({
      pages: newPagesArray,
      pageParams: pageParams
    }))

    const cacheDataConversations = queryClient.getQueryData<{
      pages: {
        getProfileConversations: Conversation[]
      }[]
    }>(['conversations', profileId, null])

    const conv = cacheDataConversations?.pages.map(pages => {
        return pages.getProfileConversations.map(item => {
          if (item.lastMessage?.id === data.updateMessage.id) {
            return { ...item, lastMessage: { ...data.updateMessage } }
          } else {
            return item
          }
        })
      })

      const newConversationsArray = conv?.reduce((acc: any, conversation, index) => {
        const getProfileConversations = [...conversation]
        return [...acc, { getProfileConversations }]
      }, [])

      queryClient.setQueryData(['conversations', profileId, null], ({ pageParams }) => ({
        pages: newConversationsArray,
        pageParams: pageParams
    }))
  })
}

export const useGetMessageCacheData = (conversationId?: string) => {
  const cacheData = queryClient.getQueryData<{ pages: { getMessages: Message[] }[] }>(['messages', conversationId])
  return cacheData?.pages
}