import { Formik, Form, Field } from 'formik'
import Yup from '../../../validation'
import { Box, Divider, Button, Typography, Theme, RadioGroup, FormControlLabel, Radio, FormControl } from '@mui/material'
import { ChangeEvent } from 'react'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from './position-information'
import { ApiTypes } from 'recruticka-frontend-libs'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ExperienceSelect } from '../../form-fields/ExperienceSelect'

interface Props {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  canBringExistingClients?: boolean
  experience?: ApiTypes.Experience
  onSave: (canBringExistingClients: boolean, experience: ApiTypes.Experience) => void
}

export const BringExisting = ({ isSaving, onSave, currentStep, backStep, canBringExistingClients, experience }: Props) => {
  const { t } = useTranslation()
  const initialValues = {
    canBringExistingClients,
    experience: experience || ApiTypes.Experience.ENTRY,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSave(Boolean(values.canBringExistingClients), values.experience)
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <Box sx={wrapper}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  {t('formFields.canBringExistingClientsTitle')}
                </Typography>
                <FormControl>
                  <Field
                    component={RadioGroup}
                    name='canBringExistingClients'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: '32px',
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                      setFieldValue('canBringExistingClients', value === 'true')
                    }}
                  >
                    <FormControlLabel
                      checked={values.canBringExistingClients === true}
                      value={true}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('yes')}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      checked={values.canBringExistingClients === false}
                      value={false}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('no')}
                      disabled={isSubmitting}
                    />
                  </Field>
                </FormControl>
              </Box>
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Box sx={wrapperStyle}>
                <Typography textAlign={'center'} sx={titleTextStyle}>
                  {t('formFields.experiencePlaceholder')}
                </Typography>
                <ExperienceSelect />
              </Box>
            </Box>
            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  Back
                </Button>
              </Box>
              <Box>
                <LoadingButton
                variant='contained'
                loading={isSaving}
                sx={buttonStyle}
                type='submit'
                size={'medium'}
                endIcon={<ArrowForwardIosIcon />}
                disabled={typeof values.canBringExistingClients !== 'boolean' || !values.experience}
              >
                {t('saveNext')}
              </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  canBringExistingClients: Yup.bool().required(),
  experience: Yup.string().required(),
})

const wrapperStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  mb: '0px',
  [theme.breakpoints.down('sm')]:{
    '& .MuiSelect-select':{
      paddingY: '12.5px'
    }
  }
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  marginBottom: '0px',
  [theme.breakpoints.down('sm')]:{
    marginBottom: '0px'
  }
})

const titleTextStyle = () => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})
