import { AutocompleteRenderInputParams, CircularProgress, TextField, InputAdornment, Box, Theme } from '@mui/material';
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-mui';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs';
import { useDebounce } from 'use-debounce';
import { useQueryRequest } from '../../services/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocationIcon } from '../icons/location';
import { autocompleteField } from './SpecializationsAutocomplete'

export const citySelector = ApiSelector('City')({
  id: true,
  name: true,
  region: {
    name: true
  },
  country: {
    name: true
  }
})

export type CityFields = ApiInputType<ApiGraphQLTypes['City'], typeof citySelector>

export const CityAutocomplete = () => {
  const [field, meta] = useField('cities');
  const { t } = useTranslation();
  const [searchCityName, setSearchCityName] = useState<string>('');
  const [citySearch] = useDebounce(searchCityName, 20);
  const { data, isLoading } = useQueryRequest(['searchCity', citySearch], {
    getCities: [
      {
        take: 20,
        where: {
          name: {
            startsWith: citySearch,
            mode: ApiTypes.QueryMode.insensitive
          },
          position: {
            gte: citySearch ? 0 : 1
          }
        },
        orderBy: [
          {
            name: ApiTypes.SortOrder.desc,
          }
        ]
      },
      citySelector
    ]
  })

  return (
    <Field
      popupIcon={<KeyboardArrowDownIcon />}
      multiple
      loading={isLoading}
      name={field.name}
      fullWidth
      limitTags={1}
      component={Autocomplete}
      onInputChange={(event: any, newInputValue: string) => {
        setSearchCityName(newInputValue)
      }}
      noOptionsText={t('startTyping')}
      options={data?.getCities || []}
      autoComplete
      includeInputInList
      sx={autocompleteField}
      filterSelectedOptions
      isOptionEqualToValue={(option: CityFields, value: CityFields) => option.id === value.id}
      getOptionLabel={(option: CityFields) => {
        if (option.id) {
          if (option.region.name !== option.name) {
            return `${option.name}, ${option.region.name}, ${option.country.name}`
          }

          return `${option.name}, ${option.country.name}`
        }

        return ''
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={params?.InputProps?.startAdornment ?
            ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span') ?
              locationStyle
              :
              { height: '100%' }
            )
            : {}}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          placeholder={!params.InputProps.startAdornment ? 'Location' : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <LocationIcon />
                </InputAdornment>
                <Box
                  sx={
                    params?.InputProps?.startAdornment ?
                      ((params?.InputProps?.startAdornment as ReactElement[]).find((el) => el?.type === 'span')
                        || (params?.InputProps?.startAdornment as ReactElement[]).length < 2 ?
                        dropOpenedStyle
                        :
                        dropStyle
                      )
                      : {}
                  }
                >
                  {params.InputProps.startAdornment}
                </Box>
              </>
            ),
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color={'inherit'} size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

const locationStyle = (theme: Theme) => ({
  // height: '248px',
  // '& > *': {
    height: '48px',
  // }
})

const dropOpenedStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  '& input:':{
    display: 'none!important'
  },
  [theme.breakpoints.down('lg')]:{
    width: 'unset',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})

const dropStyle = (theme: Theme) => ({
  width: '113px',
  maxWidth: '100%',
  '& + .MuiOutlinedInput-input':{
    display: 'block',
    width: '100%!important',
  },
  [theme.breakpoints.down('lg')]:{
    width: '100%',
    maxWidth: '15vw'
  },
  [theme.breakpoints.down('md')]:{
    maxWidth: '23vw'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: '37vw'
  }
})
