import { Main, Content } from '../components/common-files/common-styles'
import { Grid, Theme, CircularProgress, Box, useMediaQuery, Card } from '@mui/material'
import { EmployeeProfileMainInfo } from '../components/not-cms/employee-profile-main-info'
import { UploadCv } from '../components/not-cms/upload-cv'
import { EmployeeEditBillings, salaryHistorySelector } from '../components/not-cms/employee-edit-billings'
import { EmployeeEditLanguage, languageSelector } from '../components/not-cms/employee-edit-language'
import { EmployeeEditPreferences } from '../components/not-cms/employee-edit-preferences'
import { EmployeeEditSpecialises, specializationSelector } from '../components/not-cms/employee-edit-specialises'
import { EmployeeEditClients } from '../components/not-cms/employee-edit-clients'
import {
  employeeCareerNarrativeSelector,
  EmployeeEditCareerNarrative,
} from '../components/not-cms/employee-edit-career-narrative'
import TempWrapper from '../components/tempWrapper'
import { ApiSelector } from 'recruticka-frontend-libs'
import { assetSelector } from '../components/Uploader/Uploader'
import { useParams } from 'react-router-dom'
import { useAuthQueryRequest } from '../services/api'
import { TypeOfComponent } from './dashboard-employee'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { useState } from 'react'
import theme from '../styles/theme'
import { EmployeeEditManage } from '../components/not-cms/employee-edit-manage'
import { HideButtonAndAlert } from '../components/HideButtonAndAlert'
import { EmployeeListItem, employeeListItemSelector } from '../components/not-cms/CandidateListCard'
import { useChat } from '../hooks/useConversations'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'
import { useCreateMakeVisibleRequest } from '../hooks/useProfile';

import TempWrapperNew from '../components/tempWrapperNew'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

const employeeSelector = ApiSelector('Employee')({
  id: true,
  profileId: true,
  firstname: true,
  lastname: true,
  avatar: assetSelector,
  experience: true,
  locationType: true,
  currentCity: citySelector,
  cv: assetSelector,
  profile: {
    id: true,
    linkedin: true,
    isHidden: true,
    conversations: [
      { profileId: '' },
      {
        id: true
      }
    ]
  },
  languages: languageSelector,
  preferSalary: salarySelector,
  recruitCountries: {
    id: true,
    name: true,
    flagEmoji: true,
  },
  deskType: true,
  deskTypeRatio: true,
  specializations: specializationSelector,
  canBringExistingClients: true,
  clientIndustries: true,
  salaryHistory: salaryHistorySelector,
  teamAvgSalary: salarySelector,
  teammatesCount: true,
  managedTeam: true,

  ...employeeCareerNarrativeSelector,
})

type RouteParams = {
  employeeId?: string
}

const EmployeeProfile = () => {
  const { id: profileId } = useRecoilValue(profileState)
  const { employeeId } = useParams<RouteParams>()
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'))
  const { open } = useChat()
  const { data: employeeData, isLoading } = useAuthQueryRequest(
    'employee',
    {
      getEmployee: [
        { id: employeeId as string },
        {
          ...employeeSelector,
          profile: {
            ...employeeSelector.profile,
            isHidden: [
              { profileId },
              true
            ],
            conversations: [
              { profileId: profileId },
              {
                id: true
              }
            ]
          }
        }
      ],
    },
    {
      enabled: employeeId !== undefined,
    },
  )
  const createMakeVisibleRequest = useCreateMakeVisibleRequest();


  const confirm = async () => {
    if (employeeData?.getEmployee?.id) {
      await createMakeVisibleRequest.mutateAsync(employeeData.getEmployee.profile.id)
    }
  }
  const handleSendMessage = async () => {
    if (employeeData) {
      const conversationId = await open(
        {
          withProfileId: employeeData?.getEmployee.profile?.id,
          conversationId: employeeData?.getEmployee.profile?.conversations?.[0]?.id
        }
      )
    }
  }

  return (
    <TempWrapperNew>
      <Main sx={wrapper}>
        <Content sx={employeeData?.getEmployee.profile.isHidden ? { flexDirection: 'column' } : null}>
          {isLoading && (
            <Box sx={{ marginX: 'auto' }}>
              <CircularProgress />
            </Box>
          )}
          {
            employeeData?.getEmployee.profile.isHidden &&
            <HideButtonAndAlert confirm={confirm} />
          }
          {!isLoading && (
            <Grid container spacing={3}>
              <Grid item xs={matchesMd ? 12 : 4} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <EmployeeProfileMainInfo
                  avatar={employeeData?.getEmployee.avatar}
                  experience={employeeData?.getEmployee.experience}
                  firstname={employeeData?.getEmployee.firstname}
                  lastname={employeeData?.getEmployee.lastname}
                  locationType={employeeData?.getEmployee.locationType}
                  linkedin={employeeData?.getEmployee.profile.linkedin}
                  city={employeeData?.getEmployee.currentCity?.name}
                  country={employeeData?.getEmployee.currentCity?.country.name}
                  visible={!employeeData?.getEmployee.profile.isHidden}
                  onClick={handleSendMessage}
                />
                {!employeeData?.getEmployee.profile.isHidden && employeeData?.getEmployee.cv &&
                  <Card sx={cardBox}>
                    <UploadCv cv={employeeData?.getEmployee.cv} type={TypeOfComponent.VIEW} />
                  </Card>
                }
                {!!employeeData?.getEmployee?.salaryHistory?.history?.length && <EmployeeEditBillings
                  employeeName={employeeData?.getEmployee.firstname}
                  type={TypeOfComponent.VIEW}
                  salaryHistory={employeeData?.getEmployee.salaryHistory}
                />}
                
                <Card sx={cardBox}>
                  <EmployeeEditLanguage type={TypeOfComponent.VIEW} languages={employeeData?.getEmployee.languages} />
                </Card>

                <EmployeeEditManage
                  managedTeam={employeeData?.getEmployee?.managedTeam}
                  teammatesCount={employeeData?.getEmployee?.teammatesCount}
                  salary={employeeData?.getEmployee?.teamAvgSalary}
                  type={TypeOfComponent.VIEW}
                />
              </Grid>
              <Grid item xs={matchesMd ? 12 : 8} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <EmployeeEditCareerNarrative
                  type={TypeOfComponent.VIEW}
                  jobTypes={employeeData?.getEmployee.jobTypes}
                  jobCriterias={employeeData?.getEmployee.jobCriterias}
                  reasonForLeavingRecentEmployer={employeeData?.getEmployee?.reasonForLeavingRecentEmployer}
                  recruitRoles={employeeData?.getEmployee.recruitRoles}
                />
                <EmployeeEditSpecialises
                  type={TypeOfComponent.VIEW}
                  employeeName={employeeData?.getEmployee.firstname}
                  specializations={employeeData?.getEmployee.specializations}
                />
                <EmployeeEditPreferences
                  type={TypeOfComponent.VIEW}
                  deskType={employeeData?.getEmployee.deskType}
                  preferSalary={employeeData?.getEmployee.preferSalary}
                  recruitCountries={employeeData?.getEmployee.recruitCountries}
                  deskTypeRatio={employeeData?.getEmployee.deskTypeRatio}
                />
                <EmployeeEditClients
                  type={TypeOfComponent.VIEW}
                  canBringExistingClients={employeeData?.getEmployee.canBringExistingClients}
                  clientIndustries={employeeData?.getEmployee.clientIndustries}
                />
                {/* <EmployeeEditWorkExperience workExperiences={[]} />*/}
                {/* <EmployeeEditEducation educations={[]} />*/}
              </Grid>
            </Grid>
          )}
        </Content>
      </Main>
    </TempWrapperNew>
  )
}

const wrapper = (theme: Theme) => ({
  paddingBottom: '32px',
  background: theme.palette.background.default,
  [theme.breakpoints.down('sm')]:{
    paddingTop: '24px',
    paddingBottom: '0px',
  }
})

const cardBox = (theme: Theme) => ({
  height: 'auto',
})

export default EmployeeProfile
