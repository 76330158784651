import { Box, FormControl, Theme, Typography, Divider } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ConsiderPeopleFromOtherSectorRadio } from '../../form-fields/ConsiderPeopleFromOtherSectorRadio'
import { JobTypesAutocomplete } from '../../form-fields/JobTypesAutocomplete'
import { WorkFromHome } from '../../form-fields/WorkFromHome'
import { MAX, MIN } from '../employer-steps/amount-people'

export const LookingFor = () => {
  const { t } = useTranslation()
  return (
    <Box sx={[BoxWrapper, wrapper]}>
      <Box sx={BoxWrapper}>
        {/* <Typography variant={'body1'} color={(theme) => theme.palette.primary.main}>
          Kind of people we are looking for
        </Typography> */}
        <JobTypesAutocomplete label='What Kind of people we are looking for?'/>
      </Box>
      <Divider sx={{ my: 2 }}/>
      <Box sx={BoxWrapper}>
        {/* <Typography variant={'body1'} color={(theme) => theme.palette.primary.main}>
          How many people we are looking for
        </Typography> */}

        <Field
          component={TextField}
          name='lookingForPeople'
          type='number'
          label= 'How many people we are looking for'
          fullWidth
          InputProps={{
            inputProps: {
              max: MAX,
              min: MIN,
            },
          }}
        />
      </Box>
      <Divider sx={{ my: 2 }}/>
      <Box sx={BoxWrapper}>
        <Typography variant={'body1'} color={(theme) => theme.palette.text.primary}>
          {t('companyForm.considerPeopleFromOtherSector')}
        </Typography>
        <FormControl>
          <ConsiderPeopleFromOtherSectorRadio sx={radioGroupWrapper} />
        </FormControl>
      </Box>

      <Divider />
      
      <Box sx={[BoxWrapper, lookingForBox]}>
        <Typography variant={'body1'} color={(theme) => theme.palette.text.primary}>
          {t('companyForm.workFromHome')}
        </Typography>
        <WorkFromHome
          workFromHome={{
            sx: radioGroupWrapper,
          }}
          workFromHomeDays={{
            sx: { maxWidth: '100%' },
          }}
        />
      </Box>
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  gap: '18px',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
    mb: theme.spacing(1.5),
  },
})

const radioGroupWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  width: '100%',
  height: '100%',
  flexWrap: 'nowrap',
})

const BoxWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const lookingForBox = (theme: Theme) => ({
  '& .slider': {
    marginTop: '15px',
  },
  '& .MuiSlider-markLabel:nth-child(4)': {
    left: '4%!important',
    color: theme.palette.grey[800], 
  },
  '& .MuiSlider-markLabel:nth-child(6)': {
    left: '95%!important',
    color: theme.palette.grey[800], 
  },
})
