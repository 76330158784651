import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { INLINE_STYLES, Toolbar } from './Toolbar'
import { Box, FormHelperText, SxProps } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { filterEditorState } from 'draftjs-filters'
import { Theme } from '../../styles/theme'
import { useDebouncedCallback } from 'use-debounce'

export type TextEditorProps = {
  value?: string
  placeholder?: string
  error?: boolean
  helperText?: string
  onChange: (value: string) => void,
  areaSx?: SystemStyleObject
}

export const TextEditor = ({ value, onChange, placeholder, error, helperText, areaSx }: TextEditorProps) => {
  const [isFocused, setFocused] = useState(false)
  const editor = useRef<Editor>(null)
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty())

  useEffect(() => {
    if (!isFocused) {
      setEditorState((prevState) => EditorState.createWithContent(stateFromHTML(value || '')))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const focus = () => {
    if (editor.current) {
      editor.current.focus()
    }
  }

  const onToggle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style))
  }

  const checkStyles = useCallback(
    (style: string) => {
      return isFocused ? editorState.getCurrentInlineStyle().has(style) : false
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [editorState],
  )

  const onEditorChange = (nextState: EditorState) => {
    let filteredState = nextState

    const shouldFilterPaste =
      nextState.getCurrentContent() !== editorState.getCurrentContent() &&
      nextState.getLastChangeType() === 'insert-fragment'

    if (shouldFilterPaste) {
      filteredState = filterEditorState(
        {
          blocks: [],
          styles: Object.keys(INLINE_STYLES),
          entities: [],
          maxNesting: 1,
          whitespacedCharacters: ['\n', '\t'],
        },
        filteredState,
      )
    }

    setEditorState(filteredState)
  }

  const debouncedEmitChanges = useDebouncedCallback(() => {
    emitChanges()
  }, 100)

  const emitChanges = () => {
    const html = stateToHTML(editorState.getCurrentContent())

    if (html !== value) {
      onChange(html)
    }
  }

  const onBlur = () => {
    setFocused(false)
    emitChanges()
  }

  useEffect(() => {
    if (isFocused) {
      debouncedEmitChanges()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState])

  return (
    <>
      <Box>
        <Box
          sx={(theme: Theme) => ({
            border: '1px solid rgba(20, 29, 36, 0.12)',
            ...(error
              ? {
                borderColor: theme.palette.error.main,
              }
              : {}),
            borderRadius: `${theme.shape.borderRadius}px`,
            '&:hover': {
              borderColor: theme.palette.action.active,
              ...(error
                ? {
                  borderColor: theme.palette.error.main,
                }
                : {}),
            },
          })}
          onClick={focus}
        >
          <Toolbar onToggle={onToggle} checkStyles={checkStyles} />
          <Box sx={editorBox} className={'textEditor'} >
            <Box
            className={'textEditorField'}
              sx={[{
              height: '250px',
              overflowY: 'auto',
            }, areaSx || {}]}
            >
              <Editor
                placeholder={placeholder}
                ref={editor}
                editorState={editorState}
                onChange={onEditorChange}
                onBlur={onBlur}
                onFocus={() => setFocused(true)}
              />
            </Box>
          </Box>
        </Box>
        {helperText ? (
          <FormHelperText
            sx={(theme: Theme) => ({
              ...((theme.components?.MuiFormHelperText?.styleOverrides?.root as Record<string, string>) || {}),
            })}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </Box>
    </>
  )
}


const editorBox = (theme: Theme) => ({
  background: theme.palette.background.paper,
  padding: 2,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  fontWeight: '400',
  '& .textEditorField': {
    fontSize: '14px',
  }
})