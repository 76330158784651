import { Box, FormControl, Theme, Typography } from '@mui/material'

import { RecruitmentFocusAutocomplete } from '../../form-fields/RecruitmentFocusAutocomplete'
export const Recruitment = () => {
  return (
    <Box sx={[BoxWrapper, wrapper]}>
      <Box sx={BoxWrapper}>
        {/* <Typography variant={'body1'} color={(theme) => theme.palette.primary.main}>
          Recruitment focus
        </Typography> */}
        <RecruitmentFocusAutocomplete />
      </Box>
    </Box>
  )
}
const wrapper = (theme: Theme) => ({
  gap: '18px',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
    mb: theme.spacing(1.5),
  },
})
const BoxWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})