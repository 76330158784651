import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import { Autocomplete } from '@react-google-maps/api'
import { useState } from 'react'
import { PlacesAutocompleteProps, PlaceType } from './types'
import { placesAutocompleteSX } from './styles'

export const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
  const { onPlaceSelected } = props
  const { t } = useTranslation()
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>()

  const handleSelect = () => {
    if (!autocomplete) {
      return
    }

    const { geometry, formatted_address: formattedAddress } = autocomplete.getPlace()

    onPlaceSelected({
      address: formattedAddress,
      lat: geometry?.location?.lat(),
      long: geometry?.location?.lng(),
    } as PlaceType)
  }

  return (
    <>
      <Autocomplete onLoad={setAutocomplete} onPlaceChanged={handleSelect}>
        <TextField placeholder={t('startTyping')} sx={placesAutocompleteSX} />
      </Autocomplete>
    </>
  )
}
