import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { siteAllJobsPage, siteJobPreviewPage } from '../config/urls'
import { authMutation } from '../services/api'

const jobSelector = ApiSelector('Job')({
  id: true,
  title: true,
  description: true,
  experience: true,
  companyId: true,
})

type JobFields = ApiInputType<ApiGraphQLTypes['Job'], typeof jobSelector>
type CreateJobFields = Omit<JobFields, 'id'>

export const useCreateJob = (selector: Partial<typeof jobSelector>) => {
  return useMutation(async (values: CreateJobFields) => {
    const { companyId, ...input } = values

    const data = await authMutation({
      createJob: [
        {
          input: {
            ...input,

            company: {
              connect: {
                id: companyId,
              },
            },
          },
        },
        selector,
      ],
    })

    return data.createJob
  })
}

export const useDuplicateJob = (selector: Partial<typeof jobSelector>) => {
  return useMutation(async (id: string) => {
    const data = await authMutation({
      duplicateJob: [{ id }, selector],
    })

    return data.duplicateJob
  })
}

export const useArchiveJob = (selector: Partial<typeof jobSelector>) => {
  return useMutation(async (id: string) => {
    const data = await authMutation({
      archiveJob: [{ id }, selector],
    })

    return data.archiveJob
  })
}

export const useRestoreJob = (selector: Partial<typeof jobSelector>) => {
  return useMutation(async (id: string) => {
    const data = await authMutation({
      restoreJob: [{ id }, selector],
    })

    return data.restoreJob
  })
}

export const useDeleteJob = () => {
  return useMutation(async (id: string) => {
    const data = await authMutation({
      deleteJob: [{ id }, true],
    })

    return data.deleteJob
  })
}

export const usePublishJob = () => {
  return useMutation(async (JobId: string) => {
    const response = await authMutation({
      publishJob: [
        { id: JobId },
        {
          id: true,
          status: true,
          slug: true,
        },
      ],
    })

    return response.publishJob
  })
}

export const usePreviewJob = () => {
  const openPreview = (id: string) => window.open(`${siteJobPreviewPage}/?id=${id}`)

  return {
    openPreview,
  }
}

export const useOpenJobPage = () => {
  const openJobPage = (slug: string) => window.open(`${siteAllJobsPage}/${slug}`)

  return {
    openJobPage,
  }
}
