import { Box, Divider, FormControl, Grid, MenuItem, useMediaQuery } from '@mui/material'
import { useField, Field, FieldArray, useFormikContext } from 'formik'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { CurrencyAutocomplete } from './CurrencyAutocomplete'
import { SalaryPeriodSelect } from './SalaryPeriodSelect'
import { useNumberFormatter } from '../../hooks/useNumberFormatter'
import theme, { Theme } from '../../styles/theme'
import { Button } from '../ui-kits/button/button'
import AddIcon from '@mui/icons-material/Add'
import { Select, TextField } from 'formik-mui'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useMemo, useState } from 'react'
import { SystemStyleObject } from '@mui/system'

const salaryHistoryRecordSelector = ApiSelector('SalaryHistoryRecord')({
  id: true,
  from: true,
  to: true,
  year: true
})

export const salaryHistorySelector = ApiSelector('SalaryHistory')({
  id: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
    rate: true
  },
  history: salaryHistoryRecordSelector
})

export type SalaryHistoryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salaryHistorySelector>
type SalaryHistoryRecordFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salaryHistoryRecordSelector>

interface Props {
  fieldsPrefix?: string
  signupView?: boolean
}

export const SalaryHistory = ({ fieldsPrefix = '', signupView = false }: Props) => {
  const [historyField, ,historyFieldHelper] = useField<SalaryHistoryRecordFields[]>(`${fieldsPrefix}history`)
  const [currencyField, currencyMeta] = useField<SalaryHistoryRecordFields[]>('currency')
  const [scheduleField, meta] = useField<ApiTypes.SalaryPeriod>(`${fieldsPrefix}schedule`)
  const [years, setYears] = useState<number[]>()

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const years = new Array(10).fill(currentYear).map((year, index) => year - index)

    historyField.value.forEach(({ year }) => {
      if (typeof year === 'number') {
        if (!years.includes(year)) {
          years.push(year)
        }
      }
    })

    setYears(
      years.sort((a, b) => a - b)
    )
  }, [historyField.value]);

  const selectedYears = useMemo(() => {
    return historyField.value.reduce<Record<number, boolean>>((acc, prev) => {
      acc[prev.year as number] = true;
      return acc;
    }, {})
  }, [historyField.value]);

  const handleConvertPeriod = () => {
      if (meta.value === ApiTypes.SalaryPeriod.ANNUAL){
        historyFieldHelper.setValue(historyField.value?.map((salary) => {
          const from = salary.from as number
          const to = salary.to as number
            return {...salary, from: (from / 12 as number), to: to / 12}
        }))
      } else {
        historyFieldHelper.setValue(historyField.value?.map((salary) => {
          const from = salary.from as number
          const to = salary.to as number
          return {...salary, from: from * 12, to: to * 12}
        }))
      }
    }

  return (
    <Box>
      <Grid container spacing={!signupView ? 3 : 2}>
        <Grid item sm={6} xs={12} >
          <FormControl sx={signUpField}>
            <SalaryPeriodSelect onClick={handleConvertPeriod} name={`${fieldsPrefix}schedule`} />
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl sx={signUpField}>
            <CurrencyAutocomplete name={`${fieldsPrefix}currency`} />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={(theme) => ({
                            my: theme.spacing(4)
                          })} />
      <Box sx={periodWrapper}>
        <FieldArray
          name={'history'}
          render={(arrayHelper) => (
            <>
              {historyField.value.map((_, index) => (
                <Box sx={!signupView ? fieldsWrapper : signupFields} key={index + 1}>
                  <Box sx={!signupView ? fieldsInnerWrapper : viewForSetup}>
                    <Field
                      type={'number'}
                      label={'Year'}
                      component={Select}
                      name={`${fieldsPrefix}history.${index}.year`}
                      sx={!signupView ? selectField : selectFieldSignUp}
                      formControl={{ sx: !signupView ? selectWrapper : signupSelectWrapper }}
                    >
                      {years?.map(year =>
                        <MenuItem value={year} key={year} disabled={selectedYears[year]}>
                          {year}
                        </MenuItem>
                      )}
                    </Field>
                    <Field
                      type={'number'}
                      label={'Billings'}
                      component={TextField}
                      name={`${fieldsPrefix}history.${index}.from`}
                      sx={!signupView ? field : fieldSignUp}
                      fullWidth
                    />
                  </Box>
                  <Button
                    sx={!signupView ? buttonDelete : buttonForSetUp}
                    variant={'text'}
                    onClick={() => arrayHelper.remove(index)}
                  >
                    <DeleteIcon fontSize={'large'} sx={(theme) => ({
                            color: '#D32F2F',
                          })} />
                  </Button>
                </Box>
              ))}

              {historyField.value.length < 10 && <Box>
                <Button
                  sx={buttonAdd}
                  variant="outlined"
                  size={'squareSmall'}
                  onClick={() => {
                    arrayHelper.push({
                      from: '',
                      to: '',
                      year: ''
                    } as SalaryHistoryRecordFields)
                  }}
                  startIcon={<AddIcon sx={{width: '20px',}} />}
                >
                  Add
                </Button>
              </Box>}
            </>
          )}
        />
      </Box>
    </Box>
  )
}

const signupSelectWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]:{
    width: '100%'
  }
})

const selectWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('md')]:{
    width: '100%'
  }
})

const signupFields = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]:{
    flexDirection: 'row'
  },
  [theme.breakpoints.down('sm')]:{
    flexDirection: 'column'
  }
})

const signUpField = (theme: Theme) => ({
  width: '100%',
  height: '53px',
  '& .MuiFormControl-root': {
    height: '53px',
  },
  '& .MuiInputBase-root': {
    paddingLeft: '20px'
  }
})

const selectFieldSignUp = (theme: Theme) => ({
   width: '100%',
})

const buttonForSetUp = (theme: Theme) => ({
  paddingRight: '0!important',
  padding: '10px 12px',
  minWidth: '48px',
  mt: 0.5
})

const viewForSetup = (theme: Theme) =>({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 3,
  '& .MuiFormControl-root':{
    width: '100%',
    height: '52px'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]:{
    flexDirection: 'column',
    gap: '15px'
  }
})

const selectField = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%'
    // maxWidth: '400px'
  }
})

const fieldSignUp = (theme: Theme) => ({
  // maxWidth: '150px',
  '&:nth-of-type(1)': {
    marginRight: '15px'
  },
  [theme.breakpoints.down('sm')]:{
    maxWidth: 'unset',
  }
})

const buttonDelete: SystemStyleObject = {
  padding: '10px 12px',
  minWidth: '48px',
  mt: 0.5
}

const periodWrapper = (theme: Theme) => ({
  // maxHeight: '176px',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  // overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    // maxHeight: '210px'
  }
})


const fieldsWrapper = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiFormControl-root':{
    width: '50%'
  },
  // alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    // flexDirection: 'column',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiFormControl-root':{
      width: '100%'
    },
  }
})

const fieldsInnerWrapper: SystemStyleObject = {
  display: 'flex',
  width: '100%',
  gap: 2,
  alignItems: 'start',
  '& .MuiFormControl-root':{
    width: '100%',
    height: '52px'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}

const field = (theme: Theme) => ({
  '&:nth-of-type(1)': {
    marginRight: '15px'
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    width: '100%',
    '&:not(:first-of-type)': {
      marginTop: '12px'
    }
  }
})

const buttonAdd = (theme: Theme) => ({
  paddingX: '18px',
  height: '38px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})