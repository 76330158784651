import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Box, Theme, Typography } from '@mui/material'
import { Button } from '../../../ui-kits/button/button'
import { LoadingButton } from '@mui/lab'
import { ReactNode } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SystemStyleObject } from '@mui/system'

interface Props {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
  description: ReactNode
  cancelMessage?: string
  successMessage?: string
  title?: string
  deleteStyle?: SystemStyleObject
}

export default function AlertDialog({
  title,
  isOpen,
  onClose,
  description,
  onConfirm,
  isLoading,
  cancelMessage,
  successMessage,
  deleteStyle,
}: Props) {
  const handleConfirm = async () => {
    await onConfirm()
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ px: '24px' }}
    >
      <DialogContent>
        <Typography variant={'h2'}>{title}</Typography>
        <DialogContentText id='alert-dialog-description' sx={dialogContentWrapper}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={deleteStyle ? deleteStyle : {}}>
        <Button variant={'outlined'} onClick={onClose} size={'small'}>
          {cancelMessage || 'Cancel'}
        </Button>
        <LoadingButton loading={isLoading} variant='contained' autoFocus onClick={handleConfirm} size={'small'}>
          {successMessage || 'Ok'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const dialogContentWrapper = (theme: Theme) => ({
  maxWidth: '444px',
  width: '100%',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
})
