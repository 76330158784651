import { atom } from 'recoil'

type ChatWindowStateValues = {
  isOpened: boolean,
  conversationId?: string,
  withProfileId?: string,
}

export const chatWindowState = atom<ChatWindowStateValues>({
  key: 'chatWindowState',

  default: {
    isOpened: false,
    conversationId: ''
  }
})
