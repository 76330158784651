import {ApiSelector, ApiGraphQLTypes, ApiInputType} from 'recruticka-frontend-libs';

export const assetSelector = ApiSelector('Asset')({
  id: true,
  url: true,
  type: true,
  name: true,
  size: true,
  mimeType: true,
});

export type Asset = ApiInputType<ApiGraphQLTypes['Asset'], typeof assetSelector>;