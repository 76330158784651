
import { Content } from '../components/common-files/common-styles'
import { Box, Theme, Typography } from '@mui/material'
import TempWrapperNew from '../components/tempWrapperNew'
import {CandidateList} from '../components/CandidateList'
import { DashboardCounter } from '../components/DashboardCounter'


const DashboardCompany = () => {

  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}>
        <Box sx={titleWrapper}>
          <Typography variant={'h4'}>{'Dashboard'}</Typography> 
        </Box>
        {/* <DashboardCounter /> */}
        <CandidateList /> 
      </Content>
    </TempWrapperNew>
  )
}


const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  marginBottom: '128px',
  gap: '32px',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
  },
})

const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  // justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    // flexWrap: 'wrap',
    // flexDirection: 'column',
    // alignItems: 'start',
    // gap: theme.spacing(2),
  }
})

export default DashboardCompany
