import { Content } from '../components/common-files/common-styles'
import { SortBlock } from '../components/sort-block'
import { CandidateListCard, EmployeeListItem, employeeListItemSelector } from '../components/not-cms/CandidateListCard'
import { Grid, Theme, Box, CircularProgress, useMediaQuery, Stack, Alert, AlertTitle, Snackbar } from '@mui/material'
import { Pagination } from '../components/ui-kits/pagination/pagination'
import TempWrapperNew from '../components/tempWrapperNew'
import { useAuthQueryRequest } from '../services/api'
import { PER_PAGE, useList } from '../hooks/useList'
import { useEffect, useState } from 'react'
import theme from '../styles/theme'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'
import { useChat } from '../hooks/useConversations'
import { useCreateMakeVisibleRequest } from '../hooks/useProfile'
import { Filter, FilterFields } from '../components/filter/filter'
import { SectorFields, sectorSelector } from '../components/filter/SectorAutocomplete'
import { SpecializationFields, specializationSelector } from '../components/filter/SpecializationsAutocomplete'
import { ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { CurrencyFields } from '../components/form-fields/Salary'
import { CityFields, citySelector } from '../components/filter/CityAutocomplete'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { currencySelector, useDefaultCurrency } from '../hooks/useCurrency'
import { useSalaryConversion } from '../hooks/useSalary'

export interface queryFields {
  search?: string,
  sectors?: string[],
  specializations?: string[],
  experience?: string,
  cities?: string[],
  jobLocationType?: string,
  salaryPeriod?: string,
  currency?: string,
  from?: string,
  to?: string,
}

interface SalaryState {
  to?: string | number
  from?:  string | number
  currency?: any
  schedule?: any
}

const MyCandidates = () => {
  const { id: profileId } = useRecoilValue(profileState);
  const { candidatesPage: page, candidatesSkip: skip, updateCandidatesPage: updatePage, calcTotalPages } = useList()
  const [visibleError, setVisibleError] = useState<boolean>(false)
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSsm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesSmall = useMediaQuery(theme.breakpoints.down(489));
  const [query, setQuery] = useState<queryFields | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (query) {
      setSearchParams({ ...query })
    }
  }, [query]);

  const applyFilter = (filter: FilterFields) => {

    setQuery((query) => {
      const {...data} = (query || {})
      const rate = filter.currency?.rate as string
      return {
        ...data,

        search: filter.search || '',
        sectors: filter.sectors?.map((sector) => sector.id as string),
        specializations: filter.specializations.map((specialization) => specialization.id as string),
        cities: filter.cities.map((city) => city.id) as string[],
        currency: filter.currency ? filter.currency.id : '',
        rate: rate ? rate : 1,
        experience: filter.experience as string || '',
        jobLocationType: filter.jobLocationType as string || '',
        salaryPeriod: filter.salaryPeriod as string || '',
        from: filter.from as string,
        to: filter.to as string,
      }
    })
  }

  const clearAll = () => {
    setQuery((query) => {
      const { ...data } = (query || {})
      return {}
    })
  }

  const rate = searchParams.get('rate')
  const where: ApiGraphQLTypes['EmployeeWhereInput'] = {

    ...(searchParams.get('search') ? {
      OR: [
        {
          firstname: {
            contains: searchParams.get('search') as string,
            mode: ApiTypes.QueryMode.insensitive
          }
        },
        {
          lastname: {
            contains: searchParams.get('search') as string,
            mode: ApiTypes.QueryMode.insensitive
          }
        }
      ]
    } : {}),

    ...(searchParams.get('jobLocationType') ? {
      locationType: {
        equals: (searchParams.get('jobLocationType') as string).toUpperCase() as ApiTypes.JobLocationType
      }
    } : {}),

    ...(searchParams.get('experience') ? {
      experience: {
        equals: (searchParams.get('experience') as string).toUpperCase() as ApiTypes.Experience
      }
    } : {}),

    ...(searchParams.get('sectors') ? {
      specializations: {
        some: {
          sector: {
            is: {
              id: {
                in: searchParams.getAll('sectors').map((id) => id) as string[]
              }
            }
          }
        }
      }

    } : {}),

    ...(searchParams.get('specializations') ? {
      specializations: {
        some: {
          id: {
            in: searchParams.getAll('specializations').map((id) => id) as string[]
          }
        }
      }
    } : {}),


    ...(searchParams.get('cities') ? {
      currentCityId: {
        in: searchParams.getAll('cities').map((id) => id)
      },
    } : {}),

    ...(searchParams.get('from') && !searchParams.get('to') && !searchParams.get('salaryPeriod') ? {
      preferSalary: {
        is: {
          OR: [
            {
              fromBase: {
                gte: (Number(searchParams.get('from')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              }
            },
          ],
        }
      }
    } : {}),

    ...(searchParams.get('from') && searchParams.get('currency') && searchParams.get('to') && searchParams.get('salaryPeriod') ? {
      preferSalary: {
        is: {
          AND: [
            {
              fromBase: {
                gte: (Number(searchParams.get('from')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              },
              toBase: {
                lte: (Number(searchParams.get('to')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              },
              schedule: {
                equals: searchParams.get('salaryPeriod') as ApiTypes.SalaryPeriod
              }
            },
          ],
        }
      }
    } : {}),

    ...(searchParams.get('from') && searchParams.get('to') && !searchParams.get('salaryPeriod') ? {
      preferSalary: {
        is: {
              fromBase: {
                gte: (Number(searchParams.get('from')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              },
              toBase: {
                lte: (Number(searchParams.get('to')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              },
          }
      }
    } : {}),

    ...(searchParams.get('salaryPeriod') && !(searchParams.get('from') && searchParams.get('to')) ? {
      preferSalary: {
        is: {
          schedule: {
            equals: searchParams.get('salaryPeriod') as ApiTypes.SalaryPeriod
          },
          toBase: {
            lte: (Number(searchParams.get('to')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
          },
        }
      }
    } : {}),

    ...(searchParams.get('salaryPeriod') && searchParams.get('from') && !searchParams.get('to') ? {
      preferSalary: {
        is: {
          schedule: {
            equals: searchParams.get('salaryPeriod') as ApiTypes.SalaryPeriod
          },
          fromBase: {
            gte: (Number(searchParams.get('from')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
          },
        }
      }
    } : {}),

    ...(searchParams.get('to') && !searchParams.get('from') && !searchParams.get('salaryPeriod') ? {
      preferSalary: {
        is: {
          OR: [
            {
              toBase: {
                lte: (Number(searchParams.get('to')) / Number(rate)) as unknown as ApiGraphQLTypes['Decimal']
              }
            },
          ],
        }
      }
    } : {}),
  }

  const { data: candidates, isLoading } = useAuthQueryRequest(
    ['canditates', { page, where }],
    {
      getCandidates: [
        { take: PER_PAGE, skip, where },
        {
          ...employeeListItemSelector,
          profile: {
            ...employeeListItemSelector.profile,
            isHidden: [
              { profileId },
              true
            ],
            conversations: [
              { profileId },
              {
                id: true
              }
            ]
          }
        }
      ],
      countEmployees: [
        { where : { ...where, deletedAt: null }},
        true
      ],
      ...(searchParams.get('sectors') ? {
        getSectors: [
          {
            take: 100,
            where: {
              id: {
                in: searchParams.getAll('sectors') as string[]
              }
            }
          },
          sectorSelector
        ]
      } : {}),

      ...(searchParams.get('specializations') ? {
        getSpecializations: [
          {
            take: 100,
            where: {
              id: {
                in: searchParams.getAll('specializations') as string[]
              }
            }
          },
          specializationSelector
        ]
      } : {}),

      ...(searchParams.get('cities') ? {
        getCities: [
          {
            take: 100,
            where: {
              id: {
                in: searchParams.getAll('cities') as string[]
              }
            }
          },
          citySelector
        ]
      } : {}),

      ...(searchParams.get('currency') ? {
        getCurrencies: [
          {
            take: 1,
            where: {
              id: {
                equals: searchParams.get('currency') as string
              }
            }
          },
          currencySelector
        ]
      } : {}),
    },
    {
      cacheTime: 0,
    },
  )

  const filter: FilterFields = {
    search: searchParams.get('search') as string || '',
    sectors: candidates?.getSectors as SectorFields[] || [],
    specializations: candidates?.getSpecializations as SpecializationFields[] || [],
    experience: searchParams.get('experience') as ApiTypes.Experience || '',
    salaryPeriod: query?.salaryPeriod as ApiTypes.SalaryPeriod || '',
    from: searchParams.get('from') as string || '',
    to: searchParams.get('to') as string || '',
    currency: candidates?.getCurrencies?.[0] ? candidates?.getCurrencies?.[0] as CurrencyFields : null,
    cities: candidates?.getCities as CityFields[] || [],
    jobLocationType: searchParams.get('jobLocationType') as ApiTypes.JobLocationType || '',
  }

  const createMakeVisibleRequest = useCreateMakeVisibleRequest()

  const confirm = async (employeeProfileId: string) => {
    try {
      await createMakeVisibleRequest.mutateAsync(employeeProfileId)
    } catch (e) {
      await setVisibleError(true)
    }
  }

  const { open } = useChat()
  const handleSendMessage = async (employee: EmployeeListItem) => {
    const conversationId = await open(
      {
        withProfileId: employee.profile.id,
        conversationId: employee.profile.conversations?.[0]?.id
      }
    )
  }

  const totalPages = calcTotalPages(candidates?.countEmployees || 0)

  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}>
        <Filter onSave={applyFilter} onClearFilter={clearAll} initialValues={filter} />
        <Box sx={sortWrapper}>
          <SortBlock
            title={
              !candidates?.getCandidates ? 'Searching' :
            (candidates?.getCandidates && !candidates?.getCandidates.length) ? 'No results found' : 'Candidates'
          } selectOptions={[]}
          />
        </Box>
        <Snackbar
          autoHideDuration={10000}
          open={visibleError}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={() => {
            setVisibleError(false)
          }}
        >
          <Stack sx={{ width: '100%', flexDirection: 'column', gap: 2 }} spacing={2}>
            <Alert severity='error'>
              <AlertTitle>Request already created for this profile</AlertTitle>
            </Alert>
          </Stack>
        </Snackbar>
        {isLoading && (
          <Box sx={{ marginX: 'auto' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <Grid container spacing={4}>
            {candidates?.getCandidates?.map((employee) => (
              <Grid item xs={matchesMd ? 12 : 4} key={employee.id}>
                <CandidateListCard
                  employee={employee}
                  confirm={confirm}
                  onClick={handleSendMessage}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Pagination
          page={page}
          count={totalPages}
          hidePrevButton
          sx={(matchesSmall && totalPages > 4) ? paginationStyle : { ml: 'auto' }}
          onChange={(_, page) => updatePage(page )}
        />
      </Content>
    </TempWrapperNew>
  )
}

const paginationStyle = (theme: Theme) => ({
    ml: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginX: '-24px'
    }
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  '& form': {
    width: '100%'
  },
})

const sortWrapper = (theme: Theme) => ({
  mt: theme.spacing(2),
  mb: theme.spacing(0),
})

export default MyCandidates
