import { useRecoilValue } from 'recoil'
import { ApiTypes } from 'recruticka-frontend-libs'
import { profileState } from '../state/atoms/profileState'
import DashboardCompany from './dashboard-company'
import DashboardEmployee from './dashboard-employee'

const DashboardPage = () => {
  const profile = useRecoilValue(profileState)

  if (profile?.role === ApiTypes.Role.EMPLOYEE) {
    return <DashboardEmployee />
  }

  if (profile?.role === ApiTypes.Role.EMPLOYER) {
    return <DashboardCompany />
  }

  return null
}

export default DashboardPage
