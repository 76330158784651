import { Portfolio } from './icons/portfolio';
import {Box, Theme, Typography} from '@mui/material';
import { BoxContent } from './ui-kits/box-content/box-content';
import { LocationIcon } from './icons/location';
import { EJobsBoxTypes, IJobsBoxOption } from './types/jobs';
import { CreditCard } from './icons/credit-card';
import { WorkFromHomeIcon } from './icons/workFromHomeIcon';

export const JobsHotInformationList = ({ label, type }: IJobsBoxOption) => {
  const svg =
    type === EJobsBoxTypes.LOCATION ? (
     typeof label !== 'number' ? <LocationIcon /> : <WorkFromHomeIcon/>
    ) : type === EJobsBoxTypes.SALARY ? (
      <CreditCard />
    ) : (
      <Portfolio />
    );

  return (
    <BoxContent sx={boxContentBg}>
      <Box>{svg}</Box>
      <Typography variant={'subtitle2'}>{label}{typeof label === 'number' && '/5 WFH'}</Typography>
    </BoxContent>
  );
};

const boxContentBg = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  backgroundColor:'#FFF4E5',
  gap: theme.spacing(1),
  // width: '48%',
  alignItems:'center',
  '& .MuiBox-root': {
    display: 'flex',
    '& svg':{
      '& path': {
        fill: theme.palette.secondary.main,
        fillOpacity: 1,
      },
    },
  },
  '& h6': {
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]:{
    // backgroundColor: 'unset',
    // paddingLeft: 'unset',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    // borderRadius: 'unset'
  },
  [theme.breakpoints.down('xs')]:{
    '& h6': {
      fontSize: '10px',
      lineHeight: '16px',
    }
  }
});
