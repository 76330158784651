import React, { FC } from 'react'
import { HeaderNew } from './not-cms/header-new'
import { useRecoilValue } from 'recoil'
import { appLoadedState } from '../state/atoms/appLoadedState'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { Main } from '../components/common-files/common-styles'
import {Sidebar} from '../components/Sidebar'
import theme from '../styles/theme'

interface ITempWrapperNew {
  hideFooter?: boolean
  children: React.ReactNode
}

const TempWithoutSidebar: FC<ITempWrapperNew> = ({ children, hideFooter }) => {
  const isAppLoaded = useRecoilValue(appLoadedState)
  
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };  
  
  const matchMd = useMediaQuery(theme.breakpoints.down(1250))



  return (
    <Box sx={wrapper} className={'temp-Wrapper'}>
      {isAppLoaded && <HeaderNew handleDrawerOpen={handleDrawerOpen} hideMenu={true} />}
      <Main 
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.background.default,
        })}
      >
        {/* <Sidebar open={matchMd ? !open : open} /> */}
        {children}
      </Main>

      {/* {isAppLoaded && (*/}
      {/*  <Box sx={{ marginTop: "auto" }}>*/}
      {/* <Navbar bottomMenu={[*/}
      {/*  { url: "/contact", text: "Contact Us" },*/}
      {/*  { url: "/terms", text: "Terms of Business" },*/}
      {/* ]} />*/}
      {/* {!hideFooter && <Footer
            logoText="Recruiticka"
            companyDescription="We are the largest marketplace for recruiters, headhunters and recruitment leaders in the world.Our technology connects recruiters  with recruitment agencies, end clients, RPO businesses and investors on a global scale 24/7/365"
            copyright="© Copyright 2022   All rights reserved"
            bottomMenu={[
              { url: "/contact", text: "Contact Us" },
              { url: "/terms", text: "Terms of Business" },
            ]}
            subscribeForm={{
              title: "Subscribe to our newsletter!",
              submitText: "Subscribe",
            }}
          />} */}
      {/*  </Box>*/}
      {/* )}*/}
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  '& form' : {
    width: '100%',
  }
})

export default TempWithoutSidebar
