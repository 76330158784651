import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { authMutation } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'

export const companyGallerySelector = ApiSelector('CompanyGallery')({
  id: true,
  isMain: true,
  asset: assetSelector,
})

export type CompanyGallery = ApiInputType<ApiGraphQLTypes['CompanyGallery'], typeof companyGallerySelector>
type CreateOrUpdateCompanyGallery = SetOptional<CompanyGallery, 'id'>

type MutationProps = {
  companyId: string
  values: CreateOrUpdateCompanyGallery
}

export const useCreateOrUpdateGallery = (selector: Partial<typeof companyGallerySelector>) => {
  return useMutation(async ({ companyId, values }: MutationProps) => {
    const { id, asset, ...input } = values

    if (id) {
      const { updateCompanyGallery } = await authMutation({
        updateCompanyGallery: [
          {
            id,
            input,
          },
          selector,
        ],
      })

      return updateCompanyGallery
    }

    const { createCompanyGallery } = await authMutation({
      createCompanyGallery: [
        {
          input: {
            ...input,
            asset: {
              connect: {
                id: asset.id,
              },
            },
            company: {
              connect: {
                id: companyId,
              },
            },
          },
        },
        selector,
      ],
    })

    return createCompanyGallery
  })
}

export const useDeleteGallery = () => {
  return useMutation(async (id: CompanyGallery['id']): Promise<boolean> => {
    await authMutation({
      deleteCompanyGallery: [{ id }, true],
    })

    return true
  })
}
