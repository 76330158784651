import { Avatar as AvatarOrig } from '@mui/material'
import { AvatarProps } from '@mui/material/Avatar/Avatar'
import { buildAssetUrl } from '../utilities/buildAssetUrl'

export const Avatar = (props: AvatarProps) => {
  const src = buildAssetUrl(props.src)

  const avatarProps = {
    ...props,
    src,
  }

  return <AvatarOrig {...avatarProps} />
}
