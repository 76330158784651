import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional, SetRequired } from 'type-fest'
import { authMutation } from '../services/api'
import { assetSelector } from '../components/Uploader/Uploader'
import { SetNullable } from '../interfaces/nullable'
import { Office, officeSelector, useCreateOrUpdateOffice, useDeleteOffice } from './useOffice'
import { CompanyGallery, useCreateOrUpdateGallery, useDeleteGallery } from './useGallery'
import { Perk, perkSelector, useCreateOrUpdatePerk, useDeletePerk } from './usePerk'
import { useCreateOrUpdateSalary } from './useCreateOrUpdateSalary'
import { siteAllCompaniesPage, siteCompanyPreviewPage } from '../config/urls'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../state/atoms/profileState'
import { SalaryFields } from '../components/not-cms/employer-steps/budget-information'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

const galarySelector = ApiSelector('CompanyGallery')({
  id: true,
  asset: assetSelector,
  isMain: true,
})

export const recruitmentFocusSelector = ApiSelector('Sector')({
  id: true,
  name: true,
})
export type RecruitmentFocusField = ApiInputType<ApiGraphQLTypes['Sector'], typeof recruitmentFocusSelector>

const creatSisterCompany = ApiSelector('Company')({
  id: true,
  isMain: true,
  logo: assetSelector,
  name: true,
  status: true,
  strength: true,
})

const companyRatingsSelector = ApiSelector('CompanyRating')({
  companyId: true,
  rating: true,
  url: true,
  id: true,
  type: true,
})

const companyLocationsSelector = ApiSelector('CompanyLocation')({
  id: true,
  address: true,
  lat: true,
  long: true,
})

export const companyUpdateSelector = ApiSelector('Company')({
  id: true,
  name: true,
  slug: true,
  status: true,
  foundedAt: true,
  strength: true,
  isMain: true,
  size: true,
  website: true,
  description: true,
  shortDescription: true,
  offices: [{ take: 100 }, officeSelector],
  perks: perkSelector,
  jobTypes: {
    id: true,
    name: true,
  },
  logo: assetSelector,
  lookingForPeople: true,
  workFromHome: true,
  companyRatings: companyRatingsSelector,
  workFromHomeDays: true,
  considerPeopleFromOtherSector: true,
  inverviewStages: true,
  cultureOfBusiness: true,
  commissionAndIncentives: true,
  recruitmentsFocus: recruitmentFocusSelector,
  commissionAndIncentivesFiles: assetSelector,
  hiringInCities: citySelector,
  basicSalary: salarySelector,
  coverImage: assetSelector,
  coverVideo: assetSelector,
  gallery: galarySelector,
  profile: {
    linkedin: true,
  },
  companyLocations: companyLocationsSelector,
  locationFiles: assetSelector,
  budgetForBasicSalariesFiles: assetSelector,
  cultureOfBusinessFiles: assetSelector,
  facebook: true,
  instagram: true,
  twitter: true,
})

export type CompanyLocation = ApiInputType<ApiGraphQLTypes['CompanyLocation'], typeof companyLocationsSelector>
export type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companyUpdateSelector>
export type Salary = SetNullable<ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>, 'currency'>
export type CompanyRatingsField = ApiInputType<ApiGraphQLTypes['CompanyRating'], typeof companyRatingsSelector>

export interface CompanyUpdateFields
  extends SetRequired<Partial<Omit<CompanyFields, 'basicSalary' | 'gallery' | 'perks' | 'offices' | 'companyRatings'>>, 'id'> {
  basicSalary?: SetOptional<Salary, 'id'>
  gallery?: SetOptional<CompanyGallery, 'id'>[]
  perks?: SetOptional<Perk, 'id'>[]
  offices?: SetOptional<Office, 'id'>[]
  companyRatingsFields?: SetOptional<CompanyRatingsField, 'id'>[]
}

type UpdateMutationProps = {
  values: CompanyUpdateFields
  oldValues?: PartialDeep<CompanyUpdateFields>
}

export const useDuplicateCompany = () => {
  const profile = useRecoilValue(profileState)
  const data = useMutation(async (companyId?: string) => {
    return await authMutation({
      createSisterCompany: [
        {
          duplicateCompanyId: companyId,
          profileId: profile.id,
        },
        creatSisterCompany,
      ],
    })
  })
  return data
}

export const useCreateSisiterCompany = () => {
  const profile = useRecoilValue(profileState)
  const data = useMutation(async () => {
    return await authMutation({
      createSisterCompany: [
        {
          profileId: profile.id,
        },
        creatSisterCompany,
      ],
    })
  })
  return data
}

export const useDeleteSisterCompany = () => {
  return useMutation(async (companyId: string) => {
    return await authMutation({
      removeSisterCompany: [{ id: companyId }, true],
    })
  })
}

export const useUpdateCompany = () => {
  const officeMutation = useCreateOrUpdateOffice({ id: true })
  const deleteOfficeMutation = useDeleteOffice()
  const galleryMutation = useCreateOrUpdateGallery({ id: true })
  const deleteGalleryMutation = useDeleteGallery()
  const perkMutation = useCreateOrUpdatePerk({ id: true })
  const deletePerk = useDeletePerk()
  const salaryMutation = useCreateOrUpdateSalary({ id: true })
  const mainCompany = useRecoilValue(mainCompanyState)
  return useMutation(async ({ values, oldValues = {} }: UpdateMutationProps): Promise<CompanyFields> => {
    const {
      id,
      slug,
      status,
      jobTypes,
      hiringInCities,
      basicSalary,
      commissionAndIncentivesFiles,
      companyLocations,
      locationFiles,
      budgetForBasicSalariesFiles,
      cultureOfBusinessFiles,
      logo,
      coverImage,
      coverVideo,
      offices,
      perks,
      gallery,
      profile,
      recruitmentsFocus,
      companyRatingsFields,
      ...input
    } = values

    let setGalleries: string[] | undefined = undefined

    if (offices) {
      const removeOffices: string[] = []

      oldValues?.offices?.forEach((oldOffice) => {
        const office = offices?.find((office) => office.id === oldOffice?.id)

        if (!office && oldOffice?.id) {
          removeOffices.push(oldOffice.id)
        }
      })

      if (offices.length) {
        setGalleries = []

        for (const office of offices) {
          const { id: officeId } = (await officeMutation.mutateAsync({
            companyId: id,
            values: office,
            oldValues: oldValues.offices?.find((oldOffice) => oldOffice?.id === office.id),
          })) as Office

          setGalleries.push(officeId)
        }
      }

      if (removeOffices.length) {
        for await (const officeId of removeOffices) {
          await deleteOfficeMutation.mutateAsync(officeId)
        }
      }
    }

    if (gallery) {
      const removeGalleries: string[] = []

      oldValues?.gallery?.forEach((oldGalleryItem) => {
        const galleryItem = gallery?.find((galleryItem) => galleryItem.id === oldGalleryItem?.id)

        if (!galleryItem && oldGalleryItem?.id) {
          removeGalleries.push(oldGalleryItem.id)
        }
      })

      if (gallery.length) {
        setGalleries = []

        for (const galleryItem of gallery) {
          const { id: galleryId } = (await galleryMutation.mutateAsync({
            companyId: id,
            values: galleryItem,
          })) as CompanyGallery

          setGalleries.push(galleryId)
        }
      }

      if (removeGalleries.length) {
        for await (const galleryId of removeGalleries) {
          await deleteGalleryMutation.mutateAsync(galleryId)
        }
      }
    }

    if (perks) {
      const createOrUpdatePerks = perks?.filter((perk) => {
        const hasDescription = Boolean(perk.description?.length)

        const textBlocks = perk.textBlocks?.filter((textBlock) => {
          return textBlock.description || textBlock.title || textBlock.textBlockAsset?.length
        })

        return hasDescription || textBlocks?.length
      })

      for await (const perk of createOrUpdatePerks) {
        await perkMutation.mutateAsync({
          companyId: id,
          values: perk,
          oldValues: oldValues.perks?.find((oldPerk) => oldPerk?.id === perk.id),
        })
      }

      const deletePerks = oldValues.perks?.filter((oldPerk) => {
        const perk = createOrUpdatePerks?.find((perk) => perk.id === oldPerk?.id)

        return !perk && oldPerk?.id
      })

      if (deletePerks?.length) {
        for await (const perk of deletePerks) {
          if (perk?.id) {
            await deletePerk.mutateAsync(perk.id)
          }
        }
      }
    }

    if (basicSalary && basicSalary.id) {
      const currency = basicSalary.currency

      if (currency) {
        await salaryMutation.mutateAsync({
          ...basicSalary,
          currency,
        })
      }
    }

    const addedCompanyLocations = companyLocations?.filter(({ id }) => !id)

    const removedCompanyLocations: { id: string }[] = []
    oldValues?.companyLocations?.forEach((oldPlace) => {
      const place = companyLocations?.find((place) => oldPlace?.id === place.id)
      if (!place && oldPlace?.id) {
        removedCompanyLocations.push({ id: oldPlace.id })
      }
    })

    const response = await authMutation({
      updateCompany: [
        {
          id,
          input: {
            ...input,
            ...(input.foundedAt
              ? // TODO Remove after lib will update
                { foundedAt: dayjs(input.foundedAt as string).format('YYYY-MM-DD') }
              : {}),

            ...(!!companyRatingsFields?.find(rating => rating.id && rating.url) || !!companyRatingsFields?.find(rating => !rating.id) ? {
              companyRatings: {
                update: companyRatingsFields.filter(rating => rating.id && rating.url).map(rating => {
                    return ({
                      where: {
                        id: rating.id,
                      },
                      data: {
                        url: rating.url
                      }
                    })
                }),
                create: companyRatingsFields?.filter(rating => !rating.id && rating.url).map(rating => {
                  return ({
                    type: rating.type,
                    url: rating.url
                  })
                })
              }
            } : {}),

            ...(!!companyRatingsFields?.length && !!companyRatingsFields?.find(rating => rating.id && !rating.url) ? {
              companyRatings: {
                delete: companyRatingsFields.filter(rating => rating.id && !rating.url).map(rating => {
                  return ({
                      id: rating.id,
                  })
                })
              }
            } : {}),

            ...(jobTypes
              ? {
                  jobTypes: {
                    set: jobTypes.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            ...(recruitmentsFocus
              ? {
                recruitmentsFocus: {
                  set: recruitmentsFocus.map(({id}) => ({ id }))
                }
              }
              :{}),

            ...( hiringInCities?.length
              ? {
                  hiringInCities: {
                    set: hiringInCities.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            ...( !hiringInCities?.length
              ? {
                hiringInCities: {
                  set: mainCompany?.hiringInCities?.map(({ id }) => ({ id })),
                },
              }
              : {}),

            ...(commissionAndIncentivesFiles
              ? {
                  commissionAndIncentivesFiles: {
                    set: commissionAndIncentivesFiles.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            companyLocations: {
              create: addedCompanyLocations,
              delete: removedCompanyLocations,
            },

            ...(locationFiles
              ? {
                  locationFiles: {
                    set: locationFiles.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            ...(budgetForBasicSalariesFiles
              ? {
                  budgetForBasicSalariesFiles: {
                    set: budgetForBasicSalariesFiles.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            ...(cultureOfBusinessFiles
              ? {
                  cultureOfBusinessFiles: {
                    set: cultureOfBusinessFiles.map(({ id }) => ({ id })),
                  },
                }
              : {}),

            ...(logo !== undefined
              ? {
                  logo: {
                    ...(logo
                      ? {
                          connect: {
                            id: logo === null ? null : logo.id,
                          },
                        }
                      : {
                          disconnect: true,
                        }),
                  },
                }
              : {}),

            ...(coverImage !== undefined
              ? {
                  coverImage: {
                    ...(coverImage
                      ? {
                          connect: {
                            id: coverImage === null ? null : coverImage.id,
                          },
                        }
                      : {
                          disconnect: true,
                        }),
                  },
                }
              : {}),

            ...(coverVideo !== undefined
              ? {
                  coverVideo: {
                    ...(coverVideo
                      ? {
                          connect: {
                            id: coverVideo === null ? null : coverVideo.id,
                          },
                        }
                      : {
                          disconnect: true,
                        }),
                  },
                }
              : {}),

            ...(coverVideo
              ? {
                  coverVideo: {
                    connect: {
                      id: coverVideo.id,
                    },
                  },
                }
              : {}),
          },
        },
        companyUpdateSelector,
      ],
    })

    return response.updateCompany
  })
}

const companyPublishSelector = ApiSelector('Company')({
  id: true,
  status: true,
})

export type CompanyPublishSelectorFields = Partial<
  ApiInputType<ApiGraphQLTypes['Company'], typeof companyPublishSelector>
>

export const usePublishCompany = () => {
  return useMutation(async (companyId: string) => {
    const { publishCompany } = await authMutation({
      publishCompany: [
        { id: companyId },
        {
          id: true,
          status: true,
        },
      ],
    })

    return publishCompany
  })
}

export const usePreviewCompany = () => {
  const openPreview = (id: string) => window.open(`${siteCompanyPreviewPage}/?id=${id}`)

  return {
    openPreview,
  }
}

export const useOpenCompanyPage = () => {
  const openCompanyPage = (slug: string) => window.open(`${siteAllCompaniesPage}/${slug}`)

  return {
    openCompanyPage,
  }
}
