import { useMutation } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiValueTypes } from 'recruticka-frontend-libs'
import { PartialDeep, SetOptional, SetRequired } from 'type-fest'
import { authMutation } from '../services/api'
import { useCreateOrUpdateEducation } from './useEducation'
import { useCreateOrUpdateWorkExperience } from './useWorkExperience'
import { useCreateOrUpdateSalary } from './useCreateOrUpdateSalary'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import { salaryHistorySelector, useCreateOrUpdateSalaryHistory } from './useCreateOrUpdateSalaryHistory'

const workExperienceSelector = ApiSelector('WorkExperience')({
  id: true,
  companyName: true,
  current: true,
  startAt: true,
  endAt: true,
  description: true,
  title: true,
})

const educationSelector = ApiSelector('Education')({
  id: true,
  grade: true,
  school: true,
  current: true,
  startAt: true,
  endAt: true,
  description: true,
  title: true,
})

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

export const employeeSelector = ApiSelector('Employee')({
  id: true,
  firstname: true,
  lastname: true,
  experience: true,
  locationType: true,
  profileId: true,
  currentCity: citySelector,
  jobTypes: {
    id: true,
    name: true,
  },
  jobCriterias: {
    id: true,
    name: true,
  },
  languages: {
    id: true,
    name: true,
  },
  specializations: {
    id: true,
    name: true,
    sector: {
      id: true,
      name: true,
    },
  },
  recruitCountries: {
    id: true,
    name: true,
    flagEmoji: true,
  },
  avatar: {
    id: true,
  },
  coverImage: {
    id: true,
  },
  salaryHistory: salaryHistorySelector,
  workExperiences: workExperienceSelector,
  preferSalary: salarySelector,
  educations: educationSelector,
  recruitRoles: true,
  reasonForLeavingRecentEmployer: true,
  canBringExistingClients: true,
  deskType: true,
  deskTypeRatio: true,
  clientIndustries: true,
  teamAvgSalary: salarySelector,
  teammatesCount: true,
  managedTeam: true,
})

// type ProfileFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof profileSelector>
type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>
type WorkExperiencceFields = ApiInputType<ApiGraphQLTypes['WorkExperience'], typeof workExperienceSelector>
type EducationFields = ApiInputType<ApiGraphQLTypes['Education'], typeof educationSelector>
type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>
type SalaryHistory = ApiInputType<ApiGraphQLTypes['SalaryHistory'], typeof salaryHistorySelector>

export interface SaveEmployeeValues
  extends SetRequired<Partial<Omit<EmployeeFields, 'workExperiences' | 'salaryHistory' | 'educations' | 'teamAvgSalary'>>, 'id'> {
  workExperiences?: SetOptional<WorkExperiencceFields, 'id'>[]
  educations?: SetOptional<EducationFields, 'id'>[]
  teamAvgSalary?: SetOptional<SalaryFields, 'id'>
  salaryHistory?: SetOptional<SalaryHistory, 'id'>
}

interface UpdateEmployeeMutationArgs {
  values: SetRequired<Partial<SaveEmployeeValues>, 'id'>
  employeeData: Partial<EmployeeFields>
}

export const useUpdateEmployee = (selector: PartialDeep<typeof employeeSelector>) => {
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const createOrUpdateSalaryHistoryMutation = useCreateOrUpdateSalaryHistory({ id: true })
  const workExperienceMutation = useCreateOrUpdateWorkExperience({ id: true })
  const educationMutation = useCreateOrUpdateEducation({ id: true })

  return useMutation(async ({ values, employeeData = {} }: UpdateEmployeeMutationArgs) => {
    const {
      id,
      currentCity,
      jobTypes,
      jobCriterias,
      languages,
      specializations,
      workExperiences,
      educations,
      recruitCountries,
      preferSalary,
      avatar,
      coverImage,
      teamAvgSalary,
      salaryHistory,
      ...otherValues
    } = values

    let preferSalaryId = null
    let salaryHistoryId = null
    let teamAvgSalaryId = null
    const workExperienceIds: string[] = []
    const educationIds: string[] = []

    if (preferSalary) {
      const preferSalaryResponse = await createOrUpdateSalaryMutation.mutateAsync(preferSalary)

      if (preferSalaryResponse && preferSalaryResponse.id !== employeeData?.preferSalary?.id) {
        preferSalaryId = preferSalaryResponse.id
      }
    }

    if (teamAvgSalary) {
      const teamAvgSalaryResponse = await createOrUpdateSalaryMutation.mutateAsync(teamAvgSalary)

      if (teamAvgSalaryResponse && teamAvgSalaryResponse.id !== employeeData?.teamAvgSalary?.id) {
        teamAvgSalaryId = teamAvgSalaryResponse.id
      }
    }

    if (salaryHistory) {
      const salaryHistoryResponse = await createOrUpdateSalaryHistoryMutation.mutateAsync({
        values: salaryHistory,
        oldValues: employeeData.salaryHistory
      })

      if (salaryHistoryResponse && salaryHistoryResponse.id !== employeeData?.salaryHistory?.id) {
        salaryHistoryId = salaryHistoryResponse.id
      }
    }

    if (workExperiences && employeeData.id) {
      for await (const workExperience of workExperiences) {
        const data = await workExperienceMutation.mutateAsync({
          employeeId: employeeData.id,
          values: workExperience,
        })

        if (data) {
          workExperienceIds.push(data.id)
        }
      }
    }

    if (educations && employeeData.id) {
      for await (const education of educations) {
        const data = await educationMutation.mutateAsync({
          employeeId: employeeData.id,
          values: education,
        })

        if (data) {
          educationIds.push(data.id)
        }
      }
    }

    const input: ApiValueTypes['EmployeeUpdateInput'] = {
      ...otherValues,

      ...(currentCity
        ? {
          currentCity: {
            connect: {
              id: currentCity.id,
            },
          },
        }
        : {}),

      ...(jobTypes
        ? {
          jobTypes: {
            set: jobTypes.map(({ id }) => ({ id })),
          },
        }
        : {}),

      ...(recruitCountries
        ? {
          recruitCountries: {
            set: recruitCountries.map(({ id }) => ({ id })),
          },
        }
        : {}),

      ...(jobCriterias
        ? {
          jobCriterias: {
            set: jobCriterias.map(({ id }) => ({ id })),
          },
        }
        : {}),

      ...(languages
        ? {
          languages: {
            set: languages.map(({ id }) => ({ id })),
          },
        }
        : {}),

      ...(Array.isArray(specializations)
        ? {
          specializations: {
            set: specializations.map(({ id }) => ({ id })),
          },
        }
        : {}),

      ...(preferSalaryId
        ? {
          preferSalary: {
            connect: {
              id: preferSalaryId,
            },
          },
        }
        : {}),

      ...(teamAvgSalaryId
        ? {
          teamAvgSalary: {
            connect: {
              id: teamAvgSalaryId,
            },
          },
        }
        : {}),

      ...(salaryHistoryId
        ? {
          salaryHistory: {
            connect: {
              id: salaryHistoryId,
            },
          },
        }
        : {}),

      ...(workExperienceIds.length
        ? {
          workExperiences: {
            connect: workExperienceIds.map((id) => ({ id })),
          },
        }
        : {}),

      ...(avatar !== undefined
        ? {
          avatar: {
            ...(avatar ? {
              connect: {
                id: avatar.id,
              }
            } : {
              disconnect: true
            }),
          },
        }
        : {}),

      ...(coverImage !== undefined
        ? {
          coverImage: {
            ...(coverImage ? {
              connect: {
                id: coverImage.id,
              },
            } : {
              disconnect: true
            })
          },
        }
        : {}),
    }

    const data = await authMutation({
      updateEmployee: [
        {
          id,
          input,
        },
        selector,
      ],
    })

    return data.updateEmployee
  })
}
