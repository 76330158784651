import { SvgProps } from '../types/icon'

export const ExperienceIcon = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height}>
      <path
        d='M7.42077 0.11664C7.21683 0.21861 6.53217 1.09264 5.87665 2.05407L4.69671 3.78757L2.6136 4.39939C0.501363 5.01121 0.224587 5.18601 0.0352138 5.87067C-0.0958908 6.43879 0.0934824 6.81754 1.38996 8.44906L2.64274 10.0223L2.58447 12.2802L2.54077 14.5381L3.02148 14.9606C3.28369 15.1937 3.61874 15.383 3.77898 15.383C3.93921 15.383 4.95892 15.0626 6.02232 14.6692L7.98889 13.9409L9.98459 14.6692C12.1551 15.4559 12.5338 15.485 13.102 14.8732C13.4224 14.5236 13.437 14.4216 13.4079 12.2511L13.3787 10.0077L14.6315 8.43449C15.928 6.7884 16.1028 6.43879 15.9571 5.84154C15.7969 5.18602 15.3744 4.95294 13.335 4.38482L11.3102 3.8167L10.1885 2.12691C9.57671 1.20918 8.96489 0.364282 8.84835 0.262311C8.51331 -0.0144653 7.81408 -0.0873003 7.42077 0.11664ZM8.93575 4.18088C9.41647 4.89467 9.92632 5.56476 10.0866 5.6376C10.2322 5.725 10.9315 5.95807 11.6307 6.14745C13.4516 6.67187 13.335 6.59903 13 6.97778C12.8398 7.15258 12.3736 7.74984 11.9512 8.28882L11.2082 9.27939V11.0275C11.2082 11.9743 11.1791 12.7609 11.15 12.7609C11.1208 12.7609 10.4799 12.5279 9.75152 12.2511C9.02316 11.9743 8.23653 11.7412 8.03259 11.7412C7.81408 11.7412 7.02745 11.9743 6.26996 12.2511C5.52704 12.5279 4.88608 12.7609 4.85695 12.7609C4.82781 12.7609 4.79868 11.9743 4.79868 11.0275V9.29396L4.25969 8.58017C3.96835 8.20142 3.5022 7.60417 3.22542 7.26912C2.86124 6.81754 2.78841 6.62816 2.91951 6.58446C3.03605 6.54076 3.69157 6.35139 4.37623 6.14745C5.07545 5.95807 5.77468 5.725 5.92035 5.6376C6.08059 5.56476 6.59044 4.89467 7.07116 4.18088C7.53731 3.45252 7.95975 2.85527 8.00346 2.85527C8.04716 2.85527 8.46961 3.45252 8.93575 4.18088Z'
        fill='rgba(20, 29, 36, 0.4)'
      />
    </svg>
  )
}
