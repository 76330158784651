import { Box } from '@mui/material'
import { GoogleMap, LoadScriptNext, LoadScriptProps } from '@react-google-maps/api'
import { useMemo, useState } from 'react'
import { LocationMapProps, PlaceMarkerProps, PlaceType } from './types'
import { PlacesAutocomplete } from './places-autocomplete'
import { PlaceMarker } from './place-marker'
import { rootSX } from './styles'
import { converLatLng } from './converLatLng'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  zoom: 2,
  center: { lat: 0, lng: 0 },
}

export const LocationMap = (props: LocationMapProps) => {
  const { data, onChange } = props
  const libraries = useMemo(() => ['places'] as LoadScriptProps['libraries'], [])
  const [map, setMap] = useState<google.maps.Map>()

  const handlePlaceSelect = (selectedPlace: PlaceType) => {
    // avoiding duplicate
    if (data.find((place) => place.lat === selectedPlace.lat && place.long === selectedPlace.long)) {
      return
    }

    onChange([selectedPlace, ...data])

    if (map) {
      const { lat, long } = selectedPlace
      map.setCenter(converLatLng({ lat, long }))
      map.setZoom(3)
    }
  }

  const handleChangeMarkerPlace = (idx: number) =>
    ((newPlace) => {
      onChange(data.map((place, placeIdx) => (idx === placeIdx ? newPlace : place)))
    }) as PlaceMarkerProps['onChangeMarkerPlace']

  const handleDeleteMarker = (idx: number) => () => {
    onChange(data.filter((_, placeIdx) => placeIdx !== idx))
  }

  // Iterate places to size, center, and zoom map to contain all markers
  const fitBounds = (map: google.maps.Map) => {
    setMap(map)

    if (data.length < 1) {
      return
    }

    if (data.length === 1) {
      const { lat, long } = data[0]
      map.setCenter(converLatLng({ lat, long }))
      return
    }

    const bounds = new window.google.maps.LatLngBounds()
    data.forEach(({ lat, long }) => bounds.extend(converLatLng({ lat, long })))
    map.fitBounds(bounds)
  }

  return (
    <LoadScriptNext googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
      <Box sx={rootSX}>
        <GoogleMap onLoad={fitBounds} options={mapOptions} mapContainerClassName='map-container'>
          <PlacesAutocomplete onPlaceSelected={handlePlaceSelect} />
          {data.map((place, idx) => (
            <PlaceMarker
              place={place}
              onChangeMarkerPlace={handleChangeMarkerPlace(idx)}
              onDeleteMarker={handleDeleteMarker(idx)}
              key={place.address}
            />
          ))}
        </GoogleMap>
      </Box>
    </LoadScriptNext>
  )
}
