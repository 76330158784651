import { FC } from 'react';
import {Theme, Box, Typography, useMediaQuery } from '@mui/material'
import { Button } from './ui-kits/button/button'
import { LoadingButton } from '@mui/lab'


import { LeftArrow } from './icons/leftArrow'
import { RightArrow } from './icons/rightArrow'
import { SaveIcon } from './icons/saveIcon'
import { PreviewIcon } from './icons/previewIcon'
import { RocketIcon } from './icons/rocketIcon'

type PageTitleProps = {
  title: string
}

export const PageTitle: FC<PageTitleProps> = ({
  title,
}) => {
  return (
      <Box sx={titleWrapper}>
        <Typography variant={'h4'}>{title}</Typography> 
        {/* <Box sx={buttonWrap}>
            <Button
                sx={buttonStyle}
                endIcon={<SaveIcon fill='#fff' />}
                type='submit'
                size={'small'}
                variant={'contained'}
                color='success'
                >
                Save
            </Button>
            <Button
                sx={[buttonStyle, disableButton]}
                endIcon={
                <PreviewIcon /> 
                }
                href='#'
                size={'small'}
                variant={'outlined'}
                disabled
                >
                Preview
            </Button>
            <Button
                size='squareSmall'
                sx={buttonStyle}
                endIcon={<RocketIcon />}
                href='#'
                >
                Set Company Live
            </Button>
        </Box> */}
      </Box>
  )
}


const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(2),
  }
})


const buttonWrap = (theme: Theme) => ({
  [theme.breakpoints.down(400)]: {
    '& a:last-of-type': {
      marginRight: '0px',
      marginTop: '16px',
    }
  }
})

const buttonStyle = (theme: Theme) => ({
  marginRight: '16px',
  '& svg': {
    marginRight: '0px',
  },
  [theme.breakpoints.down('lg')]: {
  },
  [theme.breakpoints.down('sm')]: {
  },
})

const disableButton = (theme: Theme) => ({
  '& svg path': {
    fill: 'rgba(0, 0, 0, 0.26)!important',
  }
})