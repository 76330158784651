import React from 'react'
import Cookies from '../components/cookie/cookies'

const TestPage = () => {
  return (
    <div style={{ backgroundColor: 'rgba(166,166,166,0.55)', height: '100vh' }}>
      <h1 style={{ margin: '0' }}>Test Page</h1>
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Cookies />
      </div>
    </div>
  )
}

export default TestPage
