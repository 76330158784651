import { Box, BoxProps } from '@mui/material'

export const BoxContent = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      p={'14px'}
      boxSizing={'border-box'}
      width={'100%'}
      display={'flex'}
      borderRadius={1.5}
      alignItems={'center'}
      {...props}
    >
      {children}
    </Box>
  )
}
