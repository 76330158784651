import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FileProgress } from '@uppy/core';
import { authMutation } from '../../services/api';
import { buildAssetUrl } from '../../utilities/buildAssetUrl';
import { Box, SxProps, Theme } from '@mui/system';
import { Button, CircularProgress, Grid, IconButton, Link, TextField, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { buildPreviewFromYoutubeUrl } from '../../utilities/buildPreviewFromYoutubeUrl';
import useResizeObserver from '@react-hook/resize-observer'
import { useRecoilValue } from 'recoil';
import { profileState } from '../../state/atoms/profileState';
import { ProgressFile } from '../chat/chat-components/upload-slider'
import { FileUploader } from 'react-drag-drop-files';


// Icon
import { UploadIcon } from '../../components/icons/uploadIcon'


export const assetSelector = ApiSelector('Asset')({
  id: true,
  url: true,
  type: true,
  size: true,
  mimeType: true,
  name: true,
})

export type Asset = ApiInputType<ApiGraphQLTypes['Asset'], typeof assetSelector>

export type TypeOfComponent = 'dashboard' | 'button' | 'youtube'
export type Size = 'sm' | 'md' | 'lg'

type UploaderProps = {
  assets?: Array<Asset> | Asset | null
  type?: TypeOfComponent,
  label?: string,
  description?: string,
  multiple?: boolean,
  limit?: number,
  allowedFileTypes?: string[],
  button?: ReactNode,
  gridView?: boolean,
  onProgressFileUpload?: (file: UploaderAsset, progress: FileProgress) => void,
  onFileUploaded?: (file: Asset, files: Array<Asset>) => void
  onFileDeleted?: (file: Asset, files: Array<Asset>) => void,
  fullPreview?: boolean,
  autoDelete?: boolean,
  inputName?: string,
  setProgress?: (arg: boolean) => void
}

const upload = async (file: File, profileId: string) => {
  const createAssetResponse = await authMutation({
    createAsset: [
      {
        profileId: profileId,
        data: {
          fileName: file.name,
          mimeType: file.type as string,
          name: file.name,
          size: file.size,
        },
      },
      {
        id: true,
        url: true,
      },
    ],
  });

  await fetch(createAssetResponse.createAsset.url, {
    method: 'PUT',
    headers: new Headers({ 'Content-Type': file.type }),
    body: file,

  });

  const completeUploadAssetResponse = await authMutation({
    completeUploadAsset: [
      {
        data: { id: createAssetResponse.createAsset.id as string },
      },
      assetSelector,
    ],
  })

  return completeUploadAssetResponse.completeUploadAsset;
}

const useSize = (target: any): DOMRectReadOnly | undefined => {
  const [size, setSize] = useState<DOMRectReadOnly>()

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => setSize(entry.contentRect))

  return size
}

const getTypeByMimeType = (mimeType: string) => {
  const images = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/svg+xml'];
  const videos = ['video/mp4', 'video/webm', 'video/mkv'];
  const documents = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  if (images.includes(mimeType)) {
    return ApiTypes.AssetType.IMAGE;
  }

  if (videos.includes(mimeType)) {
    return ApiTypes.AssetType.VIDEO;
  }

  // if (documents.includes(mimeType)) {
  return ApiTypes.AssetType.DOCUMENT;
  // }

  // return IMAGES;
}

interface UploaderAsset {
  loading: boolean;
  uploadId: string;
  url: string;
  asset?: Asset
  name?: string
  type?: ApiTypes.AssetType
}

export const Uploader = (props: UploaderProps) => {
  props = {
    ...{
      multiply: true
    },
    ...props,
  }

  const { id: profileId } = useRecoilValue(profileState)
  const [files, setFiles] = useState<UploaderAsset[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openDashboardModal = () => setIsModalOpen(true)
  const closeDashboardModal = () => setIsModalOpen(false)
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null);
  const filesTotal = files.length;
  const wrapperRef = useRef(null)
  const wrapperSize = useSize(wrapperRef)
  const allowedFileTypes = useMemo(() => props.allowedFileTypes ? props.allowedFileTypes.join(',') : undefined, [props.allowedFileTypes])

  const size = useMemo<Size>(() => {
    if (wrapperSize?.width && wrapperSize.width < 724 && wrapperSize.width > 600) {
      return 'md'
    }

    if (wrapperSize?.width && wrapperSize.width > 724) {
      return 'lg'
    }

    return 'sm'
  }, [wrapperSize?.width])

  const getFilesAssets = () => {
    const assets: Asset[] = [];

    files.forEach((file) => {
      if (file.asset) {
        assets.push(file.asset)
      }
    })

    return assets;
  }

  const addFile = (file: Asset) => {
    const preview =
      file.type === ApiTypes.AssetType.YOUTUBE
        ? buildPreviewFromYoutubeUrl(file?.url)
        : buildAssetUrl(file.url);

    setFiles((prev) => [...prev, {
      loading: false,
      uploadId: crypto.randomUUID(),
      url: preview ?? '',
      asset: file,
      name: file.name,
      type: file.type,
    }])
  }

  const removeFile = (file: Asset) => {
    setFiles((prev) => prev.filter(({ asset }) => asset?.id !== file.id))
  }

  const onFileDelete = (file: UploaderAsset) => {
    if (props.onFileDeleted && file.asset) {
      const assets: Asset[] = [];

      files.forEach((file) => {
        if (file.asset && file.asset.id !== file.asset.id) {
          assets.push(file.asset)
        }
      })

      props.onFileDeleted(file.asset, assets)
    }
  }

  useEffect(() => {
    const assetFiles: UploaderAsset[] = [...files];
    const propsAssets = props.assets || [];
    const assets = Array.isArray(propsAssets) ? propsAssets : [propsAssets]

    assetFiles.forEach((uploaderAsset) => {
      const fileExists = assets.find((asset) => asset.id === uploaderAsset.asset?.id)

      if (!fileExists && uploaderAsset.asset) {
        removeFile(uploaderAsset.asset);
      }
    })

    assets.forEach((asset) => {
      const fileExists = assetFiles.some((file) => asset.id === file.asset?.id)

      if (!fileExists) {
        assetFiles.push({
          loading: false,
          uploadId: '',
          url: asset.url,
          asset,
          type: asset.type
        })

        addFile(asset)
      }
    })

  }, [props.assets])

  // console.log(files);

  const isFileHasValidType = (file: File) => {
    return props.allowedFileTypes ? props.allowedFileTypes.some((type) => file.type.endsWith('/' + type.replace('.', '')) || file.type === type) : true;
  }

  const onFileChosen = async (chosenFiles: File[]) => {
    closeDashboardModal();

    setLoading(true);
    if (props.setProgress) {
      props.setProgress(true)
    }
    await Promise.all(chosenFiles.map(async (file) => {
      if (!isFileHasValidType(file)) {
        return;
      }

      const tempFile: UploaderAsset = {
        loading: true,
        uploadId: crypto.randomUUID(),
        url: URL.createObjectURL(file),
        name: file.name,
        type: getTypeByMimeType(file.type)
      }

      setFiles((prev) => [...prev, ...[tempFile]]);

      const asset = await upload(file, profileId);

      setFiles((prev) => prev.map((prevFile) => {
        if (prevFile.uploadId === tempFile.uploadId) {
          if (prevFile.url) {
            URL.revokeObjectURL(prevFile.url);
          }

          return { ...prevFile, loading: false, asset, url: asset.url }
        }
        return prevFile;
      }));

      const assets: Asset[] = [];

      files.forEach((file) => {
        if (file.asset) {
          assets.push(file.asset)
        }
      })

      assets.push(asset)

      props.onFileUploaded && props.onFileUploaded(asset, assets)
    }))

    setLoading(false);

    if (props.setProgress) {
      props.setProgress(false)
    }
  }

  const onInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onFileChosen(Array.from(e.target.files))
    }

    e.target.value = '';
  }

  const checkYoutubeUrl = () => {
    return /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?\w?=]*)?/.test(url)
  }

  const addYoutubeUrl = async () => {
    setLoading(true)

    try {
      const { title: name } = await fetch(`https://www.youtube.com/oembed?url=${url}&format=json`).then(
        async (response) => await response.json(),
      )

      const createYoutubeAssetResponse = await authMutation({
        createYoutubeAsset: [
          {
            profileId,
            data: { name, url },
          },
          assetSelector,
        ],
      })

      addFile(createYoutubeAssetResponse.createYoutubeAsset)

      props.onFileUploaded && props.onFileUploaded(createYoutubeAssetResponse.createYoutubeAsset, getFilesAssets())
    } catch (e) {
      console.error(e);
    }

    closeDashboardModal();
    setLoading(false)

    setUrl('')
  }

  const openSelectFileDialog = () => inputRef?.current?.click()

  const getAssetUrl = (file: UploaderAsset) => {
    if (file.url.startsWith('http')) {
      return file.url
    }

    if (file.asset?.type === ApiTypes.AssetType.YOUTUBE) {
      return buildPreviewFromYoutubeUrl(file.url ?? file.asset.url)
    }

    return file.url.startsWith('blob:') ? file.url : buildAssetUrl(file.url)
  }

  return (
    <Box ref={wrapperRef} sx={[boxWrapper , { width: '100%' }]}>
      {props.type === 'button' ? <Box textAlign='center'>
        <input
          type='file'
          ref={inputRef}
          onChange={onInputChange}
          hidden
          multiple={props.multiple}
          value=''
          accept={allowedFileTypes}
        />
        <Box display='inline' onClick={() => openSelectFileDialog()}>
          {props.button || (
            <LoadingButton loading={loading} loadingPosition='start' size='small' variant='outlined' component='span'>
              {props.inputName ?? 'Choose files'}
            </LoadingButton>
          )}
        </Box>
      </Box> : <Box sx={wrapper}>
        <Box sx={innerWrapper}>
          {(props.label || props.description || !!filesTotal) && (
            <Grid className='uploader-container' container justifyContent='space-between' alignItems='center' padding='6px 10px 0' flexDirection='column'>
              {props.label && (
                <Grid item className='col-8'>
                  <Typography variant={'subtitle1'} textAlign={'initial'} fontSize='14px'>
                    {props.label}
                  </Typography>
                  {props.description && (
                    <Typography sx={description} textAlign={'initial'} fontSize='14px'>
                      {props.description}
                    </Typography>
                  )}
                </Grid>
              )}
              {/* {!!filesTotal && props.multiple && (
                <Grid item marginLeft='auto' className='col-4'>
                  <Button
                    className='add-more'
                    size='small'
                    startIcon={<Box>+</Box>}
                    variant='outlined'
                    component='span'
                    onClick={openDashboardModal}
                  >
                    Add more
                  </Button>
                </Grid>
              )} */}
            </Grid>
          )}
          <FileUploader
            disabled
            hoverTitle=' '
            multiple={props.multiple}
            classes='uploader-dnd'
            handleChange={(files: FileList | File) => {
              onFileChosen(files instanceof FileList ? Array.from(files) : [files])
            }}>
            <Box sx={dashboard}>
              <Box sx={[iconBox, {mb: 1,}]}>
                <UploadIcon />
              </Box>
              <Box>
                <Link
                  component="button"
                  variant="body2"
                  sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'none' } }}
                  onClick={() => openSelectFileDialog()}
                  type='button'
                >
                  Click to upload 
                </Link>
                &nbsp;or drag and drop
              </Box>
              <Typography variant='body2' mt={0.5} color={(theme: Theme) => theme.palette.text.secondary}>
                PNG, JPG or MOV (max. 3MB)
              </Typography>
            </Box>
          </FileUploader>
        </Box>
        {!filesTotal || files.length > 0 && (
          <Divider sx={(theme: Theme) => ({
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3)
          })} />
        )}
        <Grid container flexDirection='column' flexGrow='1'>
            <Grid container flexGrow='1'>
              {!filesTotal && props.type === 'youtube' && (
                <Box sx={youtube}>
                  <Typography variant={'body2'} textAlign={'center'}>
                    Want to use a YouTube video? Paste the URL below and well do the rest:
                  </Typography>
                  <TextField
                    sx={{ marginTop: '8px', marginBottom: '8px' }}
                    placeholder={'https://www.youtube.com/watch?v=...'}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    fullWidth
                  />
                  <LoadingButton
                    loading={loading}
                    disabled={!checkYoutubeUrl()}
                    size={'small'}
                    onClick={addYoutubeUrl}
                  >
                    Save Video
                  </LoadingButton>
                </Box>
              )}
              {((props.type !== 'youtube' || !!filesTotal) && files.length > 0) && (
                <Box sx={container}>
                  {/* {filesTotal === 0 ? <FileUploader
                    disabled
                    hoverTitle=' '
                    multiple={props.multiple}
                    classes='uploader-dnd'
                    handleChange={(files: FileList | File) => {
                      onFileChosen(files instanceof FileList ? Array.from(files) : [files])
                    }}>
                    <Box sx={dashboard}>
                      <Link
                        component="button"
                        variant="body2"
                        sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'none' } }}
                        onClick={() => openSelectFileDialog()}
                        type='button'
                      >
                        Click to upload 
                      </Link>
                      &nbsp;or drag and drop
                      <Typography variant='body2' mt={0.5} color={(theme: Theme) => theme.palette.text.secondary}>
                        PNG, JPG or MOV (max. 3MB)
                      </Typography>
                    </Box>
                  </FileUploader> : null} */}

                  <Box sx={{ display: 'block', width: '100%', boxSizing: 'border-box' }}>
                    <Typography variant={'body1'} sx={(theme: Theme) => ({ marginBottom: theme.spacing(1.5), color: theme.palette.text.primary })} >
                      Preview
                    </Typography>
                  </Box>
                  
                  <Box sx={[
                    {
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '100%',
                      boxSizing: 'border-box',
                      gap: '8px',
                    },
                    {
                      ...(size === 'md' || size === 'lg' ? {
                        display: 'flex',
                        gap: '16px',
                        pt: '0px',
                        pb: '0px',
                        px: '0px',
                        flexWrap: 'wrap',
                      } : {})
                    },
                    {
                      ...(props.fullPreview ? {
                        display: 'block',
                        gap: 0,
                        pl: '10px',
                        pr: '10px',
                      } : {})
                    },
                  ]}>
                    {files.map((file) => {
                      return (
                        <Box key={file.uploadId} sx={[
                          {
                            width: '100%',
                            boxSizing: 'border-box',
                          },
                          {
                            ...(size === 'sm' ? {
                              display: 'flex',
                              alignItems: 'center',
                              padding: '0px',
                            } : {})
                          },
                          {
                            ...(size === 'md' ? {
                              display: 'block',
                              width: 'calc(50% - 15px - 15px)',
                            } : {})
                          },
                          {
                            ...(size === 'lg' ? {
                              display: 'block',
                              width: 'calc(33.33% - 15px - 15px)',
                              height: '200px',
                            } : {})
                          },
                          {
                            ...(props.fullPreview ? {
                              display: 'block',
                              // width: '100%',
                              height: '250px',
                              padding: 0
                            } : {})
                          },
                        ]}>
                          
                          <Box sx={[
                              {
                                position: 'relative',
                              },
                              {
                                ...(size === 'sm' ? {
                                  width: '50px',
                                  minWidth: '50px',
                                  height: '50px',
                                  minHeight: '50px',
                                  mr: '10px'
                                } : {})
                              },
                              {
                                ...(size === 'md' ? {
                                  mb: '10px',
                                  height: '140px',
                                } : {})
                              },
                              {
                                ...(size === 'lg' ? {
                                  // mb: '0',
                                  width: '100%',
                                  height: '100%'
                                } : {})
                              },
                              {
                                ...(props.fullPreview ? {
                                  width: '100%',
                                  height: '100%',
                                } : {})
                              },
                            ]}>
                            {file.type === ApiTypes.AssetType.VIDEO ? <Box component='video' sx={mediaStyles} preload='metadata' src={getAssetUrl(file)} /> : null}
                            {file.type === ApiTypes.AssetType.IMAGE || file.type === ApiTypes.AssetType.YOUTUBE ? <Box component='img' sx={mediaStyles} src={getAssetUrl(file)} /> : null}
                            {file.type === ApiTypes.AssetType.DOCUMENT ? <Box component='div' sx={{ ...mediaStyles, display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 1px 2px rgb(0 0 0 / 15%)' }}>
                              <svg aria-hidden="true" focusable="false" width="25" height="25" viewBox="0 0 25 25"><g fill="#A7AFB7" fillRule="nonzero"><path d="M5.5 22a.5.5 0 0 1-.5-.5v-18a.5.5 0 0 1 .5-.5h10.719a.5.5 0 0 1 .367.16l3.281 3.556a.5.5 0 0 1 .133.339V21.5a.5.5 0 0 1-.5.5h-14zm.5-1h13V7.25L16 4H6v17z"></path><path d="M15 4v3a1 1 0 0 0 1 1h3V7h-3V4h-1z"></path></g></svg>
                            </Box> : null}
                            {file.loading && <Box sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <CircularProgress color='info' />
                            </Box>}
                            {!file.loading && (size !== 'sm' || props.fullPreview) && <IconButton sx={{ position: 'absolute', right: 0, top: 0 }} onClick={() => onFileDelete(file)}>
                              <svg aria-hidden="true" focusable="false" width="18" height="18" viewBox="0 0 18 18"><path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path></svg>
                            </IconButton>}
                          </Box>
                          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                            {size !== 'lg' && !props.fullPreview ? <Box sx={{ flexGrow: 1, flexShrink: 1, paddingRight: '5px' }}>
                              <Box sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '12px', lineHeight: 1.3, wordWrap: 'anywhere', wordBreak: 'break-all' }}>
                                {file.name ?? file.asset?.name}
                              </Box>
                              <Box sx={{ color: '#757575', fontWeight: 'normal', fontSize: '11px', lineHeight: 1 }}>138kb</Box>
                            </Box> : null}
                            {size === 'sm' && !props.fullPreview ? <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {!file.loading && <IconButton onClick={() => onFileDelete(file)}>
                                <svg aria-hidden="true" focusable="false" width="18" height="18" viewBox="0 0 18 18"><path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path></svg>
                              </IconButton>}
                            </Box> : null}
                          </Box>
                        </Box>
                      )
                    })}
                  </Box>

                </Box>
              )}
              {isModalOpen && (
                <Box sx={modal}>
                  <Grid container justifyContent='space-between' alignItems='center' padding='21px 24px 0'>
                    <Grid item>
                      <Button
                        size='small'
                        className='uppy-Dashboard-browse'
                        startIcon={
                          <Box>
                            <svg
                              width='12'
                              height='12'
                              viewBox='0 0 12 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z'
                                fill='currentColor'
                              />
                            </svg>
                          </Box>
                        }
                        variant='outlined'
                        component='span'
                        onClick={closeDashboardModal}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' flexGrow='1'>
                    <Grid container width='100%' height='100%' sx={{
                      '.uploader-dnd': {
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                    }}>
                      {props.type === 'youtube' && (
                        <Box sx={youtube}>
                          <Typography variant={'body2'} textAlign={'center'}>
                            Want to use a YouTube video? Paste the URL below and well do the rest:
                          </Typography>
                          <TextField
                            sx={{ marginTop: '8px', marginBottom: '8px' }}
                            placeholder={'https://www.youtube.com/watch?v=...'}
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            fullWidth
                          />
                          <LoadingButton
                            loading={loading}
                            disabled={!checkYoutubeUrl()}
                            size={'small'}
                            onClick={addYoutubeUrl}
                          >
                            Save Video
                          </LoadingButton>
                        </Box>
                      )}

                      {props.type !== 'youtube' && (
                        <FileUploader
                          disabled
                          hoverTitle=' '
                          multiple={props.multiple}
                          classes='uploader-dnd'
                          handleChange={(files: FileList | File) => {
                            onFileChosen(files instanceof FileList ? Array.from(files) : [files])
                          }}>
                          Drop files here or
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => openSelectFileDialog()}
                            type='button'
                          >
                            browse files
                          </Link>
                        </FileUploader>
                        // <DragDrop
                        //   uppy={uppy}
                        //   locale={{
                        //     strings: {
                        //       dropHereOr: `${props?.uppy?.locale?.strings?.dropPasteFiles ?? 'Drop files here or'
                        //         } %{browse}`,
                        //       browse: props?.uppy?.locale?.strings?.browseFiles ?? 'browse files',
                        //     },
                        //   }}
                        // />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
        </Grid>
        <input type='file' multiple={props.multiple} hidden onChange={onInputChange} ref={inputRef} accept={allowedFileTypes} />
      </Box>}
    </Box>
  )
}


const boxWrapper = () => ({
  position: 'relative',
  paddingTop: '32px',
});

const wrapper: SxProps<Theme> = {
  border: '1px solid rgb(0 0 0 / 12%)',
  borderRadius: '7px',
  position: 'unset',
  minHeight: '192px',
  // backgroundColor: '#FAFAFA',
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
  flexGrow: 1,
  zIndex: 0,
  '.uploader-dnd': {
    display: 'flex',
    width: '100%',
    height: '100%',
    outline: 'none !important',

    'input': {
      display: 'none',
    },
  }
}

const innerWrapper = () => ({
  // background: '#FAFAFA',
  border: '2px dashed rgb(0 0 0 / 12%)',
  borderRadius: '6px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  '& .uploader-container .MuiTypography-root.MuiTypography-subtitle1': {
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '0',
  }
})

const description = () => ({
  color: '#0F64E2',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  marginTop: '-5px',
})

const modal = () => ({
  position: 'absolute',
  zIndex: 1003,
  height: '100%',
  width: '100%',
  left: '0',
  top: '0',
  background: 'rgba(250, 250, 250, 0.85)',
  backdropFilter: 'blur(4px)',
  borderRadius: '6px',
  transition: 'all .3s ease',
  display: 'flex',
  flexDirection: 'column',
})

const youtube = (theme: Theme) => ({
  maxWidth: '356px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px 20px 8px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '24px 0 8px',
  }
})

const container = () => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})



const iconBox = (theme: Theme) => ({

})

const dashboard: SxProps<Theme> = {
  padding: '40px 10px',
  fontSize: '14px',
  textAlign: 'center',
  width: '100%',
  height: '100%'
}

const mediaStyles: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '3px'
}

export default Uploader;