import React, { useState } from 'react'
import { Box, Menu, MenuItem, Typography, Theme } from '@mui/material'
import { Button } from './ui-kits/button/button'

// Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate, useSearchParams, generatePath, Link as RouterLink } from 'react-router-dom'
import { PAGES } from '../router/AppRoutes'


interface PropsI {
  showDeleteModalProp : (profileId : string) => void,
  profileId: string
}
export const ProfileActionDropdown = ({showDeleteModalProp, profileId} : PropsI) => {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={dropdownStyle}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        >
        <MenuItem onClick={handleClose}> 
            <Box onClick={() => {
              navigate(
                generatePath(PAGES.PROFILE, {
                  profileId: profileId
                })
              )
            }}>
              <Typography variant={'body1'}>
                  Edit
              </Typography>
            </Box>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Box onClick={() => showDeleteModalProp(profileId)} >
            <Typography variant={'body1'}>
              Delete
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>

    
  )
}

const dropdownStyle = (theme: Theme) => ({
  borderRadius: theme.spacing(.5),
  backgroundColor: 'rgba(30, 50, 150, 0.10)',
  boxShadow: 'none',
  padding: theme.spacing(0, .5),
  minWidth: '32px',
  width: '32px',
  height: '32px',
  '& svg': {
    // width: '100%',
    // height: '100%',
    '& path': {
      fill: theme.palette.primary.main,
    }
  },
  '&:hover': {
    '& svg': {
      '& path': {
        fill: theme.palette.primary.contrastText,
      }
    },
  },
  [theme.breakpoints.down('sm')]: {
  }
})

