import { Box, CardMedia, Theme } from '@mui/material'
import ReactPlayer from 'react-player'
import { buildAssetUrl } from '../../../utilities/buildAssetUrl';

interface Props {
  content: any
  loop?: boolean
  playing?: boolean
  controls?: boolean
  muted?: boolean
  onChange?: () => void
}

export const MediaItem = ({ content, loop, controls, muted, playing }: Props) => {
  const isImage = /\.(jpg|jpeg|png|gif|svg)$/.test(content)

  if (isImage)
    return (
      <CardMedia
        component={'img'}
        alt={'bg-image'}
        src={buildAssetUrl(content)}
        sx={wrapper}
      />
    );

  return (
    <Box sx={videoWrapper}>
      <ReactPlayer url={buildAssetUrl(content)} loop={loop} controls={controls} muted={muted} playing={playing} />
    </Box>
  )
}

const wrapper = {
  height: '100%',
  width: '100%',
  objectFit: 'contain'
}

const videoWrapper = (theme: Theme) => ({
  width: '100%',
  height: '100%',
  '& > *': {
    width: '100% !important',
    height: '100% !important',
    '& > video': {
      objectFit: 'contain',
    },
  },
})
