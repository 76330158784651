import { SvgProps } from '../types/icon'

export const FilterIcon = ({ width = '20px', height = '20px' }: SvgProps) => {
  return (
    <svg width={width} height={height}>
      <path
        d='M1.66666 3.99984C1.11437 3.99984 0.666656 4.44755 0.666656 4.99984C0.666656 5.55212 1.11437 5.99984 1.66666 5.99984V3.99984ZM18.3333 5.99984C18.8856 5.99984 19.3333 5.55212 19.3333 4.99984C19.3333 4.44755 18.8856 3.99984 18.3333 3.99984V5.99984ZM1.66666 13.9998C1.11437 13.9998 0.666656 14.4476 0.666656 14.9998C0.666656 15.5521 1.11437 15.9998 1.66666 15.9998V13.9998ZM9.83332 4.99984C9.83332 6.2885 8.78865 7.33317 7.49999 7.33317V9.33317C9.89322 9.33317 11.8333 7.39307 11.8333 4.99984H9.83332ZM7.49999 7.33317C6.21133 7.33317 5.16666 6.2885 5.16666 4.99984H3.16666C3.16666 7.39307 5.10676 9.33317 7.49999 9.33317V7.33317ZM5.16666 4.99984C5.16666 3.71117 6.21133 2.6665 7.49999 2.6665V0.666504C5.10676 0.666504 3.16666 2.6066 3.16666 4.99984H5.16666ZM7.49999 2.6665C8.78865 2.6665 9.83332 3.71117 9.83332 4.99984H11.8333C11.8333 2.6066 9.89322 0.666504 7.49999 0.666504V2.6665ZM4.16666 3.99984H1.66666V5.99984H4.16666V3.99984ZM10.8333 5.99984H18.3333V3.99984H10.8333V5.99984ZM17.3333 14.9998C17.3333 16.2885 16.2887 17.3332 15 17.3332V19.3332C17.3932 19.3332 19.3333 17.3931 19.3333 14.9998H17.3333ZM15 17.3332C13.7113 17.3332 12.6667 16.2885 12.6667 14.9998H10.6667C10.6667 17.3931 12.6068 19.3332 15 19.3332V17.3332ZM12.6667 14.9998C12.6667 13.7112 13.7113 12.6665 15 12.6665V10.6665C12.6068 10.6665 10.6667 12.6066 10.6667 14.9998H12.6667ZM15 12.6665C16.2887 12.6665 17.3333 13.7112 17.3333 14.9998H19.3333C19.3333 12.6066 17.3932 10.6665 15 10.6665V12.6665ZM11.6667 13.9998H1.66666V15.9998H11.6667V13.9998Z'
        fill='#0F64E2'
      />
    </svg>
  )
}
