import { Main, Content } from '../components/common-files/common-styles'
import { Card, Divider, Theme, Typography, Box, CircularProgress, useMediaQuery, Button } from '@mui/material'
import TempWrapperNew from '../components/tempWrapperNew'
import { ApplicationList } from '../components/ApplicationList/ApplicationList'
import { useAuthQueryRequest } from '../services/api'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'
import { useList } from '../hooks/useList'
import { applicationListSelector } from '../components/ApplicationList/ApplicationListItem'
import theme from '../styles/theme'
import { useMemo } from 'react'
import { HeadCell } from '../components/not-cms/my-job-table'
import { ApiTypes } from 'recruticka-frontend-libs'
import { useSearchParams } from 'react-router-dom'
import * as React from 'react'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

const Applications = () => {
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { id: profileId } = useRecoilValue(profileState)
  const { page, perPage, updatePage, updatePerPage, orderByApplication } = useList()
  const [searchParams, setSearchParams] = useSearchParams()
  const orderBy = orderByApplication

  const headCells = useMemo<HeadCell[]>(() => {
    const [name, direction] = (searchParams.get('orderBy') || '').split(':')

    return [
      {
        id: 'title',
        label: 'Job Title'
      },
      {
        id: 'createdAt',
        label: 'Date',
        isSortable: true,
        direction: name === 'createdAt' ? (direction as ApiTypes.SortOrder) : undefined
      },
      {
        id: 'status ',
        label: 'Status'
      },
    ]
  }, [searchParams])

  const { data, isLoading } = useAuthQueryRequest(['applications', { page, perPage, orderBy }], {
    getApplications: [
      {
        take: perPage,
        skip: page * perPage,
        where: {
          profileId: {
            equals: profileId,
          },
        },
        orderBy
      },
      applicationListSelector,
    ],
    countApplications: [
      {
        where: {
          profileId: {
            equals: profileId,
          },
        },
      },
      true,
    ],
  })

  const onSortChange = (cell: string, direction: ApiTypes.SortOrder) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      orderBy: `${cell}:${direction}`
    })
  }
  const isSort = useMemo(() => {
    return headCells.find(item => item.isSortable)
  },[headCells.map(headCell => headCell)])

  const handleSort = () => {
    if (isSort){
      onSortChange(isSort.id,isSort.direction === ApiTypes.SortOrder.desc ? ApiTypes.SortOrder.asc : ApiTypes.SortOrder.desc)
    }
  }

  return (
    <TempWrapperNew>
      
        <Content sx={contentWrapper}>
          { matchSm &&
            <>
              <Typography variant={'h2'}>My Applications</Typography>
              <Button
                onClick={handleSort}
                sx={sortArrowStyle}
              >
                {
                  isSort?.direction && isSort.direction === ApiTypes.SortOrder.desc  ?
                    <SouthIcon/>
                    :
                    <NorthIcon/>

                }
              </Button>
            </>
          }
          <Card sx={tableWrapper}>
            { !matchSm &&
              <>
                <Typography variant={'h2'}>My Applications</Typography>
                <Divider sx={dividerStyle} />
              </>
            }

            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}

            <ApplicationList
              applications={data?.getApplications || []}
              onPageChange={updatePage}
              onRowsPerPageChange={updatePerPage}
              count={data?.countApplications || 0}
              page={page}
              headCells={headCells}
              onSortChange={onSortChange}
              rowsPerPage={perPage}
            />
          </Card>
        </Content>
      
    </TempWrapperNew>
  )
}

const sortArrowStyle = (theme: Theme) => ({
  marginLeft: 'auto',
  padding: 0,
  minWidth: '20px',
  minHeight: '20px',
  marginRight: 0,
  '& > *': {
    color: `${theme.palette.action.active} !important`
  },
  '& path':{
    fill: 'rgba(20, 29, 36, 0.4)'
  }
})

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]:{
    alignItems: 'start',
    '& h2':{
      marginTop: '24px',
      marginBottom: '13px'
    }
  }
})

const tableWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]:{
    marginX: '-24px',
    width: '100%',
    boxSizing: 'unset',
    paddingTop: '10px',
    borderRadius: 0,
    '& .sort-by-drop-down':{
      display: 'none'
    }
  }
})

const dividerStyle = (theme: Theme) => ({
  mx: `-${theme.spacing(4)}`,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]:{
    mx: `-${theme.spacing(3)}`,
  },
  [theme.breakpoints.down('sm')]:{
    display: 'none'
  }
})

export default Applications
