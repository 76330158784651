import { FormControl, FormControlLabel, Radio, RadioGroupProps } from '@mui/material'
import { Field, FieldAttributes, useField, useFormikContext } from 'formik'
import { RadioGroup } from 'formik-mui'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SliderField, SliderFieldProps } from '../ui-kits/slider-field/slider-field'

interface Props extends FieldAttributes<any> {
  workFromHome?: RadioGroupProps
  workFromHomeDays?: SliderFieldProps
}

export const WorkFromHome = ({ workFromHome, workFromHomeDays }: Props) => {
  const { isSubmitting } = useFormikContext()
  const [workFromHomeField, , workFromHomeHelpers] = useField<boolean>('workFromHome')
  const [workFromHomeDaysField, workFromHomeDaysMeta, workFromHomeDaysHelpers] = useField<number>('workFromHomeDays')
  const { t } = useTranslation()

  return (
    <>
      <FormControl>
        <Field
          component={RadioGroup}
          name={workFromHomeField.name}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
            workFromHomeHelpers.setValue(value === 'true')
            workFromHomeDaysHelpers.setValue(1)
          }}
          {...workFromHome}
        >
          <FormControlLabel
            checked={workFromHomeField.value}
            value={true}
            control={<Radio disabled={isSubmitting} />}
            label={t('yes')}
            disabled={isSubmitting}
          />
          <FormControlLabel
            checked={!workFromHomeField.value}
            value={false}
            control={<Radio disabled={isSubmitting} />}
            label={t('no')}
            disabled={isSubmitting}
          />
        </Field>
      </FormControl>
      {workFromHomeField.value && (
        <SliderField
          name={workFromHomeDaysField.name}
          value={workFromHomeDaysField.value || 0}
          step={1}
          max={5}
          min={1}
          aria-label='Always visible'
          valueLabelDisplay='on'
          onChange={(event, newValue) => {
            workFromHomeDaysHelpers.setValue(newValue as number)
          }}
          marks={[
            {
              value: 1,
              label: '1 day/week',
            },
            {
              value: 5,
              label: '5 days/week',
            },
          ]}
          helperText={workFromHomeDaysMeta.error}
          {...workFromHomeDays}
        />
      )}
    </>
  )
}
